import moment from 'moment';
import React, { useState } from 'react';

const ImportBox = ({
  title = '',
  text = '',
  buttonText = '',
  handleClick = () => {},
  lastSync,
  loading = false,
  toggleText = '',
  showLastSync = true,
  successBtn = false,
  disabled = false,
}) => {
  const [mergeSameAccounts, setMergeSameAccounts] = useState(true);

  return (
    <div className="flex flex-col justify-between p-6 border border-[#D0D5DD] rounded-[12px] relative">
      <div>
        <h3
          className={`text-[20px]  font-semibold mb-3 leading-tight ${successBtn ? 'text-[#78BD4F]' : 'text-indigo-500'}`}
        >
          {title}
        </h3>
        <p className="text-[#667085] leading-tight mb-2">{text}</p>
        <div className="my-4 clear-start flex gap-4">
          {toggleText && (
            <div
              className={`mt-1 form-switch min-w-11 inline-block ${successBtn ? 'form-switch-green' : 'form-switch-indigo'} `}
            >
              <input
                type="checkbox"
                id={title}
                className="sr-only"
                checked={mergeSameAccounts}
                onChange={() => setMergeSameAccounts((prev) => !prev)}
              />
              <label
                className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                htmlFor={title}
              >
                <span className="bg-gray-300" aria-hidden="true" />
                <span className="sr-only">Switch Label</span>
              </label>
            </div>
          )}
          <label htmlFor={title} className="text-sm  cursor-pointer">
            {toggleText}
          </label>
        </div>
      </div>
      <div>
        {showLastSync && (
          <div className="mb-6">
            <span className="font-medium">Last Synced: </span>{' '}
            {lastSync ? moment(lastSync).format('MMM DD, h:mm a') : 'Never'}
          </div>
        )}
        <button
          className={`shadow-button cursor-pointer inline-flex justify-center items-center h-10 w-full  text-white rounded-[5px] text-sm
       disabled:text-white disabled:cursor-not-allowed 
      ${successBtn ? 'bg-[#78BD4F] disabled:bg-[#78BD4F]' : 'bg-indigo-500 disabled:!bg-indigo-300'}`}
          type="button"
          onClick={() => handleClick(mergeSameAccounts)}
          disabled={loading || disabled}
        >
          {loading ? (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          ) : successBtn ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-h-4 min-w-4 text-white mr-2"
            >
              <path
                d="M8 16C3.58887 16 0 12.4111 0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16ZM8 1C4.14013 1 1 4.14013 1 8C1 11.8599 4.14013 15 8 15C11.8599 15 15 11.8599 15 8C15 4.14013 11.8599 1 8 1ZM6.8535 10.8535L11.8535 5.8535C12.0489 5.65812 12.0489 5.34175 11.8535 5.1465C11.6581 4.95125 11.3417 4.95113 11.1465 5.1465L6.5 9.793L4.8535 8.1465C4.65812 7.95112 4.34175 7.95112 4.1465 8.1465C3.95125 8.34188 3.95113 8.65825 4.1465 8.8535L6.1465 10.8535C6.24412 10.9511 6.37213 11 6.5 11C6.62787 11 6.75588 10.9511 6.8535 10.8535Z"
                fill="#ffffff"
              />
            </svg>
          ) : null}
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ImportBox;
