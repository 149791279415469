import React from 'react';
import { useDropzone } from 'react-dropzone';

const Step1 = ({ file, setFile, error, setError }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    maxSize: 5242880,
    onDropAccepted: (acceptedFiles) => {
      setError('');
      setFile(acceptedFiles[0]);
    },
    onDropRejected: (fileRejections) => {
      const isSizeError = fileRejections.some((rejection) =>
        rejection.errors.some((error) => error.code === 'file-too-large'),
      );
      if (isSizeError) {
        setError('File is too large. Maximum size is 5MB.');
        return;
      }
      setError('Unsupported file type. Please upload an image or a PDF.');
    },
  });

  return (
    <div className="">
      <h3 className="text-2xl mb-2 font-semibold">
        Get ready to import vendors!
      </h3>
      <ul className="list-disc ml-4 mb-8">
        <li>
          The top row of your file must contain a header title for each column
          of information
        </li>
        <li>Vendor Name is a required field</li>
        {/* <li>
          Optional fields are : Type, Account Number,Email, Website, Street, Zip
          Code, Country, State, City and Phone
        </li> */}
      </ul>
      {file && (
        <div className="border mb-2 border-indigo-500 px-4 py-2 rounded flex justify-between items-center gap-4 text-indigo-500">
          <span>{file.name}</span>
          <button
            className="ml-auto group"
            onClick={() => setFile(null)}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              className="h-5 w-5 fill-indigo-500 group-hover:fill-indigo-600"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
      )}
      <label
        htmlFor="accountFile"
        className="text-sm font-medium leading-5 text-slate-600"
      >
        Attachments - Upload a CSV or Excel file
      </label>
      <div
        {...getRootProps({
          className: `border border-dashed border-indigo-500 py-3 flex flex-col items-center justify-center rounded-xl gap-2.5 mt-2.5`,
        })}
      >
        <div className="flex flex-col items-center justify-center gap-3">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15"
              stroke="#667085"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 8L12.5 3L7.5 8"
              stroke="#667085"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 3V15"
              stroke="#667085"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-500 text-opacity-80 text-sm text-center">
            Drop here to attach or{' '}
            <span className="text-indigo-500 cursor-pointer">upload</span>
          </p>
          <p className="text-gray-600 text-xs text-center">Max size: 5MB</p>
        </div>
        <input
          {...getInputProps()}
          name="accountFile"
          id="accountFile"
          className=""
        />
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default Step1;
