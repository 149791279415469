import React from 'react';
import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import useQuickbooksSettings from 'pages/appIntegrations/quickbooksSettings/useQuickbooksSettings';
import { useSelector } from 'react-redux';
import useOnboardingSubSteps from './useOnboardingSubSteps';

const ImportAccounts = ({ updateOneStep }) => {
  const { team } = useSelector((state) => state.Team);

  const { accountLoading, syncAccounts } = useQuickbooksSettings();
  const { handleOnboardingSubStep, onBoardingSubSteps } = useOnboardingSubSteps(
    {
      subStep: 'accounts',
    },
  );

  const handleImportTransactions = async () => {
    try {
      const res = await syncAccounts();
      if (res?.data?.success) {
        handleOnboardingSubStep();
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleNext = () => {
    if (!onBoardingSubSteps?.includes('accounts')) {
      handleImportTransactions();
    } else {
      updateOneStep();
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Chart of Accounts:
      </h3>
      {/* {showSuccess && (
        <AlertBox
          type="success"
          heading="Chart of Accounts Imported Successfully"
          onActionButton={() => {
            setShowSuccess(false);
          }}
          className="mb-6"
          isCross
          text="Your Chart of Accounts data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete."
          // hideActionButton
        />
      )} */}
      <div className="grid">
        <ImportBox
          title="Import Chart of Accounts"
          text="Import all of your accounts from Quickbooks with a single click."
          buttonText={
            onBoardingSubSteps?.includes('accounts')
              ? 'Chart of Accounts Imported'
              : 'Import Chart of Accounts'
          }
          handleClick={handleImportTransactions}
          loading={accountLoading}
          lastSync={team?.app?.quickbooks?.[0]?.accountLastUpdateAt}
          toggleText="Enable this option to deactivate all default accounts and replace them with the imported chart of accounts. Note: Certain default accounts are required for COUNT's automation features and cannot be deactivated."
          successBtn={onBoardingSubSteps?.includes('accounts')}
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        <button
          type="button"
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={handleNext}
          disabled={accountLoading}
        >
          {accountLoading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Next
        </button>
      </footer>
    </div>
  );
};

export default ImportAccounts;
