import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isOnBoarding: false,
  onBoardingStep: null,
  onBoardingSubSteps: [],
  selectedItem: '',
};

const OnBoardingSlice = createSlice({
  name: 'OnBoarding',
  initialState,
  reducers: {
    setIsOnBoarding(state, action) {
      state.isOnBoarding = action.payload;
    },
    setSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },
    setOnBoardingStep(state, action) {
      state.onBoardingStep = action.payload;
    },
    setOnBoardingSubSteps(state, action) {
      state.onBoardingSubSteps = action.payload;
    },
  },
});

export const {
  setIsOnBoarding,
  setOnBoardingStep,
  setSelectedItem,
  setOnBoardingSubSteps,
} = OnBoardingSlice.actions;

export default OnBoardingSlice.reducer;
