import React, { useEffect, useRef, useState } from 'react';
import ModalBasic from 'components/ModalBasic';
import TextInputDash from 'components/TextInputDash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const FolderModal = ({
  isOpen,
  setIsOpen,
  activeFilename = null,
  handleCreteFolder,
  getFileName,
  handleRename,
  getParentFilePath,
  checkSystemDefaultFolder,
}) => {
  const inputRef = useRef();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [fileExtension, setFileExtension] = useState('');

  const getFileNameAndExtension = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return { name: filename, extension: null };
    }
    return {
      name: filename.slice(0, lastDotIndex),
      extension: filename.slice(lastDotIndex),
    };
  };

  useEffect(() => {
    if (isOpen && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
    } else if (activeFilename?.type === 'folder') {
      formikRef?.current?.setFieldValue(
        'folderName',
        getFileName(activeFilename?.name),
      );
    } else if (activeFilename?.type === 'file') {
      const file = getFileNameAndExtension(getFileName(activeFilename?.name));
      formikRef?.current?.setFieldValue('folderName', file?.name || '');
      setFileExtension(file?.extension || '');
    } else {
      formikRef?.current?.setFieldValue('folderName', '');
    }
  }, [isOpen, activeFilename]);

  const handleSubmit = async (values, formik) => {
    setLoading(true);
    try {
      if (activeFilename?.type) {
        if (
          activeFilename?.type === 'folder' &&
          checkSystemDefaultFolder(values.folderName)
        ) {
          formik.setFieldError(
            'folderName',
            'This is a system folder that already exists',
          );
          return;
        }
        await handleRename(
          activeFilename?.name,
          `${getParentFilePath(activeFilename?.name)?.join('/')}${getParentFilePath(activeFilename?.name)?.length > 0 ? '/' : ''}${values.folderName}${activeFilename?.type === 'file' ? fileExtension : '/'}`,
        );
        toast.success(
          `${activeFilename?.type === 'file' ? 'File' : 'Folder'} name updated successfully`,
        );
      } else {
        if (checkSystemDefaultFolder(values.folderName)) {
          formik.setFieldError(
            'folderName',
            'This is a system folder that already exists',
          );
          return;
        }
        await handleCreteFolder(values.folderName);
      }
      formik?.resetForm();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      title={
        activeFilename?.type
          ? `Rename ${activeFilename?.type === 'folder' ? 'Folder' : 'File'}`
          : 'Create Folder'
      }
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <Formik
        enableReinitialize={false}
        initialValues={{
          folderName: '',
        }}
        innerRef={formikRef}
        validationSchema={Yup.object({
          folderName: Yup.string()
            .test(
              'no-invalid-characters',
              `${activeFilename?.type === 'file' ? 'File' : 'Folder'} Name cannot contain any of the following characters: \\ / : * ? " < > | $`,
              (value) => !value || !/[\\/:*?"$<>|]/.test(value),
            )
            .trim()
            .required(
              `${activeFilename?.type === 'file' ? 'File' : 'Folder'} Name is required`,
            )
            .max(
              50,
              `${activeFilename?.type === 'file' ? 'File' : 'Folder'} Name must be less than or equal to 50 characters`,
            ),
        })}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form>
            <div className="w-full px-5 py-[1.875rem] flex items-center self-stretch bg-white">
              <TextInputDash
                label={
                  activeFilename?.type
                    ? `${activeFilename?.type === 'folder' ? 'Folder' : 'File'} Name`
                    : 'Folder Name'
                }
                placeholder={
                  activeFilename?.type
                    ? `Enter ${activeFilename?.type === 'folder' ? 'Folder' : 'File'} Name`
                    : 'Enter Folder Name'
                }
                value={validation.values.folderName}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                name="folderName"
                error={
                  validation.touched.folderName && validation.errors.folderName
                }
                ref={inputRef}
              />
            </div>
            <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
              <button
                className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="w-[5.625rem] h-11 
       disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed 
       flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
                disabled={loading}
                type="submit"
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {activeFilename?.type ? 'Update' : 'Create'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default FolderModal;
