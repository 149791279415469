import React, { useState } from 'react';
import {
  getTeamById,
  importQuickBookAccounts,
  importQuickBookCustomers,
  importQuickBookProducts,
  importQuickBookTaxes,
  importQuickBookTransactions,
  importQuickBookVendors,
} from 'API/backend_helper';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatDateLocally,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from 'utils/Utils';
import moment from 'moment';
import { setTeam } from '../../../redux/Team/reducer';

const useQuickbooksSettings = () => {
  const ACCOUNT = 'Accounts';
  const TRANSACTION = 'Transactions';
  const VENDOR = 'Vendors';
  const CUSTOMER = 'Customers';
  const PRODUCT = 'Products & Services';
  const TAXES = 'Taxes';
  const reauthError = 'Connecting to quickbooks requires reauthentication';

  const { team } = useSelector((state) => state.Team);
  const dispatch = useDispatch();

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [reauthIssue, setReauthIssue] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [taxesLoading, setTaxesLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [activeImport, setActiveImport] = useState('');
  const [response, setResponse] = useState(null);

  const loadTeam = async () => {
    try {
      const res = await getTeamById(team?.id);
      dispatch(setTeam(res));
    } catch (error) {
      console.error(error);
    }
  };

  const syncAccounts = async (mergeSameName = false) => {
    setActiveImport(ACCOUNT);
    setResponse(null);
    setAccountLoading(true);
    try {
      const res = await importQuickBookAccounts({
        mergeSameName,
      });
      setResponse(res?.data);
      loadTeam();
      // toast.success('Accounts have been successfully imported');
      setAccountLoading(false);
      return res;
    } catch (err) {
      setAccountLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  const syncTransactions = async (
    passCutoverDate = false,
    mergeSame = false,
  ) => {
    setActiveImport(TRANSACTION);
    setResponse(null);
    setTransactionsLoading(true);
    const cutoverDate = getFormattedDate(
      formatDateLocally(
        team?.defaults?.cutoverDate || moment().subtract(1, 'year').toDate(),
        teamDateFormat,
      ),
      teamDateFormat,
    );
    const data = passCutoverDate
      ? {
          cutoverDate,
          mergeSame,
        }
      : { mergeSame };
    try {
      const res = await importQuickBookTransactions(data);
      setResponse(res);
      loadTeam();
      // toast.success(`Transactions have been successfully imported`);
      setTransactionsLoading(false);
      return res;
    } catch (err) {
      setTransactionsLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  const syncCustomers = async (mergeSameName = false) => {
    setActiveImport(CUSTOMER);
    setCustomerLoading(true);
    setResponse(null);
    try {
      const res = await importQuickBookCustomers({ mergeSameName });
      setResponse(res?.data);
      loadTeam();
      // toast.success('Customers have been successfully imported');
      setCustomerLoading(false);
      return res;
    } catch (err) {
      setCustomerLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  const syncVendors = async (mergeSameName = false) => {
    setActiveImport(VENDOR);
    setVendorLoading(true);
    setResponse(null);
    try {
      const res = await importQuickBookVendors({ mergeSameName });
      setResponse(res?.data);
      loadTeam();
      // toast.success('Vendors have been successfully imported');
      setVendorLoading(false);
      return res;
    } catch (err) {
      setVendorLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  const syncProducts = async (mergeSameName = false) => {
    setActiveImport(PRODUCT);
    setProductLoading(true);
    setResponse(null);
    try {
      const res = await importQuickBookProducts({ mergeSameName });
      setResponse(res?.data);
      loadTeam();
      // toast.success('Products have been successfully imported');
      setProductLoading(false);
      return res;
    } catch (err) {
      setProductLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  const syncTaxes = async (mergeSameName = false) => {
    setActiveImport(TAXES);
    setTaxesLoading(true);
    setResponse(null);
    try {
      const res = await importQuickBookTaxes({ mergeSameName });
      setResponse(res?.data);
      loadTeam();
      // toast.success('Vendors have been successfully imported');
      setTaxesLoading(false);
      return res;
    } catch (err) {
      setTaxesLoading(false);
      if (err === reauthError) {
        setReauthIssue(true);
      }
      console.log('err', err);
    }
  };

  return {
    ACCOUNT,
    TRANSACTION,
    VENDOR,
    CUSTOMER,
    PRODUCT,
    TAXES,
    reauthIssue,
    setReauthIssue,
    transactionsLoading,
    accountLoading,
    customerLoading,
    vendorLoading,
    productLoading,
    taxesLoading,
    activeImport,
    setActiveImport,
    response,
    setResponse,
    syncAccounts,
    syncTransactions,
    syncCustomers,
    syncVendors,
    syncProducts,
    syncTaxes,
  };
};

export default useQuickbooksSettings;
