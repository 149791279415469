import CircularProgressBar from 'components/CircularProgressBar';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ON_BOARDING_STEPS } from 'utils/Utils';

const OnBoardingSteps = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isOnBoarding, selectedItem, onBoardingStep } = useSelector(
    (state) => state.OnBoarding,
  );

  return isOnBoarding ? (
    <div className="absolute top-1/2 left-16 md:left-1/2 md:-translate-x-2/3 xl:-translate-x-1/2 -translate-y-1/2 flex items-center gap-4">
      <h3 className="text-[20px] font-medium text-[#101828] md:block hidden">
        Onboarding Steps
      </h3>
      {selectedItem && onBoardingStep > 1 && (
        <CircularProgressBar
          content={`${
            onBoardingStep - 1 > ON_BOARDING_STEPS?.[selectedItem]?.length
              ? ON_BOARDING_STEPS?.[selectedItem]?.length
              : onBoardingStep - 1
          }/${ON_BOARDING_STEPS?.[selectedItem]?.length}`}
          percentage={
            ((onBoardingStep - 1 > ON_BOARDING_STEPS?.[selectedItem]?.length
              ? ON_BOARDING_STEPS?.[selectedItem]?.length
              : onBoardingStep - 1) /
              ON_BOARDING_STEPS?.[selectedItem]?.length) *
            100
          }
        />
      )}
      {!location.pathname?.includes('/onboarding') && (
        <button
          className="h-[30px] border border-indigo-500 text-indigo-500 text-sm font-medium rounded-[5px] px-4"
          type="button"
          onClick={() => navigate('/onboarding')}
        >
          Go to Launchpad
        </button>
      )}
    </div>
  ) : null;
};

export default OnBoardingSteps;
