import useDropdown from 'hooks/useDropdown';
import { StatusDropdownArrow } from 'pages/inbox/Svg';
import React from 'react';
import Transition from 'utils/Transition';

const ColorDropdown = ({
  statusOptions,
  selected,
  setSelected,
  isTableDropdown = false,
  bottomOfTable = false,
  ids = [],
}) => {
  const {
    trigger,
    dropdown,
    dropdownOpen,
    setDropdownOpen,
    fixedDropdownPositions,
  } = useDropdown(isTableDropdown, bottomOfTable, ids);

  return (
    <div
      className="w-[130px] relative flex py-1 px-2 justify-center items-center gap-2.5 rounded-[22px] text-[14px] leading-6"
      onClick={(e) => {
        e.stopPropagation();
        setDropdownOpen((prev) => !prev);
      }}
      ref={trigger}
      style={{
        backgroundColor: statusOptions?.find((option) => option.id === selected)
          ?.background,
        color: statusOptions?.find((option) => option.id === selected)
          ?.textColor,
      }}
      role="button"
      tabIndex="0"
    >
      <div className="flex items-center gap-2 justify-between w-full px-2">
        <p>{statusOptions?.find((option) => option.id === selected)?.name}</p>
        <StatusDropdownArrow
          fill={
            statusOptions?.find((option) => option.id === selected)?.textColor
          }
          rotate={dropdownOpen}
        />
      </div>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`
            ${
              bottomOfTable
                ? 'fixed !w-fit min-w-40'
                : 'absolute top-8 left-0 w-full z-60'
            }
              bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-visible mt-1`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        {dropdownOpen && (
          <div
            className="divide-y pointer-events-auto divide-slate-200 dark:divide-slate-700  "
            ref={dropdown}
          >
            <div className="max-h-48 overflow-auto scrollbar rounded-[22px]">
              {statusOptions.map((option, index) => (
                <button
                  type="button"
                  key={index}
                  className="flex items-center justify-between w-[80%] rounded-[22px] px-2 my-2 mx-auto cursor-pointer"
                  style={{
                    backgroundColor: option.background,
                    color: option.textColor,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected(option?.id);
                    setDropdownOpen(false);
                  }}
                >
                  {option?.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default ColorDropdown;
