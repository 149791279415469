import { useDispatch, useSelector } from 'react-redux';
import { TEAM_ID } from 'utils/Utils';
import { updateTeamById } from 'API/backend_helper';
import { setOnBoardingSubSteps } from '../../redux/onBoarding/reducer';

const useOnboardingSubSteps = ({ subStep = '' }) => {
  const dispatch = useDispatch();

  const { onBoardingSubSteps } = useSelector((state) => state.OnBoarding);
  const { team } = useSelector((state) => state.Team);

  const handleOnboardingSubStep = async (step = subStep) => {
    try {
      let value = [];
      if (onBoardingSubSteps?.length > 0) {
        value = [...onBoardingSubSteps?.filter((v) => v !== step)];
      }
      value = [...value, step];
      dispatch(setOnBoardingSubSteps(value));
      const teamId = team?.id || localStorage.getItem(TEAM_ID);
      await updateTeamById(teamId, {
        onBoardingSubSteps: value,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
  return {
    handleOnboardingSubStep,
    onBoardingSubSteps,
  };
};

export default useOnboardingSubSteps;
