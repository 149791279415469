import React, { useEffect, useState } from 'react';
import {
  getUser,
  teamAcceptfirmInviteBusinessOwner,
  teamDeclinefirmInviteBusinessOwner,
} from 'API/backend_helper';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { OWNER } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import { setLocalUser } from '../../../redux/User/reducer';

const FirmInvitesTable = ({
  onChangeInvite,
  setActiveTab,
  manageWorkspaces,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredInvites, setFilteredInvites] = useState([]);
  const { localUser } = useSelector((state) => state.User);

  const [inviteLoading, setInviteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);

  const [selectedTeam, setSelectedTeams] = useState({});
  const [teamLoader, setTeamLoader] = useState(null);
  const [myWorkspaces, setMyWorkspaces] = useState([]);

  useEffect(() => {
    if (localUser?.teams?.length > 0) {
      const myTeams = localUser?.teams?.filter(
        (team) => team?.credentials?.role === OWNER,
      );
      setMyWorkspaces(myTeams);
    }
  }, [localUser?.teams]);

  const onSelectTeam = (id, teamId) => {
    const tempSelectedTeams = selectedTeam
      ? JSON.parse(JSON.stringify(selectedTeam))
      : {};
    let tempTeams = tempSelectedTeams?.[id] || [];
    if (tempTeams?.includes(teamId)) {
      tempTeams = tempTeams?.filter((id) => id !== teamId);
    } else {
      tempTeams?.push(teamId);
    }
    tempSelectedTeams[id] = tempTeams;
    setSelectedTeams(tempSelectedTeams);
  };
  const handleOnChangeInvite = async (value, accountingFirmId) => {
    if (value === 'accept') {
      setInviteLoading(accountingFirmId);
    }
    if (value === 'decline') {
      setDeclineLoading(accountingFirmId);
    }
    await onChangeInvite(value, accountingFirmId, true);
    setInviteLoading(false);
    setDeclineLoading(false);
  };

  const getUserApi = async () => {
    try {
      const response = await getUser();
      dispatch(setLocalUser(response));
    } catch (e) {
      console.log('error', e);
    }
  };

  const declineFirmOwnerAccess = async (id) => {
    try {
      setTeamLoader(`decline${id}`);
      await teamDeclinefirmInviteBusinessOwner(id);
      await getUserApi();
      setTeamLoader(null);
      if (filteredInvites?.length < 1) {
        setActiveTab(manageWorkspaces);
      }
    } catch (e) {
      setTeamLoader(null);
      console.log('error', e);
    }
  };

  const acceptFirmOwnerAccess = async (id, firmId) => {
    try {
      setTeamLoader(`accept${id}`);
      await teamAcceptfirmInviteBusinessOwner(id, {
        teams: selectedTeam?.[firmId],
      });
      getUserApi();
      setTeamLoader(null);
      setActiveTab(manageWorkspaces);
    } catch (e) {
      setTeamLoader(null);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (localUser?.firmInvites?.length > 0) {
      setFilteredInvites(
        localUser?.firmInvites?.filter(
          (invite) => invite.status === 'active',
        ) || [],
      );
    } else if (localUser?.firmInvites?.length === 0) {
      setFilteredInvites([]);
    }
  }, [localUser?.firmInvites]);

  return (
    <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm   mb-12">
      <div className=" overflow-x-auto">
        <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
          {/* Table header */}
          <thead className="text-sm text-[#667085] dark:text-slate-400 shadow-md border-none ">
            <tr>
              <th className="pl-8 pr-2 text-left last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium">Workspace Name</div>
              </th>
              <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Workspace Type</div>
              </th>
              <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Access Type</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Actions</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
            {/* Row */}
            {filteredInvites?.length > 0 &&
              filteredInvites?.map?.((invite, index) => (
                <tr key={index} className="border-b border-slate-300">
                  <td className="pr-2  last:pr-5 py-3 whitespace-nowrap text-center">
                    <div className="flex items-center pl-8">
                      <div className="w-7 h-7 shrink-0 mr-2 sm:mr-3">
                        {invite?.accountingFirm?.logoUrl ? (
                          <img
                            src={invite?.accountingFirm?.logoUrl}
                            alt={`${invite?.accountingFirm?.name} logo`}
                            className="w-7 h-7 rounded-full"
                          />
                        ) : (
                          <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A] dark:bg-slate-700">
                            <span className="uppercase text-[#7425F4] text-xs">
                              {invite?.accountingFirm?.name
                                ?.split(' ')?.[0]
                                ?.charAt(0)}
                              {invite?.accountingFirm?.name
                                ?.split(' ')?.[1]
                                ?.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="text-sm text-[#667085] dark:text-slate-100">
                        {invite?.accountingFirm?.name}
                      </div>
                    </div>
                  </td>
                  <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                    <div className="text-sm text-[#667085]  capitalize">
                      Firm
                    </div>
                  </td>
                  <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                    <div className="text-sm text-[#667085]  capitalize">
                      Member
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                    <div className="flex gap-4">
                      <button
                        onClick={() =>
                          handleOnChangeInvite(
                            'accept',
                            invite?.accountingFirmId,
                          )
                        }
                        className="btn h-10 border-[#78BD4F] bg-[#78BD4F0D] text-[#78BD4F] text-sm font-normal w-[100px] disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          inviteLoading === invite?.accountingFirmId ||
                          declineLoading === invite?.accountingFirmId
                        }
                      >
                        {inviteLoading === invite?.accountingFirmId ? (
                          <Loader height="h-5 w-5" color="#78BD4F" />
                        ) : (
                          'Accept Invite'
                        )}
                      </button>
                      <button
                        onClick={() =>
                          handleOnChangeInvite(
                            'decline',
                            invite?.accountingFirmId,
                          )
                        }
                        className="btn h-10 border-[#FF4B4B] bg-[#FF4B4B0D] text-[#FF4B4B] text-sm font-normal px-2.5 w-[68px] disabled:cursor-not-allowed"
                        type="button"
                        disabled={
                          inviteLoading === invite?.accountingFirmId ||
                          declineLoading === invite?.accountingFirmId
                        }
                      >
                        {declineLoading === invite?.accountingFirmId ? (
                          <Loader height="h-5 w-5" color="#FF4B4B" />
                        ) : (
                          'Decline'
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {filteredInvites?.length === 0 && (
        <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
          No Firm Invites Found
        </h6>
      )}
      {localUser?.ownerInvitaions?.length > 0 && (
        <h1 className="text-[24px] text-slate-600 font-medium mb-0">
          Firm Invitations
        </h1>
      )}
      {localUser?.ownerInvitaions?.length > 0 && (
        <div>
          {localUser?.ownerInvitaions?.map((invite) => (
            <>
              <div className="mt-3">
                <span className="font-medium">
                  {invite?.accountingFirm?.name}{' '}
                </span>{' '}
                wants to access your workspace(s)
              </div>
              {myWorkspaces?.length > 0 && (
                <p className="mt-2">
                  {' '}
                  Select Workspace(s) to give them access:{' '}
                </p>
              )}
              {myWorkspaces?.length > 0 ? (
                <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                  {/* Table header */}
                  <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                    <tr>
                      <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th>
                      <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                        <div className=" text-left font-medium">Workspace</div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                        <div className=" text-left font-medium">Type</div>
                      </th>
                      <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                        <div className=" text-left font-medium">
                          Access Type
                        </div>
                      </th>
                      <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                        <div className=" text-left font-medium">
                          Employee Seats
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                    {/* Row */}
                    {myWorkspaces?.map?.((team) => (
                      <tr className="border-b border-slate-300" key={team?.id}>
                        <td className="pl-5">
                          <div className="flex h-[60px] items-center">
                            <label className="inline-flex ">
                              <span className="sr-only">Select</span>
                              <input
                                className="form-checkbox cursor-pointer !h-4 !w-4"
                                type="checkbox"
                                onChange={() =>
                                  onSelectTeam(
                                    invite?.accountingFirmId,
                                    team?.id,
                                  )
                                }
                                checked={selectedTeam?.[
                                  invite?.accountingFirmId
                                ]?.includes(team?.id)}
                              />
                            </label>
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-7 h-7 shrink-0 mr-2.5 inline-flex justify-center items-center">
                              {team?.logoUrl ? (
                                <img
                                  src={team?.logoUrl}
                                  alt={`${team.name} logo`}
                                  className="max-h-full max-w-full"
                                />
                              ) : (
                                <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
                                  <div className="uppercase text-[#7425F4] text-xs">
                                    {team?.name?.split(' ')?.[0]?.charAt(0)}
                                    {team?.name?.split(' ')?.[1]?.charAt(0)}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="text-sm text-[#667085] leading-tight mr-1">
                              {team?.name?.length > 60 ? (
                                <Tooltip
                                  content={
                                    team?.name?.length > 50
                                      ? (() => {
                                          const segmentLength = 75;
                                          const segments = [];
                                          for (
                                            let i = 0;
                                            i < team?.name?.length;
                                            i += segmentLength
                                          ) {
                                            segments.push(
                                              team?.name?.substring(
                                                i,
                                                i + segmentLength,
                                              ),
                                            );
                                          }
                                          return segments.map(
                                            (segment, index) => (
                                              <p key={index}>{segment}</p>
                                            ),
                                          );
                                        })()
                                      : team?.name
                                  }
                                  contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                                    team?.name?.length > 50 ? '' : ''
                                  }`}
                                >
                                  <span>{`${team?.name?.slice(0, 60)}...`}</span>
                                </Tooltip>
                              ) : (
                                <span>{team?.name}</span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-sm text-[#667085] ">
                            {team?.subType && team?.subType !== "I'm not sure"
                              ? team?.subType
                              : team?.type}
                          </div>
                        </td>

                        <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-sm text-[#667085]   capitalize">
                            {team?.id && team?.isOnComplimentary ? (
                              'Complimentary'
                            ) : (
                              <span className="capitalize">
                                {team?.currentSubscription?.name} (
                                {team?.subStatus})
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-sm text-[#667085]   capitalize">
                            {team?.employeeSeat || 0}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="mt-8">
                  <div className="flex flex-col items-center justify-center">
                    <button
                      className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white"
                      aria-controls="basic-modal"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('/workspace/new');
                      }}
                      type="button"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Create Workspace
                    </button>
                    <p className="text-slate-400 mt-2">
                      Set up your own workspace to grant access to the inviting
                      firm
                    </p>
                  </div>
                </div>
              )}
              {myWorkspaces?.length > 0 && (
                <div className="flex justify-end gap-4 mt-6">
                  <button
                    type="button"
                    onClick={() => declineFirmOwnerAccess(invite?.id)}
                    disabled={
                      teamLoader === `decline${invite?.id}` ||
                      teamLoader === `accept${invite?.id}`
                    }
                    className="h-10 w-20 border border-indigo-500 text-indigo-500 rounded-[5px] inline-flex justify-center items-center disabled:cursor-not-allowed"
                  >
                    {teamLoader === `decline${invite?.id}` ? (
                      <Loader height="h-5" />
                    ) : (
                      'Decline'
                    )}
                  </button>
                  <button
                    type="button"
                    className="h-10 w-24 bg-indigo-500 border border-indigo-500 text-white rounded-[5px] inline-flex justify-center items-center disabled:cursor-not-allowed"
                    onClick={() =>
                      acceptFirmOwnerAccess(
                        invite?.id,
                        invite?.accountingFirmId,
                      )
                    }
                    disabled={
                      !(selectedTeam?.[invite?.accountingFirmId]?.length > 0) ||
                      teamLoader === `decline${invite?.id}` ||
                      teamLoader === `accept${invite?.id}`
                    }
                  >
                    {teamLoader === `accept${invite?.id}` ? (
                      <Loader height="h-5" color="#fff" />
                    ) : (
                      'Add Firm'
                    )}
                  </button>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default FirmInvitesTable;
