import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FirmIcon from 'pages/accountantFirm/FirmIcon';
import Transition from '../utils/Transition';
import { setAccessType, setLocalUser } from '../redux/User/reducer';
import { setSubscription } from '../redux/Subscription/reducer';
import {
  ADMIN,
  FIRM_ID,
  IS_ACCOUNTANT,
  IS_PERSON,
  TEAM_ID,
} from '../utils/Utils';
import { setTeam } from '../redux/Team/reducer';

function DropdownProfile({ align }) {
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;
  const TEAMId = localStorage.getItem(TEAM_ID);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localUser } = useSelector((state) => state.User);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div
      className={`relative inline-flex  ${dropdownOpen ? 'shadow-2xl' : ''}`}
    >
      <button
        ref={trigger}
        type="button"
        className={`inline-flex justify-between  min-w-44  items-center group shadow-md border border-slate-200 ${
          dropdownOpen
            ? 'rounded-t-[21px] border-b-transparent '
            : 'rounded-[27px]'
        }  p-1  pr-4`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex">
          {localUser?.avatarUrl ? (
            <img
              className="w-[42px] h-[42px] rounded-full"
              src={localUser?.avatarUrl}
              width="42"
              height="42"
              alt="User upload"
            />
          ) : (
            <div className="w-8 h-8 rounded-full text-sm bg-slate-400 flex justify-center items-center text-white font-semibold">
              {localUser?.firstName?.charAt(0)}
              {localUser?.lastName?.charAt(0)}
            </div>
          )}
          <div className="flex items-center truncate">
            <span className="truncate ml-2 text-sm font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200">
              {`${localUser?.firstName} ${localUser?.lastName}`.length > 30
                ? `${`${localUser?.firstName || ''} ${localUser?.lastName || ''}`.substring(
                    0,
                    30,
                  )}...`
                : `${localUser?.firstName || ''} ${localUser?.lastName || ''}`}
            </span>
          </div>
        </div>
        <svg
          className={`w-3 h-3 shrink-0 ml-4  fill-current text-slate-400  group-hover:text-[#101828] ${
            dropdownOpen ? 'rotate-180 translate-y-[2px]' : ''
          }`}
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 w-full bg-white dark:bg-slate-800 border rounded-tr-none rounded-tl-none border-t-0 pt-2 rounded-b-[27px] border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        enter="transition ease-out  "
        enterStart=""
        enterEnd=" "
        leave="transition ease-out "
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
            {!window?.location?.href?.includes('/accountant') &&
              !window?.location?.href?.includes('/manage-workspaces') &&
              TEAMId && (
                <Link
                  to={IsPerson ? '/person/account-details' : '/account-details'}
                >
                  <button
                    className="inline-flex items-center gap-2.5 mt-2 text-[#667085CC] hover:text-slate-600"
                    type="button"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.3233 7.86568V14.1357C19.3233 15.1623 18.7733 16.1157 17.8841 16.6382L12.4391 19.7823C11.55 20.2957 10.45 20.2957 9.55165 19.7823L4.10665 16.6382C3.21748 16.1249 2.66748 15.1715 2.66748 14.1357V7.86568C2.66748 6.83902 3.21748 5.88564 4.10665 5.36314L9.55165 2.21898C10.4408 1.70565 11.5408 1.70565 12.4391 2.21898L17.8841 5.36314C18.7733 5.88564 19.3233 6.82985 19.3233 7.86568Z"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.0001 10.0841C12.1797 10.0841 13.1359 9.12788 13.1359 7.94829C13.1359 6.7687 12.1797 5.8125 11.0001 5.8125C9.8205 5.8125 8.86426 6.7687 8.86426 7.94829C8.86426 9.12788 9.8205 10.0841 11.0001 10.0841Z"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6667 15.2734C14.6667 13.6234 13.0259 12.2852 11 12.2852C8.97421 12.2852 7.33337 13.6234 7.33337 15.2734"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-sm font-medium ">
                      Account Details
                    </span>
                  </button>
                </Link>
              )}
            {localUser?.accountingFirms?.length > 0 &&
              (IsAccountant ? (
                <button
                  className="inline-flex items-center gap-2.5 mt-2 text-[#667085CC] hover:text-slate-600"
                  type="button"
                  onClick={() => {
                    localStorage.setItem(IS_ACCOUNTANT, 'false');
                    navigate('/manage-workspaces');
                  }}
                >
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-[#667085] group-hover:text-slate-800 w-[22px]"
                  >
                    <path
                      d="M14.0312 0.5625H0.96875C0.811278 0.5625 0.660255 0.625056 0.548905 0.736405C0.437556 0.847755 0.375 0.998778 0.375 1.15625V12.4375C0.375 12.7524 0.500111 13.0545 0.722811 13.2772C0.94551 13.4999 1.24756 13.625 1.5625 13.625H4.53125C4.84619 13.625 5.14824 13.4999 5.37094 13.2772C5.59364 13.0545 5.71875 12.7524 5.71875 12.4375V8.875H9.28125V10.0625C9.28125 10.3774 9.40636 10.6795 9.62906 10.9022C9.85176 11.1249 10.1538 11.25 10.4688 11.25H13.4375C13.7524 11.25 14.0545 11.1249 14.2772 10.9022C14.4999 10.6795 14.625 10.3774 14.625 10.0625V1.15625C14.625 0.998778 14.5624 0.847755 14.4511 0.736405C14.3397 0.625056 14.1887 0.5625 14.0312 0.5625ZM4.53125 12.4375H1.5625V6.5H4.53125V12.4375ZM4.53125 5.3125H1.5625V1.75H4.53125V5.3125ZM9.28125 7.6875H5.71875V1.75H9.28125V7.6875ZM13.4375 10.0625H10.4688V6.5H13.4375V10.0625ZM13.4375 5.3125H10.4688V1.75H13.4375V5.3125Z"
                      fill="#667085"
                      className="fill-current"
                    />
                  </svg>
                  <span className="text-sm font-medium ">My Workspaces</span>
                </button>
              ) : (
                <button
                  className="inline-flex items-center gap-2.5 mt-2 text-[#667085CC] hover:text-slate-600"
                  type="button"
                  onClick={() => {
                    localStorage.setItem(IS_ACCOUNTANT, 'true');
                    if (!localStorage.getItem(FIRM_ID)) {
                      localStorage.setItem(
                        FIRM_ID,
                        localUser?.accountingFirms?.[0]?.id,
                      );
                      dispatch(setAccessType(ADMIN));
                    }
                    navigate('/accountant');
                  }}
                >
                  <FirmIcon className="h-[22px] w-[22px]" />
                  <span className="text-sm font-medium ">My Firm</span>
                </button>
              ))}
          </div>

          <button
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
              const valueToPreserve = localStorage.getItem('twoFactorToken'); // Save the value

              localStorage.clear(); // Clear all other keys

              if (valueToPreserve) {
                localStorage.setItem('twoFactorToken', valueToPreserve); // Restore the preserved key
              }
              dispatch(setLocalUser(null));
              dispatch(setSubscription(''));
              dispatch(setTeam(''));
              if (IsPerson) {
                navigate('/person/signin');
              } else {
                navigate('/signin');
              }
            }}
            className="inline-flex px-3 mb-2 items-center gap-2.5 text-[#667085CC] hover:text-slate-600"
            type="button"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.15833 6.9307C8.44249 3.6307 10.1383 2.2832 13.8508 2.2832H13.97C18.0675 2.2832 19.7083 3.92404 19.7083 8.02154V13.9982C19.7083 18.0957 18.0675 19.7365 13.97 19.7365H13.8508C10.1658 19.7365 8.46999 18.4074 8.16749 15.1624"
                stroke="#FF6868"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.83337 11H13.64"
                stroke="#FF6868"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.5958 7.92969L14.6667 11.0005L11.5958 14.0714"
                stroke="#FF6868"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="text-sm font-medium ">Sign Out</span>
          </button>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownProfile;
