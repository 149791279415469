import React from 'react';
import ModalBasic from 'components/ModalBasic';
import { Expense, Income } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  CreateBillIcon,
  CreateInvoiceIcon,
  ManagePeopleIcon,
  ManageVendorsIcon,
  MangeCustomersIcon,
  NewTaskIcon,
  ProductServiceIcon,
  RecordExpenseIcon,
  RecordIncomeIcon,
  TimeEntryIcon,
} from './Icons';

const QuickLinksModal = ({
  modalOpen,
  setModalOpen,
  LinkBox,
  setTimeTrackingModalOpen,
  setAddTransactionType,
  setAddTransactionModalOpen,
  setAddTaskModalOpen,
}) => {
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <ModalBasic
      title="Quick Links"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      width="!w-[1100px]"
    >
      <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 p-6">
        {checkPermission(
          PermissionKeys.MANAGE_INVOICES,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() =>
              navigate('/invoices?tab=invoices&new=invoice&from=dashboard')
            }
            name="Create Invoice"
            Icon={CreateInvoiceIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.MANAGE_BILLS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => navigate('/bills?id=new')}
            name="Create Bill"
            Icon={CreateBillIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.MANAGE_PROJECTS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => {
              setModalOpen(false);
              setTimeTrackingModalOpen(true);
            }}
            name="Add Time Entry"
            Icon={TimeEntryIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.MANAGE_TRANSACTIONS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => {
              setModalOpen(false);
              setAddTransactionType(Expense);
              setAddTransactionModalOpen(true);
            }}
            name="Record Expense"
            Icon={RecordExpenseIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.MANAGE_TRANSACTIONS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => {
              setModalOpen(false);
              setAddTransactionType(Income);
              setAddTransactionModalOpen(true);
            }}
            name="Record Income"
            Icon={RecordIncomeIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.MANAGE_TASKS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => {
              setModalOpen(false);
              setAddTaskModalOpen(true);
            }}
            name="New Task"
            Icon={NewTaskIcon}
          />
        )}

        {checkPermission(
          PermissionKeys.VIEW_PRODUCTS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => navigate('/products')}
            name="Product and Services"
            Icon={ProductServiceIcon}
          />
        )}

        {checkPermission(
          PermissionKeys.VIEW_VENDORS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => navigate('/vendors')}
            name="Manage Vendors"
            Icon={ManageVendorsIcon}
          />
        )}
        {checkPermission(
          PermissionKeys.VIEW_CUSTOMERS,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => navigate('/customers')}
            name="Manage Customers"
            Icon={MangeCustomersIcon}
          />
        )}

        {checkPermission(
          PermissionKeys.VIEW_PEOPLE,
          accessType,
          permissions,
        ) && (
          <LinkBox
            handleClick={() => navigate('/people')}
            name="Manage People"
            Icon={ManagePeopleIcon}
          />
        )}

        {/* <LinkBox name="Upload Receipt" Icon={UploadReceiptIcon} /> */}
      </div>
    </ModalBasic>
  );
};

export default QuickLinksModal;
