import React from 'react';
import AppLayout from 'components/AppLayout';
import AttachmentModal from 'pages/inbox/AttachmentModal';
import useFiles from './useFiles';
import AddButtonDropdown from './AddButtonDropdown';
import FolderModal from './FolderModal';
import FileUploadModal from './FileUploadModal';
import FilesTable from './FilesTable';
import DeleteModal from './DeleteModal';
import Breadcrumb from './Breadcrumb';

const Files = () => {
  const {
    folders,
    folderModalOpen,
    setFolderModalOpen,
    handleCreteFolder,
    fileModalOpen,
    setFileModalOpen,
    handleFileUpload,
    getFileName,
    handleFileDelete,
    deleteModalOpen,
    setDeleteModalOpen,
    activeDeleteItem,
    setActiveDeleteItem,
    parentFolder,
    handleFolderChange,
    mainLoading,
    attachmentModalOpen,
    setAttachmentModalOpen,
    activeAttachment,
    setActiveAttachment,
    setActiveFilename,
    activeFilename,
    handleRename,
    getParentFilePath,
    checkSystemDefaultFolder,
  } = useFiles();

  return (
    <>
      <FolderModal
        isOpen={folderModalOpen}
        setIsOpen={setFolderModalOpen}
        handleCreteFolder={handleCreteFolder}
        activeFilename={activeFilename}
        getFileName={getFileName}
        handleRename={handleRename}
        getParentFilePath={getParentFilePath}
        checkSystemDefaultFolder={checkSystemDefaultFolder}
      />
      <FileUploadModal
        isOpen={fileModalOpen}
        setIsOpen={setFileModalOpen}
        handleFileUpload={handleFileUpload}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleFileDelete={handleFileDelete}
        activeDeleteItem={activeDeleteItem}
      />
      <AttachmentModal
        isOpen={attachmentModalOpen}
        setIsOpen={setAttachmentModalOpen}
        receipt={activeAttachment !== null ? activeAttachment?.fileUrl : null}
        isExpenseReporting={activeAttachment !== null}
        type={activeAttachment !== null ? activeAttachment?.fileType : ''}
        title={activeAttachment !== null ? activeAttachment?.fileName : ''}
        setActiveAttachment={setActiveAttachment}
      />
      <AppLayout pageId="filesContainer">
        <main className="relative grow ">
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex flex-row flex-wrap gap-2 sm:gap-0 justify-between mb-6">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                All Documents
              </h1>
              <AddButtonDropdown
                setFolderModalOpen={setFolderModalOpen}
                setFileModalOpen={setFileModalOpen}
                setActiveFilename={setActiveFilename}
                checkSystemDefaultFolder={checkSystemDefaultFolder}
              />
            </div>
            <Breadcrumb
              parentFolder={parentFolder}
              handleFolderChange={handleFolderChange}
            />
            {mainLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Please wait...
                </p>
              </div>
            ) : (
              <FilesTable
                files={folders?.files}
                folders={folders?.folders}
                azureSasToken={folders?.azureSasToken}
                getFileName={getFileName}
                setDeleteModalOpen={setDeleteModalOpen}
                setActiveDeleteItem={setActiveDeleteItem}
                parentFolder={parentFolder}
                handleFolderChange={handleFolderChange}
                setAttachmentModalOpen={setAttachmentModalOpen}
                setActiveAttachment={setActiveAttachment}
                setActiveFilename={setActiveFilename}
                setFolderModalOpen={setFolderModalOpen}
                checkSystemDefaultFolder={checkSystemDefaultFolder}
              />
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Files;
