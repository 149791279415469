/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  createExpenseReporter,
  createSubPaymentMethod,
  fetchSubscriptions,
  updatePeople,
} from 'API/backend_helper';
import AlertBox from 'components/AlertBox';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tooltip from '../../../components/Tooltip';
import {
  DevEnv,
  formatNumber,
  getCurrencySymbol,
  getCurrencySymbolFromIso,
  getStringSegments,
  TEAM_CURRENCY,
  trimText,
} from '../../../utils/Utils';
import FormBox from '../FormBox';
import ExpenseReportingAccountsModal from '../ExpenseRepAccountsModal';
import SubscriptionWarningModal from './SubscriptionWarningModal';

const OverviewTab = ({
  people,
  allAccounts,
  peopleSelectedExpAcc,
  setPeopleSelectExpAcc,
  expenseReporterAccounts,
  setExpenseReporterAccounts,
  notAssignedAccounts,
  setNotAssignedAccounts,
  personId,
  loadData,
}) => {
  const emailBoxRef = useRef();
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const { subscription } = useSelector((state) => state.Subscription);
  const { accessType, permissions } = useSelector((state) => state.User);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [role, setRole] = useState(people?.role ? [...people?.role] : []);
  const [expenseModal, setExpenseModal] = useState(false);
  const [localSelectedAccounts, setLocalSelectedAccounts] = useState([]);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [paymentMethodLoader, setPaymentMehtodLoader] = useState(false);
  const [triedToActiveRole, setTriedToActive] = useState(false);
  const [emailLength, setEmailLength] = useState(20);
  const [subscriptions, setSubscriptions] = useState(null);
  const [loadingSubsription, setLoadingSubscription] = useState(false);
  const [roleLoader, setRoleLoader] = useState(false);
  const [lastRoleState, setLastRoleState] = useState([]);

  useEffect(() => {
    if (people?.role) {
      setRole([...people?.role]);
    }
  }, [people]);

  const handleResize = () => {
    setEmailLength(parseInt(emailBoxRef?.current?.clientWidth / 10));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [emailBoxRef?.current]);

  const loadSubscriptions = async () => {
    try {
      setLoadingSubscription(true);
      const res = await fetchSubscriptions();
      setSubscriptions(res?.data?.perSeat);
      setLoadingSubscription(false);
    } catch (err) {
      setLoadingSubscription(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const onUpdate = async (
    _role = role,
    _peopleSelectedExpAcc = peopleSelectedExpAcc,
  ) => {
    try {
      setRoleLoader(true);
      const temp = {};
      temp.role = _role;
      await updatePeople(temp, people?.id);
      if (_role?.includes('expense reporting')) {
        await createExpenseReporter(people?.id, {
          accountIDs: _peopleSelectedExpAcc,
        });
        setLocalSelectedAccounts([]);
        // setPeopleSelectExpAcc([]);
      }
      setRoleLoader(false);
      setSubscriptionModalOpen(false);
      toast.success('Person updated successfully');
      loadData();
    } catch (e) {
      setRole(lastRoleState);
      setRoleLoader(false);
      console.log('error', e);
    }
  };

  const onRoleChange = (val) => {
    if (!subscription?.default_payment_method && !team?.hasPaymentMethod) {
      setTriedToActive(true);
    } else {
      setLastRoleState(role);
      const tempRole = [...role];
      if (tempRole?.includes(val)) {
        const filter = tempRole?.filter((r) => r !== val);
        setRole(filter);
        onUpdate(filter);
      } else {
        tempRole?.push(val);
        setRole(tempRole);
        if (val === 'expense reporting' && !expenseModal) {
          setExpenseModal(true);
        } else {
          if (
            (people?.seatStatus !== 'confirmed' &&
              people?.seatStatus !== 'confiriming') ||
            role?.length < 1
          ) {
            setSubscriptionModalOpen(true);
          } else {
            onUpdate(tempRole);
          }
        }
      }
    }
  };

  const getAccountNameById = (id) => {
    const filter = allAccounts?.filter?.((acc) => acc?.id === id);
    if (filter?.length > 0) {
      return filter?.[0]?.name;
    }
    return '';
  };

  const onConnectPaymentMethod = async () => {
    try {
      setPaymentMehtodLoader(true);

      const redirectUrl = `${window?.location?.href}`;
      const res = await createSubPaymentMethod(encodeURIComponent(redirectUrl));
      if (res?.data?.session?.url) {
        window.location = res?.data?.session?.url;
      } else {
        toast.error(
          "'Oops! Something unexpected occurred. Please contact support for assistance.",
        );
      }
      // setSubscriptions(res?.data?.perSeat);
      setPaymentMehtodLoader(false);
    } catch (err) {
      setPaymentMehtodLoader(false);
      console.log('err', err);
    }
  };

  const onConfirmCancel = () => {
    setRole(lastRoleState);
    setSubscriptionModalOpen(false);
  };

  const onAccountsSelection = (v) => {
    setPeopleSelectExpAcc(v);
    if (
      (people?.seatStatus !== 'confirmed' &&
        people?.seatStatus !== 'confiriming') ||
      role?.length < 1
    ) {
      setSubscriptionModalOpen(true);
    } else {
      onUpdate(role, v);
    }
    setExpenseModal(false);
  };

  const onCancel = () => {
    if (!people?.role?.includes('expense reporting')) {
      setRole(lastRoleState);
    }
    setExpenseModal(false);
  };

  return (
    <>
      <ExpenseReportingAccountsModal
        setOpen={() => {}}
        open={expenseModal}
        activeReporterId={personId}
        peopleSelectedExpAcc={peopleSelectedExpAcc}
        setPoepleSelectExpAcc={onAccountsSelection}
        expenseReporterAccounts={expenseReporterAccounts}
        setExpenseReporterAccounts={setExpenseReporterAccounts}
        notAssignedAccounts={notAssignedAccounts}
        setNotAssignedAccounts={setNotAssignedAccounts}
        localSelectedAccounts={localSelectedAccounts}
        setLocalSelectedAccounts={setLocalSelectedAccounts}
        onCancel={onCancel}
        hideCross
      />
      {triedToActiveRole &&
        !subscription?.default_payment_method &&
        !team?.hasPaymentMethod && (
          <div className="">
            <AlertBox
              type="warning"
              heading="Connect Payment Method"
              text="Please connect a payment method to enable assigning roles to people"
              hideActionButton={
                subscription?.default_payment_method || team?.hasPaymentMethod
              }
              actionButtonText="Connect Payment Method"
              onActionButton={onConnectPaymentMethod}
              actionLoading={paymentMethodLoader}
            />
          </div>
        )}
      <SubscriptionWarningModal
        isOpen={subscriptionModalOpen}
        setIsOpen={setSubscriptionModalOpen}
        price={`${getCurrencySymbol(subscriptions?.prices?.[0]?.currency)}${parseFloat(
          subscriptions?.prices?.[0]?.unit_amount / 100,
        )?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}/month`}
        handleDone={onUpdate}
        loading={roleLoader}
        onCancel={onConfirmCancel}
      />
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-full md:col-span-6 grid grid-cols-2 gap-y-6 h-fit">
          {process.env.REACT_APP_ENV === DevEnv &&
            people?.role?.includes?.('time tracking') && (
              <>
                <h3 className="col-span-full font-medium text-[18px] text-[#667085]">
                  Account Summary:
                </h3>
                <div className="">
                  <p className="text-sm mb-1 text-[#101828]">
                    All Time Hours :
                  </p>
                  <h3 className="text-[36px] font-medium text-[#101828]">
                    58:25
                  </h3>
                </div>
                <div className="">
                  <p className="text-sm mb-1 text-[#101828]">All Time Paid :</p>
                  <h3 className="text-[36px] font-medium text-[#101828]">
                    $2912.5
                  </h3>
                </div>
              </>
            )}

          <h3
            className={`col-span-full font-medium text-[18px] text-[#667085] ${people?.role?.includes?.('time tracking') && process.env.REACT_APP_ENV === DevEnv ? 'mt-3' : ''} `}
          >
            Basic Details:
          </h3>
          <div>
            <p className="text-sm text-[#101828] mb-2">First Name</p>
            <p className=" font-medium text-[#667085]"> {people?.firstName} </p>
          </div>
          <div>
            <p className="text-sm text-[#101828] mb-2">Last Name</p>
            <p className="font-medium text-[#667085]">{people?.lastName}</p>
          </div>
          <div ref={emailBoxRef}>
            <p className="text-sm text-[#101828] mb-2">Email Address</p>
            <Tooltip
              className="cursor-default"
              tooltipShow={people?.email?.length > emailLength}
              content={
                people?.email?.length > 20
                  ? (() => {
                      const segments = getStringSegments(people?.email, 25);
                      return segments?.map((segment, index) => (
                        <p key={index}>
                          {segment}
                          {index < segments?.length - 1 ? '-' : ''}
                        </p>
                      ));
                    })()
                  : people?.email
              }
              contentClassName="border-none leading-tight rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
            >
              <p className=" font-medium text-[#667085] overflow-hidden text-ellipsis  leading-tight">
                {trimText(people?.email, emailLength)}
              </p>
            </Tooltip>
          </div>
          <div>
            <p className="text-sm text-[#101828] mb-2">Mobile Number</p>
            <p className="font-medium text-[#667085]">
              {people?.phone ? formatPhoneNumberIntl(people?.phone) : '--'}
            </p>
          </div>
          <div className="col-span-full">
            <p className="text-sm text-[#101828] mb-2">Address</p>
            <p className="font-medium text-[#667085]">
              {people?.address?.street}{' '}
              {people?.address?.street2 ? `, ${people?.address?.street2}` : ''}
              {people?.address?.city ? `, ${people?.address?.city}` : ''}
              {people?.address?.zipCode
                ? `, ${people?.address?.zipCode} `
                : ''}{' '}
              {people?.address?.country}
            </p>
          </div>
          {team?.country === 'US' && (
            <>
              <h3 className="col-span-full font-medium text-[18px] text-[#667085] mt-3">
                Tax Information:
              </h3>
              <div>
                <p className="text-sm text-[#101828] mb-2">Legal Name</p>
                <p className="font-medium text-[#667085]">
                  {people?.vendor?.legalName || ''}
                </p>
              </div>
              <div>
                <p className="text-sm text-[#101828] mb-2">Business Name</p>
                <p className="font-medium text-[#667085]">
                  {people?.vendor?.businessName || ''}
                </p>
              </div>
              <div>
                <p className="text-sm text-[#101828] mb-2">Tax</p>
                <p className="font-medium text-[#667085]">
                  <span className="uppercase">
                    {people?.vendor?.taxType === 'none'
                      ? ''
                      : people?.vendor?.taxType || ''}
                  </span>{' '}
                  {people?.vendor?.taxNumber || ''}
                </p>
              </div>
              <div>
                <p className="text-sm text-[#101828] mb-2">
                  Federal Tax Classification
                </p>
                <p className="font-medium text-[#667085]">
                  {people?.vendor?.federalTaxClasiffication || ''}
                </p>
              </div>
            </>
          )}
          <h3 className="col-span-full font-medium text-[18px] text-[#667085] mt-3">
            Compensation:
          </h3>
          <div>
            <p className="text-sm text-[#101828] mb-2">Joined Date </p>
            <p className="font-medium text-[#667085]">
              {moment(people?.createdAt).format('DD MMM, yyyy')}
            </p>
          </div>
          <div>
            <p className="text-sm text-[#101828] mb-2">Position </p>
            <p className="font-medium text-[#667085]">
              {' '}
              {people?.position || '--'}
            </p>
          </div>
          <div>
            <p className="text-sm text-[#101828] mb-2">Pay Type </p>
            <p className="font-medium text-[#667085]">
              {' '}
              {people?.jobs?.[0]?.paymentUnit || '--'}
            </p>
          </div>
          <div>
            <p className="text-sm text-[#101828] mb-2">Wage </p>
            <p className="font-medium text-[#667085]">
              {' '}
              {people?.jobs?.[0]?.rate
                ? `${getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}${formatNumber(people?.jobs?.[0]?.rate)}`
                : '--'}
            </p>
          </div>
          {people?.provider === 'gusto' && (
            <>
              <h3 className="col-span-full font-medium text-[18px] text-[#667085] mt-3">
                Gusto Information
              </h3>
              <div>
                <p className="text-sm text-[#101828] mb-2">Position </p>
                <p className="font-medium text-[#667085]">{people?.position}</p>
              </div>
              <div>
                <p className="text-sm text-[#101828] mb-2">Wage </p>
                <p className="font-medium text-[#667085]">
                  {' '}
                  {people?.jobs?.length > 0 &&
                    people?.jobs?.[people?.jobs?.length - 1]?.compensations
                      ?.length > 0 && (
                      <span>
                        $
                        {parseFloat(
                          people?.jobs?.[people?.jobs?.length - 1]
                            ?.compensations?.[
                            people?.jobs?.[people?.jobs?.length - 1]
                              ?.compensations?.length - 1
                          ]?.rate,
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        /
                        {
                          people?.jobs?.[people?.jobs?.length - 1]
                            ?.compensations?.[
                            people?.jobs?.[people?.jobs?.length - 1]
                              ?.compensations?.length - 1
                          ]?.paymentUnit
                        }
                      </span>
                    )}
                </p>
              </div>
            </>
          )}
        </div>
        {checkPermission(
          PermissionKeys.MANAGE_PEOPLE,
          accessType,
          permissions,
        ) && (
          <div className="col-span-full md:col-span-6  space-y-[30px]">
            <h3 className="text-[18px] font-medium text-[#667085]">
              Unlock powerful management features for employees and contractors
            </h3>
            <div className="h-fit !mt-4">
              <FormBox
                checked={role?.includes('time tracking')}
                objKey="time tracking"
                onRoleChange={onRoleChange}
                title="Time Tracking"
                details="Allow this employee to clock in/out and track their hours effortlessly through the COUNT mobile app, providing clear visibility into work hours."
                componentId="peopleDetailsOverview"
              />
            </div>
            <div className="h-fit">
              <FormBox
                checked={role?.includes('sales rep')}
                onRoleChange={onRoleChange}
                title="Sales Rep Tracking"
                objKey="sales rep"
                details="Assign this person as a sales rep to customers and invoices, track their performance, and generate insightful reports to compare and optimize your sales team's effectiveness."
                componentId="peopleDetailsOverview"
              />
            </div>
            <div className="h-fit">
              <FormBox
                checked={role?.includes('expense reporting')}
                onRoleChange={onRoleChange}
                title="Expense Claim Reporting"
                objKey="expense reporting"
                expenseModal={expenseModal}
                setExpenseModal={setExpenseModal}
                enabledExpenseReporting={team?.featureExpenseReport}
                getAccountNameById={getAccountNameById}
                peopleSelectedExpAcc={peopleSelectedExpAcc}
                details="Simplify expense management and streamline the reimbursement process by enabling this person to upload receipts and submit expenses for approval directly through the COUNT mobile app."
                componentId="peopleDetailsOverview"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OverviewTab;
