import AlertBox from 'components/AlertBox';
import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import useQuickbooksSettings from 'pages/appIntegrations/quickbooksSettings/useQuickbooksSettings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useOnboardingSubSteps from './useOnboardingSubSteps';

const ImportContactList = ({ updateOneStep }) => {
  const { team } = useSelector((state) => state.Team);

  const {
    customerLoading,
    vendorLoading,
    productLoading,
    syncCustomers,
    syncVendors,
    syncProducts,
  } = useQuickbooksSettings();

  const { handleOnboardingSubStep, onBoardingSubSteps } = useOnboardingSubSteps(
    {
      subStep: 'customers',
    },
  );

  const handleImportCustomers = async () => {
    try {
      const res = await syncCustomers();
      if (res?.data?.success) {
        handleOnboardingSubStep('customers');
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleImportVendors = async () => {
    try {
      const res = await syncVendors();
      if (res?.data?.success) {
        handleOnboardingSubStep('vendors');
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleImportProducts = async () => {
    try {
      const res = await syncProducts();
      if (res?.data?.success) {
        handleOnboardingSubStep('products');
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleNext = () => {
    if (!onBoardingSubSteps?.includes('customers')) {
      handleImportCustomers();
    } else if (!onBoardingSubSteps?.includes('vendors')) {
      handleImportVendors();
    } else if (!onBoardingSubSteps?.includes('products')) {
      handleImportProducts();
    } else {
      updateOneStep();
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Contacts & Products:
      </h3>
      {/* {showSuccess && (
        <AlertBox
          type="success"
          heading={`${importedCustomersSuccessfully ? 'Customers' : ''}${importedVendorsSuccessfully ? `${importedCustomersSuccessfully ? ',' : ''} Vendors` : ''}${importedProductsSuccessfully ? `${importedCustomersSuccessfully || importedVendorsSuccessfully ? ',' : ''} Products & Services ` : ''} 
            Imported Successfully`}
          onActionButton={() => {
            setShowSuccess(false);
          }}
          className="mb-6"
          isCross
          text={`Your ${importedCustomersSuccessfully ? 'Customers' : ''}${importedVendorsSuccessfully ? `${importedCustomersSuccessfully ? ',' : ''} Vendors` : ''}${importedProductsSuccessfully ? `${importedCustomersSuccessfully || importedVendorsSuccessfully ? ',' : ''} Products & Services ` : ''} 
            data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete.`}
          // hideActionButton
        />
      )} */}
      <div className="grid gap-6">
        <ImportBox
          title="Import Customers"
          text="Import all of your customers from Quickbooks with a single click."
          buttonText={
            onBoardingSubSteps?.includes('customers')
              ? 'Customers Imported'
              : 'Import Customers'
          }
          handleClick={handleImportCustomers}
          loading={customerLoading}
          lastSync={team?.app?.quickbooks?.[0]?.customerLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Customer, the records will be automatically merged."
          successBtn={onBoardingSubSteps?.includes('customers')}
        />
        <ImportBox
          title="Import Vendors"
          text="Import all of your vendors from Quickbooks with a single click."
          buttonText={
            onBoardingSubSteps?.includes('vendors')
              ? 'Vendors Imported'
              : 'Import Vendors'
          }
          handleClick={handleImportVendors}
          loading={vendorLoading}
          lastSync={team?.app?.quickbooks?.[0]?.vendorLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Vendor, the records will be automatically merged."
          successBtn={onBoardingSubSteps?.includes('vendors')}
        />
        <ImportBox
          title="Import Products & Services"
          text="Import all of your products & services from Quickbooks with a single click."
          buttonText={
            onBoardingSubSteps?.includes('products')
              ? 'Products & Services Imported'
              : 'Import Products & Services'
          }
          handleClick={handleImportProducts}
          loading={productLoading}
          lastSync={team?.app?.quickbooks?.[0]?.productLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Product or Service, the records will be automatically merged."
          successBtn={onBoardingSubSteps?.includes('products')}
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        <button
          type="button"
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={handleNext}
          disabled={customerLoading || vendorLoading || productLoading}
        >
          {(customerLoading || vendorLoading || productLoading) && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Next
        </button>
      </footer>
    </div>
  );
};

export default ImportContactList;
