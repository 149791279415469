import React, { useEffect } from 'react';
import AppLayout from 'components/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  IS_PERSON,
  ON_BOARDING_OPTIONS,
  ON_BOARDING_STEPS,
  TEAM_ID,
} from 'utils/Utils';
import { Loader } from 'components/Svg';
import { updateTeamById } from 'API/backend_helper';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import StepsBox from './StepsBox';
import InfoBox from './InfoBox';
import OnboardingBox from './OnboardingBox';
import TeamInfoForm from './TeamInfoForm';
import ConnectBankAccounts from './ConnectBankAccounts';
import InviteMembers from './InviteMembers';
import ConnectQuickbooks from './ConnectQuickbooks';
import ImportAccounts from './ImportAccounts';
import ImportContactList from './ImportContactList';
import ImportTransactions from './ImportTransactions';
import CsvImportAccounts from './CsvImportAccounts';
import CsvImportContactList from './CsvImportContactList';
import CsvImportTransactions from './CsvImportTransactions';
import OnBoardSuccess from './OnBoardSuccess';
import {
  setIsOnBoarding,
  setOnBoardingStep,
  setOnBoardingSubSteps,
  setSelectedItem,
} from '../../redux/onBoarding/reducer';

const Onboardings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;

  const { selectedItem, onBoardingStep, isOnBoarding, onBoardingSubSteps } =
    useSelector((state) => state.OnBoarding);
  const { team } = useSelector((state) => state.Team);
  const { subscription } = useSelector((state) => state.Subscription);
  const { accessType, permissions } = useSelector((state) => state.User);

  document.title = 'COUNT | On Boarding';

  useEffect(() => {
    if (team?.id) {
      if (
        !(
          isOnBoarding &&
          !IsPerson &&
          subscription?.teamActivePlan?.status !== 'expired'
        ) ||
        !checkPermission(
          PermissionKeys.MANAGE_ONBOARDING,
          accessType,
          permissions,
        )
      ) {
        navigate('/');
      }
    }
  }, [team]);

  const updateTeamOnBoardingData = async (values) => {
    try {
      const teamId = team?.id || localStorage.getItem(TEAM_ID);
      await updateTeamById(teamId, values);
    } catch (err) {
      console.log('err', err);
    }
  };

  const updateOneStep = () => {
    dispatch(setOnBoardingStep((onBoardingStep || 2) + 1));
    dispatch(setOnBoardingSubSteps([]));
    updateTeamOnBoardingData({
      onBoardingStep: (onBoardingStep || 2) + 1,
      onBoardingSubSteps: [],
    });
  };

  const handleOnBoardComplete = () => {
    dispatch(setIsOnBoarding(false));
    updateTeamOnBoardingData({
      isOnboarding: false,
    });
    navigate('/');

    // dispatch(setIsOnBoarding(true));
    // dispatch(setSelectedItem(''));
    // updateTeamOnBoardingData({
    //   isOnboarding: true,
    //   onBoardingStep: 2,
    //   onBoardingType: '',
    // });
  };

  return (
    <AppLayout>
      <main className="relative grow bg-[#ECF1F5]">
        {/* <button type="button" onClick={handleOnBoardComplete}>
          Reset
        </button> */}
        {!team?.id ? (
          <div className="flex flex-col p-6 items-center mt-10">
            <Loader height="h-10 w-10" />
            <p className="text-[24px] text-black font-medium">
              Fetching data please wait...
            </p>
          </div>
        ) : (
          <div className=" px-4 sm:px-6 lg:px-8 pt-[60px] py-8 w-full max-w-9xl mx-auto h-full flex flex-col ">
            <h1 className="text-[32px] font-medium text-[#101828] mb-10 text-center">
              Welcome To <span className="text-indigo-500">COUNT</span>{' '}
              Onboarding
            </h1>

            <div className="grid grid-cols-12 xl:grid-cols-11 gap-6">
              {/* Show On Large Screen  */}

              <div className="hidden xl:block xl:col-span-3">
                <StepsBox
                  data={
                    selectedItem
                      ? ON_BOARDING_STEPS?.[selectedItem]
                      : ['How do you want to get started?']
                  }
                  activeIndex={selectedItem ? onBoardingStep || 1 : 1}
                  handleOnBoardComplete={handleOnBoardComplete}
                  onBoardedCompleted={
                    (selectedItem === ON_BOARDING_OPTIONS.startNew &&
                      onBoardingStep === 5) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 9)
                  }
                />
              </div>
              <div className="col-span-full xl:col-span-5">
                <div className="w-full h-fit bg-white shadow-md rounded-[12px] p-6">
                  {!selectedItem && (
                    <OnboardingBox
                      updateTeamOnBoardingData={updateTeamOnBoardingData}
                    />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.startNew &&
                    onBoardingStep === 2) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 2) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 2) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 2)) && (
                    <TeamInfoForm updateOneStep={updateOneStep} />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.startNew &&
                    onBoardingStep === 3) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 6) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 5) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 5)) && (
                    <ConnectBankAccounts
                      updateOneStep={updateOneStep}
                      useOnBoardingCutoverDate={
                        selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                        onBoardingStep === 6
                      }
                    />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.startNew &&
                    onBoardingStep === 4) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 7) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 7)) && (
                    <InviteMembers updateOneStep={updateOneStep} />
                  )}
                  {selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                    onBoardingStep === 3 && (
                      <ConnectQuickbooks updateOneStep={updateOneStep} />
                    )}
                  {selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                    onBoardingStep === 4 && (
                      <ImportAccounts updateOneStep={updateOneStep} />
                    )}
                  {selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                    onBoardingStep === 5 && (
                      <ImportContactList updateOneStep={updateOneStep} />
                    )}
                  {selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                    onBoardingStep === 7 && (
                      <ImportTransactions updateOneStep={updateOneStep} />
                    )}
                  {((selectedItem === ON_BOARDING_OPTIONS.xero &&
                    onBoardingStep === 3) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 3)) && (
                    <CsvImportAccounts updateOneStep={updateOneStep} />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.xero &&
                    onBoardingStep === 4) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 4)) && (
                    <CsvImportContactList updateOneStep={updateOneStep} />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.xero &&
                    onBoardingStep === 6) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 6)) && (
                    <CsvImportTransactions updateOneStep={updateOneStep} />
                  )}
                  {((selectedItem === ON_BOARDING_OPTIONS.startNew &&
                    onBoardingStep === 5) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 9)) && (
                    <OnBoardSuccess
                      handleOnBoardComplete={handleOnBoardComplete}
                    />
                  )}
                </div>
              </div>
              {/* Show On Small Screen  */}
              <div className="block xl:hidden sm:col-span-6 col-span-full">
                <StepsBox
                  data={
                    selectedItem
                      ? ON_BOARDING_STEPS?.[selectedItem]
                      : ['How do you want to get started?']
                  }
                  activeIndex={selectedItem ? onBoardingStep || 1 : 1}
                  handleOnBoardComplete={handleOnBoardComplete}
                  onBoardedCompleted={
                    (selectedItem === ON_BOARDING_OPTIONS.startNew &&
                      onBoardingStep === 5) ||
                    (selectedItem === ON_BOARDING_OPTIONS.xero &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.other &&
                      onBoardingStep === 8) ||
                    (selectedItem === ON_BOARDING_OPTIONS.quickBooks &&
                      onBoardingStep === 9)
                  }
                />
              </div>
              <div className="xl:col-span-3 sm:col-span-6 col-span-full">
                <InfoBox />
              </div>
            </div>
          </div>
        )}
      </main>
    </AppLayout>
  );
};

export default Onboardings;
