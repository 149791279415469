import React, { useEffect, useState, useRef } from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
  transformAccounts,
} from 'utils/Utils';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { Loader } from 'components/Svg';
import {
  getAccountTypes,
  getAccountSubTypes,
  getAccounts,
  createPlaidToken,
  updateAccountById,
  getAccountSystemBalance,
  getUserSettings,
  updateUserSettings,
  getTaxes,
  getAccountsCSVData,
} from '../../API/backend_helper';
import AccoutDetails from './AccoutDetails';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import AccountChoiceModal from './AccountChoiceModal';
import AccountsModal from './AccountsModal';
import Tooltip from '../../components/Tooltip';
import emptyAccountsImg from '../../images/custom/empty-search.svg';
import BankWarning from '../../components/BankWarning';
import InstitutionsModal from './InstitutionsModal';
import SearchForm from '../../partials/actions/SearchForm';
import SimpleAccountModal from './SimpleAccountModal';
import PlaidLink from '../settings/PlaidLink';
import AkahuLink from '../settings/AkhahuLink/AkahuLink';
import AccountsTable from './AccountsTable';
import DeActivateModal from './DeActivateModal';
import AlertBox from '../../components/AlertBox';
import AppLayout from '../../components/AppLayout';
import AccountsBox from './AccountsBox';
import ExportCSVModal from './ExportCSVModal';

const AkahuLinkButton = () => (
  <button
    className="h-10 w-[9.688rem] bg-white border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal whitespace-nowrap"
    type="button"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=" w-5 h-5 shrink-0"
    >
      <path
        d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
        stroke="#E48242"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    Connect Bank
  </button>
);

const Togglers = ({ onTogglerClick, isTableView }) => (
  <div className="flex items-center">
    {/* Table View  */}
    <button
      className="pr-2"
      onClick={() => onTogglerClick(false)}
      type="button"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${
          !isTableView
            ? 'stroke-indigo-500 fill-stroke-indigo-500 text-indigo-500 w-[20px] h-[20px]'
            : 'stroke-[#00000099] fill-stroke-[#00000099] text-[#00000099] w-[20px] h-[20px]'
        }  `}
      >
        <rect
          x="1"
          y="9"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="1"
          y="1"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="9"
          y="9"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
        <rect
          x="9"
          y="1"
          width="6"
          height="6"
          rx="2"
          stroke="#858D9D"
          strokeWidth="1.3"
          className="stroke-current"
        />
      </svg>
    </button>
    <div className="w-[1px] h-[18px] bg-indigo-500" />
    {/* Grid View  */}
    <button
      onClick={() => onTogglerClick(true)}
      className="pl-2 "
      type="button"
    >
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${
          isTableView
            ? 'stroke-indigo-500 fill-stroke-indigo-500 text-indigo-500  w-[20px] h-[20px]'
            : 'stroke-[#00000099] fill-stroke-[#00000099] text-[#00000099]  w-[20px] h-[20px]'
        }  `}
      >
        <path
          d="M1.75 3H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 3H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M1.75 8H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 8H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M1.75 13H1.7501"
          stroke="#858D9D"
          strokeWidth="2"
          strokeLinecap="round"
          className="stroke-current"
        />
        <path
          d="M4.25009 13H16.2501"
          stroke="#858D9D"
          strokeWidth="1.3"
          strokeLinecap="round"
          className="stroke-current"
        />
      </svg>
    </button>
  </div>
);

function Accounts() {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const createdAccounts = decodeURI(params.get('createdAccounts') || '');
  const skippedAccounts = decodeURI(params.get('skippedAccounts') || '');
  const totalAccounts = decodeURI(params.get('totalAccounts') || '');

  const PlaidButtonRef = useRef();
  const { type } = useParams();
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [exportCSVModalOpen, setExportCSVModalOpen] = useState(false);
  const [showImportSuccess, setShowImortSuccess] = useState(false);
  const [token, setToken] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allAccountsDrop, setAllAccountsDrop] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedParentAccount, setSelectedParentAccount] = useState(null);
  const [accountDetailsOpen, setAccountDetailsOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [choiceModalOpen, setChoiceModalOpen] = useState(false);
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSubType, setSelectedSubType] = useState(null);
  const [institutionModalOpen, setInstitutionModalOpen] = useState(false);
  const [simpleModalOpen, setSimpleModalOpen] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const [deActivateModalOpen, setDeActivateModalOpen] = useState(false);
  const [deActivateAccount, setDeActivateAccount] = useState({});
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [buttonToggler, setButtonToggler] = useState(false);
  const [accountsCountBalances, setAccountsCountBalances] = useState([]);
  const [countBalanceLoader, setCountBalanceLoader] = useState(false);

  const [deactivateAccLoader, setDeactivateAccLoader] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [taxes, setTaxes] = useState([]);

  // Reconcile Account

  const navigate = useNavigate();
  const location = useLocation();

  document.title = 'COUNT | Accounts';
  const filteredTypes = [];

  const onAccountTypeSelection = (
    acctType = filteredTypes,
    refreshData = false,
    accountsData = allAccounts,
  ) => {
    if (!refreshData) {
      setSelectedAccount(null);
      setAccountDetailsOpen(false);
    }
    accountsData?.reduce?.((result, item) => {
      if (acctType?.length === 0) {
        (result[item?.type?.subtype] = result[item?.type?.subtype] || []).push(
          item,
        );
      } else if (acctType?.includes?.(item?.type?.type)) {
        (result[item?.type?.subtype] = result[item?.type?.subtype] || []).push(
          item,
        );
      }
      return result;
    }, {});
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      const subTypeResponse = await getAccountSubTypes();
      setTypes(typeResponse);
      setSubTypes(subTypeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const accountsToTransactions = (account) => {
    navigate(`/journal-entries?accountId=${account?.id}`);
  };

  const getAccountCountBalance = async (accountIds) => {
    try {
      const response = await getAccountSystemBalance(accountIds);
      setAccountsCountBalances(response);
      setCountBalanceLoader(false);
    } catch (error) {
      console.error(`Error fetching account system balance from :`, error);
      setCountBalanceLoader(false);
    }
  };

  const loadData = async (refreshData = false, inactive = showInactive) => {
    if (!refreshData) {
      setIsLoading(true);
    }
    if (showInactive !== inactive) {
      setShowInactive(inactive);
    }
    try {
      const res = await getAccounts({
        typeId: filteredTypes || [],
        search: searchVal,
        includeBalances: true,
        inactive,
      });
      if (refreshData) {
        onAccountTypeSelection([], refreshData, res);
      } else {
        onAccountTypeSelection([], false, res);
      }

      setAllAccountsDrop(transformAccounts(res, 'allAccounts'));
      setAllAccounts(res);
      setIsLoading(false);
      if (accountsCountBalances?.length === 0 && res?.length > 0) {
        setCountBalanceLoader(true);
        const Ids = [];
        res?.forEach((acc) => {
          if (acc?.status !== 'inactive') {
            Ids?.push(acc?.id); // Add the parent account ID
            if (acc?.subAccounts?.length > 0) {
              acc.subAccounts.forEach((subAcc) => {
                if (subAcc?.status !== 'inactive') {
                  Ids?.push(subAcc?.id); // Add sub-account IDs
                }
              });
            }
          }
        });

        getAccountCountBalance(Ids);
      }
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getAccountSystemBal = (id) => {
    if (accountsCountBalances?.length > 0) {
      const tempAcc = accountsCountBalances?.find(
        (acc) => acc?.accountId === id,
      );
      return tempAcc?.systemBalance || 0;
    }
    return null;
  };

  const getCountTotalBalance = (accounts) => {
    if (countBalanceLoader) {
      return (
        <svg
          className="animate-spin w-4 h-4 fill-current shrink-0"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>
      );
    }
    let total = 0;
    if (accounts?.length > 0) {
      accounts?.forEach((acc) => {
        const bal = getAccountSystemBal(acc?.id);
        total += bal;
      });
    }
    return `${getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}${total?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    )}`;
  };

  const onBankClick = async () => {
    try {
      const plaidRes = await createPlaidToken(null, {}, '');
      const { linkToken } = plaidRes;
      setToken(linkToken);
      setTimeout(() => {
        PlaidButtonRef.current.click();
      }, 1000);
    } catch (e) {
      console.log('error', e);
    }
  };

  const deactivateAccount = async () => {
    if (!deActivateAccount?.id) {
      return;
    }
    setDeactivateAccLoader(true);
    try {
      await updateAccountById(deActivateAccount?.id, {
        status: deActivateAccount?.status === 'active' ? 'inactive' : 'active',
      });
      setDeactivateAccLoader(false);
      setDeActivateModalOpen(false);
      toast.success(
        `Account ${deActivateAccount?.status === 'active' ? 'deactivated' : 'activated'} successfully`,
      );
      setDeActivateAccount({});
      await loadData(false);
    } catch (e) {
      setDeactivateAccLoader(false);
      console.log('error', e);
    }
  };

  const updateAccountViewApi = async (viewType) => {
    try {
      await updateUserSettings({
        accountsView: viewType,
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const onTogglerClick = (bool) => {
    if (bool !== isTableView) {
      setIsTableView(bool);
      updateAccountViewApi(bool ? 'list' : 'block');
    }
  };

  useEffect(() => {
    if (location?.state?.choiceModalOpen && !isLoading) {
      onBankClick();
    }
  }, [location, isLoading]);

  useEffect(() => {
    if (
      (createdAccounts || skippedAccounts || totalAccounts) &&
      !showImportSuccess
    ) {
      setShowImortSuccess(true);
      setTimeout(() => {
        setShowImortSuccess(false);
      }, 8000);
    }
  }, [createdAccounts, skippedAccounts, totalAccounts]);

  useEffect(() => {
    if (filteredTypes.length > 0) {
      onAccountTypeSelection(filteredTypes, false, allAccounts);
    } else {
      onAccountTypeSelection([], false, allAccounts);
    }
  }, []);

  useEffect(() => {
    if (!accountsModalOpen && !simpleModalOpen) {
      setIsEditing(false);
      setSelectedAccount(null);
      setSelectedSubType(null);
      setSelectedType(null);
    }
  }, [accountsModalOpen]);

  const getUserSettingsApi = async () => {
    try {
      const settingsRes = await getUserSettings();
      setIsTableView(settingsRes?.accountsView !== 'block');
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    getUserSettingsApi();
    getAccountTypesApi();
    setSearchVal('');
    loadData();
    loadTaxes();
    setIsEditing(false);
    setSelectedAccount(null);
  }, []);

  useEffect(() => {
    if (type === 'add') {
      setChoiceModalOpen(true);
    }
  }, [type]);

  return (
    <>
      <ExportCSVModal
        isOpen={exportCSVModalOpen}
        setIsOpen={setExportCSVModalOpen}
      />
      <InstitutionsModal
        isOpen={institutionModalOpen}
        setIsOpen={setInstitutionModalOpen}
        loadData={loadData}
        setIsLoading={setIsLoading}
        token={token}
        team={team}
      />
      <SimpleAccountModal
        modalOpen={simpleModalOpen}
        setModalOpen={setSimpleModalOpen}
        account={selectedAccount}
        loadData={loadData}
      />
      <AccountChoiceModal
        isOpen={choiceModalOpen}
        setIsOpen={setChoiceModalOpen}
        setAccountsModalOpen={setAccountsModalOpen}
        setInstitutionModalOpen={setInstitutionModalOpen}
      />
      <AccountsModal
        isOpen={accountsModalOpen}
        setIsOpen={setAccountsModalOpen}
        accountTypes={types}
        accountSubTypes={subTypes}
        loadData={(arg1) => loadData(arg1, null)}
        account={selectedAccount}
        parentAccount={selectedParentAccount}
        setParentAccount={setSelectedParentAccount}
        edit={isEditing}
        currencies={currencies}
        mainType={selectedType}
        subType={selectedSubType}
        taxes={taxes}
        loadTaxes={loadTaxes}
        accounts={allAccountsDrop}
      />
      <DeActivateModal
        isOpen={deActivateModalOpen}
        setIsOpen={setDeActivateModalOpen}
        deactivateAccount={deactivateAccount}
        account={deActivateAccount}
        loading={deactivateAccLoader}
        setLoading={setDeactivateAccLoader}
        team={team}
      />
      <AppLayout>
        {accessType && (
          <main className="relative grow">
            {/* Content */}
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              {checkPermission(
                PermissionKeys.Manage_Bank_Account,
                accessType,
                permissions,
              ) && <BankWarning teamProp={team} />}
              {showImportSuccess && (
                <AlertBox
                  type="success"
                  heading="Accounts Imported Successfully"
                  text={`Created Accounts : ${createdAccounts} |
              Skipped Accounts : ${skippedAccounts} |
              Total Accounts : ${totalAccounts}
              `}
                  hideActionButton
                />
              )}
              {/* Page header */}
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-5 flex-wrap gap-4">
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem] mb-4 sm:mb-0">
                  Chart of Accounts
                </h1>
                <div className="flex flex-col sm:flex-row sm:justify-end sm:items-start gap-4 ml-auto flex-wrap">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                    transaction
                  />
                  {/* Filters */}
                  {checkPermission(
                    PermissionKeys.MANAGE_ACCOUNTS,
                    accessType,
                    permissions,
                  ) && (
                    <>
                      {team?.country === 'NZ' ? (
                        <AkahuLink ButtonUI={AkahuLinkButton} />
                      ) : (
                        <Tooltip
                          content={`Live bank feeds for ${team?.country || ''} is currently not supported. Manually import transactions instead.`}
                          contentClassName="border-none w-[250px] text-wrap overflow-visible text-sm text-[#667085] relative"
                          position="left"
                          size="sm"
                          tooltipShow={
                            teamDefaultCurrency !== 'USD' &&
                            teamDefaultCurrency !== 'CAD'
                          }
                          className="w-fit"
                        >
                          <button
                            className="h-10 w-[9.688rem] bg-white border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal whitespace-nowrap"
                            onClick={() => {
                              // setInstitutionModalOpen(true);
                              onBankClick();
                            }}
                            disabled={
                              teamDefaultCurrency !== 'USD' &&
                              teamDefaultCurrency !== 'CAD'
                            }
                            type="button"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className=" w-5 h-5 shrink-0"
                            >
                              <path
                                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                                stroke="#E48242"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Connect Bank
                          </button>
                        </Tooltip>
                      )}

                      <div className="">
                        <div className="h-10 flex relative">
                          <button
                            className={`flex gap-2 items-center justify-center rounded-tl-[5px]
                                ${
                                  !buttonToggler ? 'rounded-bl-[5px]' : ''
                                }  h-full px-2 border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAccountsModalOpen(true);
                            }}
                            type="button"
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className=""
                            >
                              <path
                                d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                                stroke="white"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Add Account
                          </button>
                          <button
                            onClick={() => setButtonToggler(!buttonToggler)}
                            className={`rounded-tr-[5px] ${
                              !buttonToggler ? 'rounded-br-[5px]' : ''
                            }  h-full px-2 border border-l-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white`}
                            type="button"
                          >
                            <svg
                              className={`shrink-0 ml-1 text-white  ${
                                buttonToggler ? 'rotate-180' : ''
                              }`}
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                                stroke="#ffffff"
                                strokeOpacity="0.8"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        {buttonToggler && (
                          <button
                            onClick={() => navigate('/import-accounts')}
                            className="absolute  w-[168px] rounded-bl-[5px] rounded-br-[5px] h-10 border border-t-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white"
                            type="button"
                          >
                            Import Accounts
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {allAccounts?.length > 0 && (
                <div className="flex justify-end mb-5 gap-4">
                  <button
                    className="h-8 px-3 border border-indigo-500 text-indigo-500 rounded-[5px] inline-flex gap-2 items-center  whitespace-nowrap text-[14px]"
                    onClick={() => {
                      loadData(false, !showInactive);
                    }}
                    type="button"
                  >
                    <input
                      type="checkbox"
                      checked={showInactive}
                      className="form-checkbox h-5 w-5"
                    />
                    Show Inactive Accounts
                  </button>
                  {checkPermission(
                    PermissionKeys.MANAGE_ACCOUNTS,
                    accessType,
                    permissions,
                  ) && (
                    <button
                      className="h-8 w-10  bg-white border border-indigo-500 rounded-[0.313rem] shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal whitespace-nowrap"
                      onClick={() => setExportCSVModalOpen(true)}
                      type="button"
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 12.5V15.8333C18 16.2754 17.8244 16.6993 17.5118 17.0118C17.1993 17.3244 16.7754 17.5 16.3333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V12.5"
                          stroke="#E48642"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.33301 8.33203L10.4997 12.4987L14.6663 8.33203"
                          stroke="#E48642"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 12.5V2.5"
                          stroke="#E48642"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              )}
              {isLoading ? (
                <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin w-10 h-10 fill-current shrink-0"
                  >
                    <path
                      d="M14.5 2.41699V7.25033"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 21.749V26.5824"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 5.95703L9.37662 9.37662"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 19.6211L23.0426 23.0407"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.41699 14.5H7.25033"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.749 14.5H26.5824"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 23.0407L9.37662 19.6211"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 9.37662L23.0426 5.95703"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                    Fetching data, please wait...
                  </p>
                </div>
              ) : (
                allAccounts?.length > 0 &&
                (isTableView ? (
                  <>
                    <div className="max-w-full flex items-center justify-between">
                      <div className="w-[400px]">
                        <BasicCheckboxDropdown
                          selectedOptions={selectedTypes}
                          setSelectedOptions={setSelectedTypes}
                          options={types.map((t) => ({
                            label: t?.name,
                            value: t?.name,
                          }))}
                          onClear={() => {
                            setSelectedTypes([]);
                          }}
                          label="Choose Types"
                          apiFilter={selectedTypes}
                          inbox
                        />
                      </div>
                      <Togglers
                        onTogglerClick={onTogglerClick}
                        isTableView={isTableView}
                      />
                    </div>
                    <AccountsTable
                      accountsToTransactions={accountsToTransactions}
                      allAccounts={allAccounts}
                      setIsEditing={setIsEditing}
                      setSelectedAccount={setSelectedAccount}
                      setAccountsModalOpen={setAccountsModalOpen}
                      setSimpleModalOpen={setSimpleModalOpen}
                      setDeActivateModalOpen={setDeActivateModalOpen}
                      setDeActivateAccount={setDeActivateAccount}
                      searchVal={searchVal}
                      selectedTypes={selectedTypes}
                      currencies={currencies}
                      getAccountSystemBal={getAccountSystemBal}
                      countBalanceLoader={countBalanceLoader}
                      team={team}
                      setSelectedParentAccount={setSelectedParentAccount}
                    />
                  </>
                ) : (
                  <>
                    {/* Tabs */}
                    <div className="flex items-start justify-between mt-2.5">
                      <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-1">
                        <div
                          onClick={() => setActiveTab('all')}
                          className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === 'all' &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                          tabIndex="0"
                          role="button"
                          onKeyDown={null}
                        >
                          All Accounts
                        </div>
                        {types?.map((type, index) => (
                          <div
                            key={index}
                            onClick={() => setActiveTab(type?.name)}
                            className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === type?.name &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                            tabIndex="0"
                            role="button"
                            onKeyDown={null}
                          >
                            {type?.name}
                          </div>
                        ))}
                      </div>
                      <div className="pt-0">
                        <Togglers
                          onTogglerClick={onTogglerClick}
                          isTableView={isTableView}
                        />
                      </div>
                    </div>
                    {/* Table */}
                    <div className="flex flex-col items-start h-full">
                      {(activeTab === 'all'
                        ? types
                            .map((type) => ({
                              ...type,
                              subTypes: [...type.subTypes].reverse(),
                            }))
                            .flatMap((type) => type.subTypes)
                        : [
                            ...types.find((type) => type.name === activeTab)
                              ?.subTypes,
                          ].reverse()
                      ).map((subType, index) => (
                        <AccountsBox
                          key={index}
                          subType={subType}
                          allAccounts={allAccounts}
                          searchVal={searchVal}
                          getAccountSystemBal={getAccountSystemBal}
                          setDeActivateModalOpen={setDeActivateModalOpen}
                          setDeActivateAccount={setDeActivateAccount}
                          accountsToTransactions={accountsToTransactions}
                          setIsEditing={setIsEditing}
                          setSelectedAccount={setSelectedAccount}
                          setAccountsModalOpen={setAccountsModalOpen}
                          setSimpleModalOpen={setSimpleModalOpen}
                          setSelectedType={setSelectedType}
                          setSelectedSubType={setSelectedSubType}
                          countBalanceLoader={countBalanceLoader}
                          getCountTotalBalance={getCountTotalBalance}
                          types={types}
                          setSelectedParentAccount={setSelectedParentAccount}
                        />
                      ))}
                    </div>
                  </>
                ))
              )}
              {!isLoading && allAccounts?.length === 0 && (
                <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                  <h6 className="text-center mt-4">No Accounts Found</h6>
                  <img
                    src={emptyAccountsImg}
                    alt="No Accounts found"
                    className="h-[12.5rem]"
                  />
                </div>
              )}
            </div>
            {selectedAccount?.name && (
              <AccoutDetails
                accountPanelOpen={accountDetailsOpen}
                setAccountPanelOpen={setAccountDetailsOpen}
                accountDetails={selectedAccount}
                resetAccountDetails={setSelectedAccount}
                setEditAccountModalOpen={setEditAccountModalOpen}
                modalOpen={editAccountModalOpen}
              />
            )}
            <PlaidLink
              loadData={loadData}
              setIsLoading={() => {}}
              token={token}
              team={team}
              accountPage
              buttonRef={PlaidButtonRef}
              cb={() => {}}
              shouldNavigate
              successCBBeforeChooseAccounts={false}
            />
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default Accounts;
