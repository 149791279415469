import React from 'react';

const ActionIcon = (
  <svg
    width="10"
    height="10"
    className="ml-2"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 -0.0078125C2.2365 -0.0078125 0 2.22844 0 4.99219C0 7.75564 2.23625 9.99219 5 9.99219C7.7635 9.99219 10 7.75594 10 4.99219C10 2.22873 7.76375 -0.0078125 5 -0.0078125ZM5.51346 6.97697C5.51346 7.13494 5.28311 7.29287 5.0001 7.29287C4.70393 7.29287 4.49334 7.13494 4.49334 6.97697V4.46941C4.49334 4.28514 4.70395 4.16006 5.0001 4.16006C5.28311 4.16006 5.51346 4.28514 5.51346 4.46941V6.97697ZM5.00012 3.55461C4.69736 3.55461 4.46045 3.33084 4.46045 3.08072C4.46045 2.83062 4.69738 2.61344 5.00012 2.61344C5.29629 2.61344 5.53324 2.83062 5.53324 3.08072C5.53324 3.33084 5.29627 3.55461 5.00012 3.55461Z"
      fill="#2575F4"
    />
  </svg>
);
const Tabs = ({ TAB_NAMES, activeTab, setActiveTab, transaction }) => {
  const TABS = [
    {
      name: 'Categorize',
      id: TAB_NAMES.categorize,
    },
    {
      id: TAB_NAMES.transfer,
      name: 'Account Transfer',
    },
    {
      id: TAB_NAMES.duplicates,
      name: 'Duplicates',
    },
    transaction?.type === 'Income' && {
      id: TAB_NAMES.matchInvoice,
      name: 'Invoice Payment',
    },
    transaction?.type === 'Income' && {
      id: TAB_NAMES.undepositTransfer,
      name: 'Undeposited Transfer',
    },
    transaction?.type === 'Expense' && {
      id: TAB_NAMES.matchBill,
      name: 'Bill Payment',
    },
  ].filter(Boolean); // Remove undefined or false values

  return (
    <div className="w-full mb-6 mt-4 ">
      <div className="flex items-center flex-wrap w-full  gap-y-2 sm:gap-y-2 border-b border-[#D0D5DD] pb-2 px-6">
        {TABS.map((tab) => (
          <div
            key={tab.id}
            role="button"
            tabIndex="0"
            onClick={() => setActiveTab(tab.id)}
            className={`text-slate-600 px-5 text-center leading-6  text-[1rem] border-b-[3px] translate-y-[10px] border-transparent  cursor-pointer pb-2 flex items-center
          ${
            activeTab === tab.id &&
            ' !border-indigo-500  font-medium !text-[#101828]'
          } `}
          >
            {tab.name}{' '}
            {tab.id === TAB_NAMES.transfer &&
              (transaction?.transferMatchings?.length > 0 ||
                transaction?.hasMatchingTransfer) &&
              ActionIcon}
            {tab.id === TAB_NAMES.duplicates &&
              (transaction?.duplicateMatchings?.length > 0 ||
                transaction?.hasMatchingDuplicate) &&
              ActionIcon}
            {tab.id === TAB_NAMES.matchInvoice &&
              (transaction?.matchingInvoices?.length > 0 ||
                transaction?.hasMatchingInvoice) &&
              ActionIcon}
            {tab.id === TAB_NAMES.matchBill &&
              (transaction?.matchingBillInstances?.length > 0 ||
                transaction?.hasMatchingBill) &&
              ActionIcon}
            {tab.id === TAB_NAMES.undepositTransfer &&
              transaction?.undepositedTransfers?.length > 0 &&
              ActionIcon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
