import React from 'react';
import DropdownFull from 'components/DropdownFull';
import MerchantsDropdown from 'components/MerchantsDropdown';
import useCustomerSearch from 'hooks/useCustomerSearch';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import useBasicDropdownSearch from 'hooks/useBasicDropdownSearch';

const TableEntryItemsDropdowns = ({
  entryRow,
  i,
  customers = [],
  setCustomers,
  projects = [],
  handleUpdateValue,
  vendors = [],
  setVendors,
  tags = [],
  products = [],
}) => {
  const {
    dropdownCustomers,
    handleCustomerSearch,
    resetCustomerSearch,
    searchCustomerLoading,
  } = useCustomerSearch(customers, setCustomers);

  const {
    dropdownOptions: dropdownProjects,
    handleDropdownSearch: handleProjectSearch,
    resetDropdownSearch: resetProjectSearch,
  } = useBasicDropdownSearch({ options: projects });

  const {
    dropdownOptions: dropdownProducts,
    handleDropdownSearch: handleProductSearch,
    resetDropdownSearch: resetProductSearch,
  } = useBasicDropdownSearch({ options: products });

  return (
    <div className="flex items-center gap-2">
      {!entryRow?.projectId?.value && (
        <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
          <div
            className={`${entryRow?.customerId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
          >
            <DropdownFull
              options={
                entryRow?.customerId?.customer?.status === 'inactive'
                  ? [
                      { ...entryRow?.customerId?.customer },
                      ...dropdownCustomers,
                    ]
                  : dropdownCustomers
              }
              disabled={entryRow?.projectId?.value}
              selected={entryRow?.customerId?.value || null}
              setSelected={(value) => {
                handleUpdateValue(i, 'customerId', value);
              }}
              name="customer"
              scrollIntoView={false}
              search
              height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!entryRow?.customerId?.value ? '!text-indigo-500' : ''}`}
              buttonTextClassSub={`!text-sm ${!entryRow?.customerId?.value ? '!text-indigo-500' : '!text-black'}`}
              flyout
              placeholder="Customer"
              handleSearch={(value) =>
                handleCustomerSearch(value, entryRow?.customerId?.value)
              }
              resetSearch={resetCustomerSearch}
              searchPlaceholder="Type To Search"
              searchLoading={searchCustomerLoading}
              noMinW
              bottomOfTable
              showDropdownChevron={false}
              isTableDropdown
              ids={[
                'journalEntriesContainer',
                'createJournalEntryTableContainer',
              ]}
            />
          </div>
          {entryRow?.customerId?.value && (
            <button
              className="text-sm text-indigo-500 cursor-pointer hover:underline"
              type="button"
              onClick={() => handleUpdateValue(i, 'customerId', null)}
            >
              reset
            </button>
          )}
        </div>
      )}
      {!entryRow?.customerId?.value && !entryRow?.projectId?.value && (
        <span className="text-black text-sm">|</span>
      )}
      {!entryRow?.customerId?.value && (
        <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
          <div
            className={`${entryRow?.projectId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
          >
            <DropdownFull
              options={
                entryRow?.projectId?.project?.isDeleted
                  ? [{ ...entryRow?.projectId?.project }, ...dropdownProjects]
                  : dropdownProjects
              }
              selected={entryRow?.projectId?.value || null}
              setSelected={(value) => {
                handleUpdateValue(i, 'projectId', value);
              }}
              name="name"
              scrollIntoView={false}
              search
              height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!entryRow?.projectId?.value ? '!text-indigo-500' : ''}`}
              buttonTextClassSub={`!text-sm ${!entryRow?.projectId?.value ? '!text-indigo-500' : '!text-black'}`}
              flyout
              noMinW
              placeholder="Project"
              bottomOfTable
              disabled={entryRow?.customerId?.value}
              className="whitespace-nowrap truncate !h-fit"
              isTableDropdown
              ids={[
                'journalEntriesContainer',
                'createJournalEntryTableContainer',
              ]}
              showDropdownChevron={false}
              searchPlaceholder="Type To Search"
              handleSearch={(value) =>
                handleProjectSearch(value, entryRow?.projectId?.value)
              }
              resetSearch={resetProjectSearch}
            />
          </div>
          {entryRow?.projectId?.value && (
            <button
              className="text-sm text-indigo-500 cursor-pointer hover:underline"
              type="button"
              onClick={() => handleUpdateValue(i, 'projectId', null)}
            >
              reset
            </button>
          )}
        </div>
      )}
      <span className="text-black text-sm">|</span>

      <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
        <div
          className={`${entryRow?.vendorId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
        >
          <MerchantsDropdown
            vendors={vendors}
            setVendors={setVendors}
            inActiveVendor={
              entryRow?.vendorId?.vendor?.status === 'inactive'
                ? entryRow?.vendorId?.vendor
                : {}
            }
            selectedMerchant={entryRow?.vendorId?.value || null}
            setSelectedMerchant={(value, merchant) => {
              handleUpdateValue(i, 'vendorId', value);
              // onChangeMerchant(value);
            }}
            placeholder="Vendor"
            showDropdownChevron={false}
            height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!entryRow?.vendorId?.value ? '!text-indigo-500 font-medium' : ''}`}
            buttonTextClassSub={`!text-sm ${!entryRow?.vendorId?.value ? '!text-indigo-500' : '!text-black'}`}
            isSetCategoryStyle
            // addNewOptionButton
            // handleNewOptionButton={() => {
            //   setVendorModalOpen(true);
            // }}
            isTableDropdown
            bottomOfTable
            ids={[
              'journalEntriesContainer',
              'createJournalEntryTableContainer',
            ]}
          />
        </div>
        {entryRow?.vendorId?.value && (
          <button
            className="text-sm text-indigo-500 cursor-pointer hover:underline"
            type="button"
            onClick={() => handleUpdateValue(i, 'vendorId', null)}
          >
            reset
          </button>
        )}
      </div>
      <span className="text-black text-sm">|</span>
      <div className="w-max max-w-[500px] gap-2  flex items-center justify-between">
        <div
          className={`${entryRow?.tags?.value?.length > 0 ? 'w-max max-w-[460px]' : 'w-full'}`}
        >
          <BasicCheckboxDropdown
            isUseStateHook={false}
            selectedOptions={entryRow?.tags?.value || []}
            setSelectedOptions={(v) => {
              handleUpdateValue(i, 'tags', v);
            }}
            options={
              tags?.map((t) => ({
                label: t?.name,
                value: t?.id,
              })) || []
            }
            showDropdownChevron={false}
            height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!(entryRow?.tags?.value?.length > 0) ? '!text-indigo-500 font-medium' : '!text-slate-600'}`}
            buttonTextClassSub={`!text-sm ${!(entryRow?.tags?.value?.length > 0) ? '!text-indigo-500 ' : '!text-black'}`}
            label="Tags"
            type="tag"
            flyout
            bottomOfTable
            ids={[
              'journalEntriesContainer',
              'createJournalEntryTableContainer',
            ]}
            noMinW
            isTableDropdown
            dropdownBoxWidth="min-w-44 w-full max-w-[300px]"
          />
        </div>
        {entryRow?.tags?.value?.length > 0 && (
          <button
            className="text-sm text-indigo-500 cursor-pointer hover:underline"
            type="button"
            onClick={() => handleUpdateValue(i, 'tags', [])}
          >
            reset
          </button>
        )}
      </div>
      <span className="text-black text-sm">|</span>
      <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
        <div
          className={`${entryRow?.productId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
        >
          <DropdownFull
            options={
              entryRow?.productId?.product?.status === 'inactive'
                ? [{ ...entryRow?.productId?.product }, ...dropdownProducts]
                : dropdownProducts
            }
            selected={entryRow?.productId?.value || null}
            setSelected={(value) => {
              handleUpdateValue(i, 'productId', value);
            }}
            name="name"
            scrollIntoView={false}
            search
            height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!entryRow?.productId?.value ? '!text-indigo-500' : ''}`}
            buttonTextClassSub={`!text-sm ${!entryRow?.productId?.value ? '!text-indigo-500' : '!text-black'}`}
            flyout
            noMinW
            placeholder="Product or Service"
            bottomOfTable
            className="whitespace-nowrap truncate !h-fit"
            ids={[
              'journalEntriesContainer',
              'createJournalEntryTableContainer',
            ]}
            isTableDropdown
            showDropdownChevron={false}
            searchPlaceholder="Type To Search"
            handleSearch={(value) =>
              handleProductSearch(value, entryRow?.productId?.value)
            }
            resetSearch={resetProductSearch}
          />
        </div>
        {entryRow?.productId?.value && (
          <button
            className="text-sm text-indigo-500 cursor-pointer hover:underline"
            type="button"
            onClick={() => handleUpdateValue(i, 'productId', null)}
          >
            reset
          </button>
        )}
      </div>
    </div>
  );
};

export default TableEntryItemsDropdowns;
