import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import {
  ADMIN,
  FIRM_ID,
  IS_ACCOUNTANT,
  STAFF,
  SUPPORT,
  TEAM_ID,
  TEAM_NAME,
} from '../../utils/Utils';
import TextInput from '../../components/TextInput';
import { signIn, twoFactorAuth } from '../../API/backend_helper';
import GreyMask from '../../images/grey-mask.svg';
import Logo from '../../images/custom/count-logo.png';
import { setAccessType, setLocalUser } from '../../redux/User/reducer';

const GuestSignUp = () => {
  document.title = 'COUNT | Guest Sign Up';

  const IsAccountant = localStorage.getItem(IS_ACCOUNTANT)
    ? JSON.parse(localStorage.getItem(IS_ACCOUNTANT))
    : null;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const defaultMail = decodeURI(searchParams.get('email') || '');
  const firstName = decodeURI(searchParams.get('firstName') || '');
  const lastName = decodeURI(searchParams.get('lastName') || '');
  const teamName = decodeURI(searchParams.get('team') || '');
  const isNewUser = decodeURI(searchParams.get('isnewuser') || '');
  const firm = decodeURI(searchParams.get('firm') || '');

  const navigate = useNavigate();

  const { localUser } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(false);
  const [isSignin, setIsSignin] = useState('');
  const [otp, setOtp] = useState('');

  const AccessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (
      AccessToken &&
      localUser?.email?.toLowerCase() === defaultMail?.toLowerCase()
    ) {
      navigate(
        IsAccountant
          ? '/accountant/invites'
          : localUser?.firmInvites?.length > 0
            ? '/manage-workspaces/firm-invites'
            : '/manage-workspaces/my-invites',
      );
    }
  }, [localUser]);

  useEffect(() => {
    if (isNewUser) {
      if (isNewUser === 'true') {
        navigate(`/signup?email=${encodeURIComponent(defaultMail)}`);
      }
    }
  }, [isNewUser]);

  const onLogin = (response) => {
    const token = response?.auth?.access_token;
    const refreshToken = response?.auth?.refresh_token;
    const twoFactorToken = response?.auth?.twoFactorToken;

    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('twoFactorToken', twoFactorToken);

    dispatch(setLocalUser(response?.user));

    // For Bookkeeper
    if (response?.user?.isAccountingFirmUser) {
      localStorage.setItem(IS_ACCOUNTANT, 'true');
      if (response?.user?.accountingFirms?.length > 0) {
        localStorage.setItem(FIRM_ID, response?.user?.accountingFirms?.[0]?.id);
        dispatch(setAccessType(ADMIN));
        navigate('/accountant/invites');
      } else {
        navigate('/practice/new');
      }
      return;
    }
    // For Staff User
    if (response?.user?.realm === STAFF || response?.user?.realm === SUPPORT) {
      navigate('/manage-workspaces');
      return;
    }

    if (response?.user?.teams?.length > 0) {
      const teamId = response?.user?.teams?.[0]?.id;
      localStorage.setItem(TEAM_ID, teamId);
      localStorage.setItem(TEAM_NAME, response?.user?.teams?.[0]?.name);
      navigate(
        response?.user?.firmInvites?.length > 0 ||
          response?.user?.ownerInvitaions?.length > 0
          ? '/manage-workspaces/firm-invites'
          : '/manage-workspaces/my-invites',
      );
    }
  };

  const onVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await twoFactorAuth(isSignin?.toLowerCase(), otp);
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        if (
          response?.message ===
          'Your email is not verified. Please verify your email first. You can verify by requesting a reset link by clicking forgot password.'
        ) {
          toast.error('Email not verified. Please click resend for a new OTP');
          navigate(`/verify-email?email=${encodeURIComponent(isSignin)}`);
          return;
        }
        onLogin(response);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (email, password) => {
    setLoading(true);
    try {
      const response = await signIn({
        email: email?.toLowerCase(),
        password,
      });
      if (typeof response === 'string') {
        toast.error(response);
      } else if (
        response?.message ===
        'Your email is not verified. Please verify your email first. You can verify by requesting a reset link by clicking forgot password.'
      ) {
        toast.error('Email not verified. Please click resend for a new OTP');
        navigate(`/verify-email?email=${encodeURIComponent(email)}`);
      } else if (response?.require2FA) {
        setIsSignin(email);
      } else {
        onLogin(response);
      }
    } catch (e) {
      // toast.error(e);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative min-h-[100vh] flex flex-col justify-center items-center bg-[#EEEEEE]">
      <img
        src={GreyMask}
        className="absolute w-full h-auto bottom-0 left-0 "
        alt="mask"
      />
      <div className="w-[442px] max-w-[90vw] relative pb-24  mx-auto flex flex-col items-center">
        <img src={Logo} className="h-8 mb-9" alt="logo" />
        <h3 className="font-medium  mb-4 text-[34px]  text-[#232323CC] text-center leading-tight -tracking-[1px]">
          {firstName} {lastName} from “{teamName || firm}” invited you to join
          their team on COUNT
        </h3>
        {!isSignin ? (
          <>
            <div className="text-lg text-[#667085CC] text-center mb-8">
              If you already have an account with COUNT, please login below or
              create a new account by{' '}
              <span
                onClick={() =>
                  navigate(`/signup?email=${encodeURIComponent(defaultMail)}`)
                }
                className="text-[#E48642] underline cursor-pointer"
                role="button"
                tabIndex="0"
                onKeyDown={null}
              >
                Signing Up here
              </span>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                email: decodeURI(defaultMail || ''),
                password: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    'Please enter valid email',
                  )
                  .required('Please Enter Email')
                  .max(100, 'Email must be at most 100 characters'),
                password: Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .matches(/(.*[a-z].*)/, 'At least lowercase letter')
                  .matches(/(.*[A-Z].*)/, 'At least uppercase letter')
                  .matches(/(.*[0-9].*)/, 'At least one number')
                  .required('Please Enter Password'),
              })}
              onSubmit={async (values) => {
                const { email, password } = values;
                onSubmit(email, password);
              }}
            >
              {(validation) => (
                <Form className="w-full">
                  <div className="space-y-8">
                    <TextInput
                      label="Email"
                      type="email"
                      placeholder=" "
                      id="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ''}
                      error={
                        validation.touched.email ? validation.errors.email : ''
                      }
                      inputClass="bg-[#EEEEEE]"
                      labelClass="bg-[#EEEEEE]"
                    />
                    <TextInput
                      label="Password"
                      type="password"
                      placeholder=" "
                      id="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ''}
                      error={
                        validation.touched.password
                          ? validation.errors.password
                          : ''
                      }
                      autoComplete="on"
                      inputClass="bg-[#EEEEEE]"
                      labelClass="bg-[#EEEEEE]"
                    />
                  </div>
                  <div className="flex items-center justify-between mt-5 mb-7">
                    <label className="inline-flex items-center text-slate-600 font-medium">
                      <input
                        type="checkbox"
                        className="form-checkbox mr-[10px] h-[18px] w-[18px]"
                      />{' '}
                      Keep me logged in
                    </label>
                    <div className="mr-1">
                      <Link
                        className="text-sm text-indigo-500 hover:underline"
                        to="/forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <button
                    className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Sign in
                  </button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <div className="flex text-center flex-col items-center max-w-[456px] min-w-[50%] mx-auto w-full  px-4 py-8">
            <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 ">
              Security Check
            </h1>
            <p className="text-lg text-[#667085CC] mb-4">
              We've sent an email to
              <span className="font-medium text-[#667085]">
                {' '}
                {isSignin}
              </span>{' '}
              with your security code. Please check your email and enter the
              code to continue.
            </p>
            <form onSubmit={onVerifyEmail}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                shouldAutoFocus
                renderSeparator={<span className="w-3" />}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="number"
                    className="form-input remove-arrows !h-10 !w-10 rounded-[12px] p-0 border-[#D0D5DD] shadow-none focus:border-indigo-600 focus:!shadow-none"
                  />
                )}
              />
              <button
                className="btn text-lg rounded-[10px] mt-8 mb-6 font-semibold text-white w-full py-3 !bg-indigo-500 disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                disabled={otp?.length !== 6 || loading}
                type="submit"
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Continue
              </button>
            </form>
            <div className="text-lg text-[#667085CC]">
              Wrong account?
              <span
                className="text-indigo-500 font-medium cursor-pointer"
                onClick={() => {
                  setIsSignin('');
                  setOtp('');
                }}
                tabIndex="0"
                onKeyDown={null}
                role="button"
              >
                {' '}
                Back to login
                {/* {resending ? 'Resending ...' : 'Click to resend'} */}
              </span>
            </div>
          </div>
        )}
        {/* Social Login Buttons  */}
        <div className="justify-between gap-2 flex-wrap w-full !hidden">
          <button
            className="mx-auto sm:mx-0 btn gap-3 whitespace-nowrap text-base py-[15px] px-6 bg-white border border-[#E6E8E7] rounded-[10px]"
            type="button"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.16 9.31774C17.16 8.71501 17.1059 8.13547 17.0055 7.5791H9V10.8671H13.5746C13.3775 11.9296 12.7786 12.8298 11.8784 13.4325V15.5653H14.6255C16.2327 14.0855 17.16 11.9064 17.16 9.31774Z"
                fill="#4285F4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99826 17.6252C11.2933 17.6252 13.2174 16.8641 14.6237 15.5659L11.8767 13.4332C11.1155 13.9432 10.1419 14.2446 8.99826 14.2446C6.7844 14.2446 4.91053 12.7493 4.24212 10.7402H1.40234V12.9425C2.80098 15.7205 5.67553 17.6252 8.99826 17.6252Z"
                fill="#34A853"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.24387 10.7399C4.07387 10.2299 3.97728 9.68512 3.97728 9.1249C3.97728 8.56467 4.07387 8.01989 4.24387 7.50989V5.30762H1.40409C0.82841 6.45512 0.5 7.7533 0.5 9.1249C0.5 10.4965 0.82841 11.7947 1.40409 12.9422L4.24387 10.7399Z"
                fill="#FBBC05"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99826 4.00569C10.2462 4.00569 11.3667 4.43455 12.2476 5.27683L14.6855 2.83887C13.2135 1.46727 11.2894 0.625 8.99826 0.625C5.67553 0.625 2.80098 2.52978 1.40234 5.30773L4.24212 7.51001C4.91053 5.50092 6.7844 4.00569 8.99826 4.00569Z"
                fill="#EA4335"
              />
            </svg>{' '}
            Sign in with Google
          </button>
          <button
            className="mx-auto sm:mx-0 btn gap-3 whitespace-nowrap text-base py-[15px] px-6 bg-white border border-[#E6E8E7] rounded-[10px]"
            type="button"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.1001 19.285C19.6901 18.385 19.9101 17.925 20.3601 16.915C17.0401 15.655 16.5101 10.925 19.7901 9.11499C18.7901 7.85499 17.3801 7.125 16.0501 7.125C15.0901 7.125 14.4301 7.37501 13.8401 7.60501C13.3401 7.79501 12.8901 7.965 12.3301 7.965C11.7301 7.965 11.2001 7.77501 10.6401 7.57501C10.0301 7.35501 9.39006 7.125 8.59006 7.125C7.10006 7.125 5.51007 8.035 4.50007 9.595C3.08007 11.795 3.33007 15.915 5.62007 19.435C6.44007 20.695 7.54007 22.105 8.97007 22.125C9.57007 22.135 9.96007 21.955 10.3901 21.765C10.8801 21.545 11.4101 21.305 12.3401 21.305C13.2701 21.295 13.7901 21.545 14.2801 21.765C14.7001 21.955 15.0801 22.135 15.6701 22.125C17.1201 22.105 18.2801 20.545 19.1001 19.285Z"
                fill="#101828"
                fillOpacity="0.8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8404 2.125C16.0004 3.225 15.5504 4.31501 14.9604 5.07501C14.3304 5.89501 13.2304 6.535 12.1704 6.495C11.9804 5.435 12.4704 4.34499 13.0704 3.61499C13.7404 2.81499 14.8704 2.195 15.8404 2.125Z"
                fill="#101828"
                fillOpacity="0.8"
              />
            </svg>{' '}
            Sign in with Apple
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuestSignUp;
