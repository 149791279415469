import ModalBasic from 'components/ModalBasic';
import UploadFile from 'components/UploadFile';
import React, { useEffect, useState } from 'react';

const FileUploadModal = ({ isOpen, setIsOpen, handleFileUpload }) => {
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setFiles([]);
      setImgPreview('');
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleFileAdd = async () => {
    setIsLoading(true);
    try {
      await handleFileUpload(files?.[0]);
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalBasic modalOpen={isOpen} setModalOpen={setIsOpen} title="Add File">
      <div className="w-full px-[30px] py-4 pb-6">
        <div className="flex flex-col gap-2.5">
          <UploadFile
            onFilesAdded={(files) => {
              setFiles((prevFiles) => [...prevFiles, ...files]);
              if (files[0]) {
                setImgPreview(files[0].preview);
              }
            }}
            name="bill"
            id="bill"
            imgPreview={imgPreview}
            setImgPreview={setImgPreview}
            files={files}
            setFiles={setFiles}
            // loading={isLoading}
            title="Upload File"
            allFileTypes
          />
        </div>
      </div>
      <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
        <button
          className="w-[5.625rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          type="button"
        >
          No
        </button>
        <button
          className="w-[5.625rem] h-11 
       disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed 
       flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
          disabled={isLoading || !files?.[0]}
          type="button"
          onClick={handleFileAdd}
        >
          {isLoading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Add
        </button>
      </div>
    </ModalBasic>
  );
};

export default FileUploadModal;
