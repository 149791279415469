import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { shutdown } from '@intercom/messenger-js-sdk';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  approveEstimateBySignature,
  getCountriesData,
  getPublicInvoiceById,
} from '../../API/backend_helper';
import CountLogo from '../../images/custom/count-logo.png';
import { getCurrencySymbolFromIso } from '../../utils/Utils';
import ReceiptModal from '../transactions/ReceiptModal';
import receiptIcon from '../../images/receipts-icon.png';
import { Loader } from '../../components/Svg';
import emptySearchImg from '../../images/custom/empty-search.svg';
import PdfContent from './InvoicePublicComponent';
import downloadPdf from '../../components/DownloadPdf';
import FontLoader from '../../components/FontLoader';

const InvoicePublicView = () => {
  const { invoice } = useParams();
  const invoiceRef = useRef(null);

  const { currencies } = useSelector((state) => state.Currencies);

  const [details, setDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [countries, setCountries] = useState([]);
  const [signature, setSignature] = useState('');
  const [signLoading, setSignLoading] = useState(false);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      shutdown();
    }, 1000);
  }, []);

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getInvoiceByIdApi = async (id) => {
    try {
      const res = await getPublicInvoiceById(id);
      if (res?.data?.invoice?.isDraft) {
        setLoading(false);
        setIsError(true);
        return;
      }
      if (res?.data?.invoice?.invoiceProducts?.length > 0) {
        const tempProducts = [];
        res?.data?.invoice?.invoiceProducts?.forEach((eI) => {
          let taxPercentage = 0;

          if (eI?.taxes?.length > 0) {
            eI?.taxes?.forEach(
              (t) =>
                (taxPercentage =
                  taxPercentage + parseFloat(t?.percentage ?? 0)),
            );
          }
          tempProducts?.push({
            id: eI?.id,
            name: eI?.product?.name,
            description:
              eI?.description ||
              eI?.priceDetails?.description ||
              eI?.product?.description,
            // categoryId: { value: null, error: false },
            quantity: eI?.quantity,
            unitPrice: parseFloat(eI?.price || eI?.product?.unitPrice),
            taxPercentage: taxPercentage,
            invoiceTaxes: eI?.taxes,
            categoryAccount: eI?.categoryAccount?.name || '--',
            total: parseFloat(eI?.price),
          });
        });
        setProducts(tempProducts);
      }
      setDetails(res?.data?.invoice);
      setLoading(false);
    } catch (err) {
      setIsError(true);
      console.log('err', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invoice) {
      setLoading(true);
      loadCountries();
      getInvoiceByIdApi(invoice);
    }
  }, [invoice]);

  const getCountryName = () => {
    let country = null;
    if (countries?.length > 0) {
      country = countries?.find(
        (cnt) => cnt?.isoCode === details?.team?.country,
      );
    }
    return country?.name;
  };

  const currencySymbol = useMemo(() => {
    if (currencies?.length > 0 && details?.currency)
      return getCurrencySymbolFromIso(details?.currency, currencies);
  }, [currencies, details]);

  const handleReceiptModalOpen = (receiptId) => {
    setActiveReceipt(
      details?.attachments.find((receipt) => receipt.id === receiptId),
    );
    setReceiptModalOpen(true);
  };

  const approveEstimate = async () => {
    try {
      setSignLoading(true);
      const res = await approveEstimateBySignature(invoice, {
        signature,
      });
      await getInvoiceByIdApi(invoice);
    } catch (err) {
      console.log('err', err);
    } finally {
      setSignLoading(false);
    }
  };

  const handlePdfDownload = async () => {
    setPdfDownloadLoading(true);
    try {
      await downloadPdf(
        'invoiceCapture',
        `${details?.invoiceNumber}-${details?.invoiceTitle}.pdf`,
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setPdfDownloadLoading(false);
    }
  };

  return (
    <>
      {details?.font && (
        <FontLoader font={details?.font?.replace(/\s/g, '+')} />
      )}
      <div className="min-h-dvh	bg-[#efefef]">
        <ReceiptModal
          isOpen={receiptModalOpen}
          setIsOpen={setReceiptModalOpen}
          receipt={activeReceipt?.documentUrl}
          isExpenseReporting
          type={activeReceipt?.type}
          title={activeReceipt?.title || activeReceipt?.name}
        />
        <nav className="h-[81px] w-full flex bg-[#101828] items-center justify-between px-10">
          <a href="https://www.getcount.com/" target="_blank" rel="noreferrer">
            <img className="h-8 cursor-pointer" src={CountLogo} alt="logo" />
          </a>
          <h3 className="text-[22px] text-white font-medium">
            {details?.businessName || ''}
          </h3>
        </nav>
        {!loading && isError ? (
          <div className="flex flex-col gap-5 items-center justify-center w-full">
            <img
              src={emptySearchImg}
              alt="No vendors found"
              className="h-[12.5rem]"
            />
            <h6 className="text-center mt-6 text-xl">
              This is an invalid Invoice link. Please ask the person who shared
              this link with you to provide a new one.
            </h6>
          </div>
        ) : loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                stroke-width="2.55882"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                stroke-width="1.58955"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="w-[1400px] mt-[30px] md:mt-[100px] pb-[20px] max-w-[90vw] mx-auto grid grid-cols-12 gap-[18px]">
            <div className="col-span-12 lg:col-span-8  h-fit relative">
              <div
                className="fixed top-0 left-0 -translate-x-full -translate-y-full bg-white border border-[#D0D5DD] rounded-[6px] shadow-button w-[900px]"
                id="invoiceCapture"
                ref={invoiceRef}
              >
                <PdfContent
                  isPdfPrint
                  details={details}
                  currencySymbol={currencySymbol}
                  getCountryName={getCountryName}
                  products={products}
                />
              </div>
              <div className="rounded-[10px] bg-white border border-[#D0D5DD] rounded-[6px] shadow-button">
                <PdfContent
                  details={details}
                  currencySymbol={currencySymbol}
                  getCountryName={getCountryName}
                  products={products}
                />
              </div>
              {details?.invoiceType === 'estimate' && !details?.signature && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    approveEstimate();
                  }}
                  className="py-4 flex items-end gap-4 flex-wrap md:flex-nowrap"
                >
                  <div className="w-[400px] max-w-full">
                    <label className="text-sm mb-[2px] font-medium break-words">
                      Signed By{' '}
                      {signature
                        ? `${signature} on ${moment().format('MMMM DD, YYYY')}`
                        : ''}
                    </label>
                    <div className="relative h-16 w-[400px] max-w-full">
                      <input
                        className="h-full w-full pb-4 px-4 !text-[24px] outline-none form-input border border-[#D0D5DD] rounded-[6px] shadow-button "
                        value={signature}
                        onChange={(e) => setSignature(e.target.value)}
                        style={{ fontFamily: 'Style Script' }}
                      />
                      <div
                        className="absolute bottom-1.5 left-0 mx-4 text-xs border-t border-[#D0D5DD] "
                        style={{ width: 'calc(100% - 32px)' }}
                      >
                        Please type your name to approve estimate
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={signLoading || !signature}
                    className="h-10 min-w-[80px] mb-[13px] inline-flex items-center gap-1 px-5 border border-indigo-500 shadow-button disabled:bg-indigo-400 disabled:cursor-not-allowed rounded-[5px] bg-indigo-500 text-white text-indigo-500 text-base"
                  >
                    {signLoading && (
                      <Loader color="#ffffff" width="w-5" height="h-5" />
                    )}{' '}
                    Approve
                  </button>
                </form>
              )}
              {details?.invoiceType === 'estimate' && details?.signature && (
                <div className="py-4 flex gap-1">
                  Signed By{' '}
                  <span
                    className="text-2xl"
                    style={{ fontFamily: 'Style Script' }}
                  >
                    {details?.signature}
                  </span>{' '}
                  on{' '}
                  <span className="font-medium pl-0 ml-0">
                    {moment(details?.signedDate)
                      .local()
                      .format('MMM DD, YYYY, hh:mm A')}
                  </span>
                </div>
              )}
            </div>

            {/* Attachments  */}
            <div className="col-span-12 lg:col-span-4">
              {/* <p className="text-slate-600 leading-5  font-medium text-base">
                Invoice Attachments ({details?.attachments?.length})
              </p> */}
              <div className=" bg-white  px-4 pt-2 pb-8 border border-[#D0D5DD] rounded-[6px] h-fit">
                {details?.attachments?.length > 0 ? (
                  details?.attachments?.map((receipt) => (
                    <div
                      key={receipt?.id}
                      className="border border-slate-600 rounded-md w-full h-[3.125rem] flex justify-between items-center bg-gray-300 bg-opacity-10 pl-[0.313rem] pr-3 py-3 mb-2.5 mt-6"
                    >
                      <div className="flex items-center gap-2">
                        {receipt?.documentUrl?.endsWith('.pdf') ||
                        receipt?.documentUrl?.endsWith('.PDF') ? (
                          <div className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem] flex items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="30"
                              width="30"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="#e48642"
                                d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                              />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={receipt?.documentUrl}
                            className="border border-slate-600 rounded-[0.313rem] w-[2.438rem] h-[2.438rem]"
                          />
                        )}
                        <p className="text-sm leading-tight break-all">
                          {receipt?.title || receipt?.name}
                        </p>
                      </div>
                      <div className="flex justify-end items-center gap-4">
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => handleReceiptModalOpen(receipt?.id)}
                        >
                          <path
                            d="M12.4956 10.0019C12.4956 11.5315 11.2595 12.7675 9.72996 12.7675C8.20038 12.7675 6.96436 11.5315 6.96436 10.0019C6.96436 8.47235 8.20038 7.23633 9.72996 7.23633C11.2595 7.23633 12.4956 8.47235 12.4956 10.0019Z"
                            stroke="#667085"
                            stroke-width="1.55"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.73046 16.3887C12.4574 16.3887 14.999 14.7818 16.7681 12.0008C17.4633 10.9115 17.4633 9.08065 16.7681 7.99141C14.999 5.21035 12.4574 3.60352 9.73046 3.60352C7.00347 3.60352 4.4619 5.21035 2.69284 7.99141C1.99757 9.08065 1.99757 10.9115 2.69284 12.0008C4.4619 14.7818 7.00347 16.3887 9.73046 16.3887Z"
                            stroke="#667085"
                            stroke-width="1.55"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-center mt-[1.875rem] gap-5">
                    <img src={receiptIcon} alt="receipt icon" />
                    <p>No attachments</p>
                  </div>
                )}
              </div>
              <div className="flex gap-4 flex-wrap mt-7">
                {details?.paymentStatus !== 'paid' &&
                  details?.invoiceType === 'invoice' &&
                  details?.paymentMethods?.length > 0 &&
                  details?.team?.stripeAccountId &&
                  parseInt(details?.team?.stripeAlertCount) === 0 &&
                  parseInt(details?.team?.pendingVerificationCount) === 0 &&
                  parseInt(details?.team?.missingRequiredFieldsCount) === 0 &&
                  parseInt(details?.team?.onboardingCount) === 0 && (
                    <Link to={`/invoice-payment/${invoice}`} target="_blank">
                      <button
                        className=" h-10 px-4 text-white text-base font-medium rounded-[4px] "
                        style={
                          details?.color
                            ? { backgroundColor: `#${details?.color}` }
                            : {}
                        }
                        type="button"
                      >
                        Pay Invoice Now
                      </button>
                    </Link>
                  )}

                <button
                  className="h-10 px-4 min-w-40 inline-flex justify-center items-center text-white text-base font-medium rounded-[4px] capitalize whitespace-nowrap border disabled:cursor-not-allowed disabled:opacity-80
                  "
                  style={
                    details?.color
                      ? {
                          borderColor: `#${details?.color}`,
                          color: `#${details?.color}`,
                        }
                      : {}
                  }
                  type="button"
                  onClick={handlePdfDownload}
                  disabled={pdfDownloadLoading}
                >
                  {pdfDownloadLoading ? (
                    <Loader color={`#${details?.color}`} height="h-5 w-5" />
                  ) : (
                    `Download ${details?.invoiceType}`
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InvoicePublicView;
