import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getFormattedDate, TEAM_DATE_FORMAT } from 'utils/Utils';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import WarningBox from '../../components/WarningBox';
import DatePickerDash from '../../components/DatePickerDash';

const ReccuringModal = ({
  isOpen,
  setIsOpen,
  validation,
  getWarningMessage,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Set Recurring Frequency"
      width="max-w-2xl"
    >
      <div className="p-[30px]">
        <div className="flex  gap-4 mb-2">
          <div className=" flex-1 mb-4">
            <DatePickerDash
              label="First Due Date"
              required
              placeholder="Select Date"
              value={new Date(validation.values.firstDueDate) || null}
              onChange={(selectedDates) => {
                const formattedDate = getFormattedDate(selectedDates[0]);
                validation.setFieldValue('firstDueDate', formattedDate);
              }}
              options={options}
              name="firstDueDate"
              id="firstDueDate"
              onBlur={(e) => {
                if (!e.target.classList?.value?.includes('active')) {
                  validation.setFieldValue('firstDueDate', e.target.value);
                }
              }}
            />
          </div>
          <div className="flex flex-col flex-1 gap-0">
            <label
              className={`text-sm font-medium text-slate-600 block mb-2 ${
                validation.touched.recurringFrequency &&
                validation.errors.recurringFrequency
                  ? '!text-rose-400'
                  : ''
              }`}
            >
              Repeats Every
            </label>

            <DropdownFull
              options={[
                {
                  id: 'daily',
                  name: 'Day',
                },
                {
                  id: 'weekly',
                  name: 'Week',
                },
                {
                  id: 'biweekly',
                  name: 'Bi-Weekly',
                },
                {
                  id: 'monthly',
                  name: 'Month',
                },
                {
                  id: 'semiannually',
                  name: 'Semi-Annual',
                },
                {
                  id: 'annually',
                  name: 'Year',
                },
              ]}
              className="w-full !h-fit"
              name="name"
              setSelected={(id) => {
                validation.setFieldValue('recurringFrequency', id);
              }}
              selected={validation.values?.recurringFrequency}
              error={
                validation.touched.recurringFrequency &&
                validation.errors.recurringFrequency
              }
            />
          </div>
        </div>
        <div className="col-span-full sm:col-span-full md:col-span-6 ">
          <label
            className="block text-sm font-medium mb-1 text-slate-600"
            htmlFor="email"
          >
            Ends
          </label>
          <div className="flex  w-[311px] justify-between items-center h-12">
            <label className="flex items-center">
              <input
                type="radio"
                name="ends"
                className="form-radio"
                value=""
                onChange={validation.handleChange}
                checked={validation.values.ends === ''}
              />
              <span className="text-sm ml-2">Never</span>
            </label>
          </div>
          <div className="flex  w-[311px] justify-between items-center h-12">
            <label className="flex items-center">
              <input
                type="radio"
                name="ends"
                className="form-radio"
                value="on"
                onChange={validation.handleChange}
                checked={validation.values.ends === 'on'}
              />
              <span className="text-sm ml-2">On</span>
            </label>
            {validation.values.ends === 'on' && (
              <div>
                <DatePickerDash
                  placeholder="Select Date"
                  value={new Date(validation.values.recurringLastDate) || null}
                  onChange={(selectedDates) => {
                    const formattedDate = getFormattedDate(selectedDates[0]);
                    validation.setFieldValue(
                      'recurringLastDate',
                      formattedDate,
                    );
                  }}
                  options={options}
                  name="recurringLastDate"
                  id="recurringLastDate"
                  error={
                    validation.touched.recurringLastDate &&
                    validation.errors.recurringLastDate
                      ? validation.errors.recurringLastDate
                      : ''
                  }
                  onBlur={(e) => {
                    if (!e.target.classList?.value?.includes('active')) {
                      validation.setFieldValue(
                        'recurringLastDate',
                        e.target.value,
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex justify-between w-[311px] items-center mt-1 h-12">
            <label className="flex items-center">
              <input
                type="radio"
                name="ends"
                className="form-radio"
                value="after"
                defaultChecked
                onChange={validation.handleChange}
                checked={validation.values.ends === 'after'}
              />
              <span className="text-sm ml-2">After</span>
            </label>
            {validation.values.ends === 'after' && (
              <div className="relative h-full">
                <div
                  className="absolute top-[50%] right-4 "
                  style={{ transform: 'translateY(-50%)' }}
                >
                  Occurrences
                </div>
                <input
                  name="recurringOccurrences"
                  id="recurringOccurrences"
                  className={`form-input h-full pr-[120px] border-[#D0D5DD] w-[200px] ${
                    validation.touched.recurringOccurrences &&
                    validation.errors.recurringOccurrences &&
                    'border-rose-300'
                  }`}
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.recurringOccurrences || ''}
                  min={1}
                  required={validation.values.ends === 'after'}
                  onWheel={(e) => e.target.blur()}
                />
                {validation.touched.recurringOccurrences &&
                validation.errors.recurringOccurrences ? (
                  <div className="text-xs mt-1 text-rose-500">
                    {validation.errors.recurringOccurrences}
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {validation.values.firstDueDate &&
            validation.values.recurringFrequency &&
            (validation.values.ends ||
              validation.values.recurringLastDate ||
              validation.values.recurringOccurrences) && (
              <WarningBox
                content={getWarningMessage(
                  validation.values.firstDueDate,
                  validation.values.recurringFrequency,
                  validation.values.ends,
                  validation.values.recurringLastDate,
                  validation.values.recurringOccurrences,
                )}
                className="mt-8"
              />
            )}
        </div>
      </div>
      <footer className="px-[30px] py-[27px] border-t border-[#D0D5DD] flex justify-end gap-4">
        <button
          onClick={() => {
            validation.setFieldValue('recurring', false);
            setIsOpen(false);
          }}
          type="button"
          className="h-[42px] border border-[#667085] text-[#667085] rounded-[5px] font-medium w-[110px]"
        >
          Clear
        </button>
        <button
          onClick={() => {
            validation.setFieldValue('recurring', true);
            setIsOpen(false);
          }}
          type="button"
          className="h-[42px] bg-indigo-500 border border-indigo-500 text-white rounded-[5px] font-medium w-[110px]"
        >
          Done
        </button>
      </footer>
    </ModalBasic>
  );
};

export default ReccuringModal;
