/* eslint-disable jsx-a11y/media-has-caption */
import Tooltip from 'components/Tooltip';
import React from 'react';
import { checkImageByLink, checkVideoByLink, TEAM_ID } from 'utils/Utils';

const FilesTable = ({
  folders = [],
  files = [],
  azureSasToken,
  getFileName,
  setDeleteModalOpen,
  setActiveDeleteItem,
  parentFolder,
  handleFolderChange,
  setAttachmentModalOpen,
  setActiveAttachment,
  setActiveFilename,
  setFolderModalOpen,
  checkSystemDefaultFolder,
}) => {
  const TeamId = localStorage.getItem(TEAM_ID);
  const BASE_URL = process.env.REACT_APP_FILE_SYSTEM_URL;

  const downloadFile = (url, title) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="overflow-x-auto h-full" id="filesTableContainer">
      <table className="table-fixed w-full dark:text-slate-300">
        {/* Table header */}
        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
          <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
            <th className="px-4 w-[18rem] sm:w-full min-w-[18rem] max-w-[18rem]">
              <div className="text-left w-[6.25rem] font-medium">Name</div>
            </th>
            <th className="min-w-[9rem] w-[9rem] max-w-[9rem]">
              <div className="text-left font-medium">Actions</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        {folders?.length > 0 && (
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {folders?.map((folder, index) => (
              <tr
                key={index}
                onClick={() => {
                  handleFolderChange(
                    parentFolder
                      ? `${parentFolder}/${getFileName(folder)}`
                      : `${getFileName(folder)}`,
                  );
                }}
                className="cursor-pointer w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                <td className="px-4">
                  <div className="flex gap-4 items-center">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 stroke-indigo-500 text-indigo-500"
                    >
                      <path
                        d="M22.3182 19C22.3182 19.5304 22.1075 20.0391 21.7325 20.4142C21.3574 20.7893 20.8487 21 20.3182 21H4.31824C3.7878 21 3.2791 20.7893 2.90402 20.4142C2.52895 20.0391 2.31824 19.5304 2.31824 19V5C2.31824 4.46957 2.52895 3.96086 2.90402 3.58579C3.2791 3.21071 3.7878 3 4.31824 3H9.31824L11.3182 6H20.3182C20.8487 6 21.3574 6.21071 21.7325 6.58579C22.1075 6.96086 22.3182 7.46957 22.3182 8V19Z"
                        className="stroke-current"
                        strokeWidth="1.63636"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                      {getFileName(folder)}
                    </div>
                  </div>
                </td>

                <td className="whitespace-nowrap pr-4">
                  <div className="inline-flex items-center gap-3.5">
                    {/* Edit Button  */}
                    <Tooltip
                      content={
                        checkSystemDefaultFolder(getFileName(folder)) ? (
                          <>
                            <p>The system default folder</p>
                            <p>cannot be modified</p>
                          </>
                        ) : (
                          'Edit'
                        )
                      }
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      className="h-7 w-7"
                      isFixed
                      ids={['filesTableContainer', 'filesContainer']}
                    >
                      <button
                        className="h-7 w-7 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                        type="button"
                        disabled={checkSystemDefaultFolder(getFileName(folder))}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveFilename({ name: folder, type: 'folder' });
                          setFolderModalOpen(true);
                        }}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                            fill="white"
                            stroke="#D0D5DD"
                            strokeOpacity="0.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                            stroke="#858D9D"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                    {/* Delete Button  */}
                    <Tooltip
                      content={
                        checkSystemDefaultFolder(getFileName(folder)) ? (
                          <>
                            <p>The system default folder</p>
                            <p>cannot be deleted</p>
                          </>
                        ) : (
                          'Delete'
                        )
                      }
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      className="h-7 w-7"
                      isFixed
                      ids={['filesTableContainer', 'filesContainer']}
                    >
                      <button
                        className="h-7 w-7 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveDeleteItem({
                            name: getFileName(folder),
                            path: folder,
                            type: 'folder',
                          });
                          setDeleteModalOpen(true);
                        }}
                        disabled={checkSystemDefaultFolder(getFileName(folder))}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                            fill="white"
                            stroke="#D0D5DD"
                            strokeOpacity="0.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                            stroke="#858D9D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.693 16.3574H14.8505"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1552 13.7656H15.3948"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
        {files?.length > 0 && (
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {files?.map((file, index) => (
              <tr
                key={index}
                onClick={() => {
                  setActiveAttachment({
                    fileUrl: `${BASE_URL}/files/team-${TeamId}/${file}?${azureSasToken}`,
                    fileName: getFileName(file),
                  });
                  setAttachmentModalOpen(true);
                }}
                className="cursor-pointer w-full pl-[1.375rem]  pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                <td className="px-4">
                  <div className="flex gap-4 items-center">
                    {file?.toLowerCase()?.endsWith('.pdf') ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30"
                        width="30"
                        viewBox="0 0 512 512"
                        className="w-6"
                      >
                        <path
                          fill="#e48642"
                          d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                        />
                      </svg>
                    ) : checkVideoByLink(file) ? (
                      <div className="relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  w-2 fill-[#667085]"
                        >
                          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                        </svg>
                        <video
                          src={`${BASE_URL}/files/team-${TeamId}/${file}?${azureSasToken}`}
                          className="h-6 w-6 object-contain rounded"
                        />
                      </div>
                    ) : checkImageByLink(file) ? (
                      <img
                        src={`${BASE_URL}/files/team-${TeamId}/${file}?${azureSasToken}`}
                        className=" h-6 w-6 object-contain rounded"
                        alt="attachment"
                      />
                    ) : (
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-indigo-500 text-indigo-500 h-6 w-6"
                      >
                        <path
                          d="M14.3182 2H6.31824C5.7878 2 5.2791 2.21071 4.90402 2.58579C4.52895 2.96086 4.31824 3.46957 4.31824 4V20C4.31824 20.5304 4.52895 21.0391 4.90402 21.4142C5.2791 21.7893 5.7878 22 6.31824 22H18.3182C18.8487 22 19.3574 21.7893 19.7325 21.4142C20.1075 21.0391 20.3182 20.5304 20.3182 20V8L14.3182 2Z"
                          className="stroke-current"
                          strokeWidth="1.63636"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.3182 2V8H20.3182"
                          className="stroke-current"
                          strokeWidth="1.63636"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3182 13H8.31824"
                          className="stroke-current"
                          strokeWidth="1.01651"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3182 17H8.31824"
                          className="stroke-current"
                          strokeWidth="1.01651"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.3182 9H9.31824H8.31824"
                          className="stroke-current"
                          strokeWidth="1.01651"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}

                    <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                      {getFileName(file)}
                    </div>
                  </div>
                </td>

                <td className="whitespace-nowrap pr-4">
                  <div className="flex items-center gap-3.5">
                    {/* Edit Button  */}
                    <Tooltip
                      content={
                        checkSystemDefaultFolder() ? (
                          <>
                            <p>The system generated file</p>
                            <p>cannot be modified</p>
                          </>
                        ) : (
                          'Edit'
                        )
                      }
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      className="h-7 w-7"
                      isFixed
                      ids={['filesTableContainer', 'filesContainer']}
                    >
                      <button
                        className="h-7 w-7 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                        type="button"
                        disabled={checkSystemDefaultFolder()}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveFilename({ name: file, type: 'file' });
                          setFolderModalOpen(true);
                        }}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                            fill="white"
                            stroke="#D0D5DD"
                            strokeOpacity="0.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                            stroke="#858D9D"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    {/* Download Button  */}
                    <Tooltip
                      content="Download"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      className="mt-1"
                    >
                      <button
                        className="bg-white inline-flex justify-center items-center h-[26px] w-[26px] border border-[#D0D5DD] rounded-[8px]"
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadFile(
                            `${BASE_URL}/files/team-${TeamId}/${file}?${azureSasToken}`,
                            getFileName(file),
                          );
                        }}
                        type="button"
                      >
                        <div className="sr-only">Download</div>
                        <svg
                          width="28px"
                          height="28px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="stroke-[#858D9D] text-[#858D9D] h-[18px] w-[18px]"
                        >
                          <path
                            d="M5.25589 16C3.8899 15.0291 3 13.4422 3 11.6493C3 9.20008 4.8 6.9375 7.5 6.5C8.34694 4.48637 10.3514 3 12.6893 3C15.684 3 18.1317 5.32251 18.3 8.25C19.8893 8.94488 21 10.6503 21 12.4969C21 14.0582 20.206 15.4339 19 16.2417M12 21V11M12 21L9 18M12 21L15 18"
                            className="stroke-current"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    {/* Delete Button  */}
                    <Tooltip
                      content={
                        checkSystemDefaultFolder() ? (
                          <>
                            <p>The system generated file</p>
                            <p>cannot be deleted</p>
                          </>
                        ) : (
                          'Delete'
                        )
                      }
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      className="h-7 w-7"
                      isFixed
                      ids={['filesTableContainer', 'filesContainer']}
                    >
                      <button
                        className="h-7 w-7 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveDeleteItem({
                            name: getFileName(file),
                            path: file,
                            type: 'file',
                          });
                          setDeleteModalOpen(true);
                        }}
                        disabled={checkSystemDefaultFolder()}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                            fill="white"
                            stroke="#D0D5DD"
                            strokeOpacity="0.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                            stroke="#858D9D"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.693 16.3574H14.8505"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1552 13.7656H15.3948"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
        {!(files?.length > 0 || folders?.length > 0) && (
          <tbody className="text-sm border-gray-300">
            <tr className="text-center w-full pl-[1.375rem]  pr-[1.125rem] h-20 ">
              <td colSpan={2} className="px-4 text-center">
                <div className=" break-words text-center text-[0.938rem] font-medium leading-6  text-slate-600">
                  No data found
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default FilesTable;
