import React from 'react';
import CongratsImage from '../../images/congrats.gif';

const OnBoardSuccess = ({ handleOnBoardComplete }) => (
  <div>
    <img src={CongratsImage} alt="gif" className="h-40 w-40 mx-auto" />
    <h3 className="text-[20px] text-center font-medium text-[#101828] mb-6 mt-2">
      Congratulations, you’ve completed the COUNT onboarding process!
    </h3>
    <footer className="flex justify-center gap-4">
      <button
        className="h-[38px]  px-4 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
        type="button"
        onClick={handleOnBoardComplete}
      >
        Go To Dashboards
      </button>
    </footer>
  </div>
);

export default OnBoardSuccess;
