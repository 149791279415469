import { deletePendingReceiptById } from 'API/backend_helper';
import PaginationClassic from 'components/PaginationClassic';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import ReceiptModal from 'pages/transactions/ReceiptModal';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  formatNumber,
  getCurrencySymbolFromIso,
  getStringSegments,
  trimText,
} from 'utils/Utils';

const UnmatchedReceiptsTab = ({
  unmatchedReceipts,
  unmatchedReceiptsLimit,
  setUnmatchedReceiptsLimit,
  loadUnmatchedReceipts,
}) => {
  const unmatchedReceiptBoxRef = useRef();
  const { currencies } = useSelector((state) => state.Currencies);
  const { permissions, accessType } = useSelector((state) => state.User);

  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [activeReceiptTitle, setActiveReceiptTitle] = useState(null);
  const [descriptionLength, setDescriptionLength] = useState(30);
  const [receiptsDeleteLoading, setReceiptsDeleteLoading] = useState({});

  const handleResize = () => {
    setDescriptionLength(
      parseInt(unmatchedReceiptBoxRef?.current?.clientWidth / 9),
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [unmatchedReceiptBoxRef?.current, unmatchedReceipts]);

  const handleDeleteReceipt = async (id) => {
    setReceiptsDeleteLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await deletePendingReceiptById(id);
      await loadUnmatchedReceipts({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setReceiptsDeleteLoading((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  return (
    <>
      <ReceiptModal
        isExpenseReporting
        title={activeReceiptTitle}
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt}
      />
      <div className="flex flex-col flex-1">
        <div className="flex-1">
          <div className=" grid sm:grid-cols-2 xl:grid-cols-3 gap-6">
            {unmatchedReceipts?.pendingReceipts?.length > 0 ? (
              unmatchedReceipts?.pendingReceipts?.map((receipt, index) => (
                <div
                  key={receipt?.id}
                  className="bg-gray-100 rounded-[10px] p-4 flex gap-4 border border-gray-300 shadow-button"
                >
                  {receipt?.receiptUrl?.toLowerCase()?.endsWith('.pdf') ? (
                    <div
                      className="w-20 max-h-40 flex pt-4 justify-center"
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        setActiveReceipt(receipt?.receiptUrl);
                        setActiveReceiptTitle(receipt?.vendorName);
                        setReceiptModalOpen(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="30"
                        width="30"
                        viewBox="0 0 512 512"
                        className="w-16 h-16"
                      >
                        <path
                          fill="#e48642"
                          d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
                        />
                      </svg>
                    </div>
                  ) : receipt?.receiptUrl ? (
                    <img
                      src={receipt?.receiptUrl}
                      alt={receipt?.vendorName}
                      className="w-20 max-h-40 object-contain cursor-pointer"
                      onClick={() => {
                        setActiveReceipt(receipt?.receiptUrl);
                        setActiveReceiptTitle(receipt?.vendorName);
                        setReceiptModalOpen(true);
                      }}
                    />
                  ) : null}
                  <div
                    className="flex flex-col gap-1 flex-1"
                    ref={index === 0 ? unmatchedReceiptBoxRef : null}
                  >
                    <h3 className="text-[18px] font-medium text-black leading-tight">
                      {receipt?.vendorName}
                    </h3>

                    <p className="text-black">
                      {moment(receipt?.date?.split('T')?.[0]).format(
                        'DD MMM, yyyy',
                      )}
                      <span className="mx-1"> |</span>
                      {getCurrencySymbolFromIso(receipt?.currency, currencies)}
                      {formatNumber(receipt?.amount)}
                    </p>
                    <p className="text-black">
                      {`${receipt?.people?.firstName} ${receipt?.people?.lastName}`}{' '}
                      {receipt?.peopleId && receipt?.accountId && (
                        <span className="mx-1"> |</span>
                      )}
                      {receipt?.account?.name}
                    </p>
                    <p className="text-black">
                      {receipt?.expenseReportType?.name}
                    </p>
                    <Tooltip
                      className="cursor-default"
                      tooltipShow={
                        receipt?.description?.length > descriptionLength
                      }
                      content={
                        receipt?.description?.length > 30
                          ? (() => {
                              const segments = getStringSegments(
                                receipt?.description,
                                40,
                              );
                              return segments.map((segment, index) => (
                                <p key={index}>{segment}</p>
                              ));
                            })()
                          : receipt?.description
                      }
                      contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    >
                      <p className="text-black ">
                        {trimText(receipt?.description, descriptionLength)}
                      </p>
                    </Tooltip>
                  </div>
                  {checkPermission(
                    PermissionKeys.DELETE_UNMATCHED_RECEIPTS,
                    accessType,
                    permissions,
                  ) && (
                    <Tooltip
                      content="Delete"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      position="left"
                      className="h-fit w-fit"
                    >
                      <button
                        className="w-7 h-7 cursor-pointer disabled:border-[1.2px] disabled:bg-white inline-flex items-center justify-center disabled:border-rose-500 disabled:rounded-[8px] disabled:cursor-not-allowed"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteReceipt(receipt?.id);
                        }}
                        type="button"
                        disabled={receiptsDeleteLoading?.[receipt?.id]}
                      >
                        {receiptsDeleteLoading?.[receipt?.id] ? (
                          <Loader height="h-4 w-4" color="#f43f5e" />
                        ) : (
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-full w-full"
                          >
                            <path
                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                              fill="white"
                              stroke="#f43f5e"
                              strokeOpacity="0.6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                              stroke="#f43f5e"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.693 16.3574H14.8505"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.1552 13.7656H15.3948"
                              stroke="#f43f5e"
                              strokeOpacity="0.8"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </button>
                    </Tooltip>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center col-span-full">
                {' '}
                No Unmatched Receipts Found
              </p>
            )}
          </div>
        </div>
        {unmatchedReceipts?.pendingReceipts?.length > 0 && (
          <div className="mt-10">
            <PaginationClassic
              setLimit={setUnmatchedReceiptsLimit}
              onPageChange={loadUnmatchedReceipts}
              pagination={{
                limit: unmatchedReceiptsLimit,
                page: unmatchedReceipts?.page,
                totalRecords: unmatchedReceipts?.totalRecords,
              }}
              pageLoad
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UnmatchedReceiptsTab;
