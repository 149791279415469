import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getMemberInvitationStatusColor } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import {
  firmInviteBusinessOwner,
  withdrawFirmInviteBusinessOwner,
} from 'API/backend_helper';
import SearchForm from '../../../partials/actions/SearchForm';
import Tooltip from '../../../components/Tooltip';
import InviteClientModal from './InviteClient';

const ClientsTable = ({
  clients,
  setIsOpenModal,
  setLeaveTeam,
  onTeamChange,
  loading,
  accountantFirmId,
  firm,
  loadAccountantFirm,
}) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredOwnerData, setFilteredOwnerData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [inviteClient, setInviteClient] = useState(false);
  const [withdrawFirmMemberLoader, setWithdrawFirmMemberLoader] = useState({});

  useEffect(() => {
    if (clients?.length > 0) {
      const tempTeams = [...clients];
      const sortedTeams = tempTeams?.sort?.(
        (a, b) =>
          moment(b?.createdAt, 'YYYY-MM-DD').toDate() -
          moment(a?.createdAt, 'YYYY-MM-DD').toDate(),
      );
      setFilteredData(sortedTeams);
    } else {
      setFilteredData([]);
    }
  }, [clients]);

  useEffect(() => {
    setFilteredOwnerData(
      firm?.ownerInvitations?.filter((m) => m?.status !== 'accepted'),
    );
  }, [firm?.ownerInvitations]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (!value) {
      setFilteredData(clients || []);
      return;
    }
    const formattedValue = value.replace(/\s/g, '').toLowerCase();
    const filtered = clients?.filter((team) =>
      team?.name?.replace(/\s/g, '')?.toLowerCase()?.includes(formattedValue),
    );

    setFilteredData(filtered);
  };

  const withdrawMemberInvitation = async (invitationId) => {
    setWithdrawFirmMemberLoader((prev) => ({ ...prev, [invitationId]: true }));
    try {
      await withdrawFirmInviteBusinessOwner(accountantFirmId, invitationId);
      await loadAccountantFirm({ loading: false });
    } catch (err) {
      console.log('err', err);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  const resendClientInvite = async (email, invitationId) => {
    try {
      setWithdrawFirmMemberLoader((prev) => ({
        ...prev,
        [invitationId]: true,
      }));
      const res = await firmInviteBusinessOwner(accountantFirmId, {
        email,
      });
      await loadAccountantFirm({ loading: false });
    } catch (e) {
      console.log('error', e);
    } finally {
      setWithdrawFirmMemberLoader((prev) => {
        delete prev[invitationId];
        return prev;
      });
    }
  };

  return (
    <div>
      <div className="flex flex-wrap  mb-5 justify-end gap-5">
        <SearchForm
          searchVal={searchValue}
          setSearchVal={handleSearch}
          placeholder="Search Client"
        />
        <InviteClientModal
          isOpen={inviteClient}
          setIsOpen={setInviteClient}
          accountantFirmId={accountantFirmId}
          loadAccountantFirm={loadAccountantFirm}
        />
        <div>
          <button
            className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white mr-2"
            aria-controls="basic-modal"
            onClick={(e) => {
              e.preventDefault();
              navigate('/workspace/new');
            }}
            type="button"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create Client's Workspace
          </button>
          <button
            className="btn whitespace-nowrap !bg-indigo-500 font-normal px-5 text-base hover:!bg-indigo-600 text-white"
            aria-controls="basic-modal"
            onClick={(e) => {
              e.preventDefault();
              setInviteClient(true);
            }}
            type="button"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2"
            >
              <path
                d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Invite a Client
          </button>
        </div>
      </div>
      <div className="bg-white flex-1 dark:bg-slate-800  rounded-sm overflow-auto">
        {loading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-4">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <>
            {filteredOwnerData?.length > 0 && (
              <>
                <h3 className="mb-4 text-[24px] text-[#666666] font-medium ">
                  Invitations
                </h3>
                <div className="max-w-full overflow-auto scrollbar pb-4 mb-8">
                  <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                    <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                      <tr>
                        {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                        <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                          <div className=" text-left font-medium">Status</div>
                        </th>
                        <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                          <div className=" text-left font-medium">Email</div>
                        </th>

                        <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                          <div className=" text-left font-medium">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                      {filteredOwnerData?.map?.(
                        (d, i) =>
                          d?.status !== 'accepted' && (
                            <tr className="border-b border-slate-300" key={i}>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div
                                  className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getMemberInvitationStatusColor(d?.status)}`}
                                >
                                  {d?.status}
                                </div>
                              </td>
                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div className=" text-[#667085] dark:text-slate-100">
                                  {d?.email}
                                </div>
                              </td>

                              <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
                                <div className="flex justify-start">
                                  <button
                                    onClick={() =>
                                      d?.status === 'withdrawn' ||
                                      d?.status === 'declined'
                                        ? resendClientInvite(d?.email, d?.id)
                                        : withdrawMemberInvitation(d?.id)
                                    }
                                    type="button"
                                    className="ms-2 h-7 w-24 font-normal shadow-md btn-xs cursor-pointer px-4  border-[#D0D5DD66]  hover:border-slate-300  text-[#667085] disabled:cursor-not-allowed disabled:opacity-80"
                                    disabled={withdrawFirmMemberLoader?.[d?.id]}
                                  >
                                    {withdrawFirmMemberLoader?.[d?.id] ? (
                                      <svg
                                        className="animate-spin w-4 h-4 fill-current shrink-0"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                      </svg>
                                    ) : d?.status === 'withdrawn' ||
                                      d?.status === 'declined' ? (
                                      'Resend Invite'
                                    ) : (
                                      'Cancel Invite'
                                    )}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ),
                      )}
                    </tbody>
                  </table>
                </div>
                <h3 className="pb-4 text-[24px] text-[#666666] font-medium">
                  Clients
                </h3>
              </>
            )}
            <div className="max-w-full overflow-auto scrollbar pb-4">
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {/* Table header */}
                <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
                  <tr>
                    {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"></th> */}
                    <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap  w-[25.696rem]">
                      <div className=" text-left font-medium">Workspace</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                      <div className=" text-left font-medium">Type</div>
                    </th>

                    <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                      <div className=" text-left font-medium">Subscription</div>
                    </th>
                    <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                      <div className=" text-left font-medium">
                        Employee Seats
                      </div>
                    </th>
                    <th className="px-5 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                      <div className=" text-left font-medium">
                        Expiration/Renewal Date
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                      <div className=" text-left font-medium">Actions</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700 pb-5">
                  {/* Row */}
                  {filteredData?.map?.((team, index) => (
                    <tr className="border-b border-slate-300" key={team?.id}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-7 h-7 shrink-0 mr-2.5 inline-flex justify-center items-center">
                            {team?.logoUrl ? (
                              <img
                                src={team?.logoUrl}
                                alt={`${team.name} logo`}
                                className="max-h-full max-w-full"
                              />
                            ) : (
                              <div className="w-7 h-7 rounded-full flex items-center justify-center border border-[#7425F4] bg-[#7425F41A]">
                                <div className="uppercase text-[#7425F4] text-xs">
                                  {team?.name?.split(' ')?.[0]?.charAt(0)}
                                  {team?.name?.split(' ')?.[1]?.charAt(0)}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="text-sm text-[#667085] leading-tight mr-1">
                            {team?.name?.length > 60 ? (
                              <Tooltip
                                content={
                                  team?.name?.length > 50
                                    ? (() => {
                                        const segmentLength = 75;
                                        const segments = [];
                                        for (
                                          let i = 0;
                                          i < team?.name?.length;
                                          i += segmentLength
                                        ) {
                                          segments.push(
                                            team?.name?.substring(
                                              i,
                                              i + segmentLength,
                                            ),
                                          );
                                        }
                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : team?.name
                                }
                                contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                                  team?.name?.length > 50 ? '' : ''
                                }`}
                              >
                                <span>{`${team?.name?.slice(0, 60)}...`}</span>
                              </Tooltip>
                            ) : (
                              <span>{team?.name}</span>
                            )}
                          </div>
                          <div className="h-[19px] w-[19px] !hidden inline-flex justify-center items-center bg-[#E48642] rounded-full text-[9px] text-white font-semibold">
                            41
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085] ">
                          {team?.subType && team?.subType !== "I'm not sure"
                            ? team?.subType
                            : team?.type}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085]   capitalize">
                          {team?.id && team?.isOnComplimentary ? (
                            'Complimentary'
                          ) : (
                            <span className="capitalize">
                              {team?.currentSubscription?.name} (
                              {team?.subStatus})
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085]   capitalize">
                          {team?.employeeSeat || 0}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-sm text-[#667085]   capitalize">
                          {team?.teamPlan?.[0]?.isOnComplimentary
                            ? '--'
                            : team?.currentPeriodEnd
                              ? moment(team?.currentPeriodEnd).format(
                                  'MMM Do, YYYY',
                                )
                              : '--'}
                        </div>
                      </td>

                      <td className="px-2 first:pl-5 last:pr-5 py-2.5 whitespace-nowrap ">
                        <div className="flex gap-4">
                          <button
                            className="btn h-10 border-[#E48642] bg-[#E486420D] text-[#E48642] text-sm font-normal px-2.5"
                            onClick={() => onTeamChange(team?.id, team)}
                            type="button"
                          >
                            Go To Workspace
                          </button>

                          <Tooltip
                            className="flex items-center"
                            content={
                              team?.createdByFirmId
                                ? 'Cannot exit Firm-created workspace'
                                : 'Leave Workspace'
                            }
                            position="left"
                            contentClassName="border-none text-center overflow-visible text-sm text-[#667085] relative tooltipArrowRight"
                            tooltipShow
                          >
                            <button
                              type="button"
                              className="border h-[26px] w-[26px] border-[#D0D5DD] rounded-[8px] shadow-button inline-flex justify-center items-center disabled:opacity-80 disabled:cursor-not-allowed"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLeaveTeam({
                                  value: team?.id,
                                  team,
                                  index,
                                });
                                setIsOpenModal(true);
                              }}
                              disabled={team?.createdByFirmId}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="fill-[#858c9e] h-3"
                              >
                                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {filteredData?.length === 0 && filteredOwnerData?.length === 0 && (
              <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
                No Clients Found
              </h6>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientsTable;
