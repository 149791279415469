import React, { useEffect, useRef, useState } from 'react';
import Tooltip from 'components/Tooltip';
import Transition from 'utils/Transition';

const AddButtonDropdown = ({
  setFolderModalOpen,
  setFileModalOpen,
  setActiveFilename,
  checkSystemDefaultFolder,
}) => {
  const options = [
    {
      id: 'folder',
      period: 'New Folder',
    },
    {
      id: 'file',
      period: 'New File',
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleClick = (id) => {
    if (id === 'folder') {
      setActiveFilename(null);
      setFolderModalOpen(true);
    } else if (id === 'file') {
      setFileModalOpen(true);
    }
    setDropdownOpen(false);
  };

  return (
    <Tooltip
      content={
        <>
          <p>Cannot add file or folder in </p>
          <p>the system default folder</p>
        </>
      }
      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
      className="w-fit h-fit"
      isFixed
      ids={['filesContainer']}
      tooltipShow={checkSystemDefaultFolder()}
    >
      <div className="relative inline-flex">
        <button
          ref={trigger}
          className="btn !bg-indigo-500 border-indigo-500 text-white !hover:bg-indigo-600 justify-center gap-2 w-fit min-w-32 h-10   disabled:!bg-indigo-400 disabled:!border-indigo-400 disabled:cursor-not-allowed"
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
          type="button"
          disabled={checkSystemDefaultFolder()}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00033 1.16699V12.8337M1.16699 7.00033H12.8337"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="text-base font-normal">New</span>
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            className="font-medium text-sm text-slate-600 dark:text-slate-300"
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            {options.map((option, i) => (
              <button
                key={option.id}
                tabIndex="0"
                className="flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer"
                type="button"
                onClick={() => {
                  handleClick(option.id);
                  setDropdownOpen(false);
                }}
              >
                <span>{option.period}</span>
              </button>
            ))}
          </div>
        </Transition>
      </div>
    </Tooltip>
  );
};

export default AddButtonDropdown;
