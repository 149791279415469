import React from 'react';

const CheckList = [
  {
    name: 'How do you want to get started?',
    status: 'done',
  },
  {
    name: 'Set Up Company Information',
    status: 'active',
  },
  {
    name: 'Connect Bank Accounts',
    status: 'pending',
  },
  {
    name: 'Invite Team Members',
    status: 'pending',
  },
  {
    name: 'Set Up First Invoice/Expense',
    status: 'pending',
  },
  {
    name: 'Review and Finalize Setup',
    status: 'pending',
  },
];

const CheckItem = ({ number, item, status }) => (
  <div
    className={`p-3 flex  gap-4 justify-between ${status === 'active' ? 'bg-[#F9FCF8]' : ''}`}
  >
    <p
      className={`text-sm  ${status === 'done' ? 'text-[#78BD4F]' : status === 'active' ? 'text-[#101828]' : 'text-[#667085]'}`}
    >
      {number}. {item}
    </p>
    {status === 'done' ? (
      // Green Icon
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="min-h-4 min-w-4 mt-1"
      >
        <g clipPath="url(#clip0_9021_34762)">
          <path
            d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0Z"
            fill="#78BD4F"
          />
          <path
            d="M12.0522 6.3042L7.71887 10.6375C7.58887 10.7675 7.41821 10.8329 7.24756 10.8329C7.0769 10.8329 6.90625 10.7675 6.77625 10.6375L4.60962 8.47083C4.34888 8.21021 4.34888 7.78882 4.60962 7.5282C4.87024 7.26746 5.2915 7.26746 5.55225 7.5282L7.24756 9.22351L11.1096 5.36157C11.3702 5.10083 11.7915 5.10083 12.0522 5.36157C12.3129 5.62219 12.3129 6.04346 12.0522 6.3042Z"
            fill="#FAFAFA"
          />
        </g>
        <defs>
          <clipPath id="clip0_9021_34762">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="min-h-4 min-w-4 mt-1"
      >
        <path
          d="M8 16C3.58887 16 0 12.4111 0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16ZM8 1C4.14013 1 1 4.14013 1 8C1 11.8599 4.14013 15 8 15C11.8599 15 15 11.8599 15 8C15 4.14013 11.8599 1 8 1ZM6.8535 10.8535L11.8535 5.8535C12.0489 5.65812 12.0489 5.34175 11.8535 5.1465C11.6581 4.95125 11.3417 4.95113 11.1465 5.1465L6.5 9.793L4.8535 8.1465C4.65812 7.95112 4.34175 7.95112 4.1465 8.1465C3.95125 8.34188 3.95113 8.65825 4.1465 8.8535L6.1465 10.8535C6.24412 10.9511 6.37213 11 6.5 11C6.62787 11 6.75588 10.9511 6.8535 10.8535Z"
          fill="#101828"
        />
      </svg>
    )}
  </div>
);

const StepsBox = ({
  data = [],
  activeIndex = 0,
  handleOnBoardComplete,
  onBoardedCompleted = false,
}) => (
  <div className="w-full h-fit bg-white shadow-md rounded-[12px] p-6">
    <h3 className="text-[18px] font-medium text-black mb-6">
      Onboarding Steps
    </h3>
    <p className="text-sm text-[#6D7585] mb-4">
      Follow these steps to complete your setup.
    </p>
    <div className="flex flex-col gap-4">
      {data?.map((item, index) => (
        <CheckItem
          key={index}
          number={index + 1}
          item={item}
          status={
            index < activeIndex - 1
              ? 'done'
              : index === activeIndex - 1
                ? 'active'
                : 'pending'
          }
        />
      ))}
    </div>
    {!onBoardedCompleted && (
      <button
        type="button"
        onClick={handleOnBoardComplete}
        className="text-base font-medium text-[#E48642] mt-4"
      >
        Skip Onboarding
      </button>
    )}
  </div>
);

export default StepsBox;
