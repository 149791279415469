import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import UploadFile from 'components/UploadFile';
import ModalBasic from '../../../components/ModalBasic';
import {
  formatDateLocally,
  formatNumber,
  getContrast,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from '../../../utils/Utils';
import DatePickerDash from '../../../components/DatePickerDash';
import {
  saveReconcilation,
  getAccountOpeningBalance,
  uploadFileInFolders,
} from '../../../API/backend_helper';

const EndingStatementModal = ({
  account,
  setAccount,
  isOpen,
  setIsOpen,
  isEdit,
  editSubmit,
  reconcileDetail,
  openingBalance,
  teamCurrency,
}) => {
  // passing account in account prop
  const formikRef = useRef(null);
  const flatpickrRef = useRef(null);
  const navigate = useNavigate();
  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [noClick, setNoClick] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
      onReady(selectedDates, dateStr, instance) {
        flatpickrRef.current = instance;
      },
    }),
    [team],
  );

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm();
      flatpickrRef.current?.close();
      setFiles([]);
      setImgPreview('');
      if (account && setAccount) {
        setAccount?.(null);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (flatpickrRef.current) {
      if (account?.reconcileBalances?.length > 0) {
        flatpickrRef.current.set(
          'minDate',
          moment(account?.reconcileBalances?.[0]?.endingBalanceDate)
            ?.add(1, 'day')
            ?.toISOString(),
        );
      } else if (openingBalance?.openingBalanceDate) {
        flatpickrRef.current.set(
          'minDate',
          moment(openingBalance?.openingBalanceDate)?.add(1, 'day')?.toISOString(),
        );
      } else {
        flatpickrRef.current.set('minDate', '');
      }
    }
    return () => {
      formikRef.current?.resetForm();
    };
  }, [account?.id]);

  const handleFileUpload = async (file, parent = 'Bank Statements') => {
    try {
      const data = new FormData();
      data.append('file', file);
      data.append('folderPath', parent);
      const res = await uploadFileInFolders(data);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const endDate = getFormattedDate(values?.endingBalanceDate, teamDateFormat);
    const data = new FormData();
    data.append('endingBalanceDate', endDate);
    data.append('endingBalanceAmount', values?.endingBalanceAmount);
    if (files?.length > 0) {
      data.append('bank_statement', files?.[0]);
    }
    if (!isEdit) {
      try {
        await getAccountOpeningBalance(account?.id, endDate);
        await saveReconcilation(account?.id, data);
        setAccount(null);
        setLoading(false);
        setIsOpen(false);
        navigate(`/account/reconcile/${account?.id}`);
      } catch (e) {
        setLoading(false);
        console.log('error', e);
      }
    } else {
      await editSubmit(account?.id, data);
      setIsOpen(false);
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      id="endingStatementModal"
      modalOpen={isOpen}
      setModalOpen={() => setIsOpen(false)}
      title="Ending Statement Balance"
      width="!w-[521px] max-w-[90vw]"
      noClick={noClick}
    >
      <div className="">
        <div className="px-5 py-6 border-b border-[#D0D5DD]">
          <p className="text-[#667085CC] font-medium mb-1">Selected Account</p>
          <div className="flex justify-between">
            <div className="flex gap-5 items-center">
              {account?.institution?.logoUrl ? (
                <img
                  src={account?.institution?.logoUrl}
                  alt={account?.name}
                  className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center"
                />
              ) : (
                <div
                  className="w-[1.875rem] h-[1.875rem] min-w-[1.875rem] min-h-[1.875rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                  style={
                    account?.color || account?.institution?.color
                      ? {
                          backgroundColor:
                            account?.color || account?.institution?.color,
                        }
                      : {}
                  }
                >
                  <div
                    className={`${getContrast(
                      account?.color || account?.institution?.color,
                      'text-black',
                      'text-white',
                    )} text-xs uppercase font-normal`}
                  >
                    {account?.officialName
                      ? account?.officialName
                          ?.replace?.(/[^\w\s]/gi, '')
                          ?.split(' ')
                          ?.filter(Boolean)
                          ?.slice(0, 2)
                          ?.map((word) => word?.charAt(0))
                          .join('')
                      : ''}
                  </div>
                </div>
              )}
              <div className="flex flex-col justify-center items-start">
                <p className="text-slate-600 text-[15px] font-medium">
                  {account?.name} {account?.mask ? `(...${account.mask})` : ''}
                </p>
                <p className="text-slate-600 text-opacity-90 text-[13px] font-normal">
                  {account?.name !== account?.officialName &&
                    account?.officialName}
                </p>
              </div>
            </div>
            <div>
              <p className="text-[#667085] font-medium leading-tight">
                {account?.type}
              </p>
              <p className="text-sm text-[#667085E5]">
                {account?.subType?.name}
              </p>
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            endingBalanceDate: reconcileDetail?.endingBalanceDate
              ? formatDateLocally(
                  reconcileDetail?.endingBalanceDate,
                  teamDateFormat,
                )
              : '',
            endingBalanceAmount: reconcileDetail?.endingBalanceAmount
              ? Number(reconcileDetail?.endingBalanceAmount).toFixed(2)
              : '',
          }}
          validationSchema={Yup.object({
            endingBalanceDate: Yup.string().required('Please Enter End Date'),
            endingBalanceAmount: Yup.string()
              .matches(
                /^-?\d{1,13}(\.\d{0,4})?$/,
                'Ending Balance can have 13 digits before the decimal and up to 4 digits after the decimal',
              )
              .required('Please Enter Ending Balance'),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => (
            <Form>
              <div className="px-5 pb-5 grid grid-cols-12 gap-3 sm:gap-2 gap-y-0 pt-2">
                <div className="col-span-full sm:col-span-6">
                  {((account?.reconcileBalances?.length > 0 &&
                    account?.reconcileBalances?.[0]?.complete) ||
                    openingBalance?.openingBalanceDate) && (
                    <p className="text-sm text-slate-600">
                      Reconciled Up To:{' '}
                      {moment(
                        openingBalance?.openingBalanceDate ||
                          account?.reconcileBalances[0]?.endingBalanceDate,
                      ).format('MMM DD, YYYY')}
                    </p>
                  )}
                  <DatePickerDash
                    label="Ending Balance Date"
                    required
                    validation={validation}
                    placeholder="Select Date"
                    value={
                      validation.values.endingBalanceDate
                        ? validation.values.endingBalanceDate
                        : null
                    }
                    options={options}
                    onChange={(selectedDates) => {
                      validation.setFieldValue(
                        'endingBalanceDate',
                        selectedDates[0],
                      );
                    }}
                    name="endingBalanceDate"
                    id="endingBalanceDate"
                    error={
                      validation.touched.endingBalanceDate &&
                      validation.errors.endingBalanceDate
                        ? validation.errors.endingBalanceDate
                        : ''
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onBlur={(e) => {
                      if (!e.target.classList?.value?.includes('active')) {
                        validation.setFieldValue(
                          'endingBalanceDate',
                          e.target.value,
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-span-full sm:col-span-6 ">
                  {((account?.reconcileBalances?.length > 0 &&
                    account?.reconcileBalances?.[0]?.complete) ||
                    (openingBalance && !openingBalance?.firstReconcile)) && (
                    <p className="text-sm text-slate-600">
                      Opening Balance: {teamCurrency?.symbol}
                      {formatNumber(
                        openingBalance?.openingBalanceAmount ||
                          account?.reconcileBalances[0]?.endingBalanceAmount,
                      )}
                    </p>
                  )}
                  <label
                    className={`text-sm font-medium text-slate-600 block mb-2 ${
                      validation.touched.endingBalanceAmount &&
                      validation.errors.endingBalanceAmount
                        ? '!text-rose-400'
                        : ''
                    }`}
                    htmlFor="endingBalanceAmount"
                  >
                    Statement Ending Balance
                    <span className="text-rose-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      value={validation.values.endingBalanceAmount}
                      className={`form-input h-12 w-full pl-10 ${
                        validation.touched.endingBalanceAmount &&
                        validation.errors.endingBalanceAmount
                          ? '!border-rose-500'
                          : ''
                      }`}
                      onChange={(e) =>
                        validation.setFieldValue(
                          'endingBalanceAmount',
                          e.target.value,
                        )
                      }
                      placeholder="0.00"
                      step="any"
                      name="endingBalanceAmount"
                      id="endingBalanceAmount"
                      onWheel={(e) => e.target.blur()}
                    />
                    <span className="absolute top-[50%] -translate-y-[50%] left-4 flex items-center gap-2">
                      <span className="text-slate-600">
                        {teamCurrency?.symbol}
                      </span>
                      <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                    </span>
                  </div>
                  {validation.touched.endingBalanceAmount &&
                    validation.errors.endingBalanceAmount && (
                      <div className="mt-1 text-xs text-rose-400">
                        {validation.errors.endingBalanceAmount}
                      </div>
                    )}
                </div>
                <div className="col-span-full flex flex-col gap-1 mt-2">
                  <UploadFile
                    onFilesAdded={(files) => {
                      setFiles((prevFiles) => [...prevFiles, ...files]);
                      if (files[0]) {
                        setImgPreview(files[0].preview);
                      }
                    }}
                    name="bill"
                    id="bill"
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    files={files}
                    setFiles={setFiles}
                    // loading={isLoading}
                    title="Upload PDF Statement (optional)"
                    pdfOnly
                  />
                </div>
              </div>

              {/* Modal footer */}
              <div className="px-5 py-4 border-t border-[#D0D5DD]">
                <div className="flex flex-wrap justify-end space-x-4">
                  {/* <button
                    type="button"
                    className="btn-sm h-11 flex-1 px-8 text-base border-[#667085]  hover:border-slate-800  text-[#667085]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                      setAccount(null);
                      formikRef.current?.resetForm();
                    }}
                  >
                    Close
                  </button> */}
                  <button
                    type="submit"
                    className="btn-sm flex-1 h-11 px-8 text-base bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
                    disabled={loading}
                  >
                    {loading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    {isEdit ? 'Update' : 'Next'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ModalBasic>
  );
};

export default EndingStatementModal;
