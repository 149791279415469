import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import UploadFile from 'components/UploadFile';
import ImageCropModal from 'components/ImageCropModal';
import DropdownInput from 'components/DropdownInput';
import { FIRM_ID } from 'utils/Utils';
import TextArea from 'components/TextArea';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import Logo from '../../images/custom/count-logo.png';
import TextInput from '../../components/TextInput';
import AuthSlider from '../../components/AuthSlider';
import {
  createAccountantFirm,
  getBusinessStructuresData,
  getCountriesData,
  getUserCountry,
} from '../../API/backend_helper';
import States from '../../data/states.json';

const AccountantFirmForm = () => {
  const navigate = useNavigate();
  const { localUser } = useSelector((state) => state.User);
  const { currencies } = useSelector((state) => state.Currencies);

  const [loading, setLoading] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [countries, setCountries] = useState([]);
  const [structures, setStructures] = useState([]);
  const [subStructures, setSubStructures] = useState([]);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);

  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  const loadStructures = async (id) => {
    try {
      const res = await getBusinessStructuresData(id);
      setStructures(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const findSubStructures = (name) => {
    const structure = structures.find((d) => d.name === name);
    if (structure) {
      const subStructures = structures.filter(
        (d) => d.parentId === structure.id,
      );
      setSubStructures(subStructures);
    }
  };

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadCountries();
    const fetchCountry = async () => {
      try {
        const res = await getUserCountry();
        const country = res?.country;
        if (country === 'US' || country === 'CA' || country === 'NZ') {
          setSelectedCountry(country);
          if (country === 'US') {
            setSelectedCurrency('USD');
          }
          if (country === 'NZ') {
            setSelectedCurrency('NZD');
          }
          if (country === 'CA') {
            setSelectedCurrency('CAD');
          }
          loadStructures(country);
        }
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchCountry();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const {
      name,
      email,
      website,
      tagline,
      phone,
      firmZipCode,
      description,
      numberOfEmployees,
      state,
      city,
      country,
      currency,
      type,
      subType,
    } = values;
    const logo = files?.[0];
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    if (website) {
      formData.append('website', website);
    }
    formData.append('tagline', tagline);
    formData.append('phone', phone);
    formData.append('firmZipCode', firmZipCode);
    formData.append('description', description);
    formData.append('numberOfEmployees', numberOfEmployees);
    formData.append(
      'address',
      JSON.stringify({
        city,
        country,
      }),
    );

    formData.append(
      'team',
      JSON.stringify({
        name,
        industry: 'Accounting, Bookkeeping',
        type,
        subType,
        country,
        state,
        howBusinessGetsPaid: '',
        currency,
        email,
        website,
      }),
    );

    if (logo) {
      formData.append('logo', logo);
    }

    try {
      const response = await createAccountantFirm(formData);
      if (response?.data?.result?.id) {
        localStorage.setItem(FIRM_ID, response?.data?.result?.id);
        navigate('/accountant');
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="relative flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 py-8">
              <h1 className="text-[40px] leading-tight font-medium text-[#232323CC] mb-8 ">
                Setup your Practice
              </h1>
              {/* <p className="text-lg text-[#667085CC] mb-9">
                Your free account is almost there
              </p> */}
              {/* Form */}
              <Formik
                enableReinitialize
                initialValues={{
                  name: '',
                  email: localUser?.email || '',
                  tagline: '',
                  phone: localUser?.phone || '',
                  type: '',
                  subType: '',
                  firmZipCode: '',
                  description: '',
                  numberOfEmployees: '',
                  website: '',
                  state: '',
                  city: '',
                  country: selectedCountry || '',
                  currency: selectedCurrency || '',
                }}
                validationSchema={Yup.object({
                  name: Yup.string()
                    .trim()
                    .required('Please Enter Practice Name')
                    .max(100, 'Practice Name must be at most 100 characters'),

                  email: Yup.string()
                    .matches(
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      'Please enter valid email',
                    )
                    .required('Please Enter Email')
                    .max(100, 'Email must be at most 100 characters'),
                  website: Yup.string().matches(
                    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+.*)$/,
                    'Invalid URL',
                  ),

                  tagline: Yup.string()
                    .trim()
                    .max(50, 'Tag Line can be at most 50 characters'),
                  description: Yup.string(),
                  // firmZipCode: Yup.string()
                  //   .trim()
                  //   .required('Please Enter Firm Zip Code')
                  //   .max(50, 'Firm Zip Code must be at most 50 characters'),
                  numberOfEmployees: Yup.number()
                    .min(0, 'Number of Clients should be positive')
                    .integer('Number of Clients should contain digits only')
                    .max(
                      999999999,
                      'Number of Clients can be 9 characters long',
                    )
                    .required('Please Enter Number of Clients'),
                  type: Yup.string().optional('Please Select Type'),
                  state: Yup.string().optional('Please Select State'),
                  country: Yup.string()
                    .trim()
                    .required('Please Select Country'),
                })}
                validate={(values) => {
                  const errors = {};
                  const { phone } = values;
                  if (phone && !isValidPhoneNumber(phone)) {
                    errors.phone = 'Phone number is not valid';
                  }

                  if (subStructures.length > 0) {
                    if (!values.subType) {
                      errors.subType = 'Please Select Business Sub Type';
                    }
                  }
                  if (values.country === 'US') {
                    if (!values.state) {
                      errors.state = 'Please Select State';
                    }
                  }
                  if (structures.length > 0 && !values.type) {
                    errors.type = 'Please Select Business Type';
                  }
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {(validation) => (
                  <Form>
                    <div className="space-y-8">
                      <TextInput
                        label="Practice Name"
                        placeholder=" "
                        id="name"
                        name="name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        error={
                          validation.touched.name ? validation.errors.name : ''
                        }
                        required
                      />
                      <TextInput
                        label="Firm Email"
                        placeholder=" "
                        id="email"
                        name="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        error={
                          validation.touched.email
                            ? validation.errors.email
                            : ''
                        }
                        required
                      />
                      {/* <TextInput
                        label="Tag Line"
                        placeholder=" "
                        id="tagline"
                        name="tagline"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tagline || ''}
                        error={
                          validation.touched.tagline
                            ? validation.errors.tagline
                            : ''
                        }
                      /> */}
                      <div className="!mt-6 relative">
                        <label
                          htmlFor="phone"
                          className={`text-sm text-[667085] mb-2 block ${
                            validation.touched.phone && validation.errors.phone
                              ? 'text-rose-400'
                              : ''
                          }`}
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={validation.values.phone}
                          onChange={(value) =>
                            validation.setFieldValue('phone', value || '')
                          }
                          international
                          defaultCountry={selectedCountry || ''}
                          id="phone"
                          name="phone"
                          className={`phone_textInput ${
                            validation.touched.phone && validation.errors.phone
                              ? 'phone_textInputError'
                              : ''
                          } `}
                          // onBlur={() => setIsPhoneNumberFocused(true)}
                          // required
                        />
                        <span className="absolute text-xs text-rose-400">
                          {validation.touched.phone
                            ? validation.errors.phone
                            : ''}
                        </span>
                      </div>
                      {/* <TextInput
                        label="Firm Zip Code"
                        placeholder=" "
                        id="firmZipCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firmZipCode || ''}
                        error={
                          validation.touched.firmZipCode
                            ? validation.errors.firmZipCode
                            : ''
                        }
                        required
                      /> */}
                      <TextInput
                        label="Number Of Clients"
                        placeholder=" "
                        id="numberOfEmployees"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfEmployees || ''}
                        error={
                          validation.touched.numberOfEmployees
                            ? validation.errors.numberOfEmployees
                            : ''
                        }
                        type="number"
                        required
                        min={0}
                        onWheel={(e) => e.target.blur()}
                      />
                      <TextInput
                        label="Website"
                        placeholder=" "
                        id="website"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.website || ''}
                        error={
                          validation.touched.website
                            ? validation.errors.website
                            : ''
                        }
                      />

                      <TextInput
                        label="City"
                        placeholder=" "
                        id="city"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city || ''}
                        error={
                          validation.touched?.city
                            ? validation.errors?.city
                            : ''
                        }
                      />
                      <div>
                        <DropdownInput
                          options={countries?.map((c) => ({
                            ...c,
                            id: c.isoCode,
                          }))}
                          label="Business Country"
                          id="country"
                          name="name"
                          setSelected={(id) => {
                            validation.setFieldValue('country', id);
                            validation.setFieldValue(
                              'currency',
                              countries?.find((c) => c?.isoCode === id)
                                ?.defaultCurrency,
                            );
                            validation.setFieldValue('type', '');
                            validation.setFieldValue('subType', '');
                            loadStructures(id);
                          }}
                          selected={validation.values.country}
                          error={
                            !validation.values.country &&
                            validation.touched.country
                              ? validation.errors.country
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          countries
                          required
                        />
                        {validation.values.currency && (
                          <div className="text-sm font-medium text-[#667085] !mt-2">
                            Your default currency is{' '}
                            {validation.values.currency}.{' '}
                            <span
                              className="text-[#E48642] cursor-pointer"
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                if (showCurrencyDropdown) {
                                  validation.setFieldValue(
                                    'currency',
                                    countries?.find(
                                      (c) =>
                                        c?.isoCode ===
                                        validation.values.country,
                                    )?.defaultCurrency,
                                  );
                                }
                                setShowCurrencyDropdown(!showCurrencyDropdown);
                              }}
                            >
                              {showCurrencyDropdown
                                ? 'Cancel'
                                : 'Change Currency'}
                            </span>
                          </div>
                        )}
                      </div>
                      {showCurrencyDropdown && (
                        <DropdownInput
                          options={currencies?.map((c) => ({
                            ...c,
                            id: c?.isoCode,
                            name: `${c?.symbol} ${c?.name}`,
                          }))}
                          id="currency"
                          label="Business Currency"
                          name="name"
                          setSelected={(id) => {
                            validation.setFieldValue('currency', id);
                          }}
                          selected={validation.values.currency}
                          error={
                            validation.touched.currency
                              ? validation.errors.currency
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          required
                        />
                      )}
                      {validation.values.country === 'US' && (
                        <DropdownInput
                          className="h-fit"
                          options={States.map((s) => ({
                            id: s.abbreviation,
                            state: `${s.name} (${s.abbreviation})`,
                          }))}
                          id="state"
                          label="State"
                          name="state"
                          setSelected={(id) => {
                            validation.setFieldValue('state', id);
                          }}
                          selected={validation.values.state}
                          error={
                            !validation.values.state && validation.touched.state
                              ? validation.errors.state
                              : ''
                          }
                          onBlur={validation.handleBlur}
                          searchable
                          required
                        />
                      )}
                      {validation.values.country && structures.length > 0 && (
                        <div>
                          <DropdownInput
                            options={structures
                              .filter((d) => d.parentId === null)
                              .map((d) => ({
                                id: d.name,
                                type: d.name,
                              }))}
                            label="Business Type"
                            id="type"
                            name="type"
                            disabled={
                              !validation.values.country ||
                              structures.length === 0
                            }
                            setSelected={(id) => {
                              validation.setFieldValue('type', id);
                              findSubStructures(id);
                              if (
                                id === 'Self-employed' ||
                                id === 'Sole Proprietorship'
                              ) {
                                validation.setFieldValue(
                                  'subType',
                                  "I'm not sure",
                                );
                              } else {
                                validation.setFieldValue('subType', '');
                              }
                            }}
                            selected={validation.values.type}
                            error={
                              !validation.values.type && validation.touched.type
                                ? validation.errors.type
                                : ''
                            }
                            onBlur={validation.handleBlur}
                            required
                          />
                          {validation.values.type &&
                            subStructures?.length > 0 && (
                              <div className="pt-6 pl-8 relative">
                                <div className="absolute top-0 left-[16px] w-[1.5px] h-[68%] bg-[#D0D5DD]">
                                  <div className="absolute bottom-0 h-[1.5px] w-[16px] bg-[#D0D5DD]" />
                                </div>
                                <DropdownInput
                                  options={subStructures.map((d) => ({
                                    id: d.name,
                                    subType: d.name,
                                  }))}
                                  id="subType"
                                  label={`What type of ${validation.values.type}`}
                                  onBlur={validation.handleBlur}
                                  name="subType"
                                  setSelected={(id) => {
                                    validation.setFieldValue('subType', id);
                                  }}
                                  selected={validation.values.subType}
                                  error={
                                    validation.touched.subType
                                      ? validation.errors.subType
                                      : ''
                                  }
                                  required
                                />
                              </div>
                            )}
                        </div>
                      )}

                      <TextArea
                        label="Practice Bio"
                        id="description"
                        name="description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ''}
                        error={
                          validation.touched.description
                            ? validation.errors.description
                            : ''
                        }
                        placeholder=" "
                        rows="4"
                      />
                      <div>
                        <UploadFile
                          title="Logo"
                          onFilesAdded={(files) => {
                            setFiles((prevFiles) => [...prevFiles, ...files]);
                            if (files[0]) {
                              const filePreviewUrl = URL.createObjectURL(
                                files[0],
                              );
                              setCurrentImageForCrop(filePreviewUrl);
                              setCropModalOpen(true);
                              setImgName(files[0].name);
                            }
                          }}
                          name="logo"
                          id="logo"
                          imgPreview={imgPreview}
                          setImgPreview={setImgPreview}
                          files={files}
                          setFiles={setFiles}
                        />
                      </div>
                      <button
                        className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                        )}
                        Finish
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
};

export default AccountantFirmForm;
