import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import ModalBasic from './ModalBasic';
import { getCroppedImg, blobUrlToBlob, blobToFile } from '../utils/Utils';

const ImageCropModal = ({
  isOpen,
  setIsOpen,
  image,
  setPreviewImage,
  setFiles,
  imgName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1.3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [shape, setShape] = useState('rect');

  useEffect(() => {
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);
    setShape('rect');
    setAspect(1.3);
  }, [isOpen]);

  useEffect(() => {
    if (croppedImage) {
      setPreviewImage(croppedImage);
      blobUrlToBlob(croppedImage).then((blob) => {
        const file = blobToFile(blob, { imgName });
        setFiles([file]);
      });
    }
  }, [croppedImage]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
        { horizontal: false, vertical: false },
        shape === 'round' ? 'circle' : 'rectangle',
      );
      setCroppedImage(croppedImage);
      setIsOpen(false);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Crop Image"
      width="!w-[700px]"
      dialogClassName="relative"
    >
      <div className="border border-slate-500 relative mx-auto mb-6 min-h-[16rem] max-w-[20rem] sm:min-h-[20rem] sm:max-w-[25rem] md:min-h-[30rem] md:max-w-[40rem]   mt-[2.25rem]">
        <Cropper
          minZoom={0.1}
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={shape === 'round' ? 1 : aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape={shape}
          restrictPosition={false}
          zoomWithScroll={false}
        />
      </div>

      <div className="px-[30px] mb-4">
        <p className="mb-2 text-slate-500 font-medium ">Shape</p>
        <div className="flex gap-4">
          <button
            type="button"
            className={`h-10 px-4 border border-indigo-500 rounded-[6px] ${shape === 'round' ? 'text-white bg-indigo-500' : 'text-indigo-500 bg-white'} `}
            onClick={() => setShape('round')}
          >
            Circle
          </button>
          <button
            type="button"
            className={`h-10 px-4 border border-indigo-500 rounded-[6px] ${shape === 'rect' ? 'text-white bg-indigo-500' : 'text-indigo-500 bg-white'} `}
            onClick={() => setShape('rect')}
          >
            Rectangle
          </button>
        </div>
      </div>
      <div className="px-[30px] grid grid-cols-1 gap-4">
        <div>
          <label
            htmlFor="steps-range-slider-usage"
            className="mb-2 text-slate-500 font-medium "
          >
            Zoom
          </label>
          <input
            type="range"
            id="steps-range-slider-usage"
            aria-orientation="horizontal"
            min="0.1"
            max="5"
            step="0.1"
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            className="w-full bg-transparent cursor-pointer appearance-none disabled:opacity-50 disabled:pointer-events-none focus:outline-none
  [&::-webkit-slider-thumb]:w-2.5
  [&::-webkit-slider-thumb]:h-2.5
  [&::-webkit-slider-thumb]:-mt-0.5
  [&::-webkit-slider-thumb]:appearance-none
  [&::-webkit-slider-thumb]:bg-white
   [&::-webkit-slider-thumb]:shadow-[0_0_0_4px_rgba(228,134,66,1)]
  [&::-webkit-slider-thumb]:rounded-full
  [&::-webkit-slider-thumb]:transition-all
  [&::-webkit-slider-thumb]:duration-150
  [&::-webkit-slider-thumb]:ease-in-out
  [&::-webkit-slider-thumb]:dark:bg-neutral-700

  [&::-moz-range-thumb]:w-2.5
  [&::-moz-range-thumb]:h-2.5
  [&::-moz-range-thumb]:appearance-none
  [&::-moz-range-thumb]:bg-white
  [&::-moz-range-thumb]:border-4
  [&::-moz-range-thumb]:border-indigo-500
  [&::-moz-range-thumb]:rounded-full
  [&::-moz-range-thumb]:transition-all
  [&::-moz-range-thumb]:duration-150
  [&::-moz-range-thumb]:ease-in-out

  [&::-webkit-slider-runnable-track]:w-full
  [&::-webkit-slider-runnable-track]:h-2
  [&::-webkit-slider-runnable-track]:bg-gray-100
  [&::-webkit-slider-runnable-track]:rounded-full
  [&::-webkit-slider-runnable-track]:dark:bg-neutral-700

  [&::-moz-range-track]:w-full
  [&::-moz-range-track]:h-2
  [&::-moz-range-track]:bg-gray-100
  [&::-moz-range-track]:rounded-full"
          />
        </div>
        {shape === 'rect' && (
          <div className="">
            <label
              htmlFor="steps-range-slider-usage"
              className="mb-2 text-slate-500 font-medium "
            >
              Aspect Ratio
            </label>
            <input
              type="range"
              id="steps-range-slider-usage"
              aria-orientation="horizontal"
              min="0.5"
              max="2.5"
              step="0.1"
              value={aspect}
              onChange={(e) => setAspect(e.target.value)}
              onWheel={(e) => e.stopPropagation()}
              className="w-full bg-transparent cursor-pointer appearance-none disabled:opacity-50 disabled:pointer-events-none focus:outline-none
  [&::-webkit-slider-thumb]:w-2.5
  [&::-webkit-slider-thumb]:h-2.5
  [&::-webkit-slider-thumb]:-mt-0.5
  [&::-webkit-slider-thumb]:appearance-none
  [&::-webkit-slider-thumb]:bg-white
   [&::-webkit-slider-thumb]:shadow-[0_0_0_4px_rgba(228,134,66,1)]
  [&::-webkit-slider-thumb]:rounded-full
  [&::-webkit-slider-thumb]:transition-all
  [&::-webkit-slider-thumb]:duration-150
  [&::-webkit-slider-thumb]:ease-in-out
  [&::-webkit-slider-thumb]:dark:bg-neutral-700

  [&::-moz-range-thumb]:w-2.5
  [&::-moz-range-thumb]:h-2.5
  [&::-moz-range-thumb]:appearance-none
  [&::-moz-range-thumb]:bg-white
  [&::-moz-range-thumb]:border-4
  [&::-moz-range-thumb]:border-indigo-500
  [&::-moz-range-thumb]:rounded-full
  [&::-moz-range-thumb]:transition-all
  [&::-moz-range-thumb]:duration-150
  [&::-moz-range-thumb]:ease-in-out

  [&::-webkit-slider-runnable-track]:w-full
  [&::-webkit-slider-runnable-track]:h-2
  [&::-webkit-slider-runnable-track]:bg-gray-100
  [&::-webkit-slider-runnable-track]:rounded-full
  [&::-webkit-slider-runnable-track]:dark:bg-neutral-700

  [&::-moz-range-track]:w-full
  [&::-moz-range-track]:h-2
  [&::-moz-range-track]:bg-gray-100
  [&::-moz-range-track]:rounded-full"
            />
          </div>
        )}
      </div>

      <div className="sticky bottom-0 border-t mt-6 border-[#D0D5DD] bg-white flex py-[1rem] px-[30px] justify-end items-center gap-4 self-stretch w-full">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            type="button"
            className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-white border border-slate-600 text-slate-600 text-base leading-6"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
              setFiles([]);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="h-11 w-[5.625rem] py-2.5 px-[1.875rem] flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                    disabled:bg-indigo-400    disabled:cursor-not-allowed"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

export default ImageCropModal;
