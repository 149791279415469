import React, { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import AddressBar from 'components/AddressBar';
import { useSelector } from 'react-redux';
import TextInputDash from '../../components/TextInputDash';
import UploadFile from '../../components/UploadFile';
import ImageCropModal from '../../components/ImageCropModal';
import DropdownFull from '../../components/DropdownFull';
import ColorDropdown from '../../components/ColorDropdown';

const STATUS_OPTIONS = [
  {
    name: 'Active',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'active',
  },
  {
    name: 'Inactive',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'inactive',
  },
];

const VendorForm = ({
  setActiveVendor = null,
  activeVendor = {},
  handleAddVendor,
  handleUpdateVendor,
  handleUploadVendorLogo,
  setFormOpen,
  setTitle = null,
  isModalView = false,
}) => {
  const { team } = useSelector((state) => state.Team);

  const formikRef = useRef(null);
  const [contactInfoOpen, setContactInfoOpen] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [loading, setLoading] = useState(false);

  // File Management
  const [files, setFiles] = useState([]);
  const [imgPreview, setImgPreview] = useState('');
  const [imgName, setImgName] = useState('');

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  useEffect(() => {
    if (activeVendor && activeVendor.logoUrl) {
      setImgPreview(activeVendor.logoUrl);
    }
  }, [activeVendor]);

  // Open contact info if contacts isn't empty
  useEffect(() => {
    if (activeVendor?.contacts?.[0]?.firstName) {
      setContactInfoOpen(true);
    }
  }, [activeVendor]);

  const uploadLogo = async (logo, vendorId) => {
    const formData = new FormData();
    formData.append('logo', logo);
    handleUploadVendorLogo(formData, vendorId);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const logo = files[0];
    let response;
    const submissionValues = { ...values };
    submissionValues.contacts = submissionValues.contacts.map((contact) => {
      if (contact.email === '') {
        const { ...rest } = contact;
        return rest;
      }
      return contact;
    });

    try {
      if (activeVendor?.id) {
        response = await handleUpdateVendor(submissionValues, activeVendor?.id);
        if (logo) {
          await uploadLogo(logo, activeVendor?.id);
        }
        toast.success('Vendor updated successfully');
      } else {
        response = await handleAddVendor(submissionValues);
        if (logo) {
          await uploadLogo(logo, response?.id);
        }
        toast.success('Vendor created successfully');
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }

    if (response) {
      formikRef.current?.resetForm();
      setFormOpen(false);
      setFiles([]);
      setImgPreview('');
      if (setActiveVendor) {
        setActiveVendor(null);
      }
    }
  };

  return (
    <>
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      <Formik
        enableReinitialize={false}
        initialValues={{
          name: activeVendor?.name || '',
          status: activeVendor?.status || 'active',
          type: activeVendor?.type || 'MERCHANT',
          mainPhone: activeVendor?.mainPhone || '',
          address: {
            street: activeVendor?.address?.street || '',
            street2: activeVendor?.address?.street2 || '',
            country:
              activeVendor?.address?.country ||
              (!activeVendor?.id ? team?.country || '' : ''),
            city: activeVendor?.address?.city || '',
            state: activeVendor?.address?.state || '',
            zipCode: activeVendor?.address?.zipCode || '',
          },
          contacts: [
            {
              firstName: activeVendor?.contacts?.[0]?.firstName || '',
              lastName: activeVendor?.contacts?.[0]?.lastName || '',
              email: activeVendor?.contacts?.[0]?.email || '',
              phone: activeVendor?.contacts?.[0]?.phone || '',
            },
          ],
          website: activeVendor?.website || '',
          accountNumber: activeVendor?.accountNumber || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .trim('Cannot be only spaces')
            .required('Vendor Name is required')
            .max(100, 'Max 100 characters are allowed')
            .min(1, 'Vendor Name is required'),
          type: Yup.string().required('Please select Vendor Type'),
          accountNumber: Yup.string()
            .matches(
              /^[a-zA-Z0-9\s]+$/,
              'Account Number can only contain letters and numbers',
            )
            .trim('Cannot be only spaces')
            .max(100, 'Max 100 characters are allowed'),
          website: Yup.string().max(100, 'Max 100 characters are allowed'),
          address: Yup.object({
            street: Yup.string(),
            street2: Yup.string(),
            country: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
          }),
          contacts: Yup.array().of(
            Yup.object({
              firstName: Yup.string()
                .matches(
                  /^[a-zA-Z0-9\s]+$/,
                  'First Name can only contain letters and numbers',
                )
                .max(100, 'Max 100 characters are allowed'),
              lastName: Yup.string()
                .matches(
                  /^[a-zA-Z0-9\s]+$/,
                  'Last Name can only contain letters and numbers',
                )
                .max(100, 'Max 100 characters are allowed'),
              email: Yup.string()
                .email('Invalid email address')
                .max(100, 'Max 100 characters are allowed'),
            }),
          ),
        })}
        validate={(values) => {
          const errors = {};
          const phone = values.contacts[0]?.phone;
          const { mainPhone } = values;
          if (phone && !isValidPhoneNumber(phone)) {
            errors.phone = 'Mobile number is not valid';
          }
          if (mainPhone && !isValidPhoneNumber(mainPhone)) {
            errors.mainPhone = 'Main phone number is not valid';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form className={`${isModalView ? 'px-8 py-6' : ''}`}>
            <div className="grid grid-cols-12 gap-4 w-full gap-y-10">
              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Vendor Details:<span className="text-rose-500">*</span>
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the primary information for the vendor. This includes
                    the vendor's name, type (individual or business), and other
                    reference details. This information helps keep vendor
                    records organized and accessible.
                  </p>
                </div>
              </div>
              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Vendor Details: <span className="text-rose-500">*</span>
                </h3>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Vendor Name"
                    id="name"
                    name="name"
                    placeholder="Individual or Business"
                    required
                    value={validation.values.name || ''}
                    error={validation.touched.name && validation.errors.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </div>
                <div className="col-span-full sm:col-span-6 pb-5">
                  <label
                    className="block text-sm text-slate-600 font-medium leading-5 mb-4"
                    htmlFor="currency"
                  >
                    Status <span className="text-rose-500">*</span>
                  </label>
                  <ColorDropdown
                    statusOptions={STATUS_OPTIONS}
                    selected={validation.values.status}
                    setSelected={(status) =>
                      validation.setFieldValue('status', status)
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <div className={`${isModalView ? 'pb-5' : 'pb-5'}`}>
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="currency"
                    >
                      Type
                    </label>
                    <DropdownFull
                      options={[
                        {
                          id: 'MERCHANT',
                          name: 'Merchant',
                        },
                        {
                          id: 'SUPPLIER',
                          name: 'Supplier',
                        },
                        {
                          id: 'CONTRACTOR',
                          name: 'Contractor',
                        },
                      ]}
                      name="name"
                      setSelected={(id) => validation.setFieldValue('type', id)}
                      selected={validation.values.type}
                      error={validation.touched.type && validation.errors.type}
                      accountStyle
                      className="!h-fit"
                      scrollIntoView={false}
                    />
                    {validation.touched.type && validation.errors.type ? (
                      <div className="text-xs mt-1 text-rose-500">
                        {validation.errors.type}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-span-full sm:col-span-6">
                  <div className="flex flex-col w-full pb-5">
                    <label
                      htmlFor="contacts.[0].phone"
                      className={`text-sm font-medium text-slate-600 block mb-2 ${
                        validation.errors.mainPhone ? '!text-rose-400' : ''
                      }`}
                    >
                      Main Phone Number
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={validation.values.mainPhone}
                      onChange={(value) =>
                        validation.setFieldValue('mainPhone', value || '')
                      }
                      international
                      defaultCountry={team?.country || ''}
                      id="mainPhone"
                      name="mainPhone"
                      className={`phone_textInputDash ${
                        validation.errors.mainPhone
                          ? 'phone_textInputDashError'
                          : ''
                      } `}
                    />
                    {validation.errors.mainPhone && (
                      <span className=" text-xs text-rose-400">
                        {validation.errors.mainPhone}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Account Number"
                    id="accountNumber"
                    name="accountNumber"
                    placeholder="(Optional)"
                    value={validation.values.accountNumber || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.accountNumber &&
                      validation.errors.accountNumber
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Website"
                    id="website"
                    name="website"
                    placeholder="(Optional)"
                    value={validation.values.website || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.website && validation.errors.website
                    }
                  />
                </div>
                <div
                  className={`col-span-full sm:flex flex-start flex-col h-fit min-h-36 ${
                    isModalView ? 'w-full xl:w-1/2' : 'w-full xl:w-1/2'
                  }`}
                >
                  <UploadFile
                    title="Logo"
                    onFilesAdded={(files) => {
                      setFiles((prevFiles) => [...prevFiles, ...files]);
                      if (files[0]) {
                        const filePreviewUrl = URL.createObjectURL(files[0]);
                        setCurrentImageForCrop(filePreviewUrl);
                        setCropModalOpen(true);
                        setImgName(files[0].name);
                      }
                    }}
                    name="logo"
                    id="logo"
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Address Information:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Enter the vendor's address details, including the street
                    address, city, state, and postal code. This information is
                    essential for accurate billing, shipping, and
                    record-keeping.
                  </p>
                </div>
              </div>

              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Address Information:
                </h3>
                <div className="flex flex-col items-start gap-2.5 w-[100%] col-span-full">
                  <label className="text-sm font-medium text-slate-600 block">
                    Address
                  </label>
                  <AddressBar
                    validation={validation}
                    values={validation.values.address}
                    streetId="address.street"
                    streetValue={validation.values.address.street}
                    streetError={
                      validation.touched?.address?.street &&
                      validation.errors?.address?.street
                    }
                    street2Id="address.street2"
                    street2Value={validation.values.address.street2}
                    street2Error={
                      validation.touched?.address?.street2 &&
                      validation.errors?.address?.street2
                    }
                    cityId="address.city"
                    cityValue={validation.values.address.city}
                    cityError={
                      validation.touched?.address?.city &&
                      validation.errors?.address?.city
                    }
                    stateId="address.state"
                    stateValue={validation.values.address.state}
                    stateError={
                      validation.touched?.address?.state &&
                      validation.errors?.address?.state
                    }
                    zipCodeId="address.zipCode"
                    zipCodeValue={validation.values.address.zipCode}
                    zipCodeError={
                      validation.touched?.address?.zipCode &&
                      validation.errors?.address?.zipCode
                    }
                    countryId="address.country"
                    countryValue={validation.values.address.country}
                    countryError={
                      validation.touched?.address?.country &&
                      validation.errors?.address?.country
                    }
                    smallView
                  />
                </div>
              </div>

              <div className="col-span-full md:col-span-4 hidden md:block">
                <div className="pr-4 flex flex-col gap-2 ">
                  <h3 className="text-[18px] font-medium text-[#101828] leading-tight">
                    Contact Information:
                  </h3>
                  <p className="text-[#6D7585] text-sm">
                    Provide the primary contact details for the vendor. This
                    allows you to stay connected with the vendor and ensures you
                    have a point of contact for communication.
                  </p>
                </div>
              </div>
              <div className="col-span-full md:col-span-8 grid grid-cols-12 gap-4 gap-y-2">
                <h3 className="mb-2 md:hidden col-span-full text-[18px] font-medium text-[#101828] leading-tight">
                  Contact Information:
                </h3>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="First Name"
                    id="firstName"
                    name="contacts.[0].firstName"
                    placeholder="First Name"
                    value={validation.values.contacts[0]?.firstName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.contacts?.[0]?.firstName &&
                      validation.errors.contacts?.[0]?.firstName
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Last Name"
                    id="lastName"
                    name="contacts.[0].lastName"
                    placeholder="Last Name"
                    value={validation.values.contacts[0]?.lastName || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.contacts?.[0]?.lastName &&
                      validation.errors.contacts?.[0]?.lastName
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <TextInputDash
                    label="Contact Email"
                    id="email"
                    name="contacts.[0].email"
                    placeholder="(Optional)"
                    value={validation.values.contacts[0]?.email || ''}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.contacts?.[0]?.email &&
                      validation.errors.contacts?.[0]?.email
                    }
                  />
                </div>
                <div className="col-span-full sm:col-span-6">
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="contacts.[0].phone"
                      className={`text-sm font-medium text-slate-600 block mb-2 ${
                        validation.errors.phone ? '!text-rose-400' : ''
                      }`}
                    >
                      Mobile Number
                    </label>
                    <PhoneInput
                      placeholder="Enter mobile number"
                      value={validation.values.contacts[0]?.phone}
                      onChange={(value) =>
                        validation.setFieldValue(
                          'contacts[0].phone',
                          value || '',
                        )
                      }
                      international
                      defaultCountry={team?.country || ''}
                      id="contacts.[0].phone"
                      name="contacts.[0].phone"
                      className={`phone_textInputDash ${
                        validation.errors.phone
                          ? 'phone_textInputDashError'
                          : ''
                      } `}
                    />
                    {validation.errors.phone && (
                      <span className=" text-xs text-rose-400">
                        {validation.errors.phone}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Close/Add Buttons */}
            <div
              className={`w-full flex justify-end items-center gap-4 mt-[1.875rem]  ${
                isModalView ? '' : 'pb-20'
              }`}
            >
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-base w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  if (setActiveVendor) {
                    setActiveVendor(null);
                  }
                  if (setTitle) {
                    setTitle('Vendors');
                  }
                  setFiles([]);
                  setFormOpen(false);
                }}
                type="button"
              >
                {activeVendor ? 'Discard' : 'Close'}
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {activeVendor ? 'Save' : 'Add'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VendorForm;
