import React, { useEffect, useRef, useState } from 'react';
import {
  getTeamById,
  updateTeamById,
  uploadTeamLogo,
} from 'API/backend_helper';
import ImageCropModal from 'components/ImageCropModal';
import TextInputDash from 'components/TextInputDash';
import UploadFile from 'components/UploadFile';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalUser } from '../../redux/User/reducer';
import { setTeam } from '../../redux/Team/reducer';

const TeamInfoForm = ({ updateOneStep = () => {} }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.Team);
  const { localUser } = useSelector((state) => state.User);

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [currentImageForCrop, setCurrentImageForCrop] = useState(null);
  const [imgPreview, setImgPreview] = useState('');
  const [files, setFiles] = useState([]);
  const [imgName, setImgName] = useState('');

  const [detailsLoading, setDetailsLoading] = useState(false);

  const uploadLogo = async (logo) => {
    const formData = new FormData();
    formData.append('logo', logo);
    await uploadTeamLogo(formData, team?.id);
  };

  const handleSubmit = async (_values, formik) => {
    const logo = files[0];
    setDetailsLoading(true);
    const values = { ..._values };
    const { email } = values;
    if (!email) {
      values.email = null;
    }
    try {
      if (logo) {
        await uploadLogo(logo);
      } else if (!imgPreview) {
        values.logoUrl = '';
      }

      if (!values?.billEmail?.includes('@bills.getcount.com')) {
        values.billEmail = `${values?.billEmail}@bills.getcount.com`;
      }
      await updateTeamById(team?.id, values);
      const res = await getTeamById(team?.id);
      dispatch(setTeam(res));
      const tempUser = JSON.parse(JSON.stringify(localUser || {}));
      const teamIndex = tempUser?.teams?.findIndex((t) => t?.id === res?.id);
      if (teamIndex >= 0) {
        tempUser.teams[teamIndex] = res;
      }
      dispatch(setLocalUser(tempUser));
      updateOneStep();
    } catch (err) {
      console.log('err', err);
    } finally {
      setDetailsLoading(false);
      if (values?.billEmail?.includes('@bills.getcount.com')) {
        formik.setFieldValue('billEmail', values?.billEmail?.split('@')?.[0]);
      }
    }
  };

  useEffect(
    () => () => files.forEach((file) => URL.revokeObjectURL(file.preview)),
    [],
  );

  useEffect(() => {
    if (team?.logoUrl) {
      setImgPreview(team?.logoUrl);
    }
  }, [team]);

  return (
    <>
      <ImageCropModal
        isOpen={cropModalOpen}
        setIsOpen={setCropModalOpen}
        image={currentImageForCrop}
        setPreviewImage={setImgPreview}
        setFiles={setFiles}
        imgName={imgName}
      />
      <div className="flex items-end gap-2 w-full">
        <Formik
          enableReinitialize
          initialValues={{
            email: team?.email || '',
            billEmail:
              team?.billEmail?.replace('@bills.getcount.com', '') || '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Please enter valid email',
              )
              .optional('Please Enter Email')
              .nullable(),
            billEmail: Yup.string()
              .test(
                'no-email',
                'Bill Email should not include email-like patterns',
                (value) => {
                  const emailPattern = /[@.]/;
                  return !emailPattern.test(value);
                },
              )
              .required('Please Enter Bill Email'),
          })}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => (
            <Form className="w-full">
              <h3 className="text-[20px] font-medium text-[#101828] mb-4">
                Company Information:
              </h3>
              <div className="flex flex-col gap-2 w-full">
                <div className="grid md:grid-cols-2  xl:grid-cols-1 gap-x-4 gap-y-2">
                  <TextInputDash
                    label="Business Email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ''}
                    error={validation.touched.email && validation.errors.email}
                  />
                  <div className="w-full ">
                    <label
                      className={`text-sm font-medium text-slate-600 block mb-2 ${
                        validation.touched.billEmail &&
                        validation.errors.billEmail
                          ? '!text-rose-400'
                          : ''
                      }`}
                      htmlFor="billEmail"
                    >
                      Bill Email
                      <span className="text-rose-500 pl-1">*</span>
                    </label>
                    <div className="relative flex flex-col w-full min-h-[60px]">
                      <input
                        id="billEmail"
                        name="billEmail"
                        className={`form-input min-w-full min-h-[48px] pr-[180px] ${
                          validation.errors.billEmail &&
                          validation.touched.billEmail
                            ? '!border-rose-500'
                            : ''
                        }
                      [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none `}
                        min={0}
                        placeholder="Bill Email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billEmail}
                      />

                      <span className="absolute text-base text-slate-800 top-[12px] text-nowrap right-8">
                        @bills.getcount.com
                      </span>

                      {validation.touched.billEmail &&
                        validation.errors.billEmail && (
                          <div className="absolute bottom-[-10px] mt-1 text-xs text-rose-400">
                            {validation.touched.billEmail &&
                              validation.errors.billEmail}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <UploadFile
                    title="Logo"
                    onFilesAdded={(files) => {
                      setFiles((prevFiles) => [...prevFiles, ...files]);
                      if (files[0]) {
                        const filePreviewUrl = URL.createObjectURL(files[0]);
                        setCurrentImageForCrop(filePreviewUrl);
                        setCropModalOpen(true);
                        setImgName(files[0].name);
                      }
                    }}
                    name="logo"
                    id="logo"
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    files={files}
                    setFiles={setFiles}
                    imgPreviewClassName="!h-fit-content !max-h-10 !max-w-10 !w-auto !h-auto  !shadow-none mt-1 !rounded-none"
                  />
                </div>

                <footer className="flex justify-between mt-[60px] gap-4">
                  <button
                    type="button"
                    onClick={updateOneStep}
                    className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
                  >
                    Skip
                  </button>
                  <button
                    type="submit"
                    className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                    disabled={detailsLoading}
                  >
                    {detailsLoading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Next
                  </button>
                </footer>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TeamInfoForm;
