import React, { useEffect, useRef, useState } from 'react';
import { shutdown, boot } from '@intercom/messenger-js-sdk';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import {
  deleteDraftProductById,
  getAccounts,
  importDraftProducts,
  productsCsvImport,
  productsImportBulk,
  productsImportCreate,
  updateBulkDraftProducts,
} from '../../API/backend_helper';
import DropdownFull from '../../components/DropdownFull';
import AlertBox from '../../components/AlertBox';
import { Loader } from '../../components/Svg';
import {
  removeValueIfNotExistInData,
  replaceKeysWithValuesAndConvertToCamelCase,
  transformAccounts,
} from '../../utils/Utils';
import { AnimatedCheckmark } from '../inbox/Svg';
import AppLayout from '../../components/AppLayout';
import Step3 from './Step3';
import useOnboardingSubSteps from '../onboarding/useOnboardingSubSteps';

const ImportProductAndServices = () => {
  const navigate = useNavigate();

  const isValidatingRef = useRef(false);

  const [searchParams] = useSearchParams();
  const From = searchParams.get('from') || '';

  const { handleOnboardingSubStep } = useOnboardingSubSteps({
    subStep: 'products',
  });

  const [mainLoading, setMainLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [parsedInfo, setParsedInfo] = useState([]);
  const [mapping, setMapping] = useState({});
  const [keyArray, setKeyArray] = useState([]);
  const [error, setError] = useState(null);
  const [importedTotals, setImportedTotals] = useState({});
  const [importStatusId, setImportStatusId] = useState('');
  const [draftProducts, setDraftProducts] = useState({});
  const [deleteDraftsLoading, setDeleteDraftsLoading] = useState({});
  const [localLimit, setLocalLimit] = useState(20);
  const [, setIsValidating] = useState(false);

  // For Step 3 Mapping
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [incomeCategories, setIncomeCategories] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [errorIndexes, setErrorIndexes] = useState([]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (activeStep > 1) {
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [activeStep]);

  useEffect(() => {
    shutdown();

    return () => {
      boot();
    };
  }, []);

  const loadAccounts = async () => {
    try {
      const res = await getAccounts();
      const incomeCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
      );
      const expenseCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
        'expense',
      );
      setExpenseCategories(expenseCategories);
      setIncomeCategories(incomeCategories);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, []);

  const setValidating = (value) => {
    isValidatingRef.current = value;
    setIsValidating((prev) => !prev); // Tog
  };

  const createKeyArray = (product) =>
    Object?.keys(product || {})
      ?.filter?.((key) => key !== '' && key)
      ?.map?.((key) => ({
        name: key?.trim(),
        id: key?.trim(),
      }))
      ?.filter?.((item) => item.name !== '' && item.id !== '');

  const csvFileUpload = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      const res = await productsCsvImport(data);
      const keys = createKeyArray(res?.data?.products?.[0]);
      if (res?.data?.productColumns) {
        const transformedObject = replaceKeysWithValuesAndConvertToCamelCase(
          res?.data?.productColumns,
        );
        setMapping(
          removeValueIfNotExistInData({ ...(transformedObject ?? {}) }, keys),
        );
      }
      setKeyArray(keys);
      setParsedInfo(res?.data);
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const checkRowsError = () => {
    let isError = false;
    if (tableRows?.length === 0) {
      return true;
    }
    const tempRows = [...tableRows];
    const indexs = [];

    tableRows.forEach((d, i) => {
      if (d?.status?.value !== 'inactive') {
        Object.keys(d).forEach((key) => {
          const tempRow = { ...tableRows[i] };
          if (
            key !== 'description' &&
            key !== 'selected' &&
            key !== 'purchaseDescription' &&
            key !== 'stockQuantity' &&
            key !== 'tax' &&
            key !== 'taxName'
          ) {
            if (key === 'name' && !d[key].value) {
              tempRow[key].error = 'Name should not be empty';
              isError = true;
              if (
                !indexs?.includes(
                  (draftProducts?.page - 1) * localLimit + i + 1,
                )
              ) {
                indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
              }
            }
            if (key === 'name' && d[key].value && d[key].value?.length > 200) {
              tempRow[key].error = 'Name must be 200 characters or less';
              isError = true;
              if (
                !indexs?.includes(
                  (draftProducts?.page - 1) * localLimit + i + 1,
                )
              ) {
                indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
              }
            }
            // if (key === 'categoryAccountId' && !d[key].value) {
            //   console.log('index', i);

            //   tempRow[key].error = 'Category required';
            //   isError = true;
            // }
            if (key === 'currency' && !d[key].value) {
              tempRow[key].error = 'currency required';
              isError = true;
              if (
                !indexs?.includes(
                  (draftProducts?.page - 1) * localLimit + i + 1,
                )
              ) {
                indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
              }
            }
            if (key === 'pricingMethod' && !d[key].value) {
              tempRow[key].error = 'Pricing Method required';
              isError = true;
              if (
                !indexs?.includes(
                  (draftProducts?.page - 1) * localLimit + i + 1,
                )
              ) {
                indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
              }
            }
            // if (key === 'unitPrice' && !d[key].value) {
            //   tempRow[key].error = 'Price required';
            //   isError = true;
            //   if (!indexs?.includes(i)) {
            //     indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
            //   }
            // }
            // if (
            //   key === 'purchasePrice' &&
            //   !d[key].value &&
            //   (d?.purchaseDescription?.value ||
            //     d?.purchaseCategoryAccountId?.value)
            // ) {
            //   tempRow[key].error = 'Price required';
            //   isError = true;
            //   if (!indexs?.includes(i)) {
            //     indexs?.push((draftProducts?.page - 1) * localLimit + i + 1);
            //   }
            // }
            // if (
            //   key === 'purchaseCategoryAccountId' &&
            //   !d[key].value &&
            //   (d?.purchasePrice?.value || d?.purchaseDescription?.value)
            // ) {
            //   console.log('index', i);

            //   tempRow[key].error = 'Category required';
            //   isError = true;
            // }
            // if (key === 'tax' && !d[key].value && d?.taxName?.value) {
            //   tempRow[key].error = 'Tax % required';
            //   isError = true;
            // }
            // if (key === 'taxName' && !d[key].value && d?.tax?.value) {
            //   tempRow[key].error = 'Tax Name required';
            //   isError = true;
            // }
          } else {
            const tempRow = { ...tableRows[i] };
            tempRow[key].error = '';
          }
          if (
            key === 'status' &&
            d[key].value !== 'active' &&
            d[key].value !== 'inactive'
          ) {
            tempRow[key].error = 'Only ‘active’ or ‘inactive’ allowed';
            // isError = true;
          }
        });
      }
    });
    setErrorIndexes(indexs);
    setTableRows(tempRows);
    return isError;
  };

  const getCheckedProductsCount = () => {
    let count = 0;
    tableRows?.forEach((t) => {
      if (t?.selected?.value) {
        count++;
      }
    });
    return count;
  };

  const loadDraftProducts = async ({
    _importStatusId = importStatusId,
    page = 1,
    limit = localLimit,
    loading = false,
    allProducts = false,
    mainLoading = false,
  }) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      if (mainLoading) {
        setMainLoading(true);
      }
      const res = await importDraftProducts({
        importStatusId: _importStatusId,
        page,
        limit,
      });
      setDraftProducts(res?.data);
      if (allProducts) {
        return res?.data?.drafts;
      }
      const temp = [];
      if (res?.data?.drafts?.length > 0) {
        res?.data?.drafts?.forEach((d) => {
          temp?.push({
            id: d?.id,
            selected: { value: true, error: false },
            code: {
              value: d?.code,
              error: false,
            },
            stockQuantity: {
              value: d?.stockQuantity,
              error: false,
            },
            currency: { value: d?.currency, error: !d?.currency },
            pricingMethod: { value: d?.pricingMethod || 'item', error: false },
            unitPrice: { value: d?.unitPrice, error: !d?.unitPrice },
            purchasePrice: {
              value: d?.purchasePrice,
              error: d?.purchaseDescription ? !d?.purchasePrice : false,
            },
            purchaseDescription: {
              value: d?.purchaseDescription,
              error: false,
            },

            name: { value: d?.name, error: !d?.name },
            description: { value: d?.description, error: false },
            purchaseCategoryAccountId: {
              value: d?.purchaseCategoryAccountId || null,
              error: d?.purchasePrice ? !d?.purchaseCategoryAccountId : false,
            },
            categoryAccountId: {
              value: d?.salesCategoryAccountId || null,
              error: !d?.salesCategoryAccountId,
            },
            tax: {
              value: d?.tax,
              error: false,
            },
            taxName: {
              value: d?.taxName,
              error: false,
            },
            status: {
              value:
                d?.status === 'active' || d?.status === 'inactive'
                  ? d?.status
                  : 'active',
              error:
                d?.status === 'active' || d?.status === 'inactive'
                  ? false
                  : 'Only ‘active’ or ‘inactive’ allowed',
            },
          });
        });
        setTableRows(temp);
        setErrorIndexes([]);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
      if (mainLoading) {
        setMainLoading(false);
      }
    }
  };

  const importMapping = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('document', file);
      data.append('mapping', JSON.stringify(mapping));
      const res = await productsImportCreate(data);
      setImportStatusId(res?.data?.importStatus?.id);
      await loadDraftProducts({ _importStatusId: res?.data?.importStatus?.id });
      setIsLoading(false);
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const importBulk = async () => {
    try {
      setIsLoading(true);
      // const products = [];
      // const allData = await loadDraftProducts({
      //   page: 1,
      //   limit: draftProducts?.totalRecords,
      //   allProducts: true,
      // });
      // if (allData?.length > 0) {
      //   allData?.forEach((row) => {
      //     // if (row?.selected?.value) {
      //     const obj = {
      //       name: row?.name,
      //       code: row?.code,
      //       description: row?.description,
      //       stockQuantity: row?.stockQuantity,
      //       pricingMethod: row?.pricingMethod,
      //       unitPrice: row?.unitPrice,
      //       currency: row?.currency,
      //       purchasePrice: row?.purchasePrice,
      //       purchaseDescription: row?.purchaseDescription,
      //       categoryAccountId: row?.salesCategoryAccountId,
      //       purchaseCategoryAccountId: row?.purchaseCategoryAccountId,
      //       status: row?.status,
      //     };
      //     if (row?.taxName && row?.tax) {
      //       obj.taxes = [
      //         {
      //           name: row?.taxName,
      //           percentage: row?.tax,
      //         },
      //       ];
      //     }
      //     products?.push(obj);
      //     // }
      //   });
      // }
      const res = await productsImportBulk({
        // products,
        importStatusId,
      });
      if (From === 'onboarding') {
        await handleOnboardingSubStep();
      }

      setImportedTotals(res?.data);
      setActiveStep(activeStep + 1);
      setIsLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsLoading(false);
    }
  };

  const isDisabled = () => {
    if (activeStep === 1 && file) {
      return false;
    }
    if (activeStep === 2) {
      return false;
    }
    if (activeStep === 3) {
      return false;
    }
    if (activeStep === 4) {
      return false;
    }
    return true;
  };

  const handleDeleteItem = async (id) => {
    setDeleteDraftsLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await deleteDraftProductById(id);
      await loadDraftProducts({ page: draftProducts?.page });
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteDraftsLoading((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  const handleLimitChange = (value) => {
    setLocalLimit(value);
    loadDraftProducts({
      limit: value,
      loading: false,
      mainLoading: true,
    });
  };

  const handleBulkUpdateDrafts = async () => {
    setIsLoading(true);
    const data = tableRows?.map((product) => ({
      id: product?.id,
      name: product?.name?.value,
      code: product?.code?.value,
      description: product?.description?.value,
      stockQuantity: product?.stockQuantity?.value,
      pricingMethod: product?.pricingMethod?.value,
      unitPrice: product?.unitPrice?.value,
      currency: product?.currency?.value,
      tax: product?.tax?.value,
      taxName: product?.taxName?.value,
      purchasePrice: product?.purchasePrice?.value,
      purchaseDescription: product?.purchaseDescription?.value,
      status: product?.status?.value,
      salesCategoryAccountId: product?.categoryAccountId?.value,
      purchaseCategoryAccountId: product?.purchaseCategoryAccountId?.value,
    }));
    try {
      await updateBulkDraftProducts(importStatusId, data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onClickNext = async () => {
    if (activeStep === 1 && file) {
      csvFileUpload();
    }
    if (activeStep === 2) {
      const requiredKeys = ['name', 'unitPrice'];
      const optionalKeys = [
        'code',
        'stockQuantity',
        'purchaseDescription',
        'purchasePrice',
        'description',
        'pricingMethod',
      ];

      const hasRequiredKeys = requiredKeys.every(
        (key) =>
          key in mapping &&
          mapping[key] &&
          mapping[key] !== '--' &&
          mapping[key] !== key,
      );

      if (!hasRequiredKeys) {
        setError('You must choose value for Name and Unit Price');
        return;
      }
      const newMapping = { ...mapping };
      optionalKeys.forEach((key) => {
        if (!newMapping[key] || newMapping[key] === '--') {
          newMapping[key] = '';
        }
      });
      setMapping(newMapping);
      setError(null);
      importMapping();
    }
    if (
      activeStep === 3 &&
      +draftProducts?.page <
        Math.ceil(draftProducts?.totalRecords / draftProducts?.limit)
    ) {
      (async () => {
        const error = await new Promise((resolve) => {
          setTimeout(() => {
            const error = checkRowsError();
            resolve(error);
          }, 0);
        });
        if (error) {
          document
            .getElementById('productServiceWarningError')
            ?.scrollIntoView?.({
              behavior: 'smooth', // Optional: Adds smooth scrolling effect
              block: 'center', // Optional: Aligns the element to the center of the view
              inline: 'nearest', // Optional: Aligns the element horizontally
            });
          return;
        }
        if (!error) {
          await handleBulkUpdateDrafts();
          loadDraftProducts({
            page: +draftProducts?.page + 1,
            loading: true,
          });
        }
      })();
    }
    if (
      activeStep === 3 &&
      draftProducts?.page ===
        Math.ceil(draftProducts?.totalRecords / draftProducts?.limit)
    ) {
      (async () => {
        const error = await new Promise((resolve) => {
          setTimeout(() => {
            const error = checkRowsError();
            resolve(error);
          }, 0);
        });
        if (error) {
          document
            .getElementById('productServiceWarningError')
            ?.scrollIntoView?.({
              behavior: 'smooth', // Optional: Adds smooth scrolling effect
              block: 'center', // Optional: Aligns the element to the center of the view
              inline: 'nearest', // Optional: Aligns the element horizontally
            });
          return;
        }
        if (!error) {
          await handleBulkUpdateDrafts();
          importBulk();
        }
      })();
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep((prev) => prev - 1);
    } else if (
      activeStep === 3 &&
      draftProducts?.page > 1 &&
      Math.ceil(draftProducts?.totalRecords / draftProducts?.limit) > 1
    ) {
      setErrorIndexes([]);
      loadDraftProducts({
        page: +draftProducts?.page - 1,
        loading: false,
        mainLoading: true,
      });
    } else if (activeStep === 3) {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <AppLayout>
      <main
        className="grow relative overflow-auto flex flex-col"
        id="productsImportContainer"
      >
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto pb-16">
          {isLoading && activeStep === 2 && (
            <AlertBox
              type="warning"
              heading="Product & Services Import in Progress"
              text="This may take 2-3 minutes"
              hideActionButton
              icon={<Loader />}
            />
          )}
          <div className="mb-8 flex justify-between">
            {/* Title */}
            <h1 className="text-2xl md:text-[36px] text-[#00000099] dark:text-slate-100 font-medium">
              Import Product & Services
            </h1>
          </div>
          <div className="flex justify-between mx-6 relative mb-8">
            <div
              className="absolute h-[1px] bg-[#667085] top-[11px] mx-auto left-1/2 -translate-x-1/2"
              style={{
                width: 'calc(100% - 3rem)',
              }}
            />
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 1
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                1
              </div>
              <h3>Upload</h3>
            </div>
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 2
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                2
              </div>
              <h3>Review column matching</h3>
            </div>

            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 3
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                3
              </div>
              <h3>Review row matching</h3>
            </div>
            <div className="flex flex-col items-center z-10 relative">
              <div
                className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 4
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
              >
                4
              </div>
              <h3>Import</h3>
            </div>
          </div>
          {mainLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : (
            <>
              {activeStep === 1 && (
                <Step1
                  error={fileError}
                  setError={setFileError}
                  file={file}
                  setFile={setFile}
                />
              )}
              {activeStep === 2 && (
                <Step2
                  mapping={mapping}
                  setMapping={setMapping}
                  parsedInfo={parsedInfo}
                  keyArray={keyArray}
                  error={error}
                />
              )}
              {activeStep === 3 && (
                <>
                  <Step3
                    incomeCategories={incomeCategories}
                    expenseCategories={expenseCategories}
                    tableRows={tableRows}
                    setTableRows={setTableRows}
                    checkRowsError={checkRowsError}
                    getCheckedProductsCount={getCheckedProductsCount}
                    errorIndexes={errorIndexes}
                    currentPage={draftProducts?.page}
                    localLimit={localLimit}
                    handleDeleteItem={handleDeleteItem}
                    deleteDraftsLoading={deleteDraftsLoading}
                    // setIsValidating={setIsValidating}
                    setValidating={setValidating}
                  />
                  <div className="flex flex-row gap-2 my-6 items-center mx-auto sm:mx-0">
                    {/* <p className="text-sm font-medium leading-6 text-slate-600">Row</p> */}
                    <div className="w-[72px] h-[42px]">
                      <DropdownFull
                        options={[
                          { id: 20, name: '20' },
                          { id: 30, name: '30' },
                          { id: 50, name: '50' },
                        ]}
                        selected={localLimit}
                        setSelected={(option) => handleLimitChange(option)}
                        name="name"
                        placeholder={localLimit}
                        pagination
                        scrollIntoView={false}
                      />
                    </div>
                    <p className="text-sm font-medium leading-6 text-slate-600">
                      {' '}
                      Per Page ( Showing{' '}
                      {(draftProducts?.page - 1) * localLimit + 1} to{' '}
                      {Math.min(
                        (draftProducts?.page - 1) * localLimit + localLimit,
                        draftProducts?.totalRecords,
                      )}{' '}
                      of Total {draftProducts?.totalRecords} )
                    </p>
                  </div>
                </>
              )}
              {activeStep === 4 && (
                <div className="w-full h-full">
                  {isLoading && (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                      <Loader />
                      <p>Importing your products & services...</p>
                    </div>
                  )}
                  {!isLoading && (
                    <div className="w-full flex items-center justify-center flex-col">
                      <AnimatedCheckmark />
                      <AlertBox
                        type="success"
                        heading="Products & Services Imported Successfully"
                        text={`Created Products & Services : ${importedTotals?.createdProducts} |
                      Skipped Products & Services : ${importedTotals?.skippedProducts} |
                      Updated Products & Services : ${importedTotals?.updatedProducts}
                      `}
                        hideActionButton
                      />
                      <div className="flex gap-2 mt-6">
                        <button
                          className="whitespace-nowrap w-[16rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600"
                          onClick={() => {
                            setActiveStep(1);
                            setFile(null);
                            setFileError('');
                            setParsedInfo([]);
                            setMapping({});
                            setKeyArray([]);
                          }}
                          type="button"
                        >
                          Import More Products & Services
                        </button>
                        {From === 'onboarding' ? (
                          <button
                            className="whitespace-nowrap w-[16rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                            onClick={() => {
                              navigate('/onboarding');
                            }}
                            type="button"
                          >
                            Back to Launchpad
                          </button>
                        ) : (
                          <button
                            className="whitespace-nowrap w-[16rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white"
                            onClick={() => {
                              navigate('/products');
                            }}
                            type="button"
                          >
                            Back to Products & Services
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </main>
      <footer
        className="absolute bottom-0 h-16 bg-white w-full border-t border-[#D0D5DD80] px-6
        flex justify-between items-center"
      >
        {activeStep === 1 ? (
          <Link to={From === 'onboarding' ? '/onboarding' : '/products'}>
            <button
              // disabled
              className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
              type="button"
            >
              Back to{' '}
              {From === 'onboarding' ? 'Launchpad' : 'Products & Services'}
            </button>
          </Link>
        ) : (
          <button
            onClick={handleBack}
            disabled={activeStep === 4}
            className="btn h-fit border-[#667085] rounded-full px-6 font-medium disabled:opacity-80 disabled:cursor-not-allowed"
            type="button"
          >
            Back
          </button>
        )}
        {activeStep !== 4 && (
          <button
            disabled={isDisabled() || isLoading || isValidatingRef.current}
            className="z-[2000000002] btn h-fit rounded-full px-6 !bg-indigo-500 text-white font-medium border-indigo-500
            disabled:!bg-indigo-400 disabled:cursor-not-allowed"
            onClick={onClickNext}
            type="button"
          >
            {isLoading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}

            {activeStep === 3 &&
            draftProducts?.page ===
              Math.ceil(draftProducts?.totalRecords / draftProducts?.limit)
              ? 'Import'
              : 'Next'}
          </button>
        )}
      </footer>
    </AppLayout>
  );
};

export default ImportProductAndServices;
