import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import DropdownInput from 'components/DropdownInput';
import TextInput from 'components/TextInput';
import ModalBasic from '../../components/ModalBasic';
import { Loader } from '../../components/Svg';
import States from '../../data/states.json';

import {
  createTeam,
  getBusinessStructuresData,
  getCountriesData,
  getUserCountry,
} from '../../API/backend_helper';

const SetupFirmWorkspace = ({
  isOpen,
  setIsOpen,
  loadAccountantFirm,
  accountantFirm,
}) => {
  const formikRef = useRef();
  const { currencies } = useSelector((state) => state.Currencies);

  const [loading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const [countries, setCountries] = useState([]);
  const [structures, setStructures] = useState([]);
  const [subStructures, setSubStructures] = useState([]);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [noClick, setNoClick] = useState(false);

  const loadStructures = async (id) => {
    try {
      const res = await getBusinessStructuresData(id);
      setStructures(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const findSubStructures = (name) => {
    const structure = structures.find((d) => d.name === name);
    if (structure) {
      const subStructures = structures.filter(
        (d) => d.parentId === structure.id,
      );
      setSubStructures(subStructures);
    }
  };

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    loadCountries();
    const fetchCountry = async () => {
      try {
        const res = await getUserCountry();
        const country = res?.country;
        if (country === 'US' || country === 'CA' || country === 'NZ') {
          setSelectedCountry(country);
          if (country === 'US') {
            setSelectedCurrency('USD');
          }
          if (country === 'NZ') {
            setSelectedCurrency('NZD');
          }
          if (country === 'CA') {
            setSelectedCurrency('CAD');
          }
          loadStructures(country);
        }
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchCountry();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await createTeam({ ...values, isFirmWorkspace: true });
      if (
        response !== `invalid input value for enum team_sub_types: ""` &&
        response?.id
      ) {
        await loadAccountantFirm({});
        setLoading(false);
        setIsOpen(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log('error', err);
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Setup Firm Workspace"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
      noClick={noClick}
      width="!w-[800px]"
      contentId="setupFirmWorkspaceModal"
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          email: accountantFirm?.email || '',
          type: '',
          subType: '',
          country: selectedCountry || '',
          state: '',
          currency: selectedCurrency || '',
        }}
        validate={(values) => {
          const errors = {};

          if (subStructures.length > 0) {
            if (!values.subType) {
              errors.subType = 'Please Select Business Sub Type';
            }
          }
          if (values.country === 'US') {
            if (!values.state) {
              errors.state = 'Please Select State';
            }
          }
          if (structures.length > 0 && !values.type) {
            errors.type = 'Please Select Business Type';
          }
          return errors;
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email'),
          type: Yup.string().optional('Please Select Type'),
          country: Yup.string().trim().required('Please Select Country'),
          state: Yup.string().optional('Please Select State'),
        })}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form>
            <div className="space-y-4 pt-8">
              <div className="grid sm:grid-cols-2 gap-x-4 gap-y-8 px-8">
                <div>
                  <DropdownInput
                    options={countries?.map((c) => ({
                      ...c,
                      id: c.isoCode,
                    }))}
                    label="Business Country"
                    id="country"
                    name="name"
                    setSelected={(id) => {
                      validation.setFieldValue('country', id);
                      validation.setFieldValue(
                        'currency',
                        countries?.find((c) => c?.isoCode === id)
                          ?.defaultCurrency,
                      );
                      validation.setFieldValue('type', '');
                      validation.setFieldValue('subType', '');
                      loadStructures(id);
                    }}
                    selected={validation.values.country}
                    error={
                      !validation.values.country && validation.touched.country
                        ? validation.errors.country
                        : ''
                    }
                    onBlur={validation.handleBlur}
                    countries
                    required
                    setNoClick={setNoClick}
                    ids={['setupFirmWorkspaceModal']}
                    bottomOfTable
                  />
                  {validation.values.currency && (
                    <div className="text-sm font-medium text-[#667085] !mt-2">
                      Your default currency is {validation.values.currency}.{' '}
                      <span
                        className="text-[#E48642] cursor-pointer"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                          if (showCurrencyDropdown) {
                            validation.setFieldValue(
                              'currency',
                              countries?.find(
                                (c) => c?.isoCode === validation.values.country,
                              )?.defaultCurrency,
                            );
                          }
                          setShowCurrencyDropdown(!showCurrencyDropdown);
                        }}
                      >
                        {showCurrencyDropdown ? 'Cancel' : 'Change Currency'}
                      </span>
                    </div>
                  )}
                </div>
                {showCurrencyDropdown && (
                  <DropdownInput
                    options={currencies?.map((c) => ({
                      ...c,
                      id: c?.isoCode,
                      name: `${c?.symbol} ${c?.name}`,
                    }))}
                    id="currency"
                    label="Business Currency"
                    name="name"
                    setSelected={(id) => {
                      validation.setFieldValue('currency', id);
                    }}
                    selected={validation.values.currency}
                    error={
                      validation.touched.currency
                        ? validation.errors.currency
                        : ''
                    }
                    onBlur={validation.handleBlur}
                    required
                    setNoClick={setNoClick}
                    ids={['setupFirmWorkspaceModal']}
                    bottomOfTable
                  />
                )}
                <TextInput
                  label="Business / Workspace Email"
                  placeholder="Email"
                  id="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  error={
                    validation.touched.email ? validation.errors.email : ''
                  }
                  required
                />
                {validation.values.country === 'US' && (
                  <DropdownInput
                    className="h-fit"
                    options={States.map((s) => ({
                      id: s.abbreviation,
                      state: `${s.name} (${s.abbreviation})`,
                    }))}
                    id="state"
                    label="State"
                    name="state"
                    setSelected={(id) => {
                      validation.setFieldValue('state', id);
                    }}
                    selected={validation.values.state}
                    error={
                      !validation.values.state && validation.touched.state
                        ? validation.errors.state
                        : ''
                    }
                    onBlur={validation.handleBlur}
                    searchable
                    required
                    setNoClick={setNoClick}
                    ids={['setupFirmWorkspaceModal']}
                    bottomOfTable
                  />
                )}
                {validation.values.country && structures.length > 0 && (
                  <div>
                    <DropdownInput
                      options={structures
                        .filter((d) => d.parentId === null)
                        .map((d) => ({
                          id: d.name,
                          type: d.name,
                        }))}
                      label="Business Type"
                      id="type"
                      name="type"
                      disabled={
                        !validation.values.country || structures.length === 0
                      }
                      setSelected={(id) => {
                        validation.setFieldValue('type', id);
                        findSubStructures(id);
                        if (
                          id === 'Self-employed' ||
                          id === 'Sole Proprietorship'
                        ) {
                          validation.setFieldValue('subType', "I'm not sure");
                        } else {
                          validation.setFieldValue('subType', '');
                        }
                      }}
                      selected={validation.values.type}
                      error={
                        !validation.values.type && validation.touched.type
                          ? validation.errors.type
                          : ''
                      }
                      onBlur={validation.handleBlur}
                      required
                      setNoClick={setNoClick}
                      ids={['setupFirmWorkspaceModal']}
                      bottomOfTable
                    />
                    {validation.values.type && subStructures?.length > 0 && (
                      <div className="pt-6 pl-8 relative">
                        <div className="absolute top-0 left-[16px] w-[1.5px] h-[68%] bg-[#D0D5DD]">
                          <div className="absolute bottom-0 h-[1.5px] w-[16px] bg-[#D0D5DD]" />
                        </div>
                        <DropdownInput
                          options={subStructures.map((d) => ({
                            id: d.name,
                            subType: d.name,
                          }))}
                          id="subType"
                          label={`What type of ${validation.values.type}`}
                          onBlur={validation.handleBlur}
                          name="subType"
                          setSelected={(id) => {
                            validation.setFieldValue('subType', id);
                          }}
                          selected={validation.values.subType}
                          error={
                            validation.touched.subType
                              ? validation.errors.subType
                              : ''
                          }
                          required
                          setNoClick={setNoClick}
                          ids={['setupFirmWorkspaceModal']}
                          bottomOfTable
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="border-t border-[#D0D5DD] py-4 px-8 flex w-full justify-end items-center gap-4">
                <button
                  type="button"
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500
            disabled:!bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader color="#FFFFFF" width="w-4" height="h-4" />
                  ) : (
                    'Setup'
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default SetupFirmWorkspace;
