import React, { useEffect, useState } from 'react';
import { formatNumberGlobally, getCurrencySymbol } from 'utils/Utils';
import {
  assignTransactionToInvoiceBill,
  getAllBills,
} from 'API/backend_helper';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import TextInputDash from 'components/TextInputDash';
import WarningBox from 'components/WarningBox';

const BillBox = ({
  bill,
  selectedBills,
  onSelectBill,
  onAmountChange,
  billInstance,
  amountDue,
  billInstanceId,
  isSplit = false,
}) => {
  const isBillSelected = selectedBills?.find((_bill) => _bill?.id === bill?.id);
  return (
    <div key={bill?.id} className="border border-[#D0D5DD] p-4 rounded-[5px]">
      <label
        className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
        htmlFor={bill?.id}
      >
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5"
          id={bill?.id}
          checked={isBillSelected?.id}
          onChange={() => {
            onSelectBill(bill, billInstanceId, amountDue);
          }}
        />

        <p className="text-[20px] text-[#101828]">
          {bill?.vendor?.name || '--'}
        </p>
      </label>

      <div className="pl-9 flex flex-col gap-y-2">
        <div className="text-base text-[#667085] flex gap-4 ">
          <p className="w-24 min-w-24">Date: </p>

          <p>{moment(billInstance?.firstBillDate).format('MMM DD, YYYY')}</p>
        </div>
        <div className="text-base text-[#667085] flex gap-4 ">
          <p className="w-24 min-w-24">Due Date: </p>
          <p>
            {moment(
              billInstance?.dueDate
                ? billInstance?.dueDate
                : billInstance?.firstDueDate,
            ).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className="text-base text-[#667085] flex gap-4 ">
          <p className="w-24 min-w-24 text-nowrap">Amount Due: </p>
          <p>
            {getCurrencySymbol(bill?.currency)}
            {formatNumberGlobally(amountDue ?? 0)}
          </p>
        </div>
        <div className="text-base text-[#667085] flex gap-4 ">
          <p className="w-24 min-w-24 text-nowrap">Total Amount: </p>
          <p>
            {getCurrencySymbol(bill?.currency)}
            {formatNumberGlobally(billInstance?.total ?? 0)}
          </p>
        </div>
        <div className="text-base text-[#667085] flex gap-4">
          <p className="w-24 min-w-24"> Bill # </p>
          <p>{bill?.billNumber ? `#${bill?.billNumber}` : '--'}</p>
        </div>
        {isBillSelected?.id && (
          <>
            {isBillSelected?.amountToPay > 0 && (
              <div
                className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                ${
                  parseFloat(amountDue)?.toFixed(4) ===
                  parseFloat(isBillSelected?.amountToPay)?.toFixed(4)
                    ? 'text-[#40AA00] bg-[#40AA001A]'
                    : 'text-[#EDB900] bg-[#EDB9001A]'
                }
           `}
              >
                {parseFloat(amountDue)?.toFixed(4) ===
                parseFloat(isBillSelected?.amountToPay)?.toFixed(4)
                  ? 'Full Payment'
                  : 'Partial Payment'}
              </div>
            )}
            <TextInputDash
              label="Amount to Pay"
              max={amountDue}
              min={0}
              type="number"
              value={isBillSelected?.amountToPay}
              onChange={(e) => onAmountChange(e.target.value, bill, amountDue)}
              width="max-w-[200px]"
              onWheel={(e) => e.target.blur()}
              disabled={isSplit}
            />
          </>
        )}
      </div>
    </div>
  );
};

const MatchBillTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setClose,
}) => {
  const [selectedBills, setSelectedBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [loadBill, setLoadBill] = useState(true);

  useEffect(() => {
    if (
      transaction?.matchingBillInstances?.length === 1 &&
      selectedBills?.length === 0
    ) {
      setSelectedBills([
        {
          id: transaction?.matchingBillInstances?.[0]?.bill?.id,
          amountDue: formatNumberGlobally(
            Math.abs(transaction?.matchingBillInstances?.[0]?.total) -
              Math.abs(transaction?.matchingBillInstances?.[0]?.paidAmount),
            true,
          ),
          billInstanceId:
            transaction?.matchingBillInstances?.[0]?.BillTransactionMatching
              ?.billInstanceId,
          amountToPay: formatNumberGlobally(
            Math.abs(transaction?.matchingBillInstances?.[0]?.total) -
              Math.abs(transaction?.matchingBillInstances?.[0]?.paidAmount),
            true,
          ),
        },
      ]);
    }
  }, [transaction]);

  const getBillsApi = async () => {
    setLoadBill(true);
    try {
      const res = await getAllBills({
        limit: 100000,
        amount: transaction?.split ? Math.abs(transaction?.amount) : '',
      });
      const filteredBills = res?.bills?.filter(
        (bill) =>
          !transaction?.matchingBillInstances?.some(
            (matchingBill) => matchingBill?.billId === bill?.id,
          ) && bill?.status !== 'paid',
      );
      setBills(filteredBills);
      setLoadBill(false);
    } catch (e) {
      setLoadBill(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!transaction?.billInstanceId) {
      getBillsApi();
    } else {
      setLoadBill(false);
    }
  }, [transaction?.billInstanceId]);

  const onAssignBills = async () => {
    try {
      setLoading(true);
      const filteredBills = selectedBills?.filter(
        (bill) => Math.abs(bill?.amountToPay) > 0,
      );
      const records = filteredBills?.map((bill) => ({
        id: bill?.billInstanceId,
        paymentAmount: Math.abs(bill?.amountToPay),
      }));
      await assignTransactionToInvoiceBill(transaction?.id, {
        records,
        matchingType: 'bill',
      });
      // await reloadTransaction({});
      toast.success('Transaction assigned to Bill(s) successfully');
      setLoading(false);
      reloadTransactions({});
      setClose(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  const AmountLeft = () => {
    let amount = 0;
    if (selectedBills?.length > 0) {
      selectedBills?.forEach((b) => {
        amount += Math.abs(parseFloat(b?.amountToPay));
      });
      return formatNumberGlobally(
        parseFloat(Math.abs(transaction?.amount) - Math.abs(amount)),
        true,
      );
    }
    return formatNumberGlobally(
      parseFloat(Math.abs(transaction?.amount)),
      true,
    );
  };

  const onSelectBill = (bill, billInstanceId, amountDue) => {
    let temp = JSON.parse(JSON.stringify(selectedBills));
    const find = selectedBills?.find((_bill) => _bill?.id === bill?.id);
    if (find) {
      temp = temp?.filter((_bill) => bill?.id !== _bill?.id);
      setSelectedBills(temp);
    } else {
      if (transaction?.split) {
        temp = [
          {
            id: bill?.id,
            amountDue,
            billInstanceId,
            amountToPay: Math.abs(transaction?.amount),
          },
        ];
      } else {
        const amount = AmountLeft();
        temp.push({
          id: bill?.id,
          amountDue,
          billInstanceId,
          amountToPay:
            formatNumberGlobally(Math.abs(amount), true) >=
            formatNumberGlobally(Math.abs(amountDue), true)
              ? formatNumberGlobally(Math.abs(amountDue), true)
              : Math.abs(amount),
        });
      }
      setSelectedBills(temp);
    }
  };

  const onAmountChange = (value, bill, amountDue) => {
    const findIndex = selectedBills?.findIndex(
      (_bill) => _bill?.id === bill?.id,
    );
    if (
      (findIndex >= 0 &&
        formatNumberGlobally(Math.abs(parseFloat(value)), true) <=
          formatNumberGlobally(amountDue, true) &&
        formatNumberGlobally(Math.abs(parseFloat(value)), true) <=
          formatNumberGlobally(Math.abs(transaction?.amount)),
      true) ||
      !value
    ) {
      const temp = JSON.parse(JSON.stringify(selectedBills));
      const tempObj = { ...temp[findIndex] };
      tempObj.amountToPay = value;
      temp[findIndex] = tempObj;
      setSelectedBills(temp);
    }
  };

  return (
    <div>
      <div
        className="px-10 flex flex-col"
        style={{
          maxHeight: 'calc(100dvh - 308px)',
          height: 'calc(100dvh - 308px)',
        }}
      >
        {((AmountLeft() > 0 &&
          AmountLeft()?.toFixed(4) !==
            parseFloat(Math.abs(transaction?.amount))?.toFixed(4)) ||
          AmountLeft() < 0) && (
          <div className="pb-4">
            {AmountLeft() > 0 &&
              AmountLeft()?.toFixed(4) !==
                parseFloat(Math.abs(transaction?.amount))?.toFixed(4) && (
                <div className="text-[#78BD4F] border border-[#78BD4F] bg-[#78BD4F] bg-opacity-5 py-1 px-4 rounded-[5px]">
                  <div>
                    <span className="font-medium">
                      {' '}
                      Amount to Pay from Transaction:{' '}
                    </span>
                    {getCurrencySymbol(transaction?.currency)}
                    {formatNumberGlobally(
                      parseFloat(Math.abs(transaction?.amount)) - AmountLeft(),
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <span className="font-medium">Amount Left: </span>{' '}
                      {getCurrencySymbol(transaction?.currency)}
                      {formatNumberGlobally(AmountLeft())}{' '}
                    </div>
                    <Tooltip
                      content={
                        <>
                          <p>Left over amount would be created</p>
                          <p>as a split transaction</p>
                        </>
                      }
                      contentClassName="text-center rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                      position="right"
                      className="w-fit"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                      >
                        <path
                          d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                          stroke="#78BD4F"
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                          stroke="#78BD4F"
                          strokeWidth="0.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                          fill="#78BD4F"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              )}

            {AmountLeft() < 0 && (
              <div className="text-rose-500 border border-rose-500 bg-rose-100 py-1 px-4 rounded-[5px]">
                <div>You paid more than the actual amount</div>
                <div>
                  <span className="font-medium"> Transaction Amount: </span>{' '}
                  {getCurrencySymbol(transaction?.currency)}
                  {formatNumberGlobally(Math.abs(transaction?.amount))}
                </div>
                <div>
                  <span className="font-medium">Amount Used: </span>
                  {getCurrencySymbol(transaction?.currency)}
                  {formatNumberGlobally(
                    Math.abs(AmountLeft()) + Math.abs(transaction?.amount),
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {transaction?.split && (
          <WarningBox
            className="mb-2"
            content="Split transactions cannot be further divided and must be fully allocated to a bill; they cannot be assigned to bills with a due amount lower than the transaction."
          />
        )}
        <p className="mb-4">
          Link this transaction to one or more specific bills here. This keeps
          your payables in sync and simplifies tracking payments.
        </p>
        <div className="flex-1 h-full flex flex-col mb-6 overflow-auto scrollbar">
          {transaction?.matchingBillInstances?.length > 0 && (
            <div className="grid md:grid-cols-2 gap-4 pb-4 pr-2">
              {transaction?.matchingBillInstances?.map((bill) => (
                <BillBox
                  bill={bill?.bill}
                  key={bill?.billId}
                  billInstance={bill?.bill}
                  billInstanceId={bill?.BillTransactionMatching?.billInstanceId}
                  selectedBills={selectedBills}
                  onSelectBill={onSelectBill}
                  onAmountChange={onAmountChange}
                  amountDue={formatNumberGlobally(
                    Math.abs(bill?.total) - Math.abs(bill?.paidAmount),
                    true,
                  )}
                  isSplit={transaction?.split}
                />
              ))}
            </div>
          )}
          {bills?.length > 0 &&
            transaction?.matchingBillInstances?.length > 0 && (
              <div className="w-full min-h-[2px] pr-1">
                <svg
                  width="800"
                  height="2"
                  viewBox="0 0 800 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-indigo-500 w-full h-[2px]"
                >
                  <path
                    d="M5 1.5L799 0.5"
                    stroke="#E48642"
                    strokeOpacity="0.8"
                    strokeLinecap="round"
                    strokeDasharray="7 7"
                  />
                </svg>
              </div>
            )}
          {!bills?.length > 0 && loadBill && (
            <div
              className="flex flex-col gap-2 items-center pb-2 justify-center mt-8"
              style={{
                maxHeight: 'calc(100dvh - 223px)',
                height: 'calc(100dvh - 223px)',
              }}
            >
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}
          {!bills?.length > 0 &&
            !transaction?.matchingBillInstances?.length &&
            !loadBill && (
              <div
                className="text-[18px] text-slate-500 text-center  flex items-center justify-center"
                style={{
                  maxHeight: 'calc(100dvh - 223px)',
                  height: 'calc(100dvh - 223px)',
                }}
              >
                No Matching Bill found
              </div>
            )}

          {bills?.length > 0 && (
            <div
              className={`grid md:grid-cols-2 gap-4 pr-2 ${transaction?.matchingBillInstances?.length > 0 ? 'pt-4' : ''}`}
            >
              {bills?.map((bill) => (
                <BillBox
                  bill={bill}
                  key={bill?.id}
                  billInstance={bill?.instances?.[bill?.instances?.length - 1]}
                  billInstanceId={
                    bill?.instances?.[bill?.instances?.length - 1]?.id
                  }
                  amountDue={formatNumberGlobally(
                    Math.abs(
                      bill?.instances?.[bill?.instances?.length - 1]?.total,
                    ) -
                      Math.abs(
                        bill?.instances?.[bill?.instances?.length - 1]
                          ?.paidAmount,
                      ),
                    true,
                  )}
                  selectedBills={selectedBills}
                  onSelectBill={onSelectBill}
                  onAmountChange={onAmountChange}
                  simpleBill
                  isSplit={transaction?.split}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <footer className="px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between items-center flex-wrap">
          {/* <p className="text-[#667085]">Remaining Amount: $100</p> */}
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setClose(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            {(transaction?.matchingBillInstances?.length > 0 ||
              bills?.length > 0) && (
              <Tooltip
                content={`${
                  !selectedBills?.length
                    ? 'Select a Bill to proceed'
                    : parseFloat(AmountLeft() ?? 0)?.toFixed(4) ===
                        parseFloat(Math.abs(transaction?.amount))?.toFixed(4)
                      ? 'Please assign an amount greater than zero to the bill(s)'
                      : parseFloat(AmountLeft() ?? 0) < 0
                        ? 'Assigned amount to bill(s) is greater than Transaction amount'
                        : ''
                }`}
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown min-w-[200px] text-wrap"
                position="top"
                size="sm"
                tooltipShow={
                  !selectedBills?.length ||
                  parseFloat(AmountLeft() ?? 0) < 0 ||
                  parseFloat(AmountLeft() ?? 0)?.toFixed(4) ===
                    parseFloat(Math.abs(transaction?.amount))?.toFixed(4)
                }
              >
                <button
                  type="button"
                  disabled={
                    !selectedBills?.length ||
                    loading ||
                    parseFloat(AmountLeft() ?? 0) < 0 ||
                    parseFloat(AmountLeft() ?? 0)?.toFixed(4) ===
                      parseFloat(Math.abs(transaction?.amount))?.toFixed(4)
                  }
                  onClick={onAssignBills}
                  className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:cursor-not-allowed"
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Apply Payment to Bill
                  {/* Apply Payment to Invoice(s) */}
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MatchBillTab;
