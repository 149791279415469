import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import AppLayout from '../../../components/AppLayout';
import {
  getAppIntegrations,
  syncGustoEmployee,
} from '../../../API/backend_helper';
import { getAppStatusColor, getGustoStatus } from '../../../utils/Utils';
import AlertBox from '../../../components/AlertBox';

let connectionPoll = null;
const GustoSettings = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const syncEmployee = async (appId, departmentId, status) => {
    const data = {
      enableSyncEmployees: status,
    };
    try {
      await syncGustoEmployee(appId, departmentId, data);
    } catch (e) {
      console.log('error', e);
    }
  };

  const gustoPoll = async (res, timeOut = 15000) => {
    let filter = [];
    if (res?.[0]?.gusto?.[0]?.departments?.length > 0) {
      filter = res?.[0]?.gusto?.[0]?.departments?.filter(
        (dep) =>
          dep?.enableSyncEmployees && dep?.employeeSyncStatus !== 'completed',
      );
    }
    if (
      filter?.length > 0 &&
      res?.[0]?.gusto?.[0]?.createStatus === 'completed'
    ) {
      if (isLoading) {
        setData(res);
        setIsLoading(false);
      } else {
        setData(res);
      }
    }
    if (
      res?.[0]?.gusto?.[0]?.createStatus !== 'completed' ||
      filter?.length > 0
    ) {
      if (!isPolling) {
        setIsPolling(true);
      }
      clearTimeout(connectionPoll);
      connectionPoll = null;
      connectionPoll = setTimeout(async () => {
        const newRes = await getAppIntegrations({ app: 'gusto' });
        await gustoPoll(newRes?.data?.apps);
      }, timeOut);
    } else {
      setData(res);
      setIsLoading(false);
    }
  };

  const getSettings = async ({ isLoading = false } = {}) => {
    if (isLoading) {
      setIsLoading(true);
    }
    try {
      const res = await getAppIntegrations({ app: 'gusto' });
      gustoPoll(res?.data?.apps);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const handleSyncEmployee = async (appId, departmentId, status) => {
    try {
      await syncEmployee(appId, departmentId, status);
      await getSettings();
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getSettings({ isLoading: true });
    return () => {
      if (connectionPoll) {
        clearTimeout(connectionPoll);
      }
    };
  }, []);

  const isDepartmentEmployeesSynced = useMemo(() => {
    let bool = true;
    if (
      data?.[0]?.gusto?.[0]?.id &&
      data?.[0]?.gusto?.[0]?.departments?.length > 0
    ) {
      for (let i = 0; i < data?.[0]?.gusto?.[0]?.departments?.length; i++) {
        if (data?.[0]?.gusto?.[0]?.departments?.[i]?.enableSyncEmployees) {
          bool = false;
          break;
        }
      }
    }
    return bool;
  }, [data]);

  return (
    <AppLayout>
      <main className="relative grow px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
        {/* Content */}
        {/* Page header */}
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-6">
          <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
            Gusto Settings
          </h1>
        </div>
        <div>
          <div className="mb-5">
            {data?.[0]?.gusto?.[0]?.id && isDepartmentEmployeesSynced && (
              <AlertBox
                type="warning"
                heading="Sync Employees from Departments!"
                text="Please select which departments you would like to sync employees for "
                hideActionButton
              />
            )}
          </div>
        </div>
        {isLoading ? (
          // Loader
          <div className="flex flex-col gap-2 items-center pb-2 justify-center">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <section className="pb-6">
            <div className="grid grid-cols-12 gap-4">
              {data?.[0]?.gusto?.map((app) => (
                <div
                  key={app?.id}
                  className="col-span-full  md:col-span-12 w-full sm:w-1/2 mx-auto bg-white dark:bg-slate-800 rounded-[10px]"
                  style={{ boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.06)' }}
                >
                  {/* Card content */}
                  <header className="flex rounded-t-[10px] justify-between items-center p-5  ">
                    <div className="flex items-center gap-3">
                      <div>
                        <h3 className="text-lg font-medium text-[#101828CC] capitalize">
                          {app?.name}
                        </h3>
                        <p>
                          {app?.address?.[0]?.street}{' '}
                          {app?.address?.[0]?.street2
                            ? `, ${app?.address?.[0]?.street2}`
                            : ''}
                        </p>
                        <p>
                          {app?.address?.[0]?.city}
                          {app?.address?.[0]?.city && ','}{' '}
                          {app?.address?.[0]?.state}{' '}
                          {app?.address?.[0]?.zipCode}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center  gap-4">
                      <div
                        className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                      ${getAppStatusColor(app?.createStatus)}
                    `}
                      >
                        {app?.createStatus === 'completed'
                          ? 'connected'
                          : app?.createStatus}
                      </div>
                    </div>
                  </header>
                  {/* Dotted Line  */}
                  <svg
                    width="574"
                    height="2"
                    viewBox="0 0 574 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full  h-auto px-5"
                  >
                    <path
                      d="M1 1H573.5"
                      stroke="#D0D5DD"
                      strokeOpacity="0.8"
                      strokeLinecap="round"
                      strokeDasharray="6 6"
                    />
                  </svg>
                  {/* Departments  */}

                  {app?.departments?.length > 0 &&
                    app?.departments
                      ?.sort((a, b) => a?.title?.localeCompare(b?.title))
                      ?.map((dept) => (
                        <div className="p-5 pb-0" key={dept?.id}>
                          <h3 className="text-base font-medium leading-tight mb-3 text-[#101828]">
                            {dept?.title}{' '}
                          </h3>
                          <div className="flex gap-2 pb-5">
                            <div className="form-switch form-switch-indigo">
                              <input
                                type="checkbox"
                                className="sr-only"
                                id={`switch-${app?.id}-${dept?.id}`}
                                checked={dept?.enableSyncEmployees}
                                onChange={() =>
                                  handleSyncEmployee(
                                    app?.id,
                                    dept?.id,
                                    !dept?.enableSyncEmployees,
                                  )
                                }
                              />
                              <label
                                className="bg-white border cursor-pointer border-gray-300 border-opacity-50 shadow-sm"
                                htmlFor={`switch-${app?.id}-${dept?.id}`}
                              >
                                <span
                                  className="bg-gray-300"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">Switch label</span>
                              </label>
                            </div>
                            <label
                              htmlFor={`switch-${app?.id}-${dept?.id}`}
                              className="text-sm text-[#667085] cursor-pointer"
                            >
                              Automatically import employees for this department
                            </label>
                          </div>

                          <div className="grid grid-cols-2 gap-2 mb-4 text-sm">
                            {dept?.title === 'Other' &&
                            dept?.employeeSyncStatus !== 'completed'
                              ? false
                              : true && (
                                  <div>
                                    <span className="font-medium">
                                      Employees Count:
                                    </span>{' '}
                                    {dept?.employeeCount || 0}
                                  </div>
                                )}
                            {dept?.enableSyncEmployees &&
                              dept?.employeeSyncStatus && (
                                <div>
                                  <span>Employees Sync Status:</span>{' '}
                                  <span
                                    className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                                ${getGustoStatus(dept?.employeeSyncStatus)}`}
                                  >
                                    {dept?.employeeSyncStatus}
                                  </span>
                                </div>
                              )}
                            {dept?.enableSyncEmployees && (
                              <div>
                                <span className="font-medium">
                                  Last Synced:{' '}
                                </span>{' '}
                                {dept?.lastEmployeesSyncDate
                                  ? moment(dept?.lastEmployeesSyncDate).format(
                                      'MMM DD, h:mm a',
                                    )
                                  : '--'}
                              </div>
                            )}
                          </div>

                          {/* Dotted Line  */}
                          <svg
                            width="574"
                            height="2"
                            viewBox="0 0 574 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full  h-auto"
                          >
                            <path
                              d="M1 1H573.5"
                              stroke="#D0D5DD"
                              strokeOpacity="0.8"
                              strokeLinecap="round"
                              strokeDasharray="6 6"
                            />
                          </svg>
                        </div>
                      ))}
                </div>
              ))}
            </div>
          </section>
        )}
      </main>
    </AppLayout>
  );
};

export default GustoSettings;
