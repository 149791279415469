import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Logo from '../../images/custom/count-logo.png';
import AuthSlider from '../../components/AuthSlider';
import { resendVerifyOtp, verifyEmail } from '../../API/backend_helper';
import { IS_ACCOUNTANT, STAFF, SUPPORT } from '../../utils/Utils';
import { setLocalUser } from '../../redux/User/reducer';

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const email = decodeURI(params.get('email') || '');

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);

  document.title = 'COUNT | Verify Email';

  const onVerifyEmail = async (e) => {
    e.preventDefault();
    if (otp?.length === 6) {
      setLoading(true);
      try {
        const response = await verifyEmail(email?.toLowerCase(), otp);
        const token = response?.data?.auth?.access_token;
        const refreshToken = response?.data?.auth?.refresh_token;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        dispatch(setLocalUser(response?.data?.user));
        setLoading(false);
        // For Bookkepper
        if (response?.data?.user?.isAccountingFirmUser) {
          localStorage.setItem(IS_ACCOUNTANT, 'true');
          navigate('/practice/new');
          return;
        }
        // For Staff User
        if (
          response?.data?.user?.realm === STAFF ||
          response?.data?.user?.realm === SUPPORT
        ) {
          navigate('/manage-workspaces');
          return;
        }
        if (response?.data?.invites?.length > 0) {
          navigate('/manage-workspaces/my-invites');
        } else if (
          response?.data?.firmInvites?.length > 0 ||
          response?.data?.ownerInvitaions?.length > 0
        ) {
          navigate('/manage-workspaces/firm-invites');
        } else {
          navigate('/workspace/new');
        }
      } catch (e) {
        console.log('error', e);
        toast.error('Please enter the correct one time password.');
        setLoading(false);
      }
    }
  };

  const onResendVerify = async () => {
    if (!resending) {
      setResending(true);
      try {
        await resendVerifyOtp(email);
        setResending(false);
      } catch (e) {
        toast.error('Email not found');
        console.log('error', e);
        setResending(false);
      }
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>

            <div className="flex text-center flex-col items-center max-w-[456px] min-w-[50%] mx-auto w-full  px-4 py-8">
              <div className="h-16 w-16 mb-6 rounded-full bg-[#FFF2EA] inline-flex justify-center items-center">
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.52734 5.26482L11.71 12.3927C12.5346 12.9699 12.9469 13.2585 13.3953 13.3703C13.7915 13.469 14.2058 13.469 14.6019 13.3703C15.0503 13.2585 15.4626 12.9699 16.2872 12.3927L26.4699 5.26482M7.51355 21.4775H20.4837C22.579 21.4775 23.6267 21.4775 24.427 21.0697C25.131 20.711 25.7034 20.1386 26.0621 19.4346C26.4699 18.6343 26.4699 17.5866 26.4699 15.4913V7.50965C26.4699 5.41428 26.4699 4.36659 26.0621 3.56627C25.7034 2.86228 25.131 2.28992 24.427 1.93122C23.6267 1.52344 22.579 1.52344 20.4837 1.52344H7.51355C5.41818 1.52344 4.3705 1.52344 3.57017 1.93122C2.86619 2.28992 2.29383 2.86228 1.93513 3.56627C1.52734 4.36659 1.52734 5.41428 1.52734 7.50965V15.4913C1.52734 17.5866 1.52734 18.6343 1.93513 19.4346C2.29383 20.1386 2.86619 20.711 3.57017 21.0697C4.3705 21.4775 5.41818 21.4775 7.51355 21.4775Z"
                    stroke="#E48642"
                    strokeWidth="2.13793"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 ">
                Verify your email now
              </h1>
              <p className="text-lg text-[#667085CC] mb-4">
                We’ve sent an email verification link to
                <span className="font-medium text-[#667085]"> {email}</span>.
                Please check your email and enter the verification code to
                continue.
              </p>
              <form onSubmit={onVerifyEmail}>
                <OtpInput
                  value={otp}
                  shouldAutoFocus
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span className="w-3" />}
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="number"
                      className="form-input remove-arrows !h-10 !w-10 rounded-[12px] p-0 border-[#D0D5DD] shadow-none focus:border-indigo-600 focus:!shadow-none"
                    />
                  )}
                />
                <button
                  className="btn text-lg rounded-[10px] mt-8 mb-6 font-semibold text-white w-full py-3 !bg-indigo-500 disabled:!bg-indigo-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={otp?.length !== 6 || loading}
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Continue
                </button>
              </form>
              <div className="text-lg text-[#667085CC]">
                Didn’t receive the email?{' '}
                <span
                  className="text-indigo-500 font-medium cursor-pointer"
                  onClick={onResendVerify}
                  tabIndex="0"
                  onKeyDown={null}
                  role="button"
                >
                  {resending ? 'Resending ...' : 'Click to resend'}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
      </div>
    </main>
  );
}

export default VerifyEmail;
