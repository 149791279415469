/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Tooltip from 'components/Tooltip';
import { getStringSegments } from 'utils/Utils';
import DropdownFull from '../../components/DropdownFull';

const Step2 = ({ mapping, setMapping, keyArray, parsedInfo, error }) => {
  const { total, products } = parsedInfo;

  const dropdownHeaders = [
    { name: 'Name', id: 'name' },
    { name: 'Sell Price', id: 'unitPrice' },
    { name: 'Code', id: 'code' },
    { name: 'Income Account', id: 'incomeAccount' },
    { name: 'Expense Account', id: 'expenseAccount' },
    { name: 'Quantity', id: 'stockQuantity' },
    { name: 'Tax Percentage', id: 'tax' },
    { name: 'Tax Name', id: 'taxName' },
    { name: 'Purchase Description', id: 'purchaseDescription' },
    { name: 'Purchase Price', id: 'purchasePrice' },
    { name: 'Description', id: 'description' },
    { name: 'Pricing Method', id: 'pricingMethod' },
    { name: 'Status', id: 'status' },
  ];

  const checkIfMatched = (mapping, key) => {
    const _key = Object.keys(mapping).find((mapKey) => mapping[mapKey] === key);
    const exists = dropdownHeaders?.filter((d) => d?.id === _key);
    if (exists?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="">
      <h3 className="text-2xl font-semibold">Match Columns to COUNT Fields</h3>
      {/* <p className="text-base text-slate-600 mb-3">
        Please use the dropdown menus in the header row to map each column to
        the appropriate field. Ensure that product & service Name and Unit Price
        are selected. You can choose the "--" option to skip any unnecessary
        columns. Below you will see the first 6 products & services displayed,
        with a summary indicating the total number of products & services being
        imported.
      </p> */}
      {error && <p className="text-rose-500">{error}</p>}
      <div className="bg-transparent w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] " />
      <div
        className="w-full overflow-x-auto scrollbar mb-6"
        id="productsImportTableContainer"
      >
        <table className="table-fixed dark:text-slate-300 border border-[#D0D5DD] rounded-[5px]">
          <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
            <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
              <th className="px-4 text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] min-w-[300px] w-[300px]">
                File Column
              </th>
              <th className="px-4 text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] min-w-[300px] w-[300px]">
                COUNT's Column
              </th>
              <th className=" px-4 text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] min-w-[180px] w-[180px]">
                Match Status
              </th>
            </tr>
          </thead>
          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
            {keyArray.map((key, index) => (
              <tr
                key={index}
                className="cursor-pointer w-full pl-[1.375rem]  pr-[1.125rem] h-16 hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                <td className="px-4 text-left text-[14px] font-medium leading-[1.125rem]">
                  <div className="flex justify-between items-center gap-4">
                    <span>{key.name}</span>
                    <Tooltip
                      content={(() => {
                        const str = `
                          Data preview for "${key.name || '-- '}" (first ${total} row)`;
                        const segments = getStringSegments(str, 70);
                        return (
                          <>
                            {segments.map((segment, index) => (
                              <p key={index}>{segment}</p>
                            ))}
                            {products?.length > 0 && (
                              <ul className="list-disc pl-4">
                                {products?.map(
                                  (p, i) =>
                                    p?.[key?.name] && (
                                      <li key={i}> {p?.[key?.name]}</li>
                                    ),
                                )}
                              </ul>
                            )}
                          </>
                        );
                      })()}
                      contentClassName="border-none rounded-[10px] overflow-visible text-xs text-[#667085] relative"
                      size="lg"
                      position="bottom"
                      isFixed
                      ids={[
                        'productsImportTableContainer',
                        'productsImportContainer',
                      ]}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-px"
                      >
                        <path
                          d="M7.00049 11.21C9.60146 11.21 11.71 9.10146 11.71 6.50049C11.71 3.89952 9.60146 1.79102 7.00049 1.79102C4.39952 1.79102 2.29102 3.89952 2.29102 6.50049C2.29102 9.10146 4.39952 11.21 7.00049 11.21Z"
                          stroke="#667085"
                          strokeWidth="0.784912"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.60938 6.10938C6.71346 6.10938 6.81328 6.15072 6.88688 6.22432C6.96048 6.29792 7.00183 6.39775 7.00183 6.50183V8.46411C7.00183 8.5682 7.04318 8.66802 7.11678 8.74162C7.19038 8.81522 7.2902 8.85657 7.39429 8.85657"
                          stroke="#667085"
                          strokeWidth="0.784912"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.80353 4.93127C7.12865 4.93127 7.39221 4.66771 7.39221 4.34259C7.39221 4.01747 7.12865 3.75391 6.80353 3.75391C6.47841 3.75391 6.21484 4.01747 6.21484 4.34259C6.21484 4.66771 6.47841 4.93127 6.80353 4.93127Z"
                          fill="#667085"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </td>
                <td className="px-4 text-left text-[14px] font-medium leading-[1.125rem]">
                  <DropdownFull
                    className="w-[200px]"
                    height="h-10"
                    options={[{ name: '--', id: '--' }, ...dropdownHeaders]}
                    name="name"
                    noMinW
                    selected={
                      Object.keys(mapping).find(
                        (mapKey) => mapping[mapKey] === keyArray[index]?.name,
                      ) || '--'
                    }
                    setSelected={(id) => {
                      const newMapping = { ...mapping };

                      const previousKey = Object.keys(newMapping).find(
                        (mapKey) =>
                          newMapping[mapKey] === keyArray[index]?.name,
                      );
                      if (previousKey) {
                        delete newMapping[previousKey];
                      }

                      newMapping[id] = keyArray[index]?.name;
                      setMapping(newMapping);
                    }}
                    buttonTextClass="truncate"
                    placeholder="--"
                    scrollIntoView={false}
                    isTableDropdown
                    bottomOfTable
                    ids={[
                      'productsImportTableContainer',
                      'productsImportContainer',
                    ]}
                  />
                </td>
                <td className="px-4">
                  {checkIfMatched(mapping, keyArray[index]?.name) ? (
                    <div className="flex gap-2">
                      <span className="inline-block h-2 w-2 rounded-full bg-green-600 mt-[7px]" />
                      <div>
                        <p>Matched</p>
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <span className="inline-block h-2 w-2 rounded-full bg-slate-400 mt-2" />
                      <div>
                        <p> No match found</p>
                        <p className="text-xs text-slate-400">
                          Will not be imported
                        </p>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {products?.length > 0 &&
            products?.map((product, index) => (
              <tr
                key={index}
                className="flex w-full items-center gap-1 justify-around bg-white h-[3.5rem] border-b border-[#D0D5DD] hover:bg-[#a0cd85] hover:bg-opacity-5"
              >
                {keyArray.map((key, _i) => (
                  <td
                    key={_i}
                    className="text-left text-[14px] font-medium leading-[1.125rem] w-[10dvw] flex-shrink-0 flex-grow-0 truncate"
                  >
                    {product?.[key?.id]}
                  </td>
                ))}
              </tr>
            ))} */}
        {/* </table> */}
      </div>
      {total > 6 && (
        <p className="text-base font-medium w-full text-center">
          ...showing 6 of {total} products & services{' '}
        </p>
      )}
    </div>
  );
};

export default Step2;
