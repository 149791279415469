import React, { useEffect, useMemo, useState } from 'react';
import {
  formatNumberGlobally,
  getCategoriesWithInactiveAccount,
} from 'utils/Utils';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import Tooltip from '../../components/Tooltip';
import WarningBox from '../../components/WarningBox';

const SplitTransactionForm = ({
  allCategories,
  activeTransaction,
  instanceTotal,
  splitTransactions,
  setSplitTransactions,
  handleTransactionValueChange,
  calcSplitsSum,
  isFixedDropdown = false,
  setNoClick = () => {},
  ids = [],
}) => {
  const [isInactiveCategory, setIsInactiveCategory] = useState(null);

  const accountPayableCat = useMemo(() => {
    if (allCategories?.length > 0) {
      return allCategories
        ?.flatMap((category) => category?.categories ?? [])
        .find(
          (subCategory) =>
            subCategory?.name === 'Accounts Payable' && !subCategory?.editable,
        );
    }
  }, [allCategories]);

  useEffect(() => {
    if (activeTransaction?.id) {
      setIsInactiveCategory(
        activeTransaction?.categoryAccount?.status === 'inactive'
          ? { ...activeTransaction?.categoryAccount }
          : null,
      );
      setSplitTransactions([
        {
          amount: {
            value: formatNumberGlobally(parseFloat(instanceTotal), true),
            error: false,
          },
          categoryAccountId: {
            value: accountPayableCat?.id,
            error: false,
          },
        },
        {
          amount: {
            value: formatNumberGlobally(
              parseFloat(parseFloat(activeTransaction?.amount)?.toFixed(4)) -
                parseFloat(parseFloat(instanceTotal)?.toFixed(4)),
              true,
            ),
            error: false,
          },
          categoryAccountId: {
            value: activeTransaction?.categoryAccountId || null,
            error: false,
          },
        },
      ]);
    }
  }, [activeTransaction]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-x-8 gap-y-4">
        {splitTransactions?.map((s, i) => (
          <div key={i} className="col-span-full md:col-span-6 flex gap-4">
            <div className="flex flex-col gap-2.5 flex-1 min-w-[30%]">
              <label
                className={`text-slate-600 text-sm leading-5 font-medium ${
                  s.amount.error ? '!text-rose-500' : ''
                }`}
              >
                Split Amount
              </label>
              <Tooltip
                content={`One of the Split Transaction should be equal to Instance Total $${instanceTotal.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  },
                )}`}
                tooltipShow={
                  parseFloat(instanceTotal).toFixed(4) ===
                    parseFloat(s.amount.value).toFixed(4) && i === 0
                }
                contentClassName="w-[250px] text-xs h-auto !overflow-visible text-wrap	"
              >
                <div className="relative">
                  <input
                    placeholder="0.00"
                    value={s.amount.value}
                    onChange={(e) =>
                      handleTransactionValueChange(e.target.value, 'amount', i)
                    }
                    type="number"
                    className={`btn w-full border-[#D0D5DD] focus:border-slate-400 hover:border-slate-400 form-input pl-[3rem] pr-4 py-2.5 justify-between rounded-[0.313rem] font-normal leading-6 bg-white h-12 text-slate-600
                   placeholder:text-slate-600 placeholder:text-slate-80  read-only:cursor-not-allowed ${
                     s.amount.error ? '!border-rose-500' : ''
                   }`}
                    readOnly={
                      parseFloat(instanceTotal).toFixed(4) ===
                        parseFloat(s.amount.value).toFixed(4) && i === 0
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                  <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                    <span className="ml-4 mr-2 text-slate-600 text-base leading-6 font-normal">
                      -$
                    </span>
                    <span className="border-l-[0.038rem] h-4 bg-slate-600" />
                  </div>
                </div>
              </Tooltip>
              {s.amount.error && (
                <span className="text-xs text-rose-500 -mt-1.5">
                  {s.amount.error ? 'Split amount is required' : ''}
                </span>
              )}
            </div>

            <div className=" flex flex-col gap-2.5 flex-1 max-w-[30%]  min-w-[200px]">
              <label
                className={`text-slate-600 text-sm leading-5 font-medium ${
                  s.categoryAccountId.error ? '!text-rose-500' : ''
                }`}
              >
                Category
              </label>
              <Tooltip
                className="w-full"
                content={`Transactions assigned to a bill should always be categorized as 'Accounts Payable'`}
                tooltipShow={
                  i === 0 && s.categoryAccountId.value === accountPayableCat?.id
                }
                contentClassName="w-[250px] text-xs h-auto !overflow-visible text-wrap	"
              >
                <CategoriesDropdown
                  allCategories={
                    isInactiveCategory?.id
                      ? getCategoriesWithInactiveAccount(
                          isInactiveCategory,
                          allCategories,
                        )
                      : allCategories || []
                  }
                  selectedCategoryId={s.categoryAccountId.value}
                  setSelectedCategoryId={(value) => {
                    handleTransactionValueChange(value, 'categoryAccountId', i);
                  }}
                  isSetCategoryStyle
                  isError={s.categoryAccountId.error}
                  height="h-12"
                  disabled={
                    i === 0 &&
                    s.categoryAccountId.value === accountPayableCat?.id
                  }
                  isTableDropdown={isFixedDropdown}
                  bottomOfTable={isFixedDropdown}
                  setNoClick={setNoClick}
                  ids={ids}
                  buttonClass={` !w-full !max-w-full ${s.categoryAccountId.error ? '!border-rose-500' : ''}`}
                />
              </Tooltip>

              {s.categoryAccountId.error && (
                <span className="text-xs text-rose-500 -mt-1.5">
                  {s.categoryAccountId.error ? 'Category is required' : ''}
                </span>
              )}
            </div>
            <button
              className="h-fit mt-10"
              onClick={() => {
                setSplitTransactions((prev) =>
                  prev.filter((t, index) => index !== i),
                );
              }}
              type="button"
            >
              {/* Trash Icon  */}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 6.64648C17.67 6.31648 14.32 6.14648 10.98 6.14648C9 6.14648 7.02 6.24648 5.04 6.44648L3 6.64648"
                  stroke="#FF4B4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 5.63602L8.72 4.32602C8.88 3.37602 9 2.66602 10.69 2.66602H13.31C15 2.66602 15.13 3.41602 15.28 4.33602L15.5 5.63602"
                  stroke="#FF4B4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.8504 9.80566L18.2004 19.8757C18.0904 21.4457 18.0004 22.6657 15.2104 22.6657H8.79039C6.00039 22.6657 5.91039 21.4457 5.80039 19.8757L5.15039 9.80566"
                  stroke="#FF4B4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3301 17.166H13.6601"
                  stroke="#FF4B4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 13.166H14.5"
                  stroke="#FF4B4B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setSplitTransactions((prev) => [
            ...prev,
            {
              amount: {
                value: '',
                error: false,
              },
              categoryAccountId: {
                value: null,
                error: false,
              },
            },
          ]);
        }}
        type="button"
        className="text-sm font-medium text-indigo-500 underline my-4"
      >
        Add Another Split
      </button>
      {parseFloat(calcSplitsSum()).toFixed(4) !==
        parseFloat(activeTransaction?.amount).toFixed(4) && (
        <WarningBox
          content={`Sum of All Split Transaction should be equal to Parent Transaction $${activeTransaction?.amount?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            },
          )}`}
          isError
          className="mb-4"
        />
      )}
    </div>
  );
};

export default SplitTransactionForm;
