import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import useQuickbooksSettings from 'pages/appIntegrations/quickbooksSettings/useQuickbooksSettings';
import React from 'react';
import { useSelector } from 'react-redux';
import useOnboardingSubSteps from './useOnboardingSubSteps';

const ImportTransactions = ({ updateOneStep }) => {
  const { team } = useSelector((state) => state.Team);

  const { transactionsLoading, syncTransactions } = useQuickbooksSettings();
  const { handleOnboardingSubStep, onBoardingSubSteps } = useOnboardingSubSteps(
    {
      subStep: 'transactions',
    },
  );

  const handleImportTransactions = async () => {
    try {
      const res = await syncTransactions(true);
      if (res?.status === 'success') {
        handleOnboardingSubStep();
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleNext = () => {
    if (!onBoardingSubSteps?.includes('transactions')) {
      handleImportTransactions();
    } else {
      updateOneStep();
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Historical Transactions:
      </h3>
      {/* {showSuccess && (
        <AlertBox
          type="success"
          heading="Transactions Imported Successfully"
          onActionButton={() => {
            setShowSuccess(false);
          }}
          className="mb-6"
          isCross
          text="Your Transactions data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete."
          // hideActionButton
        />
      )} */}
      <div className="grid">
        <ImportBox
          title="Import Historical Transactions"
          text="Import all of your transactions from Quickbooks with a single click."
          buttonText={
            onBoardingSubSteps?.includes('transactions')
              ? 'Transactions Imported'
              : 'Import Transactions'
          }
          handleClick={handleImportTransactions}
          loading={transactionsLoading}
          lastSync={team?.app?.quickbooks?.[0]?.transactionLastImportedAt}
          // toggleText="Enable this option to deactivate all default accounts and replace them with the imported chart of accounts. Note: Certain default accounts are required for COUNT's automation features and cannot be deactivated."
          successBtn={onBoardingSubSteps?.includes('transactions')}
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>{' '}
        <button
          type="button"
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={handleNext}
          disabled={transactionsLoading}
        >
          {transactionsLoading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Next
        </button>
      </footer>
    </div>
  );
};

export default ImportTransactions;
