import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  createPlaidToken,
  getAccountTypes,
  getAccounts,
  getPossibleReconcileAccounts,
  getReconciliationHistory,
} from '../../../API/backend_helper';
// import AccoutDetails from "./AccoutDetails";
import { ADMIN, OWNER, TEAM_CURRENCY } from '../../../utils/Utils';
import Tooltip from '../../../components/Tooltip';
import emptyAccountsImg from '../../../images/custom/empty-search.svg';
import BankWarning from '../../../components/BankWarning';
import ReconciliationPanel from './ReconciliationPanel';
import EndingStatementModal from './EndingStatementModal';
import { Checkmark } from './Svg';
import AppLayout from '../../../components/AppLayout';

const ReusableAccount = ({
  account,
  setReconcileAccount,
  setAccountsPanelOpen,
  teamCurrency,
  navigate,
  setReconcileModalOpen,
  isSubAccount = false,
}) => (
  <div
    onClick={() => {
      setReconcileAccount(account);
      setAccountsPanelOpen(true);
    }}
    className="flex px-[1.688rem] items-center gap-[1.375rem]  min-h-[100px]   self-stretch hover:bg-[#a0cd85] hover:bg-opacity-5 border-b cursor-pointer border-[#D0D5DD]"
    role="button"
    tabIndex="0"
    onKeyDown={null}
  >
    <div className="flex gap-4 items-center flex-grow flex-shrink-0 basis-0 justify-between">
      <div
        className={`flex flex-col justify-center items-start ${
          account?.officialName !== account?.name ? 'gap-1' : ''
        } ${isSubAccount ? 'pl-6 2xl:pl-8' : ''}`}
      >
        {account?.reconcileBalances?.length > 0 &&
          (account?.reconcileBalances?.[account?.reconcileBalances?.length - 1]
            ?.complete ? (
            <div
              className="h-[27px] inline-flex items-center px-2.5 rounded-[5px] text-[10px] font-bold"
              style={{
                background: '#78BD4F1A',
                color: '#78BD4F',
              }}
            >
              Reconciled up to{' '}
              {moment(
                account?.reconcileBalances?.[
                  account?.reconcileBalances?.length - 1
                ]?.endingBalanceDate,
              ).format('MMM DD, YYYY')}
            </div>
          ) : (
            <div
              className="h-[27px] inline-flex items-center px-2.5 rounded-[5px] text-[10px] font-bold"
              style={{
                background: '#2575F41A',
                color: '#2575F4',
              }}
            >
              In-Progress
            </div>
          ))}
        <div className="flex items-center gap-2">
          <p className="text-slate-600 font-semibold leading-6 text-[1rem]">
            {account?.name} {account?.mask && `(...${account?.mask})`}
          </p>

          {account?.providerAccountId && (
            <Tooltip
              content={
                <>
                  <p className="text-center">
                    This account is setup for live bank feeds and
                  </p>
                  <p className="text-center">
                    {' '}
                    will automatically import transactions.
                  </p>
                </>
              }
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.675"
                  y="1.175"
                  width="25.65"
                  height="25.65"
                  rx="7.425"
                  fill="white"
                />
                <rect
                  x="0.675"
                  y="1.175"
                  width="25.65"
                  height="25.65"
                  rx="7.425"
                  stroke="#D0D5DD"
                  strokeWidth="1.35"
                />
                <path
                  d="M16.5898 7.62305L19.0815 10.1147L16.5898 12.6064"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.86914 13.8527V12.6069C7.86914 11.9461 8.13165 11.3123 8.59893 10.845C9.06621 10.3777 9.69998 10.1152 10.3608 10.1152H19.0816"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3608 21.3271L7.86914 18.8354L10.3608 16.3438"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.0816 15.0977V16.3435C19.0816 17.0043 18.8191 17.6381 18.3518 18.1054C17.8846 18.5726 17.2508 18.8352 16.59 18.8352H7.86914"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Tooltip>
          )}
        </div>
        <p className="text-[#A0A7B4] text-[0.875rem] leading-6">
          {account?.officialName !== account?.name && account?.officialName}
        </p>
      </div>
      <p className="text-slate-600 justify-self-center leading-6 text-[0.875rem] min-w-[30%] w-[30%]">
        {!account?.reconcileBalances?.some?.((balance) => balance?.draft) &&
          `${account?.newTransactions || 0}
      New Transactions`}
      </p>
    </div>
    <div className="gap-5 items-center justify-end flex w-[16rem]">
      <div>
        <div className="flex items-center gap-1.5">
          {/* Unequal Sign
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.935547 6.6875H15"
        stroke="#E48642"
        stroke-width="3"
      />
      <path
        d="M0.935547 12.3125H15"
        stroke="#E48642"
        stroke-width="3"
      />
      <path
        d="M3.07031 1.09375L12.8672 17.9062"
        stroke="#E48642"
      />
    </svg> */}
          {/* Check Sign  */}
          {/* <svg
        width="21"
        height="17"
        viewBox="0 0 21 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.73242 8.52694L6.80391 13.5984L19 1.40234"
          stroke="#78BD4F"
          stroke-width="3.63075"
        />
      </svg> */}
          {account?.reconcileBalances?.[account?.reconcileBalances?.length - 1]
            ?.complete && <Checkmark />}
          {account?.reconcileBalances?.length > 0 && (
            <h3 className="text-[22px] font-semibold text-[#101828] leading-tight">
              {account?.reconcileBalances?.[
                account?.reconcileBalances?.length - 1
              ]?.endingBalanceAmount
                ?.toString()
                ?.charAt(0) === '-'
                ? '-'
                : ''}
              {teamCurrency?.symbol}
              {parseFloat(
                account?.reconcileBalances?.[
                  account?.reconcileBalances?.length - 1
                ]?.endingBalanceAmount
                  ?.toString()
                  ?.replace('-', '') ?? 0,
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h3>
          )}
        </div>
        {account?.reconcileBalances?.length > 0 && (
          <p className="text-sm text-[#667085] leading-tight mt-1 text-right">
            {moment(account?.reconcileBalances?.[0]?.endingBalanceDate).format(
              'MMM DD, YYYY',
            )}
          </p>
        )}
      </div>
      {account?.subType?.anchorTier === 'PHYSICAL_ACCOUNT' ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            const anyDraft = account?.reconcileBalances?.some?.(
              (balance) => balance?.draft,
            );
            if (anyDraft) {
              navigate(`/account/reconcile/${account?.id}`);
            } else {
              setReconcileAccount(account);
              setReconcileModalOpen(true);
            }
          }}
          className={`flex ${isSubAccount ? 'h-9' : 'h-11 '} min-w-[8.5rem] w-[8.5rem] px-[18px] justify-center items-center rounded-[5px] whitespace-nowrap
         disabled:bg-[#D0D5DD] disabled:cursor-not-allowed ${
           account?.reconcileBalances?.some?.((balance) => balance?.draft)
             ? 'bg-white text-slate-600 border border-indigo-500'
             : 'bg-indigo-500 text-white border border-indigo-500'
         }`}
          disabled={false}
          type="button"
        >
          {account?.reconcileBalances?.some?.((balance) => balance?.draft)
            ? 'Continue'
            : account?.reconcileBalances?.[
                  account?.reconcileBalances?.length - 1
                ]?.complete
              ? 'Reconcile'
              : 'Get Started'}
        </button>
      ) : (
        <div className="invisible w-[5.188rem]" />
      )}
    </div>
  </div>
);

function AccountsTable() {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [isLoading, setIsLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [possibleAccounts, setPossibleAccounts] = useState([]);
  const [types, setTypes] = useState([]);
  const [reconcileModalOpen, setReconcileModalOpen] = useState(false);
  const [teamCurrency, setTeamCurrency] = useState();
  // Reconcile Account
  const [reconcileAccount, setReconcileAccount] = useState(null);
  const [accountsPanelOpen, setAccountsPanelOpen] = useState(false);
  const navigate = useNavigate();

  document.title = 'COUNT | Reconciliation';
  const filteredTypes = [];

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(
        PermissionKeys.RECONCILE_ACCOUNTS,
        accessType,
        permissions,
      )
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const getHistory = async (id) => {
    try {
      const res = await getReconciliationHistory(id);
      return res;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onAccountTypeSelection = (
    acctType = filteredTypes,
    accountsData = allAccounts,
  ) => {
    accountsData?.reduce?.((result, item) => {
      if (acctType?.length === 0) {
        (result[item?.type?.subtype] =
          result?.[item?.type?.subtype] || [])?.push(item);
      } else if (acctType?.includes?.(item?.type?.type)) {
        (result[item?.type?.subtype] =
          result?.[item?.type?.subtype] || [])?.push(item);
      }
      return result;
    }, {});
  };

  const loadPossibleAccounts = async () => {
    try {
      const res = await getPossibleReconcileAccounts();
      setPossibleAccounts(res);
    } catch (err) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('err', err);
    }
  };

  const getAccountTypesApi = async () => {
    try {
      const typeResponse = await getAccountTypes();
      setTypes(typeResponse);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadData = async (refreshData = false) => {
    if (!refreshData) {
      setIsLoading(true);
    }
    try {
      const res = await getAccounts({
        typeId: filteredTypes || [],
        search: '',
      });
      if (refreshData) {
        onAccountTypeSelection([], res);
      } else {
        onAccountTypeSelection([], res);
      }
      await loadPossibleAccounts();
      setAllAccounts(res);
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const createLinkToken = async () => {
    try {
      await createPlaidToken();
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if ((accessType === OWNER || accessType === ADMIN) && team?.id) {
      if (teamDefaultCurrency === 'USD') {
        createLinkToken();
      }
    }
  }, [team]);

  useEffect(() => {
    if (filteredTypes?.length > 0) {
      onAccountTypeSelection(filteredTypes, allAccounts);
    } else {
      onAccountTypeSelection([], allAccounts);
    }
  }, []);

  useEffect(() => {
    if (team && currencies) {
      const teamCurrency = currencies?.find?.(
        (currency) => currency.isoCode === team.currency,
      );
      setTeamCurrency(teamCurrency);
    }
  }, [team, currencies]);

  useEffect(() => {
    getAccountTypesApi();
    loadData();
  }, []);

  return (
    <>
      <EndingStatementModal
        account={reconcileAccount}
        setAccount={setReconcileAccount}
        isOpen={reconcileModalOpen}
        setIsOpen={setReconcileModalOpen}
        teamCurrency={teamCurrency}
      />
      <AppLayout>
        {accessType && (
          <main className="relative grow">
            {/* Content */}
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              {checkPermission(
                PermissionKeys.Manage_Bank_Account,
                accessType,
                permissions,
              ) && <BankWarning teamProp={team} />}

              {/* Page header */}
              <div className="flex justify-between items-center mb-10">
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                  Reconciliation
                </h1>
              </div>

              {isLoading ? (
                <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                  <svg
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin w-10 h-10 fill-current shrink-0"
                  >
                    <path
                      d="M14.5 2.41699V7.25033"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 21.749V26.5824"
                      stroke="#E48642"
                      strokeWidth="2.55882"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 5.95703L9.37662 9.37662"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 19.6211L23.0426 23.0407"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.41699 14.5H7.25033"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.749 14.5H26.5824"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.95703 23.0407L9.37662 19.6211"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.623 9.37662L23.0426 5.95703"
                      stroke="#E48642"
                      strokeWidth="1.58955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                    Fetching data, please wait...
                  </p>
                </div>
              ) : (
                <>
                  {/* Table */}
                  <div className="flex flex-col items-start h-full">
                    {types
                      ?.map?.((type) => ({
                        ...type,
                        subTypes: [...type?.subTypes]?.reverse(),
                      }))
                      ?.flatMap?.((type) => type?.subTypes)
                      ?.filter?.((subType) =>
                        possibleAccounts?.some(
                          (account) => account?.subTypeId === subType?.id,
                        ),
                      )
                      ?.map?.((subType, i) => (
                        <div
                          key={i}
                          className="flex w-full px-[1.375rem] py-[1.563rem] flex-col justify-center items-start gap-[1.375rem]"
                        >
                          <div
                            key={subType?.id}
                            className="flex flex-col justify-center items-start self-stretch"
                          >
                            <div className="flex pl-3 py-3.5 items-center gap-2.5 self-stretch rounded bg-[#F4F4F5] ">
                              <p className="text-[#535C6E] text-[1.125rem] font-semibold leading-6">
                                {subType?.name}
                              </p>
                              <Tooltip
                                content={
                                  subType?.description?.length > 50
                                    ? (() => {
                                        const segmentLength = 60;
                                        const segments = [];
                                        for (
                                          let i = 0;
                                          i < subType?.description?.length;
                                          i += segmentLength
                                        ) {
                                          segments.push(
                                            subType?.description?.substring(
                                              i,
                                              i + segmentLength,
                                            ),
                                          );
                                        }
                                        return segments?.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : subType?.description
                                }
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative left-[4rem]"
                                tooltipShow={subType?.description}
                              >
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="1"
                                    width="19"
                                    height="19"
                                    rx="5.5"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="1"
                                    width="19"
                                    height="19"
                                    rx="5.5"
                                    stroke="#D0D5DD"
                                  />
                                  <path
                                    d="M10.0005 15.21C12.6015 15.21 14.71 13.1015 14.71 10.5005C14.71 7.89952 12.6015 5.79102 10.0005 5.79102C7.39952 5.79102 5.29102 7.89952 5.29102 10.5005C5.29102 13.1015 7.39952 15.21 10.0005 15.21Z"
                                    stroke="#667085"
                                    strokeWidth="0.784912"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.60938 10.1094C9.71346 10.1094 9.81328 10.1507 9.88688 10.2243C9.96048 10.2979 10.0018 10.3977 10.0018 10.5018V12.4641C10.0018 12.5682 10.0432 12.668 10.1168 12.7416C10.1904 12.8152 10.2902 12.8566 10.3943 12.8566"
                                    stroke="#667085"
                                    strokeWidth="0.784912"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.80353 8.93127C10.1286 8.93127 10.3922 8.66771 10.3922 8.34259C10.3922 8.01747 10.1286 7.75391 9.80353 7.75391C9.47841 7.75391 9.21484 8.01747 9.21484 8.34259C9.21484 8.66771 9.47841 8.93127 9.80353 8.93127Z"
                                    fill="#667085"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            {possibleAccounts?.filter(
                              (account) => account?.subTypeId === subType?.id,
                            ).length > 0 ? (
                              possibleAccounts
                                .filter(
                                  (account) =>
                                    account?.subTypeId === subType?.id,
                                )
                                .map((account) => (
                                  <>
                                    <ReusableAccount
                                      key={account?.id}
                                      account={account}
                                      setReconcileAccount={setReconcileAccount}
                                      setAccountsPanelOpen={
                                        setAccountsPanelOpen
                                      }
                                      teamCurrency={teamCurrency}
                                      navigate={navigate}
                                      setReconcileModalOpen={
                                        setReconcileModalOpen
                                      }
                                    />
                                    {account?.subAccounts?.length > 0
                                      ? account?.subAccounts?.map(
                                          (subAccount) => (
                                            <ReusableAccount
                                              key={subAccount?.id}
                                              account={subAccount}
                                              setReconcileAccount={
                                                setReconcileAccount
                                              }
                                              setAccountsPanelOpen={
                                                setAccountsPanelOpen
                                              }
                                              teamCurrency={teamCurrency}
                                              navigate={navigate}
                                              setReconcileModalOpen={
                                                setReconcileModalOpen
                                              }
                                              isSubAccount
                                            />
                                          ),
                                        )
                                      : null}
                                  </>
                                ))
                            ) : (
                              <p className="text-slate-600 py-[1.375rem] leading-6 text-[1rem] italic pl-[1.688rem]">
                                You haven't added any {subType?.name} accounts
                                yet.
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {!isLoading && allAccounts?.length === 0 && (
                <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
                  <h6 className="text-center mt-4">No Accounts Found</h6>
                  <img
                    src={emptyAccountsImg}
                    alt="No Accounts found"
                    className="h-[12.5rem]"
                  />
                </div>
              )}
            </div>
            <ReconciliationPanel
              account={reconcileAccount}
              accountsPanelOpen={accountsPanelOpen}
              setAccountsPanelOpen={setAccountsPanelOpen}
              setModalOpen={setReconcileModalOpen}
              getHistory={getHistory}
              teamCurrency={teamCurrency}
            />
          </main>
        )}
      </AppLayout>
    </>
  );
}

export default AccountsTable;
