import React, { useEffect, useRef, useState } from 'react';
import AlertBox from 'components/AlertBox';
import ModalBasic from '../../components/ModalBasic';
import PlaidLink from '../settings/PlaidLink';
import { createPlaidToken, getPopularBanks } from '../../API/backend_helper';
import PlaidConnect from '../../images/plaid-connect.png';

const LoadingIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin w-5 h-5 fill-current shrink-0"
  >
    <path
      d="M14.5 2.41699V7.25033"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 21.749V26.5824"
      stroke="#E48642"
      strokeWidth="2.55882"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 5.95703L9.37662 9.37662"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 19.6211L23.0426 23.0407"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.41699 14.5H7.25033"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.749 14.5H26.5824"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.95703 23.0407L9.37662 19.6211"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.623 9.37662L23.0426 5.95703"
      stroke="#E48642"
      strokeWidth="1.58955"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const InstitutionsModal = ({
  isOpen,
  setIsOpen,
  loadData,
  team,
  setIsLoading,
}) => {
  const PlaidButtonRef = useRef();

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bankConnectLoading, setBankConnectLoading] = useState(false);
  const [banks, setBanks] = useState([]);

  const getPopularBanksApi = async () => {
    setLoading(true);
    try {
      const response = await getPopularBanks();
      setBanks(response);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPopularBanksApi();
  }, []);

  const onBankClick = async (bank) => {
    setBankConnectLoading(true);
    try {
      const response = await createPlaidToken(
        null,
        {},
        bank?.routingNumbers?.[0] || '',
      );
      const { linkToken } = response;
      setToken(linkToken);
      setTimeout(() => {
        setBankConnectLoading(false);
        PlaidButtonRef.current.click();
      }, 1000);
    } catch (e) {
      setBankConnectLoading(false);
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Connect Institution"
      width="!w-[1022px] max-w-[90vw]"
    >
      <div className="py-4 sm:py-9 px-9 sm:px-[56px]">
        <p className="text-[18px] font-medium text-[#667085] leading-tight mb-3">
          Popular Banks
        </p>
        {bankConnectLoading && (
          <AlertBox
            type="info"
            heading="Please wait"
            text="We are connecting to the bank"
            hideActionButton
            icon={LoadingIcon}
            className="!mb-4"
          />
        )}
        {loading && (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        )}
        <div className="grid grid-cols-12 gap-5 gap-y-3">
          {!loading &&
            banks?.map((bank, index) => (
              <div
                key={index}
                onClick={() => onBankClick(bank)}
                className="col-span-full sm:col-span-6 lg:col-span-4 border border-[#BBBFC6] rounded-[6px] flex items-center p-1 min-h-[60px] gap-2.5 cursor-pointer"
                tabIndex="0"
                role="button"
                onKeyDown={null}
              >
                <div
                  className={`w-10 h-10  shrink-0 rounded-full  mr-2 ml-4
                          ${
                            bank?.color && !bank?.logoUrl
                              ? 'bg-gradient-to-tr from-slate-500 to-slate-300'
                              : ''
                          }
                          `}
                  style={
                    bank?.color && !bank?.logoUrl
                      ? { background: bank?.color }
                      : {}
                  }
                >
                  {bank?.logoUrl ? (
                    <img
                      src={`${bank?.logoUrl}`}
                      alt="logo"
                      className="w-10 h-10 fill-current text-white"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-10 h-10">
                      <div
                        style={{ textTransform: 'uppercase' }}
                        className="text-white font-bold text-lg "
                      >
                        {bank?.name?.charAt?.(0)}
                      </div>
                    </div>
                  )}
                </div>

                <p className="text-[18px] text-[#667085] leading-tight">
                  {bank?.name}
                </p>
              </div>
            ))}
        </div>
        <button
          className="mt-9 flex gap-2.5 items-center p-4 border border-indigo-500 rounded-[6px] w-full min-h-[94px]"
          onClick={onBankClick}
          type="button"
        >
          <img className="h-9" src={PlaidConnect} alt="plaid" />
          <div>
            <p className="text-left text-[18px] font-semibold text-[#667085] leading-tight mb-[2px]">
              Do not see your institution above?
            </p>
            <p className="text-sm text-[#667085] text-left">
              Connect to other banks securely via Plaid
            </p>
          </div>
        </button>
        <PlaidLink
          loadData={loadData}
          setIsLoading={setIsLoading}
          token={token}
          team={team}
          accountPage
          buttonRef={PlaidButtonRef}
          shouldNavigate
          successCBBeforeChooseAccounts={false}
        />
      </div>
    </ModalBasic>
  );
};

export default InstitutionsModal;
