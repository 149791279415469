import React, { forwardRef } from 'react';
import {
  formatNumberGlobally,
  getCategoriesWithInactiveAccount,
} from 'utils/Utils';
import CategoriesDropdown from '../transactions/CategoriesDropdown';
import TextInputDash from '../../components/TextInputDash';
import TableEntryItemsDropdowns from './TableEntryItemsDropdowns';

const CreateBillTable = forwardRef(
  (
    {
      allCategories,
      billRows,
      setBillRows,
      TABLE_ROW_SCHEMA,
      isLineItemsError = false,
      currency,
      checkBillRowsError,
      tax,
      setTax,
      discount,
      setDiscount,
      customers,
      projects,
      tags,
      setCustomers,
    },
    ref,
  ) => {
    const handleUpdateValue = (index, field, value) => {
      const tempRows = [...billRows];
      const tempObj = { ...tempRows[index] };
      tempObj[field] = { ...tempObj[field], value };
      if (value) {
        tempObj[field] = { ...tempObj[field], error: false };
      } else if (field !== 'tax') {
        tempObj[field] = { ...tempObj[field], error: true };
      }
      tempRows[index] = tempObj;
      setBillRows(tempRows);
    };

    const calcSubTotal = () => {
      let sum = 0;
      billRows?.forEach((d) => {
        sum +=
          parseFloat(d.price.value || 0) * parseFloat(d.quantity.value || 0);
      });
      return sum || 0;
    };

    return (
      <div className="bg-transparent  w-full rounded-[5px]  my-5 dark:bg-transparent border border-[#D0D5DD] flex-1 ">
        {/* Table */}
        <div
          className="overflow-x-auto h-full w-full scrollbar"
          id="createBillTableContainer"
        >
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-sm font-semibold text-[#667085] border-b border-[#D0D5DD] bg-[#A0CD850F]">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">Description</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">Account</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-medium text-left">Qty</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-left">Price (per unit)</div>
                </th>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-left">Tax (per unit)</div>
                </th> */}
                <th className="pr-8  pl-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden ">
                  <div className="font-medium text-right">Amount</div>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap sm:table-cell hidden " />
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-[#D0D5DD] ">
              {billRows.map((b, i) => (
                <>
                  <tr className="" key={i}>
                    <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                      <div className="h-[60px] flex flex-col">
                        <input
                          type="text"
                          placeholder="Description"
                          value={b.description.value}
                          className={`form-input text-slate-600  ${
                            b.description.error ? '!border-rose-500' : ''
                          }`}
                          onChange={(e) =>
                            handleUpdateValue(i, 'description', e.target.value)
                          }
                          onBlur={
                            b.description.error ? checkBillRowsError : () => {}
                          }
                        />
                        <span className="h-[10px] text-xs mt-1 text-rose-500 ">
                          {b?.description?.error || ''}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                      <div className="h-[60px] flex flex-col">
                        <div className="w-[200px] ">
                          <CategoriesDropdown
                            allCategories={
                              b?.categoryAccountId?.categoryAccount?.status ===
                              'inactive'
                                ? getCategoriesWithInactiveAccount(
                                    b?.categoryAccountId?.categoryAccount,
                                    allCategories,
                                  )
                                : allCategories || []
                            }
                            selectedCategoryId={
                              b?.categoryAccountId?.value || null
                            }
                            setSelectedCategoryId={(value) => {
                              handleUpdateValue(i, 'categoryAccountId', value);
                            }}
                            isError={b?.categoryAccountId?.error}
                            isSetCategoryStyle
                            bottomOfTable
                            // type="bills"
                            onBlur={
                              b?.categoryAccountId?.error
                                ? checkBillRowsError
                                : () => {}
                            }
                            ids={[
                              'billsPageContainer',
                              'createBillTableContainer',
                            ]}
                            placeholderText="Select Account"
                            height={`h-[38px] ${b?.categoryAccountId?.error ? '!border-rose-500' : '!border-[#D0D5DD] hover:!border-slate-400'}   `}
                          />
                        </div>
                        <span className="text-xs mt-1 text-rose-500 h-[10px]">
                          {b?.categoryAccountId?.error || ''}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                      <div className="h-[60px] flex flex-col">
                        <input
                          type="number"
                          value={b.quantity.value}
                          className={`form-input text-slate-600 w-32 ${
                            b.quantity.error ? '!border-rose-500' : ''
                          }`}
                          min={0}
                          onChange={(e) =>
                            handleUpdateValue(i, 'quantity', e.target.value)
                          }
                          placeholder="0"
                          step="any"
                          onBlur={
                            b.quantity.error ? checkBillRowsError : () => {}
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                        <span className="text-xs mt-1 text-rose-500 h-[10px]">
                          {b?.quantity?.error || ''}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 pt-5 pb-[2px] text-left">
                      <div className="h-[60px] flex flex-col">
                        <div className="relative">
                          <input
                            type="number"
                            value={b.price.value}
                            className={`form-input text-slate-600 w-40 pl-8 ${
                              b.price.error ? '!border-rose-500' : ''
                            }`}
                            min={0}
                            onChange={(e) =>
                              handleUpdateValue(i, 'price', e.target.value)
                            }
                            placeholder="0.00"
                            step="any"
                            onBlur={
                              b.price.error ? checkBillRowsError : () => {}
                            }
                            onWheel={(e) => e.target.blur()}
                          />

                          <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                            {currency?.symbol}
                            <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                          </span>
                        </div>
                        <span className="text-xs mt-1 text-rose-500 h-[10px]">
                          {b?.price?.error || ''}
                        </span>
                      </div>
                    </td>
                    {/* <td className="px-2 first:pl-5 relative last:pr-5 pt-5 pb-[2px] text-left">
                    <div className="h-[60px] flex flex-col">
                      <div className="relative">
                        <input
                          type="number"
                          value={b.tax.value}
                          className={`form-input w-40 pl-8 ${
                            b.tax.error ? "!border-rose-500" : ""
                          }`}
                          min={0}
                          onChange={(e) =>
                            handleUpdateValue(i, "tax", e.target.value)
                          }
                          onBlur={b.tax.error && checkBillRowsError}
                          placeholder="0.00"
                          step="any"
                        />
                        <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                          {currency?.symbol}
                          <div className="w-[0.5px] h-[1.188rem] bg-slate-600"></div>
                        </span>
                      </div>
                      <span className="text-xs mt-1 text-rose-500 h-[10px]">
                        {b?.tax?.error || ""}
                      </span>
                    </div>
                  </td> */}
                    <td className="pr-5 pl-2  first:pl-5 last:pr-5 py-2.5 text-right">
                      <div className="text-right h-[60px] pt-5">
                        <div className="min-w-[4rem] w-fit ml-auto text-slate-600  text-left">
                          {currency?.symbol}
                          {formatNumberGlobally(
                            parseFloat(b.price.value || 0) *
                              +b.quantity.value || 0,
                          )}
                        </div>
                      </div>
                    </td>

                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                      <div className=" h-[60px] pt-[18px]">
                        <button
                          onClick={() =>
                            setBillRows((prev) =>
                              prev.filter((d, index) => index !== i),
                            )
                          }
                          className="border-[#D0D5DD99] border rounded-[5px] h-[26px] w-[26px] shadow-sm inline-flex justify-center items-center"
                          type="button"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.6059 3.87474C11.4483 3.66093 9.27778 3.55078 7.11373 3.55078C5.83086 3.55078 4.54798 3.61557 3.26511 3.74516L1.94336 3.87474"
                              stroke="#E48642"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.50781 3.22021L5.65035 2.37144C5.75402 1.75592 5.83177 1.2959 6.92675 1.2959H8.62429C9.71927 1.2959 9.8035 1.78184 9.90069 2.37792L10.0432 3.22021"
                              stroke="#E48642"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.2124 5.92188L11.7913 12.4464C11.72 13.4636 11.6617 14.2541 9.85398 14.2541H5.69435C3.88667 14.2541 3.82835 13.4636 3.75708 12.4464L3.33594 5.92188"
                              stroke="#E48642"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.69336 10.6904H8.85092"
                              stroke="#E48642"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.15625 8.09863H9.39583"
                              stroke="#E48642"
                              strokeWidth="1.3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr className="!border-t-0">
                    <td colSpan={6} className="px-5 pb-[18px]">
                      <TableEntryItemsDropdowns
                        b={b}
                        i={i}
                        handleUpdateValue={handleUpdateValue}
                        customers={customers}
                        setCustomers={setCustomers}
                        projects={projects}
                        tags={tags}
                      />
                    </td>
                  </tr>
                </>
              ))}
              <tr>
                <td colSpan={6}>
                  <div className="flex  justify-between p-5">
                    <div className="w-full relative">
                      <button
                        type="button"
                        className="btn mb-4 h-9 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
                        onClick={() =>
                          setBillRows([
                            ...billRows,
                            JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA)),
                          ])
                        }
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.33337 8H10.6667"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 10.6663V5.33301"
                            stroke="#E48642"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Add a line
                      </button>
                      {!billRows?.length > 0 && (
                        <h6 className="left-full sm:left-1/2 whitespace-nowrap -translate-x-full sm:-translate-x-1/2 top-2 absolute text-center">
                          Please Add A New Line
                        </h6>
                      )}
                      <TextInputDash
                        label="Memo"
                        type="textarea"
                        rows={3}
                        cols={40}
                        paddingBottom="pb-3"
                        id="notes"
                        name="notes"
                        placeholder="Memo"
                        onChange={ref?.current?.handleChange}
                        onBlur={ref?.current?.handleBlur}
                        value={ref?.current?.values.notes || ''}
                        error={
                          ref?.current?.touched.notes &&
                          ref?.current?.errors.notes
                        }
                        width="w-2/3"
                      />
                    </div>
                    {billRows?.length > 0 && (
                      <div className="flex gap-6 justify-end">
                        <div className="flex flex-col gap-3 text-slate-600">
                          <span>Subtotal:</span>
                          <span className="h-[41px] inline-flex items-center">
                            Tax:
                          </span>
                          <span className="h-[41px] inline-flex items-center">
                            Discount:
                          </span>
                          <span className="text-nowrap">
                            Total ({currency?.isoCode}):
                          </span>
                        </div>
                        <div className="flex flex-col gap-3 text-slate-600 font-medium">
                          <span className="min-w-[4rem]">
                            {currency?.symbol}
                            {formatNumberGlobally(calcSubTotal())}
                          </span>
                          <span className="min-w-[4rem]">
                            <div className="relative">
                              <input
                                type="number"
                                value={tax}
                                className="form-input text-slate-600 w-40 pl-8"
                                min={0}
                                onChange={(e) => setTax(e.target.value)}
                                placeholder="0.00"
                                step="any"
                                // onBlur={b.price.error && checkBillRowsError}
                                onWheel={(e) => e.target.blur()}
                              />

                              <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                                {currency?.symbol}
                                <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                              </span>
                            </div>
                            {/* {currency?.symbol}
                          {parseFloat(calcTax())?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} */}
                          </span>
                          <span className="min-w-[4rem]">
                            <div className="relative">
                              <input
                                type="number"
                                value={discount}
                                className="form-input text-slate-600 w-40 pl-8"
                                min={0}
                                onChange={(e) => setDiscount(e.target.value)}
                                placeholder="0.00"
                                step="any"
                                // onBlur={b.price.error && checkBillRowsError}
                                onWheel={(e) => e.target.blur()}
                              />

                              <span className="absolute top-[50%] -translate-y-[50%] left-3 flex items-center gap-2">
                                {currency?.symbol}
                                <div className="w-[0.5px] h-[1.188rem] bg-slate-600" />
                              </span>
                            </div>
                            {/* {currency?.symbol}
                          {parseFloat(calcTax())?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })} */}
                          </span>
                          <span className="min-w-[4rem]">
                            {currency?.symbol}
                            {formatNumberGlobally(
                              parseFloat(tax || 0) +
                                parseFloat(calcSubTotal()) -
                                parseFloat(discount || 0),
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {isLineItemsError && (
            <p className="text-rose-500 text-center font-semibold mb-4">
              {isLineItemsError
                ? billRows.length === 0
                  ? 'At least one Line Item is required.'
                  : typeof isLineItemsError === 'boolean'
                    ? 'Please Fill Line Items Properly'
                    : isLineItemsError || ''
                : ''}
            </p>
          )}
        </div>
      </div>
    );
  },
);

CreateBillTable.displayName = 'CreateBillTable';

export default CreateBillTable;
