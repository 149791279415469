import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import WarningBox from '../../components/WarningBox';
import {
  onConfirmMatchingTrans,
  onIgnoreMatchingTransaction,
} from '../../API/backend_helper';
import {
  amountColor,
  getContrast,
  TEAM_CURRENCY,
  TEAM_ID,
} from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import { Loader } from '../../components/Svg';
import PaginationClassic from '../../components/PaginationClassic';

const MatchingTransactions = ({
  matchings,
  currencies,
  selectedTransactions,
  setSelectedTransactions,
  loadTransactionsApi,
  localLimit,
  setLocalLimit,
  setTransactionFlyoutOpen,
  setTransaction,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [descriptionLength, setDescriptionLength] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [keepAllloading, setKeepAllLoading] = useState({});
  const [keepSelectedLoading, setKeepSelectedLoading] = useState({});
  const [keepAllSelectedLoading, setKeepAllSelectedLoading] = useState(false);
  const teamId = localStorage.getItem(TEAM_ID);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(100);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(80);
      } else if (window.innerWidth > 1300) {
        setDescriptionLength(60);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(50);
      } else {
        setDescriptionLength(20);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const findCurrencySymbol = (isoCode) => {
    const currency = currencies?.find?.(
      (currency) => currency.isoCode === isoCode,
    );
    return currency?.symbol === '$' && currency?.isoCode != 'USD'
      ? currency.symbol
      : currency?.symbol;
  };

  const handleTransactionCheckbox = (id, matchId) => {
    const parsedId = parseInt(id);
    setSelectedTransactions((prev) => {
      const currentMatchTransactions = prev[matchId] || [];
      if (currentMatchTransactions.includes(parsedId)) {
        return {
          ...prev,
          [matchId]: currentMatchTransactions.filter(
            (item) => item !== parsedId,
          ),
        };
      }
      return {
        ...prev,
        [matchId]: [parsedId],
      };
    });
  };

  const onSkipMatching = async (id) => {
    setKeepAllLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await onIgnoreMatchingTransaction(id);
      await loadTransactionsApi({});
      setSelectedTransactions((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setKeepAllLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const onConfirmSelected = async (id) => {
    setKeepSelectedLoading((prev) => ({ ...prev, [id]: true }));
    if (selectedTransactions[id]?.length > 0) {
      try {
        await onConfirmMatchingTrans(selectedTransactions[id][0]);
        await loadTransactionsApi({});
        setSelectedTransactions((prev) => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
      }
    } else {
      setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const onMatchAll = async () => {
    setKeepAllSelectedLoading(true);
    setKeepSelectedLoading((prev) => {
      const loadingState = {};
      Object.keys(selectedTransactions).forEach((id) => {
        loadingState[id] = true;
      });
      return { ...prev, ...loadingState };
    });

    try {
      for (const id of Object.keys(selectedTransactions)) {
        if (selectedTransactions[id]?.length > 0) {
          await onConfirmMatchingTrans(selectedTransactions[id][0]);
        }
      }
      await loadTransactionsApi({});
      setSelectedTransactions({});
    } catch (error) {
      console.log(error);
    } finally {
      setKeepSelectedLoading((prev) => {
        const loadingState = {};
        Object.keys(selectedTransactions).forEach((id) => {
          loadingState[id] = false;
        });
        return { ...prev, ...loadingState };
      });
      setKeepAllSelectedLoading(false);
    }
  };

  const isMatchAllDisable = () => {
    let bool = false;
    if (Object.keys(selectedTransactions)?.length === 0) {
      bool = true;
    } else {
      matchings?.matchings?.forEach?.((match) => {
        if (selectedTransactions[match?.id]?.length === 0) {
          bool = true;
        }
      });
    }
    return bool;
  };

  const { page, totalRecords } = matchings;

  return (
    <>
      {matchings?.matchings?.length > 0 && !isLoading && (
        <WarningBox content="Please review potential matching transactions below. You can choose to confirm or ignore each match." />
      )}
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <Loader />
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        matchings?.matchings?.length > 0 && (
          <>
            <div className="w-full mt-4 rounded-[5px] shadow flex items-center px-6 self-stretch gap-11 h-[3.5rem]">
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[10%]">
                Date
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[35%]">
                Description
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%]">
                Account
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[15%]">
                Amount
              </div>
              <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[10%]">
                Status
              </div>
            </div>
            {matchings?.matchings?.map((matching) => (
              <div className="w-full flex items-start mt-4" key={matching?.id}>
                <div className="w-full flex-col flex gap-4 items-start">
                  <div className="flex flex-col w-full">
                    <div className="flex pl-6 text-slate-600 text-[18px] font-medium">
                      Outgoing Funds
                    </div>
                    <div className="w-full rounded-[5px] flex items-center pl-6 self-stretch gap-11 py-4 px-6 relative">
                      <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap">
                        <div className="flex items-center opacity-0 h-5 w-5" />
                        <p>
                          {' '}
                          {moment(
                            matching?.mainTransaction?.authorizedDate,
                          ).format('MMM DD, YYYY')}
                        </p>
                      </div>
                      <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[35%]">
                        <div className="flex items-center">
                          <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                            {matching?.mainTransaction?.vendor?.logoUrl ||
                            matching?.mainTransaction?.plaidCategoryIconUrl ? (
                              <img
                                className="rounded-full"
                                src={
                                  matching?.mainTransaction?.vendor?.logoUrl ||
                                  matching?.mainTransaction
                                    ?.plaidCategoryIconUrl
                                }
                                alt={matching?.mainTransaction?.name}
                              />
                            ) : (
                              <div
                                className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                style={{
                                  backgroundColor: matching?.mainTransaction
                                    ?.categoryAccount?.group?.color
                                    ? matching?.mainTransaction?.categoryAccount
                                        ?.group?.color
                                    : '',
                                }}
                              >
                                <div
                                  className={`${
                                    matching?.mainTransaction?.categoryAccount
                                      ?.group?.color
                                      ? getContrast(
                                          matching?.mainTransaction
                                            ?.categoryAccount?.group?.color,
                                          'text-black',
                                          'text-white',
                                        )
                                      : 'text-black'
                                  } text-xs font-normal uppercase`}
                                >
                                  {matching?.mainTransaction?.description
                                    ? matching?.mainTransaction?.description
                                        .replace(/[^\w\s]/gi, '')
                                        .split(' ')
                                        .filter(Boolean)
                                        .slice(0, 2)
                                        .map((word) => word.charAt(0))
                                        .join('')
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="font-normal leading-6 mr-2">
                            <span
                              className="text-indigo-500 cursor-pointer"
                              onClick={() => {
                                setTransaction(matching?.mainTransaction);
                                setTransactionFlyoutOpen(true);
                              }}
                            >
                              {matching?.mainTransaction?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[20%]">
                        {`${matching?.mainTransaction?.account?.name} ${
                          matching?.mainTransaction?.account?.mask
                            ? `(...${matching?.mainTransaction?.account?.mask})`
                            : ``
                        }`}
                      </div>
                      <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[15%]">
                        <div
                          className={`font-semibold leading-6 text-sm  ${amountColor(
                            matching?.mainTransaction?.amount?.toString(),
                          )}`}
                        >
                          {matching?.mainTransaction?.amount
                            ?.toString()
                            .charAt(0) === '-'
                            ? ''
                            : '-'}
                          {findCurrencySymbol(
                            matching?.mainTransaction?.currency,
                          )}
                          {matching?.mainTransaction?.amount
                            ?.toString()
                            .charAt(0) === '-'
                            ? parseFloat(
                                matching?.mainTransaction?.amount
                                  ?.toString()
                                  .replace('-', ''),
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : parseFloat(
                                matching?.mainTransaction?.amount,
                              )?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          {matching?.mainTransaction?.currency !==
                            teamDefaultCurrency &&
                            ` (${matching?.mainTransaction?.currency})`}
                        </div>
                      </div>
                      <div className="text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap text-slate-600">
                        <p>
                          {matching?.mainTransaction?.reconciled
                            ? 'Reconciled'
                            : matching?.mainTransaction?.pending
                              ? 'Pending'
                              : matching?.mainTransaction?.excluded
                                ? 'Excluded'
                                : matching?.mainTransaction?.reviewed
                                  ? 'Reviewed'
                                  : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col border-b">
                    <div className="flex pl-6 text-slate-600 text-[18px] font-medium">
                      Possible Matches
                    </div>

                    {matching?.transferTransactions?.map((transaction) => {
                      const anySelected =
                        selectedTransactions[matching.id]?.length > 0;
                      const isSelected =
                        selectedTransactions[matching.id]?.includes(
                          transaction?.TransferMatchingTransaction?.id,
                        ) || false;
                      return (
                        <div
                          key={transaction?.TransferMatchingTransaction?.id}
                          className="w-full rounded-[5px] flex items-center pl-6 self-stretch gap-11 py-4 px-6 relative"
                        >
                          <div
                            className={`text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap ${
                              !isSelected && anySelected
                                ? 'text-slate-300'
                                : 'text-slate-600 '
                            }`}
                          >
                            {checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            ) && (
                              <div className="flex items-center">
                                <label className="inline-flex">
                                  <span className="sr-only">Select</span>
                                  <input
                                    id={
                                      transaction?.TransferMatchingTransaction
                                        ?.id
                                    }
                                    className="form-checkbox cursor-pointer h-5 w-5"
                                    type="checkbox"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      handleTransactionCheckbox(
                                        transaction?.TransferMatchingTransaction
                                          ?.id,
                                        matching.id,
                                      );
                                    }}
                                    checked={
                                      selectedTransactions[
                                        matching.id
                                      ]?.includes(
                                        transaction?.TransferMatchingTransaction
                                          ?.id,
                                      ) || false
                                    }
                                  />
                                </label>
                              </div>
                            )}
                            <p>
                              {moment(transaction?.authorizedDate).format(
                                'MMM DD, YYYY',
                              )}
                            </p>
                          </div>
                          <div
                            className={`${
                              !isSelected && anySelected
                                ? 'text-slate-300'
                                : 'text-slate-600 '
                            } text-[14px] leading-[1.125rem] w-[35%]`}
                          >
                            <div className="flex items-center">
                              <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                                {transaction?.vendor?.logoUrl ||
                                transaction?.plaidCategoryIconUrl ? (
                                  <img
                                    className="rounded-full"
                                    src={
                                      transaction?.vendor?.logoUrl ||
                                      transaction?.plaidCategoryIconUrl
                                    }
                                    alt={transaction?.name}
                                  />
                                ) : (
                                  <div
                                    className={`w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600 ${
                                      !isSelected && anySelected
                                        ? '!bg-opacity-50'
                                        : ' '
                                    }`}
                                    style={{
                                      backgroundColor: transaction
                                        ?.categoryAccount?.group?.color
                                        ? transaction?.categoryAccount?.group
                                            ?.color
                                        : '',
                                    }}
                                  >
                                    <div
                                      className={`${
                                        transaction?.categoryAccount?.group
                                          ?.color
                                          ? getContrast(
                                              transaction?.categoryAccount
                                                ?.group?.color,
                                              'text-black',
                                              'text-white',
                                            )
                                          : 'text-black'
                                      } text-xs font-normal uppercase ${
                                        !isSelected && anySelected
                                          ? 'text-opacity-30'
                                          : ' '
                                      }`}
                                    >
                                      {transaction?.description
                                        ? transaction.description
                                            .replace(/[^\w\s]/gi, '')
                                            .split(' ')
                                            .filter(Boolean)
                                            .slice(0, 2)
                                            .map((word) => word.charAt(0))
                                            .join('')
                                        : ''}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="font-normal leading-6 mr-2">
                                <span
                                  className="text-indigo-500 cursor-pointer"
                                  onClick={() => {
                                    setTransaction(transaction);
                                    setTransactionFlyoutOpen(true);
                                  }}
                                >
                                  {transaction?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              !isSelected && anySelected
                                ? 'text-slate-300'
                                : 'text-slate-600 '
                            } text-[14px] leading-[1.125rem] w-[20%]`}
                          >
                            {`${transaction?.account?.name} ${
                              transaction?.account?.mask
                                ? `(...${transaction?.account?.mask})`
                                : ``
                            }`}
                          </div>
                          <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[15%]">
                            <div
                              className={`font-semibold leading-6 text-sm  ${amountColor(
                                transaction?.amount?.toString(),
                              )} ${
                                !isSelected && anySelected
                                  ? '!text-slate-300'
                                  : ' '
                              }`}
                            >
                              {transaction?.amount?.toString().charAt(0) === '-'
                                ? ''
                                : '-'}
                              {findCurrencySymbol(transaction?.currency)}
                              {transaction?.amount?.toString().charAt(0) === '-'
                                ? parseFloat(
                                    transaction?.amount
                                      ?.toString()
                                      .replace('-', ''),
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(
                                    transaction?.amount,
                                  )?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              {transaction?.currency !== teamDefaultCurrency &&
                                ` (${transaction?.currency})`}
                            </div>
                          </div>
                          <div
                            className={`text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap ${
                              !isSelected && anySelected
                                ? 'text-slate-300'
                                : 'text-slate-600 '
                            }`}
                          >
                            <p>
                              {transaction?.reconciled
                                ? 'Reconciled'
                                : transaction?.pending
                                  ? 'Pending'
                                  : transaction?.excluded
                                    ? 'Excluded'
                                    : transaction?.reviewed
                                      ? 'Reviewed'
                                      : ''}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-full flex justify-end items-center pb-4 gap-4 border-b-2 border-[#D0D5DD]">
                    <Tooltip
                      contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                      content="You do not have permission"
                      tooltipShow={
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                      position="top-left"
                    >
                      <button
                        type="button"
                        onClick={() => onSkipMatching(matching?.id)}
                        className="whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600 disabled:cursor-not-allowed"
                        disabled={
                          keepSelectedLoading[matching.id] ||
                          keepAllloading[matching.id] ||
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                        }
                      >
                        {keepAllloading[matching.id] ? (
                          <Loader width="w-4" height="w-4" />
                        ) : (
                          'Skip'
                        )}
                      </button>
                    </Tooltip>
                    <Tooltip
                      contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                      tooltipShow={
                        selectedTransactions[matching.id]?.length === 0 ||
                        !selectedTransactions[matching.id]?.length ||
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                      content={
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                          ? 'You do not have permission'
                          : 'Please select at least one transaction to confirm match.'
                      }
                      position="top-left"
                    >
                      <button
                        type="button"
                        onClick={() => onConfirmSelected(matching?.id)}
                        className={`whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white disabled:cursor-not-allowed disabled:bg-indigo-300 ${
                          selectedTransactions[matching.id]?.length === 0 ||
                          !selectedTransactions[matching.id]?.length
                            ? 'bg-indigo-400'
                            : ''
                        }`}
                        disabled={
                          keepSelectedLoading[matching.id] ||
                          keepAllloading[matching.id] ||
                          selectedTransactions[matching.id]?.length === 0 ||
                          !selectedTransactions[matching.id]?.length ||
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                        }
                      >
                        {keepSelectedLoading[matching.id] ? (
                          <Loader width="w-4" height="w-4" color="#FFFFFF" />
                        ) : (
                          'Confirm Match'
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
            <div className="w-full flex justify-center mt-4">
              <Tooltip
                tooltipShow={
                  isMatchAllDisable() ||
                  !checkPermission(
                    PermissionKeys.MANAGE_TRANSACTIONS,
                    accessType,
                    permissions,
                  )
                }
                content={
                  !checkPermission(
                    PermissionKeys.MANAGE_TRANSACTIONS,
                    accessType,
                    permissions,
                  )
                    ? 'You do not have permission'
                    : 'Please select at least one transaction to confirm match.'
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                size="sm"
              >
                <button
                  type="button"
                  onClick={() => onMatchAll()}
                  disabled={isMatchAllDisable() || keepAllSelectedLoading}
                  className="whitespace-nowrap w-[11.25rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white disabled:cursor-not-allowed"
                >
                  {keepAllSelectedLoading ? (
                    <Loader width="w-4" height="w-4" color="#FFFFFF" />
                  ) : (
                    'Match All'
                  )}
                </button>
              </Tooltip>
            </div>
          </>
        )
      )}
      {matchings?.matchings?.length === 0 && !isLoading && (
        <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
          <h6 className="text-center mt-4">No Matching Transactions Found</h6>
          <button
            className="h-11 bg-indigo-500 rounded-[5px] text-white whitespace-nowrap w-[30%] max-w-[200px]"
            onClick={() => {
              navigate('/transactions');
            }}
            type="button"
          >
            Back to Transactions
          </button>
        </div>
      )}
      {!isLoading && totalRecords > 0 && (
        <div className="mt-10">
          <PaginationClassic
            pagination={{ limit: localLimit, page, totalRecords }}
            setLimit={setLocalLimit}
            onPageChange={loadTransactionsApi}
          />
        </div>
      )}
    </>
  );
};

export default MatchingTransactions;
