import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useClickOutside from '../../utils/useClickOutside';
import BarChartInsights from '../../charts/BarChartInsights';
import Tooltip from '../../components/Tooltip';
import {
  amountColor,
  formatNumber,
  getContrast,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';

const CategoryFlyout = ({
  activeCategory,
  setActiveCategory,
  flyoutIsLoading,
  flyoutOpen,
  setFlyoutOpen,
  startDate,
  endDate,
  type,
  activeCategorySpendingData,
  activeCategoryIncomeData,
  activeCategoryTransactions,
  teamCurrency,
}) => {
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const flyoutContent = useRef(null);
  const [localCategory, setLocalCategory] = useState(activeCategory);
  const [localType, setLocalType] = useState(type);
  const [descriptionLength, setDescriptionLength] = useState(28);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 475) {
        setDescriptionLength(15);
        setIsMobile(true);
      } else {
        setDescriptionLength(28);
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLocalCategory(activeCategory);
  }, [activeCategory]);

  useEffect(() => {
    if (!flyoutOpen) {
      setFlyoutOpen(false);
      setActiveCategory(null);
      setLocalCategory(null);
      setLocalType(type);
    } else {
      setLocalType(type);
    }
  }, [flyoutOpen]);

  useClickOutside(flyoutContent, () => {
    if (flyoutOpen) {
      setFlyoutOpen(false);
      setActiveCategory(null);
      setLocalCategory(null);
      setLocalType(type);
    }
  });

  const handleBarChartData = () => {
    if (activeCategoryIncomeData && activeCategorySpendingData) {
      if (localType === 'both') {
        return [
          {
            label: `Expenses`,
            backgroundColor: '#E48642',
            borderColor: '#E48642',
            data: Object.entries(activeCategorySpendingData?.months).map(
              ([month, amount]) => ({
                month: month.substring(0, 7),
                amount,
              }),
            ),
          },
          {
            label: `Income`,
            backgroundColor: '#78BD4F',
            borderColor: '#78BD4F',
            data: Object.entries(activeCategoryIncomeData?.months).map(
              ([month, amount]) => ({
                month: month.substring(0, 7),
                amount,
              }),
            ),
          },
        ];
      }
      if (localType === 'spending') {
        return [
          {
            label: 'Expenses',
            backgroundColor: '#E48642',
            borderColor: '#E48642',
            data: Object.entries(activeCategorySpendingData?.months).map(
              ([month, amount]) => ({
                month: month.substring(0, 7),
                amount,
              }),
            ),
          },
        ];
      }
      if (localType === 'income') {
        return [
          {
            label: `Income`,
            backgroundColor: '#78BD4F',
            borderColor: '#78BD4F',
            data: Object.entries(activeCategoryIncomeData?.months).map(
              ([month, amount]) => ({
                month: month.substring(0, 7),
                amount,
              }),
            ),
          },
        ];
      }
    }
  };

  const createDateRange = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const startMonth = start.toISOString().slice(0, 7);
      const endMonth = end.toISOString().slice(0, 7);

      return { start: startMonth, end: endMonth };
    }
  };

  const handleBarChartClick = (start, end) => {
    navigate(
      `/reports/account-transaction?accountId=${localCategory?.id}${start ? `&startDate=${getFormattedDate(start, teamDateFormat)}` : ''}${end ? `&endDate=${getFormattedDate(end, teamDateFormat)}` : ''}`,
    );
  };

  return (
    <div
      ref={flyoutContent}
      className={`absolute inset-0 bg-white sm:left-auto  transition-transform duration-200 ease-in-out z-40 ${
        flyoutOpen ? 'translate-x-0 shadow-xl' : 'translate-x-full'
      }`}
    >
      <div
        className="sticky top-16 bg-white dark:bg-gradient-to-b dark:from-slate-800 dark:to-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0  w-full sm:w-[550px] h-[calc(100dvh-64px)]"
        style={flyoutOpen ? { boxShadow: ' -10px 0px 15px 0px #D0D5DD80' } : {}}
      >
        {/* Header */}
        <div className="sticky top-0 h-[3.875rem] bg-white z-10">
          <div className="flex justify-end pt-2.5 pr-2.5">
            <button
              className="ml-auto group"
              onClick={() => setFlyoutOpen(false)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="h-5 w-5 fill-[#667085] group-hover:fill-slate-700"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </button>
          </div>
          <div className="relative flex flex-row gap-[3.125rem] justify-start items-center mt-3 pb-1 w-full border-b-2 border-gray-300 pl-[1.625rem] bg-white">
            <p className="text-sm leading-6 font-normal cursor-pointer text-black">
              {localCategory?.name} from{' '}
              {moment(startDate).format('MMM DD, YY')} -{' '}
              {moment(endDate).format('MMM DD, YY')}
            </p>
          </div>
        </div>
        {flyoutIsLoading ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className="flex flex-col px-[1.688rem] pt-5">
            <div className="w-full mt-3 bg-white border border-[#D0D5DD] rounded-[0.313rem] shadow-sm">
              <div className="flex w-full py-4 px-7 justify-between border-b-[2px] border-[#F0F0F0]">
                <div className="flex flex-col">
                  <p className="text-slate-600 text-[1.125rem] font-medium">
                    {localType === 'spending'
                      ? 'Total Spending'
                      : localType === 'income'
                        ? 'Total Income'
                        : 'Total'}
                  </p>
                  <p className="text-black font-bold text-[2rem]">
                    {localType === 'spending'
                      ? `${
                          teamCurrency ? teamCurrency?.symbol : '$'
                        }${formatNumber(activeCategorySpendingData?.total)}`
                      : localType === 'income'
                        ? `${
                            teamCurrency ? teamCurrency?.symbol : '$'
                          }${formatNumber(activeCategoryIncomeData?.total)}`
                        : (() => {
                            const result =
                              activeCategoryIncomeData?.total -
                              activeCategorySpendingData?.total;
                            return result >= 0
                              ? `${
                                  teamCurrency ? teamCurrency?.symbol : '$'
                                }${formatNumber(result.toFixed(2))}`
                              : `-${
                                  teamCurrency ? teamCurrency?.symbol : '$'
                                }${formatNumber(Math.abs(result).toFixed(2))}`;
                          })()}
                  </p>
                </div>
                <Tooltip
                  position="top"
                  content={
                    <>
                      <p className="text-center">
                        A graphical view of{' '}
                        {localType === 'spending'
                          ? 'Spending'
                          : localType === 'income'
                            ? 'Income'
                            : 'Spending & Income '}
                      </p>
                      <p className="text-center">
                        {' '}
                        for {localCategory?.name} month by month.
                      </p>
                    </>
                  }
                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative right-[5rem]"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-1.5"
                  >
                    <circle cx="12" cy="12.6016" r="12" fill="#BDBDBD" />
                    <rect
                      x="10.0001"
                      y="9.9375"
                      width="3.74456"
                      height="10.6666"
                      rx="1.87228"
                      fill="white"
                    />
                    <rect
                      x="10.0001"
                      y="4.60156"
                      width="3.74456"
                      height="3.99999"
                      rx="1.87228"
                      fill="white"
                    />
                  </svg>
                </Tooltip>
              </div>
              <div className="w-full h-[12.5rem] p-2">
                <BarChartInsights
                  datasets={handleBarChartData()}
                  width={400}
                  height={300}
                  dateRange={createDateRange()}
                  goToTransactions={handleBarChartClick}
                  teamCurrency={teamCurrency}
                />
              </div>
            </div>
            <div className="flex flex-col items-start gap-2.5 self-stretch mt-3">
              {activeCategoryTransactions?.[localType]?.length === 0 ? (
                <p className="text-slate-600 text-center text-[1rem] font-medium leading-5 w-full">
                  There are no transactions for{' '}
                  {moment(startDate).format('MMM DD, YY')} -{' '}
                  {moment(endDate).format('MMM DD, YY')}
                </p>
              ) : (
                <table className="w-full flex flex-col items-start">
                  <thead className="flex w-full items-center gap-5 rounded-[0.313rem] shadow-md bg-white h-[3.5rem] mb-2">
                    <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[15%] sm:w-[20%] ml-4">
                      Date
                    </th>
                    <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[50%] sm:w-[60%]">
                      Description
                    </th>
                    <th className="text-slate-600 text-left text-[14px] font-medium leading-[1.125rem] w-[40%] sm:w-[20%]">
                      Amount
                    </th>
                  </thead>
                  {activeCategoryTransactions?.[localType]?.map(
                    (transaction, index) => (
                      <tr
                        key={transaction?.id}
                        className={`flex w-full items-center gap-5 bg-white h-[3.5rem] ${
                          index === 7 ? '' : 'border-b border-[#D0D5DD]'
                        }`}
                      >
                        <td className="whitespace-nowrap text-slate-600 text-[14px] leading-6 w-[15%] sm:w-[20%] ml-4">
                          {!isMobile
                            ? moment
                                ?.utc(transaction?.authorizedDate)
                                ?.format('MMM DD, YYYY')
                            : moment
                                ?.utc(transaction?.authorizedDate)
                                ?.format('MMM DD')}
                        </td>
                        <td className="text-slate-600 text-[14px] font-medium leading-6 flex items-center gap-2 w-[50%] sm:w-[60%]">
                          <div className="h-[1.688rem] w-[1.688rem] min-h-[1.688rem] min-w-[1.688rem] rounded-full">
                            {transaction?.vendor?.logoUrl ||
                            transaction?.plaidCategoryIconUrl ? (
                              <img
                                className="rounded-full"
                                src={
                                  transaction?.vendor?.logoUrl ||
                                  transaction?.plaidCategoryIconUrl
                                }
                                alt={transaction?.name}
                              />
                            ) : (
                              <div
                                className="w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                style={{
                                  backgroundColor: transaction?.categoryAccount
                                    ?.group?.color
                                    ? transaction?.categoryAccount?.group?.color
                                    : '',
                                }}
                              >
                                <div
                                  className={`${
                                    transaction?.categoryAccount?.group?.color
                                      ? getContrast(
                                          transaction?.categoryAccount?.group
                                            ?.color,
                                          'text-black',
                                          'text-white',
                                        )
                                      : 'text-black'
                                  } text-xs font-normal uppercase`}
                                >
                                  {transaction?.descriptionEntry
                                    ? transaction.descriptionEntry
                                        .replace(/[^\w\s]/gi, '')
                                        .split(' ')
                                        .filter(Boolean)
                                        .slice(0, 2)
                                        .map((word) => word.charAt(0))
                                        .join('')
                                    : ''}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="whitespace-nowrap flex flex-col">
                            <p className="leading-6 font-medium text-slate-600 text-[14px]">
                              {transaction?.descriptionEntry.length >
                              descriptionLength
                                ? `${transaction?.descriptionEntry.substring(
                                    0,
                                    descriptionLength,
                                  )}...`
                                : transaction?.descriptionEntry}
                            </p>
                            <p className="leading-6 text-slate-600 text-opacity-80 text-[12px]">
                              {transaction?.categoryAccount?.name}
                            </p>
                          </div>
                        </td>
                        <td
                          className={`text-[14px] font-semibold leading-6 w-[40%] sm:w-[20%] ${amountColor(
                            transaction?.amountCredit
                              ? (transaction?.amount * -1).toString()
                              : transaction?.amount.toString(),
                          )}`}
                        >
                          {transaction?.amountCredit
                            ? `${
                                teamCurrency ? teamCurrency?.symbol : '$'
                              }${formatNumber(
                                Math.abs(transaction.amount).toFixed(2),
                              )}`
                            : transaction?.amount < 0
                              ? `${
                                  teamCurrency ? teamCurrency?.symbol : '$'
                                }${formatNumber(
                                  Math.abs(transaction.amount).toFixed(2),
                                )}`
                              : `-${
                                  teamCurrency ? teamCurrency?.symbol : '$'
                                }${formatNumber(
                                  Math.abs(transaction.amount).toFixed(2),
                                )}`}
                        </td>
                      </tr>
                    ),
                  )}
                </table>
              )}
            </div>
            {activeCategoryTransactions?.[localType]?.length > 0 && (
              <div
                className="w-full pb-10 flex justify-center items-center gap-1 cursor-pointer mt-4"
                onClick={() => {
                  handleBarChartClick(startDate, endDate);
                }}
                role="button"
                tabIndex="0"
              >
                <p className="text-center text-indigo-500 leading-6 text-[1rem]">
                  Load More
                </p>
                <svg
                  width="9"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.354004 1.00781L4.34762 5.00187L8.34168 1.00781"
                    stroke="#E48642"
                  />
                </svg>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryFlyout;
