import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import UploadFile from 'components/UploadFile';
import TextInput from 'components/TextInput';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import DropdownInput from 'components/DropdownInput';
import TextArea from 'components/TextArea';
import { toast } from 'react-toastify';
import ModalBasic from '../../components/ModalBasic';
import { Loader } from '../../components/Svg';
import {
  getCountriesData,
  updateAccountantFirm,
} from '../../API/backend_helper';

const EditAccountantFirmModal = ({
  isOpen,
  setIsOpen,
  accountantFirm,
  loadAccountantFirm,
  setFiles,
  setCurrentImageForCrop,
  setCropModalOpen,
  setImgName,
  imgPreview,
  setImgPreview,
  files,
  cropModalOpen,
}) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      formikRef?.current?.resetForm();
      setFiles([]);
      setImgPreview('');
    }
  }, [isOpen]);

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (isOpen && countries?.length === 0) {
      loadCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (accountantFirm?.logoUrl) {
      setFiles([accountantFirm?.logoUrl]);
      setImgPreview(accountantFirm?.logoUrl);
    }
  }, [accountantFirm?.logoUrl, isOpen]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const logo = files?.[0];
    const {
      name,
      email,
      firmZipCode,
      tagline,
      phone,
      description,
      website,
      city,
      country,
    } = values;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('firmZipCode', firmZipCode);
    formData.append('tagline', tagline);
    formData.append('phone', phone);
    formData.append('description', description);
    if (website) {
      formData.append('website', website);
    }
    formData.append(
      'address',
      JSON.stringify({
        city,
        country,
      }),
    );
    if (logo) {
      formData.append('logo', logo);
    } else {
      formData.append('logoUrl', '');
    }

    try {
      await updateAccountantFirm(accountantFirm?.id, formData);
      await loadAccountantFirm({});
      setIsOpen(false);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBasic
      title="Update Accountant Practice"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
      noClick={cropModalOpen}
      width="!w-[800px]"
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          name: accountantFirm?.name || '',
          email: accountantFirm?.email || '',
          website: accountantFirm?.website || '',
          firmZipCode: accountantFirm?.firmZipCode || '',
          tagline: accountantFirm?.tagline || '',
          phone:
            accountantFirm?.phone && isValidPhoneNumber(accountantFirm?.phone)
              ? accountantFirm?.phone
              : '',
          description: accountantFirm?.description || '',
          city: accountantFirm?.address?.city || '',
          country:
            accountantFirm?.address?.country &&
            (accountantFirm?.address?.country === 'US' ||
              accountantFirm?.address?.country === 'CA' ||
              accountantFirm?.address?.country === 'NZ' ||
              accountantFirm?.address?.country === 'ZA')
              ? accountantFirm?.address?.country
              : '',
        }}
        validate={(values) => {
          const errors = {};
          const { phone } = values;
          if (phone && !isValidPhoneNumber(phone)) {
            errors.phone = 'Phone number is not valid';
          }

          return errors;
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .trim()
            .required('Please Enter Practice Name')
            .max(100, 'Practice Name must be at most 100 characters'),
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email')
            .max(100, 'Email must be at most 100 characters'),
          country: Yup.string().trim().required('Please Select a Country'),
          website: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+.*)$/,
            'Invalid URL',
          ),
          tagline: Yup.string()
            .trim()
            .max(50, 'Tag line must be at most 50 characters'),
        })}
        onSubmit={handleSubmit}
      >
        {(validation) => (
          <Form>
            <div className="space-y-4 pt-8">
              <div className="grid sm:grid-cols-2 gap-x-4 gap-y-8 px-8">
                <TextInput
                  label="Practice Name"
                  placeholder=" "
                  id="name"
                  name="name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ''}
                  error={validation.touched.name ? validation.errors.name : ''}
                  required
                />
                <TextInput
                  label="Email"
                  placeholder=" "
                  id="email"
                  name="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  error={
                    validation.touched.email ? validation.errors.email : ''
                  }
                  required
                />
                <div>
                  <label
                    htmlFor="phone"
                    className={`text-sm text-[667085] mb-2 block ${
                      validation.touched.phone && validation.errors.phone
                        ? 'text-rose-400'
                        : ''
                    }`}
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={validation.values.phone}
                    onChange={(value) =>
                      validation.setFieldValue('phone', value || '')
                    }
                    international
                    defaultCountry="US"
                    id="phone"
                    name="phone"
                    className={`phone_textInput ${
                      validation.touched.phone && validation.errors.phone
                        ? 'phone_textInputError'
                        : ''
                    } `}
                  />
                  <span className=" text-xs text-rose-400">
                    {validation.touched.phone ? validation.errors.phone : ''}
                  </span>
                </div>
                <div className="sm:mt-[30px]">
                  <TextInput
                    label="Website"
                    placeholder=" "
                    id="website"
                    name="website"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.website || ''}
                    error={
                      validation.touched.website
                        ? validation.errors.website
                        : ''
                    }
                  />
                </div>
                <TextInput
                  label="City"
                  placeholder=" "
                  id="city"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.city || ''}
                  error={
                    validation.touched?.city ? validation.errors?.city : ''
                  }
                />
                <DropdownInput
                  options={countries?.map((c) => ({
                    ...c,
                    id: c.isoCode,
                  }))}
                  label="Country"
                  id="address.country"
                  name="name"
                  setSelected={(id) => {
                    validation.setFieldValue('country', id);
                  }}
                  selected={validation.values?.country}
                  error={
                    !validation.values?.country && validation.touched?.country
                      ? validation.errors?.country
                      : ''
                  }
                  onBlur={validation.handleBlur}
                  countries
                  required
                  // bottomOfTable
                />
                <TextArea
                  label="Practice Bio"
                  id="description"
                  name="description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ''}
                  error={
                    validation.touched.description
                      ? validation.errors.description
                      : ''
                  }
                  placeholder=" "
                  rows="4"
                  className="col-span-full"
                />
                <div className="col-span-full -mt-4">
                  <UploadFile
                    title="Logo"
                    onFilesAdded={(files) => {
                      setFiles((prevFiles) => [...prevFiles, ...files]);
                      if (files[0]) {
                        const filePreviewUrl = URL.createObjectURL(files[0]);
                        setCurrentImageForCrop(filePreviewUrl);
                        setCropModalOpen(true);
                        setImgName(files[0].name);
                      }
                    }}
                    name="logo"
                    id="logo"
                    imgPreview={imgPreview}
                    setImgPreview={setImgPreview}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
              </div>
              <div className="border-t border-[#D0D5DD] py-4 px-8 flex w-full justify-end items-center gap-4">
                <button
                  type="button"
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-white text-slate-600 border border-slate-600"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  className="h-11 w-[90px] flex justify-center items-center rounded-[5px] bg-indigo-500 text-white border border-indigo-500
            disabled:!bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader color="#FFFFFF" width="w-4" height="h-4" />
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default EditAccountantFirmModal;
