import React, { useEffect, useState } from "react";
import {
  Card,
  CardUpdated,
  CardDeleted,
  CollapsibleArrow,
  ThreeLines,
  MultiSelect,
  Expense,
  Transfer,
  Liabilities,
  Assets,
  Equity,
  Income,
  CloseBtn,
  Bullseye,
} from "../svg";
import CategoriesFilter from "../../transactions/Filters/CategoriesFilter";

const Trigger2 = ({
  panelStep,
  setPanelStep,
  triggerType,
  nodes,
  accounts,
  isEditing,
  setIsEditing,
  setActiveNodeId,
  setPanelOpen,
  createNewNode,
  updateNode,
}) => {
  const [localNode, setLocalNode] = useState(nodes[0]);
  const [accountsOpen, setAccountsOpen] = useState(true);
  const [accountTypesOpen, setAccountTypesOpen] = useState(true);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [transactionTypesOpen, setTransactionTypesOpen] = useState(true);
  const [optionalFiltersOpen, setOptionalFiltersOpen] = useState(true);
  const [selections, setSelections] = useState({
    accounts:
      localNode?.data?.filters?.find((filter) => filter.key === "accounts")
        ?.value.length > 0
        ? ["selectMultiple"]
        : ["all"],
    accountTypes: localNode?.data?.filters?.find(
      (filter) => filter.key === "accountType"
    )?.value || ["all"],
    transactionTypes: localNode?.data?.filters?.find(
      (filter) => filter.key === "type"
    )?.value || ["all"],
  });

  useEffect(() => {
    if (localNode?.data?.filters?.length > 0) {
      setOptionalFiltersOpen(true);
      setAccountsOpen(true);
      setAccountTypesOpen(true);
      setTransactionTypesOpen(true);
    }
  }, [localNode]);

  const [selectedAccount, setSelectedAccount] = useState(
    localNode?.data?.filters
      ?.find((filter) => filter.key === "accounts")
      ?.value.map(Number) || []
  );

  const toggleSelection = (category, option) => {
    const currentIndex = selections[category].indexOf(option);
    let newSelections = [...selections[category]];

    if (option === "all") {
      newSelections = currentIndex === -1 ? ["all"] : [];
    } else {
      if (currentIndex === -1) {
        newSelections = newSelections.filter((opt) => opt !== "all");
        newSelections.push(option);
      } else {
        newSelections.splice(currentIndex, 1);
      }
    }

    setSelections((prevSelections) => ({
      ...prevSelections,
      [category]: newSelections,
    }));
  };

  const isSelected = (category, option) => {
    return selections[category].includes(option);
  };

  const findTriggerIcon = () => {
    switch (triggerType) {
      case "created":
        return <Card />;
      case "updated":
        return <CardUpdated />;
      case "deleted":
        return <CardDeleted />;
      default:
        return <Bullseye />;
    }
  };

  const handleNext = () => {
    const filter = createFilterData();
    const data = {
      ...localNode?.data,
      filters: filter,
    };
    updateNode(localNode?.id, data);
    setPanelStep("component1");
  };

  const createFilterData = () => {
    const filters = [];

    if (selections.accountTypes && !selections.accountTypes.includes("all")) {
      filters.push({
        key: "accountType",
        value: selections.accountTypes,
      });
    }

    if (selections.accounts && selections.accounts.includes("selectMultiple")) {
      filters.push({
        key: "accounts",
        value: selectedAccount,
      });
    }

    if (
      selections.transactionTypes &&
      !selections.transactionTypes.includes("all")
    ) {
      filters.push({
        key: "type",
        value: selections.transactionTypes,
      });
    }
    return filters;
  };

  const handleFilterClick = () => {
    if (optionalFiltersOpen) {
      setOptionalFiltersOpen(false);
      setAccountsOpen(false);
      setAccountTypesOpen(false);
      setTransactionTypesOpen(false);
    } else if (!optionalFiltersOpen) {
      setOptionalFiltersOpen(true);
      setAccountsOpen(true);
      setAccountTypesOpen(true);
      setTransactionTypesOpen(true);
    }
  };

  return (
    <>
      <div className="flex flex-col items-start gap-2.5 self-stretch">
        <div className="flex items-start gap-2.5 self-stretch">
          <div className="w-11 h-11 flex items-center justify-center rounded-[10px] px-2.5 py-[0.938rem] bg-[#EFFFE5]">
            {findTriggerIcon()}
          </div>
          <div className="w-full flex justify-between">
            <div className="flex flex-col items-start justify-center -mt-1">
              <p className="text-black font-medium text-[1.375rem]">
                {localNode?.data?.name}
              </p>
              <p className="text-slate-600 text-[0.875rem]">
                {localNode?.data?.description}
              </p>
            </div>
            <CloseBtn
              onClick={() => setPanelOpen(false)}
              className={"mt-[0.800rem]"}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-2.5 self-stretch overflow-x-auto scrollbar pr-[6px]">
        <div
          className="flex justify-end items-center gap-2.5 self-stretch"
          onClick={(e) => {
            e.stopPropagation();
            handleFilterClick();
          }}
        >
          <div className="px-2.5 cursor-pointer">
            <CollapsibleArrow
              className={`${optionalFiltersOpen ? "" : "rotate-[270deg]"}`}
            />
          </div>
          <p className="text-black font-medium text-[1rem] leading-[2.375rem] whitespace-nowrap cursor-pointer">
            Optional Filters
          </p>
          <div className="w-full h-px bg-black bg-opacity-10"></div>
        </div>
        {optionalFiltersOpen && (
          <>
            <div className="flex flex-col items-start gap-2.5 self-stretch">
              <div className="flex flex-col items-start self-stretch">
                <div className="flex justify-end items-center gap-2.5 self-stretch">
                  <p className="text-[14px] leading-[2.375rem] text-black">
                    Accounts
                  </p>
                  <div className="w-full h-px bg-black bg-opacity-10" />
                </div>
                <div
                  className={`w-full overflow-hidden flex flex-col gap-1 ${
                    isSelected("accounts", "selectMultiple") &&
                    accountsOpen &&
                    accountDropdownOpen
                      ? "h-[45dvh]"
                      : accountsOpen
                      ? "scale-y-100 h-auto"
                      : "scale-y-0 h-4"
                  }`}
                >
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accounts", "all")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("accounts", "all")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accounts", "all") ? "bg-white" : ""
                        }`}
                      >
                        <ThreeLines
                          stroke={
                            isSelected("accounts", "all")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        All
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10  ${
                        isSelected("accounts", "selectMultiple")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("accounts", "selectMultiple")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accounts", "selectMultiple")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <MultiSelect
                          stroke={
                            isSelected("accounts", "selectMultiple")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Select Multiple
                      </p>
                    </div>
                  </div>
                  {isSelected("accounts", "selectMultiple") && (
                    <CategoriesFilter
                      allCategories={accounts}
                      selectedCategoryId={selectedAccount}
                      setSelectedCategoryId={setSelectedAccount}
                      getTransactionsApi={() => {}}
                      apiFilter={() => {}}
                      type="account"
                      isSetCategoryStyle
                      dropdownOpen={accountDropdownOpen}
                      setDropdownOpen={setAccountDropdownOpen}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2.5 self-stretch">
              <div className="flex flex-col items-start self-stretch">
                <div className="flex justify-end items-center gap-2.5 self-stretch">
                  <p className="text-[14px] leading-[2.375rem] text-black whitespace-nowrap">
                    Account Type
                  </p>
                  <div className="w-full h-px bg-black bg-opacity-10" />
                </div>
                <div
                  className={`w-full overflow-hidden flex flex-col gap-1 ${
                    accountTypesOpen ? "scale-y-100 h-auto" : "scale-y-0 h-4"
                  }`}
                >
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "all")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("accountTypes", "all")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "all") ? "bg-white" : ""
                        }`}
                      >
                        <ThreeLines
                          stroke={
                            isSelected("accountTypes", "all")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        All
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "Assets")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("accountTypes", "Assets")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "Assets") ? "bg-white" : ""
                        }`}
                      >
                        <Assets
                          stroke={
                            isSelected("accountTypes", "Assets")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Assets
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "Liabilities")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("accountTypes", "Liabilities")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "Liabilities")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <Liabilities
                          stroke={
                            isSelected("accountTypes", "Liabilities")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Liabilities
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "Equity")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("accountTypes", "Equity")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "Equity") ? "bg-white" : ""
                        }`}
                      >
                        <Equity
                          stroke={
                            isSelected("accountTypes", "Equity")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Equity
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "Income")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("accountTypes", "Income")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "Income") ? "bg-white" : ""
                        }`}
                      >
                        <Income
                          stroke={
                            isSelected("accountTypes", "Income")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Income
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("accountTypes", "Expenses")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("accountTypes", "Expenses")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("accountTypes", "Expenses")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <Expense
                          stroke={
                            isSelected("accountTypes", "Expenses")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Expenses
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2.5 self-stretch">
              <div className="flex flex-col items-start self-stretch">
                <div className="flex justify-end items-center gap-2.5 self-stretch">
                  <p className="text-[14px] leading-[2.375rem] text-black whitespace-nowrap">
                    Transaction Types
                  </p>
                  <div className="w-full h-px bg-black bg-opacity-10" />
                </div>
                <div
                  className={`w-full overflow-hidden flex flex-col gap-1 ${
                    transactionTypesOpen
                      ? "scale-y-100 h-auto"
                      : "scale-y-0 h-4"
                  } `}
                >
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("transactionTypes", "all")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() => toggleSelection("transactionTypes", "all")}
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("transactionTypes", "all")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <ThreeLines
                          stroke={
                            isSelected("transactionTypes", "all")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        All
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("transactionTypes", "Expense")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("transactionTypes", "Expense")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("transactionTypes", "Expense")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <Expense
                          stroke={
                            isSelected("transactionTypes", "Expense")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Expense
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex items-center self-stretch justify-between">
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("transactionTypes", "Income")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("transactionTypes", "Income")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("transactionTypes", "Income")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <Income
                          stroke={
                            isSelected("transactionTypes", "Income")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Income
                      </p>
                    </div>
                    <div
                      className={`w-[45%] p-2.5 gap-2 flex rounded-[5px] cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-10 ${
                        isSelected("transactionTypes", "Transfer")
                          ? "bg-[#a0cd85] bg-opacity-20"
                          : ""
                      }`}
                      onClick={() =>
                        toggleSelection("transactionTypes", "Transfer")
                      }
                    >
                      <div
                        className={`w-9 h-9 rounded-[5px] bg-[#D9D9D9] flex items-center justify-center ${
                          isSelected("transactionTypes", "Transfer")
                            ? "bg-white"
                            : ""
                        }`}
                      >
                        <Transfer
                          stroke={
                            isSelected("transactionTypes", "Transfer")
                              ? "#78BD4F"
                              : "#667085"
                          }
                        />
                      </div>
                      <p className="text-slate-600 text-[14px] leading-[2.375rem]">
                        Transfer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex items-start gap-5 self-stretch">
        {/* 
        <button
          className="w-1/2 py-2.5 px-[1.125rem] flex items-center justify-center gap-2 rounded-[0.313rem] shadow bg-white text-indigo-500 border border-indigo-500 leading-6"
          onClick={() => setPanelStep("trigger1")}
        >
          Back
        </button>*/}
        <button
          className="w-1/3 py-[5px] flex items-center justify-center gap-2 rounded-[0.313rem] shadow text-white bg-indigo-500 leading-6"
          disabled={false}
          onClick={() => handleNext()}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Trigger2;
