import React from 'react';

const CategoryBox = ({
  category,
  selectedCategory,
  setSelectedCategory,
  selectedTaxes,
  setSelectedTaxes,
  onCategorySelect,
  onSelectTaxes,
}) => (
  <div
    onClick={() => onCategorySelect(category?.id)}
    className={`border ${selectedCategory === category?.id ? 'border-indigo-500 border-2' : 'border-[#D0D5DD]'} rounded-[13px] py-2 px-3 cursor-pointer`}
    key={category?.id}
    id={category?.id}
    role="button"
    tabIndex="0"
  >
    <h3 className="text-[14px] text-[#101828] mb-1">
      {category?.type} | {category?.name}
    </h3>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Code</p>
      <p>{category?.accountNumber || '--'}</p>
    </div>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Sub-Type</p>
      <p>{category?.subType?.name}</p>
    </div>
    <div className="text-xs text-[#667085] flex gap-4 mb-1">
      <p className="w-24 min-w-24 sm:min-w-40 sm:w-40">Description</p>
      <p>{category?.description}</p>
    </div>
    {category?.taxes?.length > 0 && (
      <h3 className="text-[18px] text-[#101828] mb-2 mt-1">
        Apply Tax(es) to transaction
      </h3>
    )}
    {category?.taxes?.map((tax) => (
      <label
        key={tax?.id}
        htmlFor={`${category?.id}${tax?.id}`}
        className="w-fit text-sm text-[#667085] flex items-center gap-4 cursor-pointer mb-1.5"
      >
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5"
          id={`${category?.id}${tax?.id}`}
          checked={
            selectedCategory == category?.id && selectedTaxes?.includes(tax?.id)
          }
          onChange={(e) => {
            e.stopPropagation();
            onSelectTaxes(tax?.id, category?.id);
          }}
        />

        <p>
          {tax?.name}({+tax?.percentage}%)
        </p>
      </label>
    ))}
  </div>
);

export default CategoryBox;
