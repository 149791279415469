import React, { useEffect, useRef, useState } from 'react';
import Tooltip from 'components/Tooltip';
import { getCurrencySymbolFromIso, TEAM_CURRENCY, trimText } from 'utils/Utils';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Svg';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import AkahuLink from 'pages/settings/AkhahuLink/AkahuLink';
import { createPlaidToken } from 'API/backend_helper';
import PlaidLink from 'pages/settings/PlaidLink';
import { useNavigate } from 'react-router-dom';

const Institution = ({ institution }) => (
  <div className="flex items-center">
    <Tooltip
      content={institution?.name}
      contentClassName="  text-sm text-[#667085] relative tooltipArrowDown"
      isFixed
      ids={['insightsOverviewAccountsTable', 'insightsPage']}
    >
      <div
        className={`w-10 h-10  shrink-0 rounded-full  mr-3
      ${
        institution?.color && !institution?.logoUrl
          ? 'bg-gradient-to-tr from-slate-500 to-slate-300'
          : ''
      }
      `}
        style={
          institution?.color && !institution?.logoUrl
            ? { background: institution?.color }
            : {}
        }
      >
        {institution?.logoUrl ? (
          <img
            src={`${institution?.logoUrl}`}
            alt="logo"
            className="w-10 h-10 fill-current text-white"
          />
        ) : (
          <div className="flex items-center justify-center w-10 h-10">
            <div
              style={{ textTransform: 'uppercase' }}
              className="text-white font-bold text-lg "
            >
              {institution?.name?.charAt?.(0)}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
    <h3 className="text-lg font-medium text-[#101828CC] ">
      {institution?.name}
    </h3>
  </div>
);

const ConnectionBox = ({
  overviewLoadings,
  connection,
  accountLength,
  getAccountSystemBal,
}) => {
  const navigate = useNavigate();
  const { currencies } = useSelector((state) => state.Currencies);
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [showAllAccounts, setShowAllAccounts] = useState(true);

  return (
    <>
      <tr className="!border-slate-500">
        <td colSpan={3} className="">
          <div className="flex justify-between items-center bg-[#F4F4F5] py-3  px-5">
            {connection?.institution?.id ? (
              <Institution institution={connection?.institution} />
            ) : connection?.institutions?.length > 0 ? (
              <div className="flex-col">
                {connection?.institutions?.map((inst, index) => (
                  <Institution key={index} institution={inst} />
                ))}
              </div>
            ) : null}
            <svg
              className={` h-4 cursor-pointer w-4 shrink-0 ml-1 stroke-[#667085cc]
                ${!showAllAccounts ? 'rotate-180' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowAllAccounts((prev) => !prev)}
            >
              <path
                d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                className="stroke-current"
                strokeOpacity="0.8"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </td>
      </tr>
      {showAllAccounts &&
        connection?.accounts?.map((d, i) => (
          <tr
            key={d?.id}
            onClick={() =>
              navigate(
                `/reports/account-transaction?accountId=${d?.id}&from=dashboard`,
              )
            }
            className="cursor-pointer hover:bg-[#a0cd85] hover:bg-opacity-5"
          >
            <td className="py-3 pl-5 pr-2" id={`accountCellId${i}`}>
              <div className="flex gap-2.5">
                <div>
                  <Tooltip
                    tooltipShow={d?.name?.length > accountLength}
                    content={d?.name}
                    contentClassName="border-none rounded-[10px] translate-y-1 overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                    isFixed
                    ids={['insightsOverviewAccountsTable', 'insightsPage']}
                  >
                    <h4 className="text-[18px] my-0 leading-normal text-[#667085]">
                      {trimText(d?.name, accountLength)}
                    </h4>
                  </Tooltip>
                  <p className="text-xs text-[#66708580]">**** {d?.mask}</p>
                </div>
              </div>
            </td>
            <td className="py-3 px-2 min-w-[8rem] ">
              <div>
                <p className="text-xs text-[#66708580] text-right">
                  Balance in COUNT
                </p>
                <div className="text-[18px] text-[#667085] font-medium text-right">
                  {overviewLoadings?.countBalances ? (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 ml-auto mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  ) : getAccountSystemBal(d?.id) !== null &&
                    getAccountSystemBal(d?.id) !== undefined ? (
                    <span>
                      {getAccountSystemBal(d?.id)?.toString().charAt(0) === '-'
                        ? '-'
                        : ''}
                      {getCurrencySymbolFromIso(
                        d?.currency || teamDefaultCurrency,
                        currencies,
                      )}
                      {parseFloat(
                        getAccountSystemBal(d?.id)
                          ?.toString()
                          .replace('-', '') || 0,
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            </td>
            <td className="py-3 pl-2 pr-5  min-w-[11rem]">
              <div>
                <p className="text-xs text-right text-[#66708580]">
                  Statement Balance
                </p>
                <div className="text-[18px] text-[#667085] font-medium text-right">
                  {d?.providerBalances?.[
                    d?.providerBalances?.length - 1
                  ]?.balanceCurrent
                    ?.toString()
                    .charAt(0) === '-'
                    ? '-'
                    : ''}
                  {getCurrencySymbolFromIso(
                    d?.currency || teamDefaultCurrency,
                    currencies,
                  )}
                  {parseFloat(
                    d?.providerBalances?.[
                      d?.providerBalances?.length - 1
                    ]?.balanceCurrent
                      ?.toString()
                      .replace('-', '') || 0,
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || '--'}
                </div>
              </div>
            </td>
          </tr>
        ))}
    </>
  );
};

const AkahuLinkButton = () => (
  <button
    className="h-[38px]  px-[18px] bg-indigo-500 text-white inline-flex justify-center items-center gap-2 text-base rounded-[5px] "
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M10.4998 4.16663V15.8333M4.6665 9.99996H16.3332"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    Add
  </button>
);

const AccountsTable = ({
  overviewLoadings,
  connectedAccounts,
  getConnectionsApi,
  accountsCountBalances,
}) => {
  const PlaidButtonRef = useRef();

  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const { accessType, permissions } = useSelector((state) => state.User);

  const [accountLength, setAccountLength] = useState(20);
  const [token, setToken] = useState(null);
  const [tokenLoading, setTokenLoading] = useState(false);

  const handleResize = () => {
    const accountCell = document.getElementById('accountCellId0');
    if (accountCell) {
      setAccountLength(parseInt((accountCell?.clientWidth - 20) / 10));
    }
  };

  const onBankClick = async () => {
    setTokenLoading(true);
    try {
      const plaidRes = await createPlaidToken(null, {}, '');
      const { linkToken } = plaidRes;
      setToken(linkToken);
      setTimeout(() => {
        PlaidButtonRef.current.click();
        setTokenLoading(false);
      }, 1000);
    } catch (e) {
      console.log('error', e);
      setTokenLoading(false);
    }
  };

  const getAccountSystemBal = (id) => {
    if (accountsCountBalances?.length > 0) {
      const tempAcc = accountsCountBalances?.find?.(
        (acc) => acc?.accountId === id,
      );
      return tempAcc?.systemBalance || 0;
    }
    return null;
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [connectedAccounts]);

  return (
    <div className="flex flex-col p-6 rounded-[12px] border border-[#D0D5DD] ">
      <div className="flex justify-between items-center">
        <h3 className="text-[22px] text-[#666] font-medium">
          Connected Accounts
        </h3>
        {checkPermission(
          PermissionKeys.Manage_Bank_Account,
          accessType,
          permissions,
        ) &&
          (team?.country === 'NZ' ? (
            <AkahuLink ButtonUI={AkahuLinkButton} />
          ) : (
            <Tooltip
              content={`Live bank feeds for ${team?.country || ''} is currently not supported. Manually import transactions instead.`}
              contentClassName="border-none w-[250px] text-wrap overflow-visible text-sm text-[#667085] relative"
              position="left"
              size="sm"
              tooltipShow={
                teamDefaultCurrency !== 'USD' && teamDefaultCurrency !== 'CAD'
              }
            >
              <button
                className="h-[38px] min-w-[94px]  px-[18px] bg-indigo-500 text-white inline-flex justify-center items-center gap-2 text-base rounded-[5px] disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="button"
                onClick={() => {
                  // setInstitutionModalOpen(true);
                  onBankClick();
                }}
                disabled={
                  tokenLoading ||
                  (teamDefaultCurrency !== 'USD' &&
                    teamDefaultCurrency !== 'CAD')
                }
              >
                {tokenLoading ? (
                  <Loader height="h-4 w-4" color="#ffffff" />
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M10.4998 4.16663V15.8333M4.6665 9.99996H16.3332"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add
                  </>
                )}
              </button>
            </Tooltip>
          ))}
      </div>

      {overviewLoadings?.connectedAccounts ? (
        <div className="flex flex-col h-[330px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : connectedAccounts?.length > 0 ? (
        <div
          className="overflow-auto scrollbar h-[330px] mt-4"
          id="insightsOverviewAccountsTable"
        >
          <table className="w-full table-auto">
            <tbody className="divide-y divide-[#D0D5DD]">
              {connectedAccounts?.map((connection) => (
                <ConnectionBox
                  connection={connection}
                  key={connection?.id}
                  accountLength={accountLength}
                  getAccountSystemBal={getAccountSystemBal}
                  overviewLoadings={overviewLoadings}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex-1 mt-8 flex flex-col justify-center items-center">
          <h6 className="text-center">No Connections Found </h6>
          {/* No Connection Found SVG  */}
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 800 600"
            height="200"
            className="max-w-full"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="635.729"
                y1="405.426"
                x2="463.547"
                y2="233.244"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#ff5607" />
                <stop offset="1" stopColor="#ffa700" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-2"
                x1="569.245"
                y1="415.788"
                x2="569.245"
                y2="253.08"
                xlinkHref="#linear-gradient"
              />
            </defs>
            <g id="no_connection" data-name="no connection">
              <circle cx="425.839" cy="335.548" r="169.657" fill="#fcfbd9" />
              <circle cx="293.215" cy="368.329" r="67.863" fill="#f16091" />
              <line
                x1="316.966"
                y1="362.937"
                x2="302.273"
                y2="354.454"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <line
                x1="305.378"
                y1="366.042"
                x2="313.861"
                y2="351.349"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <line
                x1="278.426"
                y1="373.264"
                x2="263.733"
                y2="364.781"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <line
                x1="266.838"
                y1="376.369"
                x2="275.321"
                y2="361.676"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <path
                d="M313.572,396.621a33.555,33.555,0,0,0-27.049,6.862"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <path
                d="M169.462,326.614A82.04,82.04,0,0,1,251.5,244.575"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <path
                d="M210.481,326.614A41.02,41.02,0,0,1,251.5,285.6"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="3.393"
              />
              <path
                d="M506.8,363.916c-6.7,2.1-27.5,8.923-39.557,26.536-2.015,2.944-6.724,9.975-4.3,13.769,2.972,4.655,15.079,2.048,19.815.7,15.227-4.344,60.819-25.073,121.164-55.852C582.517,349.76,543.592,352.4,506.8,363.916Z"
                fill="#081f2d"
                stroke="#081f2d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.036"
              />
              <path
                d="M614.671,400.2,502.837,410.8a223.433,223.433,0,0,0-31.882-57.433c-32.626-42.272-89.049-82.541-86.832-95.964a7.956,7.956,0,0,1,2.411-4.77c4.078-3.28,11.951.106,16.6,2.305,9.433,4.451,21.207,11.331,31.421,17.625-44.259-27.892-64.668-38.052-63.32-50.819a9.625,9.625,0,0,1,2.234-5.727c9.469-10.32,47.131,8.724,78.747,27.573-27.467-17.431-69.225-46.67-67.381-63.161.159-1.578.691-6.259,4.131-8.883,8.831-6.685,28.141,6.631,30.038,7.979-14.044-11.561-22.714-21.065-21.314-30.8.178-1.224.816-5.2,3.919-7.731,5.532-4.522,15.835-1.986,29.807,4.912,32.095,15.87,83.783,55,98.731,66.44A32.714,32.714,0,0,1,525,198.656c-1.188-8.618.053-22.077,6.667-24.86,11.118-4.664,33.779,22.519,45.713,41.279a177.917,177.917,0,0,1,25.782,71.406c4.255,34.1-4.149,49.223,4.592,89.1A219.274,219.274,0,0,0,614.671,400.2Z"
                fill="url(#linear-gradient)"
              />
              <path
                d="M409.065,256.7c7.386,4.594,15.876,9.816,25.491,15.875,9.649,6.079,17.291,11.022,21.447,13.724"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M426.151,226.07c17.409,12.522,35.606,23.549,44.228,28.679"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M408.008,169.687a196.507,196.507,0,0,0,17.929,15.4,615.605,615.605,0,0,0,53.044,37.238"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <rect
                x="408.11"
                y="94.795"
                width="132.988"
                height="230.513"
                rx="17.732"
                fill="#f1e302"
              />
              <line
                x1="457.639"
                y1="106.331"
                x2="491.57"
                y2="106.331"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <line
                x1="449.495"
                y1="311.735"
                x2="499.713"
                y2="311.735"
                fill="none"
                stroke="#fff"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M525.054,333.9c-.78-4.575-6.578-41.6,16-60.04a44.3,44.3,0,0,1,11.1-6.437c.478-16.153-3.369-27.324-7.607-35.02-7.448-13.547-17.466-18.831-19.541-33.743-1.188-8.618.053-22.077,6.667-24.86,11.118-4.664,33.779,22.519,45.713,41.279a177.917,177.917,0,0,1,25.782,71.406c4.256,34.1-4.132,49.223,4.593,89.1a222.819,222.819,0,0,0,6.915,24.612"
                fill="url(#linear-gradient-2)"
              />
              <path
                d="M552.149,267.419c0-20.2-3.774-28.059-7.607-35.02-7.448-13.547-17.466-18.831-19.541-33.743"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M525.054,333.9c-.78-4.575-6.578-41.6,15.994-60.04a44.977,44.977,0,0,1,17.091-8.371"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M630.538,446.651,476.717,497.444c-3.7-31.794-7.665-53.717-9.989-68.246q-2.108-13.2-4.321-26.21c.02.06.081.242.158.429a3.2,3.2,0,0,0,.189.455,2.108,2.108,0,0,0,.2.349c2.959,4.648,15.063,2.049,19.807.7,15.224-4.34,60.813-25.065,121.164-55.843Z"
                fill="#b29adc"
              />
              <path
                d="M466.338,426.759h0a56.064,56.064,0,0,0,36.974-2.155l108.841-45.359"
                fill="none"
                stroke="#081f2d"
                strokeMiterlimit="10"
                strokeWidth="2.036"
              />
              <path
                d="M467.246,390.452c-2.015,2.944-6.724,9.975-4.3,13.769,2.972,4.655,15.079,2.048,19.815.7,15.227-4.344,60.819-25.073,121.164-55.852"
                fill="none"
                stroke="#081f2d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.036"
              />
              <rect width="800" height="600" fill="none" />
            </g>
          </svg>
        </div>
      )}
      <PlaidLink
        loadData={getConnectionsApi}
        setIsLoading={() => {}}
        token={token}
        team={team}
        accountPage
        buttonRef={PlaidButtonRef}
        cb={() => {}}
        shouldNavigate
        successCBBeforeChooseAccounts={false}
      />
    </div>
  );
};

export default AccountsTable;
