import React from 'react';
import InfoBoxIcon from './InfoBoxIcon';

const InfoBox = () => (
  <div className="w-full h-fit bg-white shadow-md rounded-[12px] p-6">
    <InfoBoxIcon className="max-w-full mx-auto mb-8" />
    <h3 className="text-base font-medium text-indigo-500 mb-2">Start Fresh</h3>
    <p className="text-[#667085] mb-6">
      Build your accounting system from scratch, adding accounts and
      transactions as needed.
    </p>
    <h3 className="text-base font-medium text-indigo-500 mb-2">Migrate Data</h3>
    <p className="text-[#667085] mb-6">
      Seamlessly import data from QuickBooks, Xero, or other systems without
      losing any history.
    </p>
    <h3 className="text-base font-medium text-[#101828] mb-2">
      Need Assistance?
    </h3>
    <p className="text-[#667085] mb-6">
      If you have questions, check out our resources or get in touch.
    </p>
    <button
      type="button"
      className="hidden text-sm font-medium text-indigo-500"
    >
      Read Getting Started Guide
    </button>
  </div>
);

export default InfoBox;
