import Tooltip from 'components/Tooltip';
import React from 'react';
import { getStringSegments, trimText } from 'utils/Utils';

const Breadcrumb = ({ parentFolder, handleFolderChange }) => {
  const onFolderClick = (index) => {
    const subFolders = parentFolder?.split('/');
    let parent = '';
    subFolders?.forEach((f, i) => {
      if (i <= index) {
        if (parent) {
          parent = `${parent}/${f}`;
        } else {
          parent = `${f}`;
        }
      }
    });
    handleFolderChange(parent);
  };

  return (
    <div className="flex gap-3 items-center mb-4 text-[18px] ">
      <button type="button" onClick={() => handleFolderChange('')}>
        My Documents
      </button>
      {parentFolder
        ? parentFolder?.split('/')?.map(
            (subFolder, index) =>
              (parentFolder?.split('/')?.length < 5 ||
                index > parentFolder?.split('/')?.length - 4 ||
                index === 0) && (
                <>
                  <svg
                    className="shrink-0 h-4 w-4 stroke-[#667085cc] -rotate-90"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                      className="stroke-current"
                      strokeOpacity="0.8"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {parentFolder?.split('/')?.length > 5 && index === 0 ? (
                    '...'
                  ) : (
                    <Tooltip
                      content={
                        subFolder?.length > 30
                          ? (() => {
                              const segments = getStringSegments(subFolder, 30);
                              return segments.map((segment, index) => (
                                <p key={index}>{segment}</p>
                              ));
                            })()
                          : subFolder
                      }
                      contentClassName={`border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown ${
                        subFolder?.length > 50 ? '' : ''
                      }`}
                      tooltipShow={subFolder?.length > 20}
                      position="top"
                    >
                      <button
                        type="button"
                        onClick={() => onFolderClick(index)}
                      >
                        {subFolder?.length > 20
                          ? trimText(subFolder, 20)
                          : subFolder}
                      </button>
                    </Tooltip>
                  )}
                </>
              ),
          )
        : null}
    </div>
  );
};

export default Breadcrumb;
