import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import PeopleForm from './PeopleForm';
import {
  getPeople,
  createPeople,
  updatePeople,
  deletePeople,
} from '../../API/backend_helper';
import PaginationClassic from '../../components/PaginationClassic';
import SearchForm from '../../partials/actions/SearchForm';
import NoPeopleImage from '../../images/custom/empty-search.svg';

import DeletePeopleModal from './DeletePeopleModal';
import { PEOPLE_TYPES } from '../../utils/Utils';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import AppLayout from '../../components/AppLayout';
import GustoIcon from '../../images/gusto-icon.png';
import Tooltip from '../../components/Tooltip';
import { Loader } from '../../components/Svg';
import SendEmailModal from './SendEmailModal';

let searchTimeout = null;
const ROLES = [
  {
    value: 'sales rep',
    label: 'Sales Representative',
  },
  {
    value: 'expense reporting',
    label: 'Expense Reporting',
  },
  {
    value: 'time tracking',
    label: 'Time Tracking',
  },
];
const People = () => {
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const [params] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [activeEmailPerson, setActiveEmailPerson] = useState(null);

  const [_people, setPeople] = useState([]);
  const [activePeople, setActivePeople] = useState(null);
  const [displayedPeople, setDisplayedPeople] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [modalOpen, setModalOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('People');
  const [activeTab, setActiveTab] = useState('all');
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [activePeopleLoading, setActivePeopleLoading] = useState({});

  const [errMessage, setErrMessage] = useState('');

  document.title = 'COUNT | People';

  const createPersonParam = params.get('addPerson') || false;

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_PEOPLE, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  useEffect(() => {
    if (createPersonParam === 'true') {
      setFormOpen(true);
    }
  }, [createPersonParam]);

  const getData = async ({
    limit = localLimit,
    page = _people?.page,
    search = searchVal || '',
    loading = false,
    type = activeTab || '',
    roles = selectedRoles,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getPeople({
        page,
        search,
        limit,
        type: type === 'all' ? '' : type,
        roles,
      });
      setPeople(res);
      setDisplayedPeople(res);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const handleAddPeople = async (data) => {
    try {
      const response = await createPeople(data);
      if (response?.data?.people?.role?.length > 0) {
        setActiveEmailPerson(response?.data?.people);
        setSendEmailModalOpen(true);
      }
      if (activeTab === 'all' || activeTab === data?.type) {
        getData({});
      } else {
        if (data?.type) {
          setActiveTab(data.type);
        }
        getData({ type: data?.type });
      }
      return response;
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handleUpdatePeople = async (data, id) => {
    try {
      const temp = { ...data };
      if (!temp?.phone) {
        delete temp?.phone;
      }
      const res = await updatePeople(temp, id);
      if (
        res?.data?.updatedPeople?.people?.isTemporaryPassword &&
        !res?.data?.updatedPeople?.people?.isEmailSent &&
        res?.data?.updatedPeople?.people?.role?.length > 0 &&
        res?.data?.updatedPeople?.people?.enabled
      ) {
        setActiveEmailPerson(res?.data?.updatedPeople?.people);
        setSendEmailModalOpen(true);
      }
      if (activeTab === 'all' || activeTab === data?.type) {
        getData({});
      } else {
        if (data?.type) {
          setActiveTab(data?.type);
        }
        getData({ type: data?.type });
      }
      setActivePeople(null);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handlePersonStatusUpdate = async (data, id) => {
    setActivePeopleLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await handleUpdatePeople(data, id);
      toast.success(
        `Person ${data?.enabled ? 'activated' : 'deactivated'} successfully.`,
      );
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setActivePeopleLoading((prev) => {
        delete prev[id];
        return prev;
      });
    }
  };

  const handleDeletePeople = async (people) => {
    setDeleteLoader(true);
    try {
      await deletePeople(people?.id);
      toast.success('Person deleted successfully.');
      getData({});
      setActivePeople(null);
      setDeleteLoader(false);
      setModalOpen(false);
    } catch (err) {
      setDeleteLoader(false);
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
      } else {
        setModalOpen(false);
      }
    }
  };

  useEffect(() => {
    getData({ loading: true, type: '' });
  }, []);

  const onChangeTab = (name) => {
    setActiveTab(name);
    if (name === 'all') {
      getData({ loading: true, type: '' });
    } else {
      getData({ loading: true, type: name });
    }
  };

  const onSearch = (value) => {
    setSearchVal(value);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({ search: encodeURIComponent(value || '') });
    }, 900);
  };

  const { page, totalRecords, people, filters } = _people || {};

  return (
    <>
      <DeletePeopleModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        errMessage={errMessage}
        activePeople={activePeople}
        handleDeletePeople={handleDeletePeople}
        loader={deleteLoader}
      />
      <SendEmailModal
        isOpen={sendEmailModalOpen}
        setIsOpen={setSendEmailModalOpen}
        activeEmailPerson={activeEmailPerson}
        getData={getData}
      />
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex justify-between mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {!formOpen ? (
                <div className="sm:flex flex-row gap-4 items-center">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchVal}
                    setSearchVal={onSearch}
                    transaction
                  />
                  <BasicCheckboxDropdown
                    selectedOptions={selectedRoles}
                    setSelectedOptions={setSelectedRoles}
                    apiFunc={(obj) => getData({ ...obj, loading: true })}
                    options={ROLES}
                    onClear={() => {
                      getData({ roles: [], page: 1, loading: true });
                      setSelectedRoles([]);
                    }}
                    label="Role"
                    type="roles"
                    apiFilter={filters?.roles}
                  />
                  {checkPermission(
                    PermissionKeys.MANAGE_PEOPLE,
                    accessType,
                    permissions,
                  ) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setActivePeople(null);
                        setTitle('Add Person');
                        setFormOpen(true);
                      }}
                      type="button"
                      className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setFormOpen(true)}
                      >
                        <path
                          d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add Person
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActivePeople(null);
                    setTitle('People');
                    setFormOpen(false);
                  }}
                  className=""
                  type="button"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {formOpen && (
              <PeopleForm
                setActivePeople={setActivePeople}
                activePeople={activePeople}
                handleUpdatePeople={handleUpdatePeople}
                handleAddPeople={handleAddPeople}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
                team={team}
              />
            )}
            {!formOpen && (
              <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-10 flex-wrap">
                <div
                  onClick={() => onChangeTab('all')}
                  className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                        ${
                          activeTab === 'all' &&
                          ' !border-indigo-500  font-medium !text-[#101828]'
                        } `}
                  role="button"
                  tabIndex="0"
                >
                  All People
                </div>
                {PEOPLE_TYPES?.map((type) => (
                  <div
                    key={type}
                    onClick={() => onChangeTab(type?.id)}
                    className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                          ${
                            activeTab === type?.id &&
                            ' !border-indigo-500  font-medium !text-[#101828]'
                          } `}
                    role="button"
                    tabIndex="0"
                  >
                    {type?.name}s
                  </div>
                ))}
              </div>
            )}
            {!formOpen && isLoading && (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            )}
            {people?.length > 0 && !isLoading
              ? !formOpen && (
                  <>
                    <div className="bg-white flex-1">
                      {/* Table */}
                      <div className="overflow-x-auto h-full scrollbar">
                        <table className="table-fixed w-full dark:text-slate-300">
                          {/* Table header */}
                          <thead className="w-full h-16 shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                            <tr className="w-full py-[1.438rem] px-[1.375rem] ">
                              <th className="w-[14rem] min-w-[14rem] 3xl:w-[16rem] 3xl:min-w-[16rem] pl-4">
                                <div className="text-left font-medium">
                                  Name
                                </div>
                              </th>
                              <th className=" w-[14rem] min-w-[14rem] 3xl:w-[16rem] 3xl:min-w-[16rem]">
                                <div className="text-left  font-medium">
                                  Email
                                </div>
                              </th>
                              <th className="w-[9rem] max-w-[9rem] 3xl:w-[13.5rem] 3xl:max-w-[13.5rem]">
                                <div className="text-left  font-medium">
                                  Position
                                </div>
                              </th>
                              <th className="w-[8.75rem] max-w-[8.75rem]">
                                <div className="text-left font-medium">
                                  Type
                                </div>
                              </th>
                              <th className=" w-[10rem] max-w-[10rem]">
                                <div className="text-left font-medium">
                                  Role
                                </div>
                              </th>
                              <th className="w-[4rem] max-w-[4rem]">
                                <div className="text-left w-[5%] font-medium">
                                  Active
                                </div>
                              </th>
                              {checkPermission(
                                PermissionKeys.MANAGE_PEOPLE,
                                accessType,
                                permissions,
                              ) && (
                                <th className="w-[8rem] max-w-[8rem]">
                                  <div className="text-left font-medium">
                                    Actions
                                  </div>
                                </th>
                              )}
                            </tr>
                          </thead>
                          {/* Table body */}
                          {people?.length > 0 && (
                            <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                              {displayedPeople?.people?.map(
                                (peopleMap, index) => (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      navigate(`/people/${peopleMap?.id}`)
                                    }
                                    className="w-full pl-[1.375rem] pr-[1.125rem] h-20 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer"
                                  >
                                    <td className="px-4">
                                      <div className="flex items-center w-full">
                                        {peopleMap?.provider === 'gusto' && (
                                          <img
                                            src={GustoIcon}
                                            className="h-5 mr-2"
                                            alt="gusto"
                                          />
                                        )}
                                        <div className="text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                                          {`${peopleMap?.firstName} ${
                                            peopleMap?.lastName
                                          }`}{' '}
                                          {!peopleMap?.firstName &&
                                            !peopleMap?.lastName &&
                                            '---'}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="pr-4">
                                      <div className="text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                                        {peopleMap?.email
                                          ? peopleMap?.email
                                          : '---'}
                                      </div>
                                    </td>
                                    <td className="">
                                      <div className="text-left">
                                        <p className="text-slate-600 text-[0.938rem] leading-6">
                                          {peopleMap?.position || '---'}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap">
                                      <div className="text-left w-[8.75rem]">
                                        <p className="text-slate-600 text-[0.938rem] leading-6">
                                          {peopleMap?.type
                                            ? peopleMap?.type
                                            : '---'}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="whitespace-break-spaces">
                                      <div className="text-left ">
                                        <p className="text-slate-600 text-[0.938rem] leading-6 first:capitalize">
                                          {peopleMap?.role?.map((r) => (
                                            <div key={r}>{r}</div>
                                          ))}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap">
                                      {activePeopleLoading?.[peopleMap?.id] ? (
                                        <Loader height="h-5 w-5" />
                                      ) : (
                                        <Tooltip
                                          tooltipShow={
                                            peopleMap?.provider === 'gusto'
                                          }
                                          content="Cannot change status of the Gusto imported person"
                                          position="left"
                                        >
                                          <div
                                            className="form-switch form-switch-indigo"
                                            onClick={(e) => e.stopPropagation()}
                                            role="button"
                                            tabIndex="0"
                                          >
                                            <input
                                              type="checkbox"
                                              id={`switch-${peopleMap?.id}`}
                                              className="sr-only"
                                              checked={peopleMap?.enabled}
                                              onChange={(e) => {
                                                e.stopPropagation();
                                                handlePersonStatusUpdate(
                                                  {
                                                    enabled:
                                                      !peopleMap?.enabled,
                                                  },
                                                  peopleMap?.id,
                                                );
                                              }}
                                              disabled={
                                                peopleMap?.provider ===
                                                  'gusto' ||
                                                !checkPermission(
                                                  PermissionKeys.MANAGE_PEOPLE,
                                                  accessType,
                                                  permissions,
                                                )
                                              }
                                            />
                                            <label
                                              className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                                              htmlFor={`switch-${peopleMap?.id}`}
                                            >
                                              <span
                                                className="bg-gray-300"
                                                aria-hidden="true"
                                              />
                                              <span className="sr-only">
                                                Switch label
                                              </span>
                                            </label>
                                          </div>
                                        </Tooltip>
                                      )}
                                    </td>
                                    {checkPermission(
                                      PermissionKeys.MANAGE_PEOPLE,
                                      accessType,
                                      permissions,
                                    ) && (
                                      <td className="whitespace-nowrap">
                                        <div className=" inline-flex items-center gap-3.5">
                                          {peopleMap?.isTemporaryPassword &&
                                            peopleMap?.role?.length > 0 && (
                                              <Tooltip
                                                content={
                                                  peopleMap?.isEmailSent
                                                    ? 'Resend Email'
                                                    : 'Send Email'
                                                }
                                                position="left"
                                                contentClassName="text-center p-1 relative overflow-visible tooltipArrowRight"
                                              >
                                                <div
                                                  className={`w-[26px] h-[26px] border  rounded-[8px] inline-flex justify-center items-center
                                                    ${peopleMap?.isEmailSent ? 'bg-[#40AA00] bg-opacity-5 border-[#40AA00]' : 'border-[#D0D5DD]'}
                                                    `}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSendEmailModalOpen(true);
                                                    setActiveEmailPerson(
                                                      peopleMap,
                                                    );
                                                  }}
                                                  role="button"
                                                  tabIndex="0"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 64 64"
                                                    className={`h-[18px] w-4 ${
                                                      peopleMap?.isEmailSent
                                                        ? 'text-[#40AA00] fill-[#40AA00]'
                                                        : 'text-[#667085] fill-[#667085]'
                                                    }`}
                                                  >
                                                    <path
                                                      className="fill-current"
                                                      d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"
                                                    />
                                                    <path
                                                      className="fill-current"
                                                      d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"
                                                    />
                                                    <path
                                                      className="fill-current"
                                                      d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"
                                                    />
                                                  </svg>
                                                </div>
                                              </Tooltip>
                                            )}
                                          {!peopleMap?.isTemporaryPassword && (
                                            <Tooltip
                                              content={
                                                <>
                                                  <p>
                                                    This user has successfully
                                                    setup
                                                  </p>
                                                  <p>their employee account!</p>
                                                </>
                                              }
                                              position="left"
                                              className="mt-1"
                                              contentClassName="text-center p-1 relative overflow-visible tooltipArrowRight"
                                            >
                                              <div
                                                className="w-[26px] h-[26px] border  cursor-default rounded-[8px] inline-flex justify-center items-center bg-[#40AA00] bg-opacity-5 border-[#40AA00]"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                                role="button"
                                                tabIndex="0"
                                              >
                                                <svg
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 18 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className={`h-[14px] w-[14px] text-[#40AA00] stroke-[#40AA00] `}
                                                >
                                                  <g clipPath="url(#clip0_4618_46369)">
                                                    <path
                                                      d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                                                      className="stroke-current"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                    <path
                                                      d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                                                      className="stroke-current"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_4618_46369">
                                                      <rect
                                                        width="17"
                                                        height="17"
                                                        fill="white"
                                                        transform="translate(0.199219 0.291016)"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                              </div>
                                            </Tooltip>
                                          )}
                                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setTitle('Edit Person');
                                                setActivePeople(peopleMap);
                                                setFormOpen(true);
                                              }}
                                            >
                                              <path
                                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                fill="white"
                                                stroke="#D0D5DD"
                                                strokeOpacity="0.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                                stroke="#858D9D"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </div>
                                          <Tooltip
                                            tooltipShow={
                                              peopleMap?.provider === 'gusto'
                                            }
                                            content="Cannot delete the Gusto imported person"
                                            position="left"
                                          >
                                            <div
                                              className={`w-[1.625rem] h-[1.625rem] ${peopleMap?.provider === 'gusto' ? 'cursor-not-allowed' : 'cursor-pointer'} `}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (!peopleMap?.provider) {
                                                    setActivePeople(peopleMap);
                                                    setModalOpen(true);
                                                  }
                                                }}
                                              >
                                                <path
                                                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                  fill="white"
                                                  stroke="#D0D5DD"
                                                  strokeOpacity="0.6"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                                  stroke="#667085"
                                                  strokeOpacity="0.8"
                                                  strokeWidth="1.3"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                                  stroke="#858D9D"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                                  stroke="#667085"
                                                  strokeOpacity="0.8"
                                                  strokeWidth="1.3"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M12.693 16.3574H14.8505"
                                                  stroke="#667085"
                                                  strokeOpacity="0.8"
                                                  strokeWidth="1.3"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M12.1552 13.7656H15.3948"
                                                  stroke="#667085"
                                                  strokeOpacity="0.8"
                                                  strokeWidth="1.3"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                ),
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    {totalRecords > 0 && (
                      <div className="mt-10">
                        <PaginationClassic
                          setLimit={setLocalLimit}
                          onPageChange={getData}
                          pagination={{
                            limit: localLimit,
                            page: parseInt(page),
                            totalRecords,
                          }}
                          pageLoad
                        />
                      </div>
                    )}
                  </>
                )
              : !isLoading &&
                !formOpen && (
                  <div className="flex flex-col gap-5 items-center justify-center">
                    <h6 className="text-center mt-4 pb-3">
                      No{' '}
                      <span className="capitalize">
                        {activeTab === 'all' ? 'People' : activeTab}
                      </span>{' '}
                      Found
                    </h6>
                    <img
                      src={NoPeopleImage}
                      alt="No Tags Found"
                      className="h-[12.5rem]"
                    />
                  </div>
                )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default People;
