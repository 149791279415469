import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAccessType } from '../../../redux/User/reducer';
import { ADMIN, TEAM_ID, TEAM_NAME } from '../../../utils/Utils';
import { leaveTeamFromFirmApi } from '../../../API/backend_helper';
import LeaveModal from '../../manageWorkspaces/manage/LeaveModal';
import { setTeam } from '../../../redux/Team/reducer';
import ClientsTable from './ClientsTable';

const MyClients = ({
  accountantFirmId,
  firm,
  loadAccountantFirm,
  clients,
  getClients,
  clientsLoader,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [leaveTeam, setLeaveTeam] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onTeamLeave = async () => {
    setLoading(true);
    try {
      const { value } = leaveTeam;
      await leaveTeamFromFirmApi(accountantFirmId, value);
      localStorage.setItem(TEAM_ID, '');
      localStorage.setItem(TEAM_NAME, '');
      getClients();
      setIsOpenModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsOpenModal(false);
      console.log('err', err);
    }
  };

  const onTeamChange = (id, teamObj = null) => {
    if (id) {
      localStorage.setItem(TEAM_ID, id);
      localStorage.setItem(TEAM_NAME, teamObj?.name);
      dispatch(setAccessType(ADMIN));
      dispatch(setTeam(teamObj));
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <div>
      <LeaveModal
        isOpen={isOpenModal}
        title="Leave Workspace"
        description="Are you sure you want to leave selected worksapce(s) ? This action cannot be undone."
        setIsOpen={setIsOpenModal}
        onCompletion={onTeamLeave}
        loading={loading}
        team={leaveTeam}
      />
      <ClientsTable
        clients={clients}
        setIsOpenModal={setIsOpenModal}
        setLeaveTeam={setLeaveTeam}
        loading={clientsLoader}
        onTeamChange={onTeamChange}
        accountantFirmId={accountantFirmId}
        firm={firm}
        loadAccountantFirm={loadAccountantFirm}
      />
    </div>
  );
};

export default MyClients;
