import React, { useState, useRef } from "react";
import DropdownFull from "../../../components/DropdownFull";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { enrichPlayground } from "../../../API/backend_helper";
import TextInputDash from "../../../components/TextInputDash";

const KeyValuePair = ({ option, value }) => {
  return (
    <code className="flex gap-2 w-full max-w-full overflow-hidden">
      <pre className="font-semibold whitespace-nowrap text-slate-600">
        {option}:
      </pre>
      <code className=" flex-1 overflow-hidden w-fit">
        <span className="max-w-full w-full overflow-hidden break-words">
          {value || "--"}
        </span>
      </code>
    </code>
  );
};
const EnrichPlayground = () => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});
  const [accountRes, setAccountRes] = useState({});

  const handleSubmit = async (values) => {
    setResults({});
    setAccountRes({});
    setLoading(true);
    try {
      const res = await enrichPlayground(values);
      setResults(
        res?.data?.result?.output
      );
      setAccountRes(
        res?.data?.result?.results
      )
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="">
        <Formik
          enableReinitialize={true}
          initialValues={{
            bankDescription: "",
            country: "US",
            memo: "",
            amount: "",
          }}
          validationSchema={Yup.object({
            bankDescription: Yup.string().required(
              "Please Enter Bank Description"
            ),
            memo: Yup.string().optional().nullable(),
            amount: Yup.string().required("Please Enter Amount"),
            country: Yup.string().trim().required("Please Enter Country"),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {(validation) => {
            return (
              <Form>
                <div className=" pb-1 grid grid-cols-12 gap-3 sm:gap-6 sm:gap-y-0 gap-y-0">
                  <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-6 ">
                    <TextInputDash
                      label="Country"
                      id="country"
                      name="country"
                      placeholder="Country"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.country || ""}
                      error={
                        validation.touched.country && validation.errors.country
                      }
                      required
                    />
                  </div>
                  <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-6 ">
                    <TextInputDash
                      label="Amount"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount || ""}
                      error={
                        validation.touched.amount && validation.errors.amount
                      }
                      required
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                  <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-6 ">
                    <TextInputDash
                      label="Memo"
                      id="memo"
                      name="memo"
                      placeholder="Memo"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.memo || ""}
                      error={validation.touched.memo && validation.errors.memo}
                    />
                  </div>

                  <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-6 ">
                    <label
                      className="text-sm font-medium text-slate-600 block mb-2"
                      htmlFor="bankDescription"
                    >
                      Bank Description <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      id="bankDescription"
                      name="bankDescription"
                      placeholder="Description"
                      value={validation.values.bankDescription || ""}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={`form-input w-full h-[4.875rem] px-4 text-[#667085] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6   border-[#D0D5DD] shadow-sm mb-[2px] rounded-[5px]  ${
                        validation.touched.bankDescription &&
                        validation.errors.bankDescription
                          ? "!border-rose-400"
                          : ""
                      }`}
                    />
                    {validation.touched.bankDescription &&
                    validation.errors.bankDescription ? (
                      <div className="text-xs mt-0 text-rose-500">
                        {validation.errors.bankDescription}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Modal footer */}
                <div className="flex flex-wrap justify-between space-x-2 items-center mt-5">
                  <div
                    className="font-medium underline ml-1 text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 mr-5 cursor-pointer"
                    onClick={() => {
                      formikRef.current?.resetForm();
                      setLoading(false);
                      setResults({});
                      setAccountRes({});
                    }}
                  >
                    Reset
                  </div>
                  <button
                    type="submit"
                    className="h-11 py-2.5 px-3 flex items-center justify-center rounded-[0.313rem] bg-indigo-500 text-white font-normal text-base leading-6 shadow-sm
                      disabled:bg-indigo-400    disabled:cursor-not-allowed"
                    disabled={loading}
                  >
                    {loading && (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    )}
                    Generate Results
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="">
        {/* {results&&<div
            dangerouslySetInnerHTML={{
              __html: results?.replace?.(/\n/g, "<br>"),
            }}
          />} */}
        {results && (
          <div className="py-[1.875rem] pt-0 px-5 ">
            <h3 className="text-xl font-semibold mb-1 text-black text-opacity-60">
              Result
            </h3>
            {/* <pre className="whitespace-pre-wrap break-word">{JSON.stringify(results, null, 2)}</pre> */}
            {loading ? (
              <div className="flex justify-evenly w-full">
                <div>
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                </div>
              </div>
            ) :<div> {typeof results === "object" ? (
              Object.keys(results)?.map((key) => (
                <KeyValuePair option={key} value={results?.[key]} />
              ))
            ) : (
              <h2 className="text-center">Not Found</h2>
            )}
            <div className="border underline my-5"/>
            {typeof accountRes === "object" ? (
              Object.keys(accountRes)?.map((key) => (
                <KeyValuePair option={key} value={accountRes?.[key]} />
              ))
            ) : (
              <h2 className="text-center">Category Details Not Found</h2>
            )}
            </div>}



          </div>
        )}
      </div>
    </div>
  );
};

export default EnrichPlayground;
