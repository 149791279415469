import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CsvImportTransactions = ({ updateOneStep }) => {
  const navigate = useNavigate();
  const { onBoardingSubSteps } = useSelector((state) => state.OnBoarding);

  const handleNext = () => {
    if (!onBoardingSubSteps?.includes('transactions')) {
      navigate('/import-transactions?from=onboarding');
    } else {
      updateOneStep();
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Historical Transactions:
      </h3>
      <div className="grid">
        <ImportBox
          title="Import Historical Transactions"
          text="Import all of your accounts from CSV with a single click."
          buttonText={
            onBoardingSubSteps?.includes('transactions')
              ? 'Transactions Imported'
              : 'Import  Transactions'
          }
          handleClick={() => navigate('/import-transactions?from=onboarding')}
          showLastSync={false}
          successBtn={onBoardingSubSteps?.includes('transactions')}
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        <button
          type="button"
          className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={handleNext}
        >
          Next
        </button>
      </footer>
    </div>
  );
};

export default CsvImportTransactions;
