import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import DeleteModal from './DeleteModal';
import Tooltip from '../../components/Tooltip';

import {
  formatNumberGlobally,
  getBillStatusColor,
  getCurrencySymbolFromIso,
} from '../../utils/Utils';

const BillsTable = ({
  bills = [],
  setActiveBillId,
  handleDeleteBill,
  setInstancesModalOpen,
  setIsActive,
  setBillDetails,
  currencies,
  setActiveBill,
  setTasksModalOpen,
  BILLS_MODAL,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteBillId, setDeleteBillId] = useState(null);

  return (
    <>
      <DeleteModal
        handleDone={async () => {
          await handleDeleteBill(bills?.[deleteBillId]?.id);
        }}
        billNumber={bills?.[deleteBillId]?.billNumber}
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
      />

      <div className="bg-white  mt-5 flex-1 dark:bg-slate-800  rounded-sm   overflow-auto">
        <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
          {/* Table header */}
          <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Bill Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Due Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Status</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                <div className="font-medium text-left">Vendor</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-left">Bill Number</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-center">Total</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-center">Amount Due</div>
              </th>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell hidden ">
                <div className="font-medium text-center">Recurring?</div>
              </th> */}
              {checkPermission(
                PermissionKeys.MANAGE_BILLS,
                accessType,
                permissions,
              ) && (
                <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell hidden w-[320px]">
                  <div className="font-medium text-left">Actions</div>
                </th>
              )}
            </tr>
          </thead>
          {/* Table body */}
          {bills?.length ? (
            <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
              {bills.map((b, index) => (
                <tr
                  key={b?.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    setBillDetails(b?.id);
                  }}
                  className={`h-20 cursor-pointer  hover:bg-[#A0CD850F]  `}
                >
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    {moment(b?.firstBillDate).format('MMM DD, YYYY')}
                  </td>
                  <td className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap">
                    {moment(b?.dueDate ? b?.dueDate : b?.firstDueDate).format(
                      'MMM DD, YYYY',
                    )}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    <div
                      className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                      ${getBillStatusColor(b?.approvalStatus === 'draft' ? 'draft' : b?.status)}
                    `}
                    >
                      {b?.approvalStatus === 'draft'
                        ? 'Draft'
                        : b?.status === 'unpaid'
                          ? 'Approved'
                          : b?.status || '--'}
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    <div className="flex items-center">
                      <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                        {b?.vendor?.logoUrl ? (
                          <img
                            className="rounded-full"
                            src={b?.vendor?.logoUrl}
                            alt={b?.vendor?.name}
                          />
                        ) : (
                          <div className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem] flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                            <div className="text-xs font-normal uppercase text-white">
                              {b?.vendor?.name
                                ? b.vendor.name
                                    .replace(/[^\w\s]/gi, '')
                                    .split(' ')
                                    .filter(Boolean)
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="font-normal text-slate-600 leading-6 mr-2">
                        {b?.vendor?.name?.length > 12 ? (
                          <Tooltip
                            content={b?.vendor?.name}
                            contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          >
                            <span>{`${b?.vendor?.name?.slice(0, 12)}...`}</span>
                          </Tooltip>
                        ) : (
                          <span>{b?.vendor?.name}</span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
                    {b?.billNumber || '--'}
                  </td>
                  {/* <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                      <div className="px-3 mx-auto py-1 w-fit rounded-md bg-slate-100 text-sm font-semibold dark:bg-slate-700">
                        {b.upcoming}
                      </div>
                    </td> */}
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    {getCurrencySymbolFromIso(b?.currency, currencies)}
                    {formatNumberGlobally(b?.total)}
                  </td>
                  <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    {getCurrencySymbolFromIso(b?.currency, currencies)}
                    {formatNumberGlobally(b?.unpaidAmount)}
                  </td>
                  {/* <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                    <div className="text-sm">
                      {" "}
                      {b?.recurring ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-5 w-5 mx-auto fill-[#78bd4f]"
                        >
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                        </svg>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td> */}
                  {checkPermission(
                    PermissionKeys.MANAGE_BILLS,
                    accessType,
                    permissions,
                  ) && (
                    <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
                      <div className="flex gap-[14px] justify-between">
                        <div className="flex gap-[14px] justify-end">
                          <div className="text-start">
                            <Tooltip
                              content="View Bill Instances"
                              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                              className="w-fit"
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setInstancesModalOpen(true);
                                  setActiveBill(b);
                                }}
                                className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                type="button"
                              >
                                <svg
                                  className="  fill-[#9A9A9A] h-[14px] "
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                </svg>
                              </button>
                            </Tooltip>
                          </div>
                          {/* Edit Button  */}
                          <Tooltip
                            content="Edit Bill"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            tooltipShow={b?.status !== 'paid'}
                          >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveBillId(b?.id);
                                setIsActive(BILLS_MODAL);
                              }}
                              disabled={b?.status === 'paid'}
                              className=" disabled:cursor-not-allowed h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                              type="button"
                            >
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.98682 1.16699H5.82015C2.90348 1.16699 1.73682 2.33366 1.73682 5.25033V8.75033C1.73682 11.667 2.90348 12.8337 5.82015 12.8337H9.32015C12.2368 12.8337 13.4035 11.667 13.4035 8.75033V7.58366"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.92691 1.76206L5.33024 6.35872C5.15524 6.53372 4.98024 6.87789 4.94524 7.12872L4.69441 8.88456C4.60108 9.52039 5.05024 9.96372 5.68608 9.87622L7.44191 9.62539C7.68691 9.59039 8.03107 9.41539 8.21191 9.24039L12.8086 4.64372C13.6019 3.85039 13.9752 2.92872 12.8086 1.76206C11.6419 0.595392 10.7202 0.968725 9.92691 1.76206Z"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.26807 2.4209C9.6589 3.81507 10.7497 4.9059 12.1497 5.30257"
                                  stroke="#858D9D"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                          {/* Add Task Button  */}
                          {checkPermission(
                            PermissionKeys.MANAGE_TASKS,
                            accessType,
                            permissions,
                          ) &&
                            b?.approvalStatus !== 'draft' && (
                              <Tooltip
                                content="Add Task"
                                contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                tooltipShow={b?.status !== 'paid'}
                              >
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setActiveBill(b);
                                    setTasksModalOpen(true);
                                  }}
                                  className=" disabled:cursor-not-allowed h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                  disabled={b?.status === 'paid'}
                                  type="button"
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_4851_39681)">
                                      <path
                                        d="M13.6078 6.47826V4.85846C13.6078 3.42713 12.4475 2.2668 11.0161 2.2668H4.53698C3.10564 2.2668 1.94531 3.42713 1.94531 4.85846V11.6616C1.94531 13.0929 3.10564 14.2533 4.53698 14.2533H5.83281M5.1849 1.29492V3.23867M10.3682 1.29492V3.23867M7.77656 5.18242V9.06992M9.72031 7.12617H5.83281M7.77656 14.2533L9.75968 13.6879C9.866 13.6576 9.96283 13.6007 10.041 13.5225L13.2902 10.2733C13.7137 9.84979 13.7137 9.16315 13.2902 8.73964C12.8667 8.31613 12.18 8.31613 11.7565 8.73964L8.50734 11.9888C8.42916 12.067 8.37223 12.1638 8.34192 12.2701L7.77656 14.2533Z"
                                        stroke="#667085"
                                        strokeOpacity="0.8"
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_4851_39681">
                                        <rect
                                          width="15.55"
                                          height="15.55"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </Tooltip>
                            )}
                          {/* Delete Button  */}
                          <Tooltip
                            content="Delete Bill"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            tooltipShow={b?.status !== 'paid'}
                          >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteBillId(index);
                                setDeleteModalOpen(true);
                              }}
                              className="disabled:cursor-not-allowed  h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                              disabled={b?.status === 'paid'}
                              type="button"
                            >
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.177 3.87474C12.0195 3.66093 9.84894 3.55078 7.6849 3.55078C6.40203 3.55078 5.11915 3.61557 3.83628 3.74516L2.51453 3.87474"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.07812 3.22021L6.22067 2.37144C6.32433 1.75592 6.40208 1.2959 7.49706 1.2959H9.1946C10.2896 1.2959 10.3738 1.78184 10.471 2.37792L10.6135 3.22021"
                                  stroke="#858D9D"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.7841 5.92188L12.3629 12.4464C12.2916 13.4636 12.2333 14.2541 10.4256 14.2541H6.26601C4.45832 14.2541 4.40001 13.4636 4.32874 12.4464L3.90759 5.92188"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.26379 10.6904H9.42136"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.72595 8.09863H9.96554"
                                  stroke="#667085"
                                  strokeOpacity="0.8"
                                  strokeWidth="1.3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                        {b?.status !== 'paid' &&
                          b?.approvalStatus !== 'draft' && (
                            <div className="flex items-center">
                              {checkPermission(
                                PermissionKeys.APPROVE_BILLS,
                                accessType,
                                permissions,
                              ) && (
                                <button
                                  className="btn-sm h-[30px] text-sm text-indigo-500 whitespace-nowrap bg-[#E486420D] px-2.5 border border-[#E48642] "
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setInstancesModalOpen(true);
                                    setActiveBill(b);
                                  }}
                                >
                                  Record A Payment
                                </button>
                              )}
                            </div>
                          )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        {!bills?.length > 0 && (
          <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
            No Bill Found
          </h6>
        )}
      </div>
    </>
  );
};

export default BillsTable;
