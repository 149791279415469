import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tooltip from '../../components/Tooltip';
import {
  getCurrencySymbolFromIso,
  getStringSegments,
  sortObjectsArray,
  TEAM_CURRENCY,
  trimText,
} from '../../utils/Utils';

const ReusableAccount = ({
  b,
  countBalanceLoader,
  getAccountSystemBal,
  teamDefaultCurrency,
  currencies,
  setDeActivateModalOpen,
  setDeActivateAccount,
  accountsToTransactions,
  setIsEditing,
  setAccountsModalOpen,
  setSelectedAccount,
  setSimpleModalOpen,
  isSubAccount = false,
  setSelectedParentAccount = () => {},
  parent = null,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <tr
      key={b?.id}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`  hover:bg-[#A0CD850F]  ${isSubAccount ? 'h-12' : 'h-20'}`}
    >
      <td
        className={`px-2  last:pr-5 py-2.5 text-left text-sm ${isSubAccount ? ' first:pl-10' : ' first:pl-5'}`}
      >
        {b?.accountNumber || '--'}
      </td>
      <td
        className={`px-2 first:pl-5 last:pr-5 py-2.5 text-left ${isSubAccount ? 'pl-6' : 'text-sm'}`}
      >
        <div className="flex items-center">
          {b?.institution?.logoUrl && (
            <img
              src={b?.institution?.logoUrl}
              className="w-6 h-6 rounded-full mr-2"
              alt="institute-logo"
            />
          )}
          {b?.name} {b?.mask && <>(...{b?.mask})</>}
        </div>
        {b?.status === 'inactive' && (
          <div className="capitalize  px-1.5 py-0.5 w-fit rounded-md  text-[10px] font-medium text-[#FF4B4B] bg-[#FF4B4B1A]">
            Inactive
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
        <div
          className={`capitalize  px-2.5 py-1 w-fit rounded-md  font-medium text-sm `}
        >
          {b?.type || '--'}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-left">
        {b?.subType?.name}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 md:table-cell hidden text-slate-700">
        <div className="flex items-center whitespace-nowrap ">
          {b?.taxes?.length > 0 ? (
            <Tooltip
              tooltipShow={
                `${b?.taxes?.[0]?.name} (${+b?.taxes?.[0]?.percentage}%)`
                  ?.length > 20 || b?.taxes?.length > 1
              }
              content={b?.taxes?.map((t, i) => {
                if (`${t?.name} (${+t?.percentage}%)`?.length > 30) {
                  const segments = getStringSegments(
                    `${t?.name} (${+t?.percentage}%)`,
                    30,
                  );
                  return segments.map((segment, index) => (
                    <p
                      key={index}
                      className="text-sm text-center leading-tight"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {segment}{' '}
                      {index === segments?.length - 1 &&
                        b?.taxes?.length - 1 !== i &&
                        ','}
                    </p>
                  ));
                }
                return (
                  <p
                    key={t?.id}
                    className="text-sm text-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {`${t?.name} (${+t?.percentage}%)`}
                    {b?.taxes?.length - 1 !== i && ','}
                  </p>
                );
              })}
              contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
              position="bottom"
            >
              <span className="">
                {trimText(
                  `${b?.taxes?.[0]?.name} (${+b?.taxes?.[0]?.percentage}%)`,
                  20,
                )}{' '}
                {b?.taxes?.length > 1 && `+${b?.taxes?.length - 1}`}
              </span>
            </Tooltip>
          ) : (
            'No Taxes'
          )}
        </div>
      </td>
      <td
        className={`px-2 first:pl-5 last:pr-5 py-2.5 ${
          countBalanceLoader ? 'flex justify-center' : 'text-left'
        }`}
      >
        {countBalanceLoader ? (
          <svg
            className="animate-spin w-4 h-4 fill-current shrink-0 mt-5"
            viewBox="0 0 16 16"
          >
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
        ) : getAccountSystemBal(b?.id) !== null &&
          getAccountSystemBal(b?.id) !== undefined ? (
          <span>
            {getAccountSystemBal(b?.id)?.toString().charAt(0) === '-'
              ? '-'
              : ''}
            {getCurrencySymbolFromIso(
              b?.currency || teamDefaultCurrency,
              currencies,
            )}
            {parseFloat(
              getAccountSystemBal(b?.id)?.toString().replace('-', '') || 0,
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ) : (
          '--'
        )}
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
        {b?.providerBalances?.[b?.providerBalances?.length - 1]
          ?.balanceCurrent !== null &&
        b?.providerBalances?.[b?.providerBalances?.length - 1]
          ?.balanceCurrent !== undefined ? (
          <>
            {b?.providerBalances?.[
              b?.providerBalances?.length - 1
            ]?.balanceCurrent
              ?.toString()
              .charAt(0) === '-'
              ? '-'
              : ''}
            {getCurrencySymbolFromIso(
              b?.currency || teamDefaultCurrency,
              currencies,
            )}
            {parseFloat(
              b?.providerBalances?.[
                b?.providerBalances?.length - 1
              ]?.balanceCurrent
                ?.toString()
                .replace('-', '') || 0,
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ) : (
          '--'
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-2.5 text-center">
        {b?.status === 'inactive' ? (
          <div className="col-span-2 gap-4 items-center flex justify-end">
            <Tooltip
              tooltipShow
              content={
                b?.editable ? 'Activate' : "Can't Activate non editable account"
              }
              contentClassName={`border-none overflow-visible text-sm text-[#667085] relative ${
                b?.editable ? 'tooltipArrowDown' : 'min-w-[270px] text-wrap'
              }`}
              position={b?.editable ? '' : 'left'}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (b?.editable) {
                    setDeActivateModalOpen(true);
                    setDeActivateAccount(b);
                  }
                }}
                className="h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                type="button"
              >
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // className={''}
                >
                  <path
                    d="M7.85524 6.6217C7.99464 6.6217 8.13334 6.56385 8.23219 6.4503L12.5356 1.51815C12.7173 1.31015 12.6958 0.994197 12.4875 0.812847C12.2802 0.631447 11.9643 0.652197 11.7822 0.860947L7.47879 5.7931C7.29714 6.0011 7.31864 6.31705 7.52689 6.4984C7.62159 6.58115 7.73854 6.6217 7.85524 6.6217Z"
                    fill="#78BD4F"
                  />
                  <path
                    d="M15.8126 0.378983C15.6051 0.197833 15.2891 0.218833 15.1073 0.427083L8.07111 8.49078L4.67021 4.71513C4.48491 4.50983 4.16901 4.49323 3.96391 4.67828C3.75881 4.86308 3.74221 5.17928 3.92706 5.38458L5.91151 7.58768L5.12531 8.49008L1.72516 4.71568C1.53986 4.51038 1.22396 4.49378 1.01886 4.67883C0.813758 4.86388 0.797158 5.17983 0.982008 5.38513L4.76011 9.57873C4.85506 9.68393 4.99011 9.74403 5.13171 9.74403H5.13586C5.27891 9.74283 5.41466 9.68033 5.50866 9.57238L6.58571 8.33613L7.70546 9.57923C7.80041 9.68443 7.93546 9.74453 8.07706 9.74453H8.08096C8.22401 9.74333 8.35976 9.68103 8.45376 9.57313L15.8608 1.08438C16.0423 0.876283 16.0208 0.560633 15.8126 0.378983Z"
                    fill="#78BD4F"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        ) : (
          <div className="flex gap-[14px] justify-center">
            {/* View Transactions  */}
            <Tooltip
              content="View Journal Entries"
              contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => accountsToTransactions(b)}
                className="cursor-pointer"
              >
                <path
                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                  fill="white"
                  stroke="#D0D5DD"
                  strokeOpacity="0.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                  fill="white"
                  stroke="#D0D5DD"
                  strokeOpacity="0.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.2861 19.9902H17.8008C19.7445 19.9902 21.0404 18.6944 21.0404 16.7507V13.5111C21.0404 11.7617 19.9389 10.4659 18.3191 10.2715C18.1895 10.2715 17.9952 10.2715 17.8008 10.2715H11.3216C11.1272 10.2715 10.9977 10.2715 10.8033 10.3363C9.1187 10.5306 8.08203 11.7617 8.08203 13.5111C8.08203 13.7054 8.08203 13.9646 8.08203 14.159"
                  stroke="#858D9D"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.3191 10.272C18.1895 10.272 17.9951 10.272 17.8007 10.272H11.3216C11.1272 10.272 10.9976 10.272 10.8032 10.3368C10.868 10.1424 10.9976 10.0128 11.192 9.81843L13.2653 7.68031C14.1724 6.77323 15.5978 6.77323 16.5049 7.68031L17.6711 8.84655C18.0599 9.2353 18.2543 9.75365 18.3191 10.272Z"
                  stroke="#858D9D"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.7861 13.7363H19.5861C19.1461 13.7363 18.7861 14.4113 18.7861 15.2363C18.7861 16.0613 19.1461 16.7363 19.5861 16.7363H20.7861"
                  stroke="#858D9D"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4367 20.6379C12.0373 20.6379 13.529 19.7705 14.5674 18.2693C14.9755 17.6813 14.9755 16.6929 14.5674 16.105C13.529 14.6037 12.0373 13.7363 10.4367 13.7363C8.83614 13.7363 7.34439 14.6037 6.30606 16.105C5.89798 16.6929 5.89798 17.6813 6.30606 18.2693C7.34439 19.7705 8.83614 20.6379 10.4367 20.6379Z"
                  fill="white"
                  stroke="#858D9D"
                  strokeWidth="1.18355"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.2196 17.2041C11.2196 17.2436 11.1732 17.3975 10.9973 17.5735C10.8213 17.7494 10.6674 17.7958 10.6279 17.7958C10.5883 17.7958 10.4344 17.7494 10.2584 17.5735C10.0825 17.3975 10.0361 17.2436 10.0361 17.204C10.0361 17.1645 10.0825 17.0106 10.2584 16.8346C10.4344 16.6587 10.5883 16.6123 10.6279 16.6123C10.6674 16.6123 10.8213 16.6587 10.9973 16.8346C11.1732 17.0106 11.2196 17.1645 11.2196 17.2041Z"
                  fill="#858D9D"
                  stroke="#858D9D"
                  strokeWidth="1.18355"
                />
              </svg>
            </Tooltip>

            {/* Edit Account */}
            {(b?.editable || b?.subType?.anchorTier === 'PHYSICAL_ACCOUNT') &&
              checkPermission(
                PermissionKeys.MANAGE_ACCOUNTS,
                accessType,
                permissions,
              ) && (
                <>
                  <Tooltip
                    content="Edit Account"
                    contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                  >
                    <svg
                      width="28"
                      height="27"
                      viewBox="0 0 28 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                      onClick={() => {
                        if (b?.editable) {
                          setIsEditing(true);
                          setSelectedAccount(b);
                          setSelectedParentAccount(parent);
                          setAccountsModalOpen(true);
                        } else {
                          setSelectedAccount(b);
                          setSelectedParentAccount(parent);
                          setSimpleModalOpen(true);
                        }
                      }}
                    >
                      <path
                        d="M10.1 26.5H17.9C24.4 26.5 27 23.9 27 17.4V9.6C27 3.1 24.4 0.5 17.9 0.5H10.1C3.6 0.5 1 3.1 1 9.6V17.4C1 23.9 3.6 26.5 10.1 26.5Z"
                        fill="white"
                        stroke="#D0D5DD"
                        strokeOpacity="0.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.416 7.66797H12.2493C9.33268 7.66797 8.16602 8.83464 8.16602 11.7513V15.2513C8.16602 18.168 9.33268 19.3346 12.2493 19.3346H15.7493C18.666 19.3346 19.8327 18.168 19.8327 15.2513V14.0846"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.3561 8.26108L11.7594 12.8577C11.5844 13.0327 11.4094 13.3769 11.3744 13.6277L11.1236 15.3836C11.0303 16.0194 11.4794 16.4627 12.1153 16.3752L13.8711 16.1244C14.1161 16.0894 14.4603 15.9144 14.6411 15.7394L19.2378 11.1427C20.0311 10.3494 20.4044 9.42775 19.2378 8.26108C18.0711 7.09442 17.1494 7.46775 16.3561 8.26108Z"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.6973 8.91992C16.0881 10.3141 17.1789 11.4049 18.5789 11.8016"
                        stroke="#858D9D"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Tooltip>
                  {/* DeActivate Button  */}

                  <Tooltip
                    content={
                      b?.editable
                        ? 'Deactivate'
                        : "Can't Deactivate non editable account"
                    }
                    position={b?.editable ? '' : 'left'}
                    contentClassName={`border-none overflow-visible text-sm text-[#667085] relative ${b?.editable ? 'tooltipArrowDown' : 'min-w-[270px] text-wrap'}`}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (b?.editable) {
                          setDeActivateModalOpen(true);
                          setDeActivateAccount(b);
                        }
                      }}
                      className=" h-[26px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                      type="button"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        className="cursor-pointer"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1"
                          width="14"
                          height="14"
                          rx="7"
                          stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                          strokeWidth="1.3"
                        />
                        <path
                          d="M3.05026 3.05025L12.9498 12.9497"
                          stroke={b?.editable ? '#FF4B4B' : '#cbd5e1'}
                          strokeWidth="1.3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </>
              )}
          </div>
        )}
      </td>
    </tr>
  );
};

const AccountsTable = ({
  allAccounts,
  accountsToTransactions,
  setIsEditing,
  setSelectedAccount,
  setAccountsModalOpen,
  setSimpleModalOpen,
  setDeActivateModalOpen,
  setDeActivateAccount,
  searchVal,
  selectedTypes,
  currencies,
  countBalanceLoader,
  getAccountSystemBal,
  setSelectedParentAccount = () => {},
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [tableAccounts, setTableAccounts] = useState(allAccounts);
  const [orderDir, setOrderDir] = useState('');
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    setTableAccounts(allAccounts);
  }, [allAccounts]);

  const onSortBy = (colName) => {
    const tempAccounts = JSON.parse(JSON.stringify(allAccounts || []));
    const sorted = sortObjectsArray(
      tempAccounts,
      colName,
      orderDir === 'asc' ? 'desc' : 'asc',
    );
    setTableAccounts(sorted);
    setSortBy(colName);
    if (orderDir === 'asc') {
      setOrderDir('desc');
    } else {
      setOrderDir('asc');
    }
  };

  const FilteredAccounts = useMemo(
    () =>
      tableAccounts?.filter((account) => {
        // Check if the main account matches the criteria
        const isMainAccountMatch =
          account?.name.toLowerCase().includes(searchVal.toLowerCase()) &&
          (selectedTypes.length ? selectedTypes.includes(account?.type) : true);

        // Check if any sub-account matches the criteria
        const isSubAccountMatch = account?.subAccounts?.some(
          (subAccount) =>
            subAccount?.name.toLowerCase().includes(searchVal.toLowerCase()) &&
            (selectedTypes.length
              ? selectedTypes.includes(subAccount?.type)
              : true),
        );

        return isMainAccountMatch || isSubAccountMatch;
      }),
    [tableAccounts, selectedTypes, searchVal],
  );

  return (
    <div className="bg-white  mt-5 dark:bg-slate-800 h-full rounded-sm w-full overflow-auto">
      <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
        {/* Table header */}
        <thead className="text-sm text-[#667085] font-medium dark:text-slate-400 shadow-md border-none">
          <tr>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap flex items-center  cursor-pointer"
              onClick={() => onSortBy('accountNumber')}
            >
              <div className="font-medium text-left">Account Number / Code</div>
              {sortBy === 'accountNumber' && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                  className={`w-4 h-3 ml-2 ${
                    orderDir === 'asc' ? 'rotate-180' : ''
                  } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
              )}
            </th>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap"
              onClick={() => onSortBy('name')}
            >
              <div className="flex items-center cursor-pointer">
                <div className="font-medium text-left">Name</div>
                {sortBy === 'name' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ml-2 ${
                      orderDir === 'asc' ? 'rotate-180' : ''
                    } shrink-0 fill-current  text-slate-400
                        dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )}
              </div>
            </th>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap flex items-center cursor-pointer"
              onClick={() => onSortBy('type')}
            >
              <div className="font-medium text-left">Type</div>
              {sortBy === 'type' && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="12"
                  viewBox="0 0 384 512"
                  className={`w-4 h-3 ml-2 ${
                    orderDir === 'asc' ? 'rotate-180' : ''
                  } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
              )}
            </th>
            <th
              className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap cursor-pointer"
              onClick={() => onSortBy('subType.name')}
            >
              <div className="flex items-center">
                <div className="font-medium text-left">Sub-Type</div>
                {sortBy === 'subType.name' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                    className={`w-4 h-3 ml-2 ${
                      orderDir === 'asc' ? 'rotate-180' : ''
                    } shrink-0 fill-current  text-slate-400
                         dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400`}
                  >
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                )}
              </div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell ">
              <div className="font-medium text-left">Taxes</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell ">
              <div className="font-medium text-left">COUNT Balance</div>
            </th>

            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell ">
              <div className="font-medium text-center">Bank Balance</div>
            </th>
            <th className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap sm:table-cell ">
              <div className="font-medium text-center">Actions</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        {FilteredAccounts?.length > 0 ? (
          <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
            {FilteredAccounts?.map((b) => (
              <>
                <ReusableAccount
                  key={b?.id}
                  b={b}
                  countBalanceLoader={countBalanceLoader}
                  getAccountSystemBal={getAccountSystemBal}
                  teamDefaultCurrency={teamDefaultCurrency}
                  currencies={currencies}
                  setDeActivateModalOpen={setDeActivateModalOpen}
                  setDeActivateAccount={setDeActivateAccount}
                  accountsToTransactions={accountsToTransactions}
                  setIsEditing={setIsEditing}
                  setAccountsModalOpen={setAccountsModalOpen}
                  setSelectedAccount={setSelectedAccount}
                  setSimpleModalOpen={setSimpleModalOpen}
                  setSelectedParentAccount={setSelectedParentAccount}
                />
                {b?.subAccounts?.length > 0 &&
                  b?.subAccounts?.map((subAccount) => (
                    <ReusableAccount
                      key={subAccount?.id}
                      b={subAccount}
                      countBalanceLoader={countBalanceLoader}
                      getAccountSystemBal={getAccountSystemBal}
                      teamDefaultCurrency={teamDefaultCurrency}
                      currencies={currencies}
                      setDeActivateModalOpen={setDeActivateModalOpen}
                      setDeActivateAccount={setDeActivateAccount}
                      accountsToTransactions={accountsToTransactions}
                      setIsEditing={setIsEditing}
                      setAccountsModalOpen={setAccountsModalOpen}
                      setSelectedAccount={setSelectedAccount}
                      setSimpleModalOpen={setSimpleModalOpen}
                      isSubAccount
                      parent={b}
                      setSelectedParentAccount={setSelectedParentAccount}
                    />
                  ))}
              </>
            ))}
          </tbody>
        ) : (
          <tbody className="text-sm divide-y text-[#667085] divide-slate-200 dark:divide-slate-700">
            <tr>
              <td
                colSpan={7}
                className="px-2 text-sm first:pl-5 last:pr-5 py-2.5 text-left whitespace-nowrap"
              >
                <div className="text-center">No Data Found</div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {tableAccounts?.length === 0 && (
        <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
          No Account Found
        </h6>
      )}
    </div>
  );
};

export default AccountsTable;
