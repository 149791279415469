import React from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import AccountsTable from './AccountsTable';
import QuickLinksBox from './QuickLinksBox';
import ReleaseNotesBox from './ReleaseNotesBox';
import RecurringTransactions from './RecurringTransactions';
import TasksBox from './TasksBox';
import ProjectsBox from './ProjectsBox';
import PeopleHoursTable from './PeopleHoursTable';
import ExpenseClaimsBox from './ExpenseClaimsBox';
import InvoiceBalanceBox from './InvoiceBalanceBox';
import BillBalanceBox from './BillBalanceBox';
import ExpensesBox from './ExpensesBox';
import IncomeBox from './IncomeBox';
import CashFlowChart from './CashFlowChart';
import Transactions from './Transactions';

const OverviewTab = ({
  overviewLoadings,
  setOverviewLoadings,
  connectedAccounts,
  accountsCountBalances,
  recurringTransactions,
  tasks,
  projects,
  peopleHoursData,
  peopleHourTimePeriod,
  handlePeopleTimePeriodChange,
  expenseClaims,
  loadExpenseClaims,
  invoiceBalance,
  billBalance,
  expensesData,
  selectedExpensesYear,
  handleExpenseMonthChange,
  ChartColors,
  selectedIncomeYear,
  incomeData,
  handleIncomeMonthChange,
  transactions,
  activeTransaction,
  setActiveTransaction,
  setTransactionPanelOpen,
  cashFlowData,
  getConnectionsApi,
  setActiveTab,
  cashStat,
  setAddTransactionModalOpen,
  setAddTransactionType,
  setTimeTrackingModalOpen,
  setAddTaskModalOpen,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  return (
    <div>
      <div className="grid xml:grid-cols-2 gap-6">
        {checkPermission(
          PermissionKeys.VIEW_ACCOUNTS,
          accessType,
          permissions,
        ) && (
          <AccountsTable
            overviewLoadings={overviewLoadings}
            connectedAccounts={connectedAccounts}
            getConnectionsApi={getConnectionsApi}
            accountsCountBalances={accountsCountBalances}
          />
        )}
        <QuickLinksBox
          setAddTransactionModalOpen={setAddTransactionModalOpen}
          setAddTransactionType={setAddTransactionType}
          setTimeTrackingModalOpen={setTimeTrackingModalOpen}
          setAddTaskModalOpen={setAddTaskModalOpen}
        />
      </div>
      <div className="grid col-span-1 xml:grid-cols-3 gap-6 mt-6">
        <div className="grid md:grid-cols-2  xml:grid-cols-1 gap-6 h-fit">
          {/* <ReleaseNotesBox /> */}
          {checkPermission(
            PermissionKeys.VIEW_TRANSACTIONS,
            accessType,
            permissions,
          ) && (
            <RecurringTransactions
              recurringTransactions={recurringTransactions}
              overviewLoadings={overviewLoadings}
              setActiveTab={setActiveTab}
            />
          )}
          {checkPermission(
            PermissionKeys.VIEW_TASKS,
            accessType,
            permissions,
          ) && (
            <TasksBox
              tasks={tasks}
              overviewLoadings={overviewLoadings}
              setAddTaskModalOpen={setAddTaskModalOpen}
            />
          )}
          {checkPermission(
            PermissionKeys.VIEW_PROJECTS,
            accessType,
            permissions,
          ) && (
            <ProjectsBox
              projects={projects}
              overviewLoadings={overviewLoadings}
            />
          )}
          {checkPermission(
            PermissionKeys.VIEW_PEOPLE,
            accessType,
            permissions,
          ) && (
            <PeopleHoursTable
              overviewLoadings={overviewLoadings}
              peopleHoursData={peopleHoursData}
              peopleHourTimePeriod={peopleHourTimePeriod}
              handlePeopleTimePeriodChange={handlePeopleTimePeriodChange}
            />
          )}
          <ExpenseClaimsBox
            expenseClaims={expenseClaims}
            overviewLoadings={overviewLoadings}
            loadExpenseClaims={loadExpenseClaims}
          />
        </div>
        <div className="xml:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6 h-fit">
          <div className="col-span-full">
            <CashFlowChart
              overviewLoadings={overviewLoadings}
              cashFlowData={cashFlowData}
              cashStat={cashStat}
            />
          </div>
          <InvoiceBalanceBox
            invoiceBalance={invoiceBalance}
            overviewLoadings={overviewLoadings}
          />
          <BillBalanceBox
            billBalance={billBalance}
            overviewLoadings={overviewLoadings}
          />
          <ExpensesBox
            expensesData={expensesData}
            selectedExpensesYear={selectedExpensesYear}
            overviewLoadings={overviewLoadings}
            handleExpenseMonthChange={handleExpenseMonthChange}
            ChartColors={ChartColors}
            setActiveTab={setActiveTab}
          />
          <IncomeBox
            overviewLoadings={overviewLoadings}
            selectedIncomeYear={selectedIncomeYear}
            incomeData={incomeData}
            handleIncomeMonthChange={handleIncomeMonthChange}
            ChartColors={ChartColors}
            setActiveTab={setActiveTab}
          />
          {checkPermission(
            PermissionKeys.VIEW_TRANSACTIONS,
            accessType,
            permissions,
          ) && (
            <div className="col-span-full">
              <Transactions
                overviewLoadings={overviewLoadings}
                transactions={transactions}
                activeTransaction={activeTransaction}
                setActiveTransaction={setActiveTransaction}
                setTransactionPanelOpen={setTransactionPanelOpen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
