import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import DropdownFull from '../../../components/DropdownFull';
import Tooltip from '../../../components/Tooltip';
import DeleteModal from './DeleteModal';

const TimesheetTab = ({
  data,
  setData,
  tasks,
  projects,
  getWeekDates,
  loadWeeklyTime,
  peopleId,
  handleCreateTimeEntry,
  handleUpdateTimeEntry,
  handleDeleteTimeEntry,
  minutesToTimeString,
}) => {
  const navigate = useNavigate();
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

  const { accessType, permissions } = useSelector((state) => state.User);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);

  const timeStringToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  };

  const isNextWeekButtonDisables = () => {
    const date = moment(data?.[0]?.timeEntries?.[0]?.date);
    const startOfNextWeek = date.clone().add(1, 'week').startOf('week');
    return moment(startOfNextWeek).isAfter(moment());
  };

  const onNext = () => {
    const date = moment(data?.[0]?.timeEntries?.[0]?.date);
    const startOfNextWeek = date.clone().add(1, 'week').startOf('week');
    getWeekDates(startOfNextWeek);
    loadWeeklyTime({ startDate: startOfNextWeek.format('YYYY-MM-DD') });
  };

  const onPrevious = () => {
    const date = moment(data?.[0]?.timeEntries?.[0]?.date);
    const startOfPreviousWeek = date
      .clone()
      .subtract(1, 'week')
      .startOf('week');
    getWeekDates(startOfPreviousWeek);
    loadWeeklyTime({ startDate: startOfPreviousWeek.format('YYYY-MM-DD') });
  };

  const onTimeChange = (value, i, j) => {
    let input = value.replace(/[^0-9]/g, '');
    if (input?.length > 4) {
      input = input.substring(0, 4);
    }

    if (input?.length >= 3) {
      input = `${input.substring(0, 2)}:${input.substring(2)}`; // Insert colon
    }

    const tempData = JSON.parse(JSON.stringify(data));
    tempData[i].timeEntries[j].time = input;
    if (tempData[i].timeEntries[j].error && timeRegex.test(input)) {
      tempData[i].timeEntries[j].error = '';
    }
    if (tempData[i].timeEntries[j].error && !input) {
      tempData[i].timeEntries[j].error = '';
    }
    setData(tempData);
  };

  const onAddEntry = async (index, entry) => {
    const { date, minutes } = entry;
    try {
      const entry = {
        projectId: data?.[index]?.projectId,
        peopleId,
        productServiceId: data?.[index]?.productServiceId,
        timeEntries: [
          {
            date,
            minutes,
            isBillable: data[index]?.isBillable,
          },
        ],
      };
      await handleCreateTimeEntry(entry);
    } catch (err) {
      console.log('err', err);
    }
  };

  const checkError = (value, i, j) => {
    const tempData = JSON.parse(JSON.stringify(data));
    if (tempData[i].timeEntries[j]?.id && !tempData[i].timeEntries[j]?.time) {
      handleDeleteTimeEntry(tempData[i].timeEntries[j]?.id);
    }

    if (!value) {
      return;
    }
    let input = value;
    if (input.includes(':')) {
      const [hh, mm] = input.split(':');
      const hours = parseInt(hh);
      const minutes = parseInt(mm);
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        input = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}`;
      }
    } else {
      input = parseInt(input);
      if (input >= 0 && input <= 23) {
        input = `${input.toString().padStart(2, '0')}:00`;
      }
    }

    if (timeRegex.test(input)) {
      tempData[i].timeEntries[j].error = '';
      tempData[i].timeEntries[j].time = input;
      if (tempData[i].timeEntries[j]?.id) {
        handleUpdateTimeEntry(tempData[i].timeEntries[j]?.id, {
          minutes: timeStringToMinutes(input),
        });
      } else {
        onAddEntry(i, {
          date: tempData[i].timeEntries[j]?.date,
          minutes: timeStringToMinutes(input),
        });
      }
    } else {
      tempData[i].timeEntries[j].error = 'Invalid time';
    }
    setData(tempData);
  };

  const onProjectTaskChange = (value, name, index) => {
    const tempData = JSON.parse(JSON.stringify(data));
    tempData[index][name] = value;

    tempData?.[index]?.timeEntries?.forEach((t) => {
      if (t?.id) {
        const updatedEntry = {};
        updatedEntry[name] = value;
        handleUpdateTimeEntry(t?.id, updatedEntry);
      }
    });
    setData(tempData);
  };

  const handleAddRow = () => {
    const tempData = JSON.parse(JSON.stringify(data));
    if (tempData?.length > 0) {
      let timeEntries = [];
      timeEntries = tempData?.[0]?.timeEntries.map((t) => ({
        date: t.date,
        time: '',
        error: '',
      }));
      tempData.push({
        projectId: null,
        productServiceId: null,
        isBillable: true,
        timeEntries,
      });
      setData(tempData);
    } else {
      const today = moment().format('YYYY-MM-DD');
      getWeekDates(today);
      loadWeeklyTime({
        startDate: moment().clone().startOf('week').format('YYYY-MM-DD'),
      });
    }
  };

  const handleDeleteRow = (index) => {
    const tempData = JSON.parse(JSON.stringify(data));
    tempData?.[index]?.timeEntries?.forEach((t) => {
      if (t?.id) {
        handleDeleteTimeEntry(t?.id);
      }
    });
    tempData.splice(index, 1);
    setData(tempData);
  };

  const calcHours = () => {
    let totalMinutes = 0;
    let billableMinutes = 0;
    let unBillableMinutes = 0;
    data?.forEach((d) => {
      d?.timeEntries?.forEach((t) => {
        if (t?.time && timeRegex.test(t?.time)) {
          const minutes = timeStringToMinutes(t?.time);
          totalMinutes += minutes;
          if (d?.isBillable) {
            billableMinutes += minutes;
          } else {
            unBillableMinutes += minutes;
          }
        }
      });
    });
    return {
      totalHours: minutesToTimeString(totalMinutes),
      billableHours: minutesToTimeString(billableMinutes),
      unBillableHours: minutesToTimeString(unBillableMinutes),
    };
  };

  const calcRowHours = (index) => {
    let totalMinutes = 0;
    data?.[index]?.timeEntries?.forEach((t) => {
      if (t?.time && timeRegex.test(t?.time)) {
        const minutes = timeStringToMinutes(t?.time);
        totalMinutes += minutes;
      }
    });
    return minutesToTimeString(totalMinutes);
  };

  return (
    <div className="relative">
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDone={() => {
          handleDeleteRow(activeRowIndex);
          setActiveRowIndex(-1);
          setDeleteModalOpen(false);
        }}
      />
      <div className="flex justify-between flex-wrap items-center gap-4 mb-12">
        <div className="flex gap-6 items-center">
          <h3 className="text-[20px] font-medium min-w-[136px]">
            {moment(data?.[0]?.timeEntries?.[0]?.date).isSame(
              moment().clone().startOf('week'),
            ) && <p>This Week</p>}
            <p
              className={`${
                moment(data?.[0]?.timeEntries?.[0]?.date).isSame(
                  moment().clone().startOf('week'),
                )
                  ? 'text-sm text-[#667085] font-normal'
                  : ''
              } `}
            >
              {`${moment(data?.[0]?.timeEntries?.[0]?.date).format('MMM DD')} -
              ${moment(
                data?.[0]?.timeEntries?.[data?.[0]?.timeEntries?.length - 1]
                  ?.date,
              ).format('MMM DD')}`}
            </p>
          </h3>
          <div className="flex gap-3 h-fit items-center">
            <button
              type="button"
              onClick={onPrevious}
              className="h-9 w-9 border border-[#D0D5DD] rounded-[5px] inline-flex justify-center items-center disabled:cursor-not-allowed"
              disabled={
                !checkPermission(
                  PermissionKeys.MANAGE_PEOPLE,
                  accessType,
                  permissions,
                )
              }
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8346 10.0003H4.16797M4.16797 10.0003L10.0013 15.8337M4.16797 10.0003L10.0013 4.16699"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              onClick={onNext}
              disabled={
                isNextWeekButtonDisables() ||
                !checkPermission(
                  PermissionKeys.MANAGE_PEOPLE,
                  accessType,
                  permissions,
                )
              }
              type="button"
              className="h-9 w-9 border border-[#D0D5DD] rounded-[5px] inline-flex justify-center items-center disabled:cursor-not-allowed group fill-slate-400 "
            >
              <svg
                className="h-6 fill-slate-400 stroke-slate-400 group-disabled:stroke-slate-300 group-disabled:fill-slate-300"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16536 10.0003H15.832M15.832 10.0003L9.9987 15.8337M15.832 10.0003L9.9987 4.16699"
                  className="stroke-current"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex gap-10 whitespace-nowrap flex-wrap ml-auto">
          <div className="flex flex-col text-center">
            <p className="text-[#707479]">Total Hours</p>
            <p className="text-[#667085] text-[36px] font-medium">
              {calcHours()?.totalHours}
            </p>
          </div>
          <div className="flex flex-col text-center">
            <p className="text-[#707479]">Billable Hours</p>
            <p className="text-[#667085] text-[36px] font-medium">
              {calcHours()?.billableHours}
            </p>
          </div>
          <div className="flex flex-col text-center">
            <p className="text-[#707479]">Unbillable Hours</p>
            <p className="text-[#667085] text-[36px] font-medium">
              {calcHours()?.unBillableHours}
            </p>
          </div>
          <div className="flex items-center ml-auto">
            <button
              type="button"
              onClick={() =>
                navigate(
                  `/reports/time-sheets?person=${peopleId}&startDate=${data?.[0]?.timeEntries?.[0]?.date}&endDate=${
                    data?.[0]?.timeEntries?.[data?.[0]?.timeEntries?.length - 1]
                      ?.date
                  }`,
                )
              }
              disabled={
                !checkPermission(
                  PermissionKeys.VIEW_REPORTS,
                  accessType,
                  permissions,
                )
              }
              className="h-[30px] px-3 text-sm text-indigo-500 border border-indigo-500 rounded-[5px] disabled:cursor-not-allowed"
            >
              View Full Report
            </button>
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div
          id="timesheetContainer"
          className="relative flex  max-w-full overflow-auto scrollbar w-full px-2 "
        >
          {/* Hours Summary  */}

          <div className=" pt-[48px]">
            <p className="text-sm font-medium  text-slate-600 mb-3">BILLABLE</p>
            <div className="flex flex-col gap-2">
              {data?.map((d, i) => (
                <div
                  key={i}
                  className="pt-2 pb-4 pl-4 pr-10 border-b border-[#D0D5DD] flex justify-center"
                >
                  <label className="inline-flex mx-auto ">
                    <span className="sr-only">Select</span>
                    <input
                      className="form-checkbox cursor-pointer !h-6 !w-6 my-2 disabled:cursor-not-allowed"
                      type="checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        onProjectTaskChange(!d?.isBillable, 'isBillable', i);
                      }}
                      checked={d?.isBillable}
                      disabled={
                        !checkPermission(
                          PermissionKeys.MANAGE_PEOPLE,
                          accessType,
                          permissions,
                        )
                      }
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex  pt-[48px] overflow-visible">
            <div className="w-full">
              <p className="text-sm font-medium  text-slate-600 mb-3 px-2">
                PROJECT<span className="text-rose-500">*</span>
              </p>
              <div className="flex flex-col min-w-fit w-full gap-2">
                {data?.map((d, i) => (
                  <div
                    key={i}
                    className="pt-2 pb-4 px-2 border-b border-[#D0D5DD] flex justify-center"
                  >
                    <DropdownFull
                      options={
                        d?.project?.isDeleted
                          ? [{ ...d?.project }, ...projects]
                          : projects
                      }
                      name="name"
                      setSelected={(name) => {
                        onProjectTaskChange(name, 'projectId', i);
                      }}
                      selected={d?.projectId}
                      // error={validation.touched.type && validation.errors.type}
                      accountStyle
                      placeholder="Select Project"
                      height="!w-full 3xl:max-w-[265px] max-w-[175px] !h-10"
                      className="!h-fit"
                      isTableDropdown
                      bottomOfTable
                      ids={['timesheetContainer']}
                      disabled={
                        !checkPermission(
                          PermissionKeys.MANAGE_PEOPLE,
                          accessType,
                          permissions,
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full">
              <p className="text-sm font-medium  text-slate-600 mb-3 px-2">
                SERVICE<span className="text-rose-500">*</span>
              </p>
              <div className="flex flex-col gap-2 min-w-fit w-full">
                {data?.map((d, i) => (
                  <div
                    key={i}
                    className="pt-2 pb-4 px-2 border-b border-[#D0D5DD] flex justify-center"
                  >
                    <DropdownFull
                      options={
                        d?.productService?.status === 'inactive'
                          ? [{ ...d?.productService }, ...tasks]
                          : tasks
                      }
                      name="name"
                      setSelected={(name) => {
                        onProjectTaskChange(name, 'productServiceId', i);
                      }}
                      selected={d?.productServiceId}
                      // error={validation.touched.type && validation.errors.type}
                      accountStyle
                      placeholder="Select Service"
                      height="!w-full 3xl:max-w-[265px] max-w-[175px] !h-10"
                      className="!h-fit"
                      isTableDropdown
                      bottomOfTable
                      ids={['timesheetContainer']}
                      disabled={
                        !checkPermission(
                          PermissionKeys.MANAGE_PEOPLE,
                          accessType,
                          permissions,
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-fit relative bg-white">
            <div className="flex flex-col gap-x-2 mt-6 max-w-full pb-6  ">
              <div className="flex gap-4 px-2 mb-3">
                {data?.[0]?.timeEntries?.map((t, i) => (
                  <div key={i} className="w-[80px]">
                    <p className="text-base text-slate-400">
                      {moment(t?.date).format('ddd').toUpperCase()}
                    </p>
                    <p className="text-sm font-medium  text-slate-600">
                      {moment(t?.date).format('MMM DD').toUpperCase()}
                    </p>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-2">
                {data?.map((d, i) => (
                  <div key={i} className="flex ">
                    {d?.timeEntries?.map((t, j) => (
                      <div
                        key={j}
                        className="relative pt-2 pb-4 px-2 border-b border-[#D0D5DD] "
                      >
                        <Tooltip
                          className=""
                          content="Please select a Project and Service first"
                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                          tooltipShow={
                            (!d?.productServiceId || !d?.projectId) &&
                            !moment(t?.date).isAfter(moment())
                          }
                        >
                          <input
                            type="text"
                            placeholder="00:00"
                            className={`w-[80px] px-1 text-slate-600 placeholder:text-slate-400 active:border-none focus:!border-none focus:!outline-none focus:!shadow-none !shadow-none h-10 border border-[#D0D5DD] rounded-[0.313rem] !text-[18px] text-center
                          disabled:cursor-not-allowed disabled:opacity-50 ${
                            t?.error
                              ? 'border-[2px] border-rose-400 focus:!border-rose-400'
                              : ''
                          }`}
                            maxLength={5}
                            disabled={
                              !d?.productServiceId ||
                              !d?.projectId ||
                              moment(t?.date).isAfter(moment()) ||
                              !checkPermission(
                                PermissionKeys.MANAGE_PEOPLE,
                                accessType,
                                permissions,
                              )
                            }
                            onChange={(e) => onTimeChange(e.target.value, i, j)}
                            value={t.time}
                            onBlur={() => checkError(t?.time, i, j)}
                          />
                        </Tooltip>
                        <p className="absolute m-0 -mt-[1px] text-[12px] text-rose-400 font-medium">
                          {t?.error}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Row Hours  */}
          <div className="pt-[48px]">
            <p className="text-sm text-nowrap font-medium  text-slate-600 mb-3">
              TOTAL HOURS
            </p>
            <div className="flex flex-col gap-2">
              {data?.map((_, i) => (
                <div
                  key={i}
                  className="pt-2 pb-4 px-2 border-b border-[#D0D5DD]"
                >
                  <div className="inline-flex items-center h-8 text-[18px] my-1">
                    {calcRowHours(i)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Delete Button  */}
          <div className="pt-[82px]">
            <div className="flex flex-col gap-2">
              {data?.map((_, i) => (
                <div
                  key={i}
                  className="pt-2 pb-4 px-2 border-b border-[#D0D5DD]"
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModalOpen(true);
                      setActiveRowIndex(i);
                    }}
                    type="button"
                    className="disabled:cursor-not-allowed ml-[50%] -translate-x-1/2 my-1 h-8 w-8 border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                    disabled={
                      !checkPermission(
                        PermissionKeys.MANAGE_PEOPLE,
                        accessType,
                        permissions,
                      )
                    }
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.177 3.87474C12.0195 3.66093 9.84894 3.55078 7.6849 3.55078C6.40203 3.55078 5.11915 3.61557 3.83628 3.74516L2.51453 3.87474"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.07812 3.22021L6.22067 2.37144C6.32433 1.75592 6.40208 1.2959 7.49706 1.2959H9.1946C10.2896 1.2959 10.3738 1.78184 10.471 2.37792L10.6135 3.22021"
                        stroke="#858D9D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.7841 5.92188L12.3629 12.4464C12.2916 13.4636 12.2333 14.2541 10.4256 14.2541H6.26601C4.45832 14.2541 4.40001 13.4636 4.32874 12.4464L3.90759 5.92188"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.26379 10.6904H9.42136"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.72595 8.09863H9.96554"
                        stroke="#667085"
                        strokeOpacity="0.8"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="font-medium text-center  border-b border-[#D0D5DD] py-4">
          Please Add A Row
        </div>
      )}

      {checkPermission(
        PermissionKeys.MANAGE_PEOPLE,
        accessType,
        permissions,
      ) && (
        <button
          type="button"
          className="btn h-9 mt-4 ml-[50%] -translate-x-1/2 px-[14px] gap-2 text-indigo-500 text-base border border-indigo-500 cursor-pointer"
          onClick={handleAddRow}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00004 14.6673C11.6667 14.6673 14.6667 11.6673 14.6667 8.00065C14.6667 4.33398 11.6667 1.33398 8.00004 1.33398C4.33337 1.33398 1.33337 4.33398 1.33337 8.00065C1.33337 11.6673 4.33337 14.6673 8.00004 14.6673Z"
              stroke="#E48642"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.33337 8H10.6667"
              stroke="#E48642"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10.6663V5.33301"
              stroke="#E48642"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add A Row
        </button>
      )}
    </div>
  );
};

export default TimesheetTab;
