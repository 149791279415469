import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import {
  formatNumberGlobally,
  getCategoriesWithInactiveAccount,
  sortSplitTransactions,
} from 'utils/Utils';
import CategoriesDropdown from './CategoriesDropdown';
import {
  getSingleTransaction,
  splitTransactionsAPI,
} from '../../API/backend_helper';

const SPLIT_TRANSACTION_SCHEMA = {
  amount: {
    value: '',
    error: false,
  },
  categoryAccountId: {
    value: null,
    error: false,
  },
};

const SplitTransactionModal = ({
  isOpen,
  setIsOpen,
  closeSplitTransaction,
  allCategories,
  transaction,
  reloadTransactions,
  isSelectBillTransaction = false,
  handleSplitModalSelectInstance = null,
  isAddTransaction = false,
  splitData = {},
  setSplitData,
  isCategoryError = false,
  type,
  transactionPanel = false,
  setTransactionPanelOpen,
  selectedCategory,
  addTransactionParent,
  addTransactionCategory,
  currency,
  reloadCurrentTransaction,
}) => {
  const modalContent = useRef(null);

  const [splitTransactions, setSplitTransactions] = useState([
    SPLIT_TRANSACTION_SCHEMA,
  ]);
  const [isInactiveCategory, setIsInactiveCategory] = useState(null);
  const [localTransaction, setLocalTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFieldError, setIsFieldError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [parentAmount, setParentAmount] = useState(
    +addTransactionParent
      ? formatNumberGlobally(
          parseFloat(
            type === 'Expense'
              ? addTransactionParent * -1
              : addTransactionParent,
          ),
          true,
        )
      : '',
  );
  // it will be True if splitting transaction will be completed for InstanceModal
  const [isSplittingComplete, setIsSplittingComplete] = useState(false);
  // it will be selected trasaction for Instance Modal
  const [selectedBillTransaction, setSelectedBillTransaction] = useState(null);
  const [initialTransactionSign, setInitialTransactionSign] = useState(
    type === 'Expense' ? '-' : '',
  );

  const loadTransaction = async () => {
    try {
      const res = transaction;
      setLocalTransaction(res);
      const tempSplitTransactions = [];
      tempSplitTransactions.push({
        amount: {
          value: formatNumberGlobally(parseFloat(res?.amount * -1), true) || '',
          error: false,
        },
        categoryAccountId: {
          value: res?.categoryAccountId || null,
          name: res?.categoryAccount?.mask
            ? `${res?.categoryAccount?.name} (...${res?.categoryAccount?.mask})`
            : res?.categoryAccount?.name,
        },
      });
      const tempSplitTransactionsMap = res?.splits?.map((trans) => ({
        amount: {
          value:
            formatNumberGlobally(parseFloat(trans?.amount * -1), true) || '',
          error: false,
        },
        categoryAccountId: {
          value: trans?.categoryAccountId || null,
          name: trans?.categoryAccount?.mask
            ? `${trans?.categoryAccount?.name} (...${trans?.categoryAccount?.mask})`
            : trans?.categoryAccount?.name,
          error: false,
        },
      }));

      tempSplitTransactions.push(...tempSplitTransactionsMap);
      setSplitTransactions(
        tempSplitTransactions,
        formatNumberGlobally(parseFloat(res?.originalAmount * -1), true),
      );
      setParentAmount(
        formatNumberGlobally(parseFloat(res?.originalAmount * -1), true),
      );
      setLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    setSplitTransactions([SPLIT_TRANSACTION_SCHEMA]);
  }, []);

  useEffect(() => {
    if (isAddTransaction && splitTransactions.length === 0 && isOpen) {
      setIsOpen(false);
    }
  }, [splitTransactions, isAddTransaction]);

  useEffect(() => {
    if (!isOpen) {
      setSplitTransactions([SPLIT_TRANSACTION_SCHEMA]);
      setLocalTransaction(null);
      setParentAmount('');
      setIsSplittingComplete(false);
      setSelectedBillTransaction(null);
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (transaction?.id) {
      setLoading(true);
      if (transaction?.split) {
        setLocalTransaction(transaction);
        setParentAmount(
          formatNumberGlobally(parseFloat(transaction?.amount * -1), true),
        );
        loadTransaction();
        return;
      }
      const totalAmount = transaction?.amount * -1;
      const halfAmount = totalAmount / 2;
      const firstHalf = parseFloat(halfAmount).toFixed(4);
      const secondHalf = parseFloat(totalAmount - firstHalf).toFixed(4);
      setIsInactiveCategory(
        transaction?.categoryAccount?.status === 'inactive'
          ? transaction?.categoryAccount
          : null,
      );
      setSplitTransactions([
        {
          amount: {
            value: formatNumberGlobally(firstHalf, true) || '',
            error: false,
          },
          categoryAccountId: {
            value: transaction?.categoryAccountId || null,
            error: false,
          },
        },
        {
          amount: {
            value: formatNumberGlobally(secondHalf, true) || '',
            error: false,
          },
          categoryAccountId: {
            value: null,
            error: false,
          },
        },
      ]);
      setLocalTransaction(transaction);
      setParentAmount(
        formatNumberGlobally(parseFloat(transaction?.amount * -1), true),
      );
      setLoading(false);
    }
  }, [transaction?.id]);

  useEffect(() => {
    if (isAddTransaction && splitData?.splitTransactions?.length > 0) {
      setSplitTransactions(splitData?.splitTransactions);
    }
  }, [isAddTransaction, isCategoryError]);

  useEffect(() => {
    if (isAddTransaction) {
      const halfAmount = parentAmount / 2;
      const firstAmount = parseFloat(halfAmount).toFixed(4);
      const secondAmount = parseFloat(parentAmount - firstAmount).toFixed(4);
      const data = [
        {
          amount: {
            value: formatNumberGlobally(firstAmount, true),
            error: false,
          },
          categoryAccountId: {
            value: addTransactionCategory || null,
            error: false,
          },
        },
        {
          amount: {
            value: formatNumberGlobally(secondAmount, true),
            error: false,
          },
          categoryAccountId: {
            value: addTransactionCategory || null,
            error: false,
          },
        },
      ];
      setSplitTransactions(data);
      if (isAddTransaction) {
        setSplitData({
          parentAmount: formatNumberGlobally(parseFloat(parentAmount), true),
          splitTransactions: data,
        });
      }
    }
  }, [isAddTransaction]);

  const checkIsError = () => {
    let isError = false;
    splitTransactions.forEach((t, i) => {
      Object.keys(t).map((key) => {
        if (!t[key].value) {
          isError = true;
          handleTransactionValueChange(null, key, i);
        }
      });
    });
    if (isError) {
      setIsFieldError(true);
    }
    return isError;
  };

  const handleSave = async () => {
    try {
      if (splitTransactions.some((t) => !t.categoryAccountId.value)) {
        setCategoryError(true);
        return;
      }
      if (checkIsError()) {
        return;
      }
      setCategoryError(false);
      setLoading(true);

      let splitsData = (
        sortSplitTransactions(splitTransactions, type) || []
      ).map((s) => {
        return {
          amount: s.amount.value,
          categoryAccountId: s.categoryAccountId.value,
        };
      });
      const parentSplit = splitsData[0];
      splitsData = splitsData.slice(1);
      let data = {
        parent: {
          categoryAccountId: parentSplit?.categoryAccountId,
          amount: parentSplit?.amount,
          type: localTransaction?.type,
          originalAmount: parseFloat(parentAmount).toFixed(4),
        },
        splits: splitsData,
      };

      if (localTransaction && !localTransaction?.split) {
        if (splitTransactions.length <= 1) {
          setSplitTransactions([]);
          setParentAmount('');
          setIsOpen(false);
          closeSplitTransaction();
          return;
        }
      }

      if (splitTransactions.length <= 1) {
        data = {
          parent: {
            categoryAccountId:
              parentSplit?.categoryAccountId ||
              localTransaction?.categoryAccountId,
          },
          splits: [],
        };
      }

      const res = await splitTransactionsAPI(localTransaction?.id, data);
      if (res?.parent?.id) {
        if (splitTransactions.length <= 1) {
          toast.success('Transaction split successfully removed');
        } else if (reloadCurrentTransaction) {
          reloadCurrentTransaction(true);
        }
        reloadTransactions(10, { scrollToTop: false });
        if (!isSelectBillTransaction) {
          setSplitTransactions([SPLIT_TRANSACTION_SCHEMA]);
          setParentAmount('');
          setIsOpen(false);
          closeSplitTransaction();
        } else {
          setLocalTransaction(res?.parent);
          const tempSplitTransactions = res?.splits?.map((trans) => ({
            id: trans?.id,
            amount: {
              value: trans?.amount,
              error: false,
            },
            categoryAccountId: {
              value: trans?.categoryAccountId || null,
              error: false,
            },
          }));
          setSplitTransactions(tempSplitTransactions);
          setParentAmount(parseFloat(res?.amount).toFixed(4));
          setIsSplittingComplete(true);
        }
      }
      if (transactionPanel) {
        setTransactionPanelOpen(false);
        setSplitTransactions([]);
        setParentAmount('');
        setIsOpen(false);
        closeSplitTransaction();
      }
    } catch (err) {
      if (reloadCurrentTransaction) {
        reloadCurrentTransaction();
      }
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  const handleTransactionValueChange = (value, field, index) => {
    if (field === 'amount' && isNaN(value)) {
      return;
    }

    if (!value.toString().match(/^[-]?\d*\.?\d{0,4}$/)) {
      return;
    }

    let newTotal = 0;

    // if (field === 'amount') {
    //   if (newTotal > parseFloat(parentAmount)) {
    //     return;
    //   }
    // }

    // splitTransactions.forEach((t, i) => {
    //   if (i === index) {
    //     newTotal += parseFloat(value);
    //   } else {
    //     newTotal += parseFloat(t.amount.value);
    //   }
    // });

    setSplitTransactions((prev) => {
      const tempTransactions = [...prev];
      const tempObj = { ...tempTransactions[index] };
      tempObj[field].value = value;
      if (value) {
        tempObj[field].error = false;
      } else {
        tempObj[field].error = true;
      }
      tempTransactions[index] = tempObj;
      if (isAddTransaction) {
        setSplitData({
          parentAmount: formatNumberGlobally(parseFloat(parentAmount), true),
          splitTransactions: tempTransactions,
        });
      }
      return tempTransactions;
    });
  };

  const handleRemoveSplit = (index) => {
    setSplitTransactions((prev) => {
      const newData = prev.filter((t, i) => i !== index);
      if (isAddTransaction) {
        setSplitData({
          parentAmount: formatNumberGlobally(parseFloat(parentAmount), true),
          splitTransactions: newData,
        });
      }
      return newData;
    });
  };

  const totalSplitAmount = splitTransactions.reduce(
    (total, transaction) =>
      total + parseFloat(transaction.amount.value ?? '0') || 0,
    0,
  );
  const equalityCheck =
    totalSplitAmount?.toFixed(4) === parseFloat(parentAmount)?.toFixed(4);

  const calcSum = () => {
    let sum = 0;
    splitTransactions.forEach((t) => {
      sum += parseFloat(t.amount.value ?? 0);
    });
    return sum;
  };

  const onSelectInstance = async () => {
    setLoading(true);
    try {
      const res = await getSingleTransaction(selectedBillTransaction);
      setLoading(false);
      setIsOpen(false);
      // search selected Transaction from splits and parent
      let tempSelectedTransaction = null;
      if (res?.id === selectedBillTransaction) {
        tempSelectedTransaction = { ...res };
      } else {
        res?.splits?.forEach((t, i) => {
          if (t?.id === selectedBillTransaction) {
            tempSelectedTransaction = res?.splits?.[i];
          }
        });
      }
      // callback from parent
      handleSplitModalSelectInstance(tempSelectedTransaction);
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Modal dialog */}
      <div
        ref={modalContent}
        className="flex flex-col justify-between dark:bg-slate-800 rounded w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal header */}
        <div>
          <div className="w-full relative">
            <div
              className={`absolute h-full pl-3 pt-[39px] ${isAddTransaction ? 'pb-[22px]' : 'pb-[24px]'}`}
            >
              <div
                className={`h-full w-[2px] bg-gray-300
                  ${isAddTransaction ? 'bg-slate-600 w-px' : ''}`}
              />
            </div>
            <label className="text-slate-600 text-sm leading-5 font-medium">
              Original Amount
            </label>
            {/* Table */}
            <div
              className={`relative w-full flex justify-between items-center mt-3 rounded-lg text-slate-600 dark:text-slate-400 bg-transparent dark:bg-slate-900/20 border
              ${isAddTransaction ? 'border-none h-12' : 'border-none h-10'}`}
            >
              {isSplittingComplete && (
                <input
                  type="radio"
                  className="form-radio ml-4 mr-1"
                  onChange={() =>
                    setSelectedBillTransaction(localTransaction?.id)
                  }
                  checked={selectedBillTransaction === localTransaction?.id}
                />
              )}
              {isAddTransaction ? (
                <span className="absolute top-[0.92rem] left-4 flex items-center gap-2">
                  {isAddTransaction && type === 'Expense'
                    ? `${currency}`
                    : `+${currency}`}
                  <div className="w-[0.038rem] h-[1.188rem] bg-slate-600"></div>
                </span>
              ) : (
                <span className={`bg-white absolute left-4`}>{currency}</span>
              )}
              <input
                value={!isAddTransaction ? parentAmount : parentAmount}
                onChange={(e) => setParentAmount(e.target.value)}
                type="number"
                placeholder="0.00"
                disabled={transactionPanel}
                className={`h-10 w-full border rounded-[0.313rem] text-slate-600 leading-6 text-base font-normal bg-white px-6 outline-none pr-[50px]
                    ${isAddTransaction ? 'border-[0.031rem] h-12 border-slate-600 hover:border-slate-600 bg-white pl-[3.1rem] pr-[1rem] py-3.5 text-left placeholder:text-slate-600 placeholder:text-opacity-80' : 'border-[#D0D5DD] h-full'}`}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className={`w-full ${isAddTransaction ? 'mb-6' : ''}`}>
              {splitTransactions.map((s, i) => (
                <div
                  key={i}
                  className={`ml-6 relative flex items-center gap-0 sm:gap-4 bg-transparent dark:bg-slate-900/20 rounded-lg ${isAddTransaction ? 'mt-8' : 'pt-2.5'}`}
                >
                  <div
                    className={`absolute top-[72%] left-0.5 w-[0.813rem] h-[2px] bg-gray-300
                        ${isAddTransaction ? 'bg-slate-600 h-px' : ''}`}
                    style={{ transform: 'translate(-100%, -50%)' }}
                  />
                  {isSplittingComplete && (
                    <input
                      type="radio"
                      className="form-radio"
                      onChange={() => setSelectedBillTransaction(s?.id)}
                      checked={selectedBillTransaction === s?.id}
                    />
                  )}
                  <div className="rounded-lg text-slate-500 w-1/2 flex flex-col">
                    <div className="flex flex-col gap-2.5 w-[100%] sm:w-[11.094rem]">
                      <label
                        className={`text-slate-600 text-sm leading-5 font-medium whitespace-nowrap ${
                          s.amount.error ? '!text-rose-500' : ''
                        }  `}
                      >
                        Split Amount
                      </label>
                      <div className="relative">
                        <input
                          placeholder="0.00"
                          value={s.amount.value}
                          onChange={(e) =>
                            handleTransactionValueChange(
                              e.target.value,
                              'amount',
                              i,
                            )
                          }
                          type="number"
                          className={`btn pl-[2.5rem] sm:pl-[3rem] pr-4 py-2.5 justify-between rounded-[0.313rem] font-normal leading-6 bg-white h-10 w-[11.094rem] text-slate-600 placeholder:text-slate-600 placeholder:text-slate-80
                            ${isAddTransaction ? 'border-slate-600 h-12 w-[6rem] sm:w-[17.25rem]' : 'border-gray-300 border-opacity-50 w-full'}  placeholder:text-slate-600 placeholder:text-opacity-80 text-slate-600 ${
                              s.amount.error ? '!border-rose-500' : ''
                            } `}
                          readOnly={isSplittingComplete}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
                          {isAddTransaction && type === 'Expense' ? (
                            <span className="ml-4 mr-2 text-slate-600 text-base leading-6 font-normal">
                              {/* -  */}
                              {currency}
                            </span>
                          ) : (
                            <span className="ml-4 mr-2 text-slate-600 text-base leading-6 font-normal">
                              {/* + */}
                              {currency}
                            </span>
                          )}
                          <span className="border-l-[0.038rem] h-4 bg-slate-600"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-lg text-slate-500 w-1/2 flex flex-col">
                    <div
                      className={`relative flex flex-col ${isAddTransaction ? 'w-[7rem] sm:w-[17.25rem]' : 'w-[8rem] sm:w-[11.094rem]'}`}
                    >
                      <label
                        className={`text-slate-600 text-sm mb-2.5 leading-5 font-medium ${
                          s.categoryAccountId.error ? '!text-rose-500' : ''
                        }  `}
                      >
                        Category
                      </label>
                      <CategoriesDropdown
                        allCategories={
                          isInactiveCategory?.id
                            ? getCategoriesWithInactiveAccount(
                                isInactiveCategory,
                                allCategories,
                              )
                            : allCategories || []
                        }
                        selectedCategoryId={s.categoryAccountId.value}
                        setSelectedCategoryId={(value) =>
                          handleTransactionValueChange(
                            value,
                            'categoryAccountId',
                            i,
                          )
                        }
                        isSetCategoryStyle
                        addTransactionSplit={isAddTransaction}
                        isError={s.categoryAccountId.error}
                        readOnly={isSplittingComplete}
                        height="h-10"
                        split
                        buttonClass={` ${s.categoryAccountId.error ? '!border-rose-500' : ''}`}
                        selectedCategoryName={s.categoryAccountId?.name || ''}
                      />
                      {s?.categoryAccountId?.error && (
                        <span className="absolute font-normal bottom-0 translate-y-full text-xs text-rose-500 mt-[2px]">
                          {s.categoryAccountId.error}
                        </span>
                      )}
                    </div>
                  </div>
                  {!isSplittingComplete && (
                    <svg
                      width="24"
                      height="48"
                      viewBox="0 0 24 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer mt-8 pb-1 w-6 h-12"
                      onClick={() => {
                        handleRemoveSplit(i);
                      }}
                    >
                      <path
                        d="M21 18.1465C17.67 17.8165 14.32 17.6465 10.98 17.6465C9 17.6465 7.02 17.7465 5.04 17.9465L3 18.1465"
                        stroke="#FF4B4B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 17.136L8.72 15.826C8.88 14.876 9 14.166 10.69 14.166H13.31C15 14.166 15.13 14.916 15.28 15.836L15.5 17.136"
                        stroke="#FF4B4B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.8499 21.3057L18.1999 31.3757C18.0899 32.9457 17.9999 34.1657 15.2099 34.1657H8.7899C5.9999 34.1657 5.9099 32.9457 5.7999 31.3757L5.1499 21.3057"
                        stroke="#FF4B4B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3301 28.666H13.6601"
                        stroke="#FF4B4B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 24.666H14.5"
                        stroke="#FF4B4B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              ))}
            </div>
          </div>
          {!isSplittingComplete && !isAddTransaction && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setSplitTransactions((prev) => [
                  ...prev,
                  {
                    amount: {
                      value: 0,
                      error: false,
                    },
                    categoryAccountId: {
                      value: null,
                      error: false,
                    },
                  },
                ]);
              }}
              className="text-sm font-medium leading-5 underline text-indigo-500 mt-2"
              type="button"
            >
              Add Another Split
            </button>
          )}
          {!isSplittingComplete && isAddTransaction && (
            <div className="flex justify-between w-full items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSplitTransactions((prev) => [
                    ...prev,
                    {
                      amount: {
                        value: '',
                        error: false,
                      },
                      categoryAccountId: {
                        value: null,
                        error: false,
                      },
                    },
                  ]);
                }}
                className="text-sm font-medium leading-5 underline text-indigo-500 mt-2"
                type="button"
              >
                Add Another Split
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
                className="text-sm font-medium leading-5 underline text-indigo-500 mt-2"
                type="button"
              >
                Cancel Splitting
              </button>
            </div>
          )}
          {!loading && (
            <>
              {localTransaction && splitTransactions.length <= 1 && (
                <>
                  {localTransaction?.split ? (
                    <p className="text-xs text-center text-red-500">
                      Updating a split transaction with 1 or less splits will
                      convert it to a normal transaction.
                    </p>
                  ) : (
                    <p className="text-xs text-center text-red-500">
                      Saving with 1 or less splits will maintain this
                      transaction in its current form.
                    </p>
                  )}
                </>
              )}
              {!equalityCheck &&
                parentAmount &&
                splitTransactions.length > 1 && (
                  <div className="space-y-1 my-2">
                    <div className="text-sm  flex justify-between w-full">
                      <span className="font-medium">Split Amount:</span>
                      <span>
                        {totalSplitAmount?.toString().charAt(0) === '-'
                          ? '-'
                          : ''}
                        {currency}
                        {totalSplitAmount?.toString().charAt(0) === '-'
                          ? parseFloat(
                              totalSplitAmount?.toString().replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })
                          : parseFloat(totalSplitAmount)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              },
                            )}
                      </span>
                    </div>
                    <div className="text-sm  flex justify-between w-full">
                      <span className="font-medium">Original Amount:</span>
                      <span>
                        {parentAmount?.toString().charAt(0) === '-' ? '-' : ''}
                        {currency}
                        {parentAmount?.toString().charAt(0) === '-'
                          ? parseFloat(
                              parentAmount?.toString().replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })
                          : parseFloat(parentAmount)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 4,
                                maximumFractionDigits: 4,
                              },
                            )}
                      </span>
                    </div>
                    <div className="text-sm  flex justify-between w-full">
                      <span className="font-medium">Difference:</span>
                      <span className="text-rose-400 font-medium ">
                        {(parentAmount - totalSplitAmount)
                          ?.toString()
                          .charAt(0) === '-'
                          ? '-'
                          : ''}
                        {currency}
                        {(parentAmount - totalSplitAmount)
                          ?.toString()
                          .charAt(0) === '-'
                          ? parseFloat(
                              (parentAmount - totalSplitAmount)
                                ?.toString()
                                .replace('-', ''),
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })
                          : parseFloat(
                              parentAmount - totalSplitAmount,
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                      </span>
                    </div>
                  </div>
                )}
              {categoryError &&
                splitTransactions.some((t) => !t.categoryAccountId.value) && (
                  <p className="text-xs text-center text-red-500">
                    Please select a category for each split.
                  </p>
                )}
            </>
          )}
        </div>
        {!isAddTransaction && (
          <div className="flex justify-end mt-auto">
            <button
              type="button"
              className="bg-white rounded-[0.313rem] px-[1.875rem] py-2.5 mr-4 h-10 w-[5.938rem] flex items-center justify-center border border-slate-600 text-slate-600 text-base leading-6"
              disabled={loading}
              onClick={closeSplitTransaction}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-indigo-500 hover:bg-indigo-600 text-white rounded-[0.313rem] px-[1.875rem] py-2.5 text-base shadow-sm h-10 w-[5.938rem] flex items-center justify-center
                  disabled:bg-indigo-300    disabled:cursor-not-allowed"
              disabled={
                loading ||
                (splitTransactions?.length > 1 &&
                  (!isSplittingComplete
                    ? localTransaction?.split &&
                      localTransaction?.splitParentId === null
                      ? calcSum()?.toFixed(4) !==
                        parseFloat(parentAmount)?.toFixed(4)
                      : calcSum()?.toFixed(4) !==
                        (
                          parseFloat(localTransaction?.originalAmount) * -1
                        )?.toFixed(4)
                    : selectedBillTransaction === null ||
                      splitTransactions?.length <= 1))
              }
              onClick={() => {
                if (isSplittingComplete) {
                  onSelectInstance();
                } else {
                  handleSave();
                }
              }}
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              {isSplittingComplete
                ? 'Select Instance'
                : localTransaction?.split
                  ? 'Update'
                  : 'Save'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SplitTransactionModal;
