import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalBasic from 'components/ModalBasic';
import { getAccountsCSVData } from 'API/backend_helper';

const ExportCSVModal = ({ isOpen, setIsOpen }) => {
  const { team } = useSelector((state) => state.Team);

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('active');

  useEffect(() => {
    if (isOpen) {
      setSelected('active');
    }
  }, [isOpen]);

  const convertToCSV = (data) => {
    const headers = [
      'Account Name',
      'Account Number',
      'Type',
      'Sub-Type',
      'Description',
      'Color',
      'System Created',
      'Status',
    ];
    const keys = [
      'name',
      'accountNumber',
      'type',
      'subType',
      'description',
      'color',
      'systemCreated',
      'status',
    ];

    const escapeValue = (value) => {
      if (typeof value === 'string') {
        // Enclose in double quotes and escape any double quotes inside the value
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value ?? '';
    };

    const csvRows = data.map((row) =>
      keys.map((key) => escapeValue(row[key])).join(','),
    );
    csvRows.unshift(headers.join(','));
    return csvRows.join('\n');
  };

  const downloadCSV = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
  };

  const downloadAccountsCSV = async (activeOnly) => {
    try {
      setLoading(true);
      const res = await getAccountsCSVData(activeOnly);
      const csvContent = convertToCSV(res?.data?.results || []);
      downloadCSV(csvContent, `${team?.name?.replace(/ /g, '-')}-accounts.csv`);
      setLoading(false);
      setIsOpen(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };

  return (
    <ModalBasic
      title="Export Accounts CSV"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      <div className="px-5 py-8 grid sm:grid-cols-2 gap-4">
        <div
          className={`p-4 inline-flex items-center justify-center border text-base rounded-[10px] text-center cursor-pointer
           ${selected === 'active' ? 'border-indigo-500 text-indigo-500 bg-indigo-500 bg-opacity-5' : 'border-[#D0D5DD] text-slate-500'}
           `}
          onClick={() => setSelected('active')}
          role="button"
          tabIndex="0"
        >
          Download All Active Accounts
        </div>
        <div
          className={`p-4 inline-flex items-center justify-center border text-base rounded-[10px] text-center cursor-pointer
           ${selected === 'all' ? 'border-indigo-500 text-indigo-500 bg-indigo-500 bg-opacity-5' : 'border-[#D0D5DD] text-slate-500'}
           `}
          onClick={() => setSelected('all')}
          role="button"
          tabIndex="0"
        >
          Download All Active / Inactive Accounts
        </div>
      </div>
      <div className="border-t border-gray-300 h-20 flex items-center justify-end gap-4 px-5">
        <button
          className="w-[7rem] h-11 flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] border border-gray-300 border-opacity-40 shadow bg-white text-slate-600 leading-6"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          type="button"
        >
          Cancel
        </button>
        <button
          className="w-[7rem] h-11
       disabled:bg-indigo-300 disabled:border-indigo-300 disabled:cursor-not-allowed
       flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow text-white leading-6"
          disabled={loading}
          type="button"
          onClick={() => downloadAccountsCSV(selected === 'active')}
        >
          {loading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          Download
        </button>
      </div>
    </ModalBasic>
  );
};

export default ExportCSVModal;
